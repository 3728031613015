<script setup>
import { Icon } from '@iconify/vue'
import { useTimeAgo } from '@vueuse/core'
import { vOnClickOutside } from '@vueuse/components'

import { formatLocalDate } from '@/tools/helpers'

const emit = defineEmits(['close', 'share']);
</script>

<template>
  <div class="pb-10 mx-auto hyphens-auto w-full md:max-w-[60%] relative" v-on-click-outside="[() => emit('close')]">
    <div class="absolute top-0 right-0 z-50">
      <button @click="emit('close')" class="p-[5px] sm:px-[10px]">
        <Icon icon="clarity:window-close-line" class="size-8 sm:size-9 text-white" />
      </button>
    </div>
    <div class="bg-gradient-to-tr from-ww-blue to-black rounded-t-lg p-4 md:p-8 text-white relative overflow-hidden">
      <div class="flex items-center space-x-4">
        <div>
          <h2 class="text-3xl">
            <span class="font-bold capitalize">🚀 App Updates</span>
          </h2>
          <p class="flex items-center mt-2">
            <Icon icon="mdi:clock-outline" class="min-w-5 min-h-5 mr-2" />
            Last update: {{ useTimeAgo(updates.at(0).released_at) }}
          </p>
        </div>
      </div>
    </div>

    <!-- Main Content -->
    <div class="bg-white dark:bg-ww-blue dark:text-white shadow-xl rounded-b-lg">
      <div class="p-4 md:p-8">
        <div>
          <template v-for="(update, index) in updates">
            <component :is="update.component" />
            <div class="text-sm text-gray-600 dark:text-gray-400 mt-4 text-center">
              Released: {{ formatLocalDate(update.released_at) }}
            </div>
            <hr v-if="index + 1 !== updates.length" class="my-10">
          </template>
        </div>
      </div>

      <div class="p-5">
        <button @click="emit('close')" class="w-full bg-ww-blue hover:bg-ww-blue-700 dark:border text-white font-bold py-2 px-4 rounded">
          Close
        </button>
      </div>

      <!-- Adjoined Footer -->
      <div class="border-t border-gray-100 dark:border-ww-blue-200 hidden">
        <div class="px-8 py-4">
          <p class="mt-2 text-sm text-gray-600 dark:text-gray-400"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppUpdates',
  props: ['updates'],
  computed: {
    
  },
  methods: {

  }
}
</script>
