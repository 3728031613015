<script lang="ts" setup>
import { onMounted, useTemplateRef, ref } from 'vue'

import mapboxgl from '@/tools/mapboxgl'
import { useRouter } from 'vue-router'
import { useUserAccount } from '@/logic/Composables/UserAccount'
import {useSubscription} from '@/logic/Composables/Subscription'
import modals from '@/logic/Modals/Helper'

const router = useRouter()
const { loading, errors, register, login, logout, forgotPassword } = useUserAccount()
const subscription = useSubscription()

const mapboxglAccessToken = import.meta.env.VITE_MAPBOX_ACCESS_TOKEN

const input = useTemplateRef('map')

const showForgotPassword = ref(false)
const showResetSuccess = ref(false)

const requirementText = 'A WeatherWise Account and Plus subscription are required to use the Desktop app.'

const formData = ref({
  email: '',
  password: '',
})

const resetViewState = () => {
  showForgotPassword.value = false
  showResetSuccess.value = false
  errors.value = {}
}

const showForgotPasswordView = () => {
  showForgotPassword.value = true
  showResetSuccess.value = false
  errors.value = {}
}

const handleLogin = async () => {
  try {
    const success = await login({
      ...formData.value
    })
    
    if (!success) return

    if (subscription.hasActiveSubscription()) {
      return router.replace({ name: 'home' })
    }
    
    logout()
    return alert(requirementText)
  } catch (e) {
    console.error(e)
  }
}

const handlePasswordReset = async () => {
  try {
    await forgotPassword(formData.value.email)
    showResetSuccess.value = true
  } catch (e) {
    console.error(e)
  }
}

onMounted(() => {
  initializeMap()
})

const initializeMap = () => {
  mapboxgl.accessToken = mapboxglAccessToken
  
  const map = new mapboxgl.Map({
    container: input.value,
    style: 'mapbox://styles/mapbox/satellite-v9',
    projection: 'globe', // Display the map as a globe, since satellite-v9 defaults to Mercator
    zoom: 3.5,
    pitch: 60,
    center: [-90, 40]
  });
  
  map.on('style.load', () => {
    map.setFog({}); // Set the default atmosphere style
  });
  
  // The following values can be changed to control rotation speed:
  
  // At low zooms, complete a revolution every two minutes.
  const secondsPerRevolution = 120;
  // Above zoom level 5, do not rotate.
  const maxSpinZoom = 5;
  // Rotate at intermediate speeds between zoom levels 3 and 5.
  const slowSpinZoom = 3;

  let spinEnabled = true;
  
  function spinGlobe() {
    const zoom = map.getZoom();
    if (spinEnabled && zoom < maxSpinZoom) {
      let distancePerSecond = 360 / secondsPerRevolution;
      if (zoom > slowSpinZoom) {
        // Slow spinning at higher zooms
        const zoomDif =
        (maxSpinZoom - zoom) / (maxSpinZoom - slowSpinZoom);
        distancePerSecond *= zoomDif;
      }
      const center = map.getCenter();
      center.lng -= distancePerSecond;
      // Smoothly animate the map over one second.
      // When this animation is complete, it calls a 'moveend' event.
      map.easeTo({ center, duration: 1000, easing: (n) => n });
    }
  }

  map.on('load', () => {
    map.on('moveend', () => {
      setTimeout(() => {
        spinGlobe();
      }, 1)
    });
    spinGlobe();
  })
}
</script>

<template>
  <div class="map mapboxgl-map" ref="map"></div>
  
  <div class="relative z-5" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="fixed inset-0 z-5 w-screen overflow-y-auto">
      <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
        <div class="relative transform overflow-hidden bg-white/[0.7] dark:bg-ww-blue/[0.7] shadow-[0_4px_30px_rgba(0,0,0,0.1)] backdrop-blur-[7.2px] border rounded-2xl border-solid border-white dark:border-ww-blue-200 px-4 pb-4 pt-5 text-left transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
          <div>
            <div class="flex items-center group my-2 relative">
              <img src="../assets/weatherwise-logo.svg" class="max-w-[36px] ml-auto" alt="WeatherWise Logo">
              <div class="font-[Outfit] text-2xl ml-1 mr-auto relative">
                <span class="text-[#17274F] dark:text-gray-100 font-bold drop-shadow-sm">Weather</span><span class="text-[#F6B91A] font-bold group-hover:text-[#F6B91A]">Wise</span>
                <span class="absolute text-lg font-bold -right-3 -top-2 bg-gradient-to-tl from-[#F6B91A] to-[#FF5722] bg-clip-text text-transparent">+</span>
              </div>
            </div>
          </div>
          <h2 v-if="!showResetSuccess" class="mt-6 text-center text-2xl/9 font-bold tracking-tight text-gray-900 dark:text-gray-100">
            {{ showForgotPassword ? 'Reset Password' : 'Log in to your account' }}
          </h2>
          <p v-if="!showForgotPassword && !showResetSuccess" class="mt-2 text-center text-sm text-gray-600 dark:text-gray-300">
            {{ requirementText }}<br />
            <a href="#" @click.prevent="() => modals.apps()" class="font-semibold text-blue-600 hover:text-blue-500 dark:text-blue-400 dark:hover:text-blue-300">
              Learn how to get started
            </a>
          </p>
          <p v-if="showForgotPassword && !showResetSuccess" class="mt-2 text-center text-sm text-gray-600 dark:text-gray-300">
            Enter your WeatherWise Account email address and we'll send you instructions to reset your password.
          </p>
          <div class="mt-6 sm:mx-auto sm:w-full sm:max-w-sm">
            <Transition
              enter="transition-opacity duration-300"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="transition-opacity duration-300"
              leave-from="opacity-100"
              leave-to="opacity-0"
              mode="out-in"
            >
              <div v-if="showResetSuccess" class="space-y-6 text-center" key="success">
                <div class="flex justify-center">
                  <div class="rounded-full bg-green-100 p-6">
                    <svg class="h-8 w-8 text-green-600" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                    </svg>
                  </div>
                </div>
                <h3 class="text-2xl font-bold">Password Reset Email Sent</h3>
                <p class="text-gray-600">
                  We've sent a link to your email address. Please check your inbox or spam folder and follow the instructions to reset your password.
                </p>
                <div>
                  <button
                    @click="resetViewState"
                    :disabled="loading"
                    class="inline-flex justify-center rounded-md bg-blue-700 hover:bg-blue-800 px-3 py-1.5 text-sm/6 font-semibold text-white shadow-xs hover:bg-blue-800 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 disabled:opacity-50 disabled:cursor-not-allowed"
                  >
                    Return to Login
                  </button>
                </div>
              </div>

              <form v-else-if="!showForgotPassword" class="space-y-6" @submit.prevent="handleLogin" key="login">
                <div>
                  <label for="email" class="block text-sm/6 font-medium dark:text-white">Email address</label>
                  <div class="mt-2">
                    <input type="email" v-model="formData.email" name="email" id="email" autocomplete="email" required class="block w-full rounded-md bg-white px-3 py-1.5 text-base outline-1 -outline-offset-1 outline-gray-300 dark:outline-gray-600 placeholder:text-gray-400 dark:placeholder:text-gray-500 focus:outline-2 focus:-outline-offset-2 focus:outline-blue-600 dark:focus:outline-blue-400 sm:text-sm/6">
                  </div>
                  <p v-if="errors.email" class="mt-1 text-sm text-red-600">{{ errors.email[0] }}</p>
                </div>
                
                <div>
                  <div class="flex items-center justify-between">
                    <label for="password" class="block text-sm/6 font-medium dark:text-white">Password</label>
                  </div>
                  <div class="mt-2">
                    <input type="password" v-model="formData.password" name="password" id="password" autocomplete="current-password" required class="block w-full rounded-md bg-white px-3 py-1.5 text-base outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline-2 focus:-outline-offset-2 focus:outline-blue-600 sm:text-sm/6">
                  </div>
                </div>
                
                <div class="text-sm">
                  <a @click.prevent="showForgotPasswordView" href="#" class="font-semibold text-blue-600 hover:text-blue-500 dark:text-blue-400 dark:hover:text-blue-300">
                    Forgot your password?
                  </a>
                </div>
                
                <div>
                  <button 
                    type="submit" 
                    :disabled="loading"
                    class="flex w-full justify-center rounded-md bg-blue-700 hover:bg-blue-800 dark:bg-blue-600 dark:hover:bg-blue-700 px-3 py-1.5 text-sm/6 font-semibold text-white shadow-xs focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 dark:focus-visible:outline-blue-400 disabled:opacity-50 disabled:cursor-not-allowed"
                  >
                    {{ loading ? 'Logging in...' : 'Log in' }}
                  </button>
                </div>
              </form>

              <form v-else class="space-y-6" @submit.prevent="handlePasswordReset" key="forgot">
                <div>
                  <label for="email" class="block text-sm/6 font-medium dark:text-white">Email address</label>
                  <div class="mt-2">
                    <input type="email" v-model="formData.email" name="email" id="email" autocomplete="email" required class="block w-full rounded-md bg-white px-3 py-1.5 text-base outline-1 -outline-offset-1 outline-gray-300 dark:outline-gray-600 placeholder:text-gray-400 dark:placeholder:text-gray-500 focus:outline-2 focus:-outline-offset-2 focus:outline-blue-600 dark:focus:outline-blue-400 sm:text-sm/6">
                  </div>
                  <p v-if="errors.email" class="mt-1 text-sm text-red-600">{{ errors.email[0] }}</p>
                </div>

                <div>
                  <button 
                    type="submit" 
                    :disabled="loading"
                    class="flex w-full justify-center rounded-md bg-blue-700 hover:bg-blue-800 px-3 py-1.5 text-sm/6 font-semibold text-white shadow-xs hover:bg-blue-800 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 disabled:opacity-50 disabled:cursor-not-allowed"
                  >
                    {{ loading ? 'Sending reset link...' : 'Send Reset Link' }}
                  </button>
                </div>

                <div class="text-sm text-center">
                  <a @click.prevent="resetViewState" href="#" class="font-semibold text-blue-600 hover:text-blue-500">
                    Back to login
                  </a>
                </div>
              </form>
            </Transition>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.map {
  width: 100%;
  height: 100%;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.3s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>