import { toRaw } from 'vue';
import syncMaps from '@mapbox/mapbox-gl-sync-move'

import { useMapsStore } from '@/stores/maps';
import { useMapSettingsStore } from '@/stores/settings/map';

import mapboxgl from '@/tools/mapboxgl'
import { applyIsInteracting } from '@/tools/mapbox-map'
import MapboxPopups from '@/tools/mapbox-popups'
import UrlHash from '@/tools/url-hash'

import App from '@/logic/App'
import ToDesktop from '@/logic/ToDesktop'

import { useMapStyle } from '@/logic/Composables/MapStyle'
import { useWestMode } from '@/logic/WestMode';

import MapStyleNavigation from '@/data/Mapbox/Styles/navigation.json'
import MapStyleWestMode from '@/data/Mapbox/Styles/westmode.json'

import { MAP_PROJECTION_GLOBE, MAP_PROJECTION_MERCATOR } from '@/tools/constants'
import {useSubscription} from '@/logic/Composables/Subscription'

const mapboxglAccessToken = import.meta.env.VITE_MAPBOX_ACCESS_TOKEN

const MAP_LIMIT = 2;

class MapKeeper {
  constructor() {
    this.currentId = 0;

    this.mapTable = {};

    this.components = [];
    this.stores = [];
    this.onLoadFirstMapCallbacks = []
    this.onLoadCallbacks = []

    this.defaultMaxPitch = 85
    this.defaultMinPitch = 0

    this.popups = new MapboxPopups(this);

    this.addComponent('nav-control', (map) => {
      const navControl = new mapboxgl.NavigationControl({
        showZoom: ! App.isNativeApp(),
        visualizePitch: true
      })

      if(this.subscription.hasActiveSubscription() === true) {
        if (! map.hasControl(navControl)) {
          map.addControl(navControl)
        }
      }

      return navControl;
    });

    this.mapsStore = null;
    this.mapSettingsStore = null;

    this.subscription = null;
  }

  setup() {
    this.mapsStore = useMapsStore();
    this.mapSettingsStore = useMapSettingsStore();

    this.subscription = useSubscription()

    // Let's create our first map
    this.push()
  }

  push() {
    const id = this.currentId++;

    if(this.mapsStore.mapsToLoad.length >= MAP_LIMIT) {
      throw new Error('Max. maps reached');
    }

    this.mapsStore.mapsToLoad.push({
      id
    });

    this.forEach(m => {
      if(m.getProjection().name === MAP_PROJECTION_GLOBE) {
        m.setProjection(MAP_PROJECTION_MERCATOR)
      }
    })
  }

  pop(id) {
    if(!(id in this.mapTable)) return;

    // If the active map is the one we're removing
    // Default to the first
    if(this.mapsStore.activeMapId === id) {
      this.mapsStore.activeMapId = this.mapsStore.maps[0].id;
    }

    const map = this.map(id);

    // Destroy components
    // TODO
    // This piece of code might not be needed because
    // The following code will remove all the layers from the map
    // As long as the renderer has 'onRemove' for the layer
    // Then the resources will get cleaned up....

    // for(const key in map.components) {
    //   console.log({key})
    //   try {
    //     map.components[key].destroy()
    //   }
    //   catch(e) {
    //     console.error(`Failed to destroy map component: ${key}`, map.id, e);
    //   }
    // }

    // Destroy the map
    map.off(); // Remove all event listeners.
    map.stop(); // Stop ongoing animations.

    // Remove layers and sources (if applicable).
    const layers = map.getStyle().layers || [];
    for (const layer of layers) {
      if (map.getLayer(layer.id)) {
        map.removeLayer(layer.id);
      }
    }

    const sources = Object.keys(map.getStyle().sources);
    for (const source of sources) {
      if (map.getSource(source)) {
        map.removeSource(source);
      }
    }

    map.remove(); // Destroy the map instance.

    this.mapsStore.maps = this.mapsStore.maps.filter(m => m.id !== id);
    this.mapsStore.mapsToLoad = this.mapsStore.mapsToLoad.filter(m => m.id !== id);

    delete this.mapTable[id];

    if(this.mapsStore.maps.length === 1) {
      if(this.mapSettingsStore.getProjection === MAP_PROJECTION_GLOBE) {
        this.mapboxMapsFirst().setProjection(MAP_PROJECTION_GLOBE)
      }
    }
  }

  defaultMapStyle() {
    // This can be a premium feature
    // Remove traffic layer

    const style = JSON.parse(JSON.stringify(MapStyleNavigation));

    style.layers = style.layers.filter(l => !l.id.startsWith('traffic-')).filter(l => !l.id.startsWith('incident-'))

    if('mapbox-incidents' in style.sources) {
      delete style.sources['mapbox-incidents']
    }
    if('mapbox-traffic' in style.sources) {
      delete style.sources['mapbox-traffic']
    }

    // Now apply user map settings
    const mapStyleMods = this.mapSettingsStore.getMapStyleMods();
    mapStyleMods.forEach(mod => {
      const layer = style.layers.find(l => l.id === mod.layer);

      if(layer === undefined) {
        return console.warn(`Unable to locate layer: ${mod.layer}`);
      }

      if(mod.kind === 'filter') {
        layer[mod.kind] = mod.value;
      }
      else {
        layer[mod.kind][mod.property] = mod.value;
      }
    });

    // Apply satellite tiles
    if (this.mapSettingsStore.tileStyle === 'satellite') {
      const mapStyle = useMapStyle();

      const lowLevelForSatelliteLayerId = mapStyle.insertBeforeLayerIdx(style);

      const satelliteTilesLayer = mapStyle.satelliteTilesLayer();

      style.sources[mapStyle.sourceId()] = mapStyle.satelliteTilesSource();

      style.layers = [
        ...style.layers.slice(0, lowLevelForSatelliteLayerId),
        satelliteTilesLayer,
        ...style.layers.slice(lowLevelForSatelliteLayerId)
      ];
    }

    return style;
  }

  async create(id, container) {
    const westMode = useWestMode();

    // Copy style from existing map
    const style = (() => {
      if(id === 0) {
        if(westMode.isVisible()) {
          return MapStyleWestMode;
        }
        else {
          return this.defaultMapStyle();
        }
      }

      return this.mapboxMapsFirst().getStyle();
    })();

    const USA_bounds = [
      [ -130.689866, 15.458159 ],
      [ -62.009844, 53.686814 ]
    ];

    const bounds = id === 0 ? USA_bounds : this.mapboxMapsFirst().getBounds()

    // We'll limit split view to mercator projection for performance reasons
    const projection = id === 0 ? this.getProjection() : 'mercator';

    const options = {
      accessToken: mapboxglAccessToken,
      container,
      style,
      bounds,
      projection,
      // This is needed for grab the pixels from the canvas
      preserveDrawingBuffer: true,
      fadeDuration: 0,
      crossSourceCollisions: false,
      transformRequest: (url, type) => {
        if (type === 'Image') {
          return {
            url: url,
            headers: {
              Origin: window.location.origin
            }
          }
        }
      }
    };

    if(id === 0) {
      options.hash = 'map';
    }

    return new Promise((resolve, reject) => {
      // instantiate map.  this method runs once after the vue component is mounted to the dom
      const map = new mapboxgl.Map(options)

      // Bind some extra properties
      map.id = id;
      map.components = {};
      map.stores = {};

      // If this map is not the 'master map'
      // ie is an additional map
      if(id > 0) {
        // Copy images from existing style
        map.style.imageManager.images = this.mapboxMapsFirst().style.imageManager.images;

        // Copy event listeners
        for(const eventType in this.mapboxMapsFirst()._delegatedListeners) {
          for(const listener of this.mapboxMapsFirst()._delegatedListeners[eventType]) {
            map.on(eventType, Array.from(listener.layers), listener.listener)
          }
        }
      }

      if(typeof map.touchZoomRotate._touchRotate._isBelowThreshold !== 'function') {
        throw new Error('Unable to monkey patch _isBelowThreshold in touchRotate')
      }

      map.touchZoomRotate._touchRotate._isBelowThreshold = (function(vector) {
        const ROTATION_THRESHOLD = 45; // pixels along circumference of touch circle

        function getBearingDelta(a, b) {
          return a.angleWith(b) * 180 / Math.PI;
        }

        this._minDiameter = Math.min(this._minDiameter, vector.mag());
        const circumference = Math.PI * this._minDiameter;
        const threshold = ROTATION_THRESHOLD / circumference * 360;

        const startVector = this._startVector;
        if (!startVector) return false;

        const bearingDeltaSinceStart = getBearingDelta(vector, startVector);
        return Math.abs(bearingDeltaSinceStart) < threshold;
      }).bind(map.touchZoomRotate._touchRotate)

      applyIsInteracting(map);

      this.removeMapPitchRotation(map);

      // 0.01 is the default
      map.scrollZoom.setZoomRate(0.01 * 2.2)

      // 0.002222 is the default
      map.scrollZoom.setWheelZoomRate(0.002222 * 2.2)

      // If this an additional map
      // Then we'll sync the moves with the first ('master map')
      if(id > 0) {
        syncMaps(this.mapboxMapsFirst(), map);

        this.addMapPitchRotation(map);

        // A hack to fix the pitch on the new map
        this.mapboxMapsFirst().setCenter(this.mapboxMapsFirst().getCenter())
      }

      // Add stores
      this.stores.forEach(c => {
        map.stores[c.key] = c.fn(map);
      })

      map.on('load', () => {
        // Add components
        this.components.forEach(c => {
          map.components[c.key] = c.fn(map);
        })

        // Now that the map has loaded, we'll add it to the maps list
        this.mapsStore.maps.push({
          id
        });

        if (this.userHasPlusSubscription()) {
          this.applyPlusFeaturesToMap(map)
        }

        // We'll init the map after the first map has loaded
        if(map.id === 0) {
          App.init();

          // console.log(111, this.subscription.hasActiveSubscription())
          if(App.isNativeApp()) {
            if (this.subscription.hasActiveSubscription() === true) {
              this.subscription.enablePlusFeatures()
            } else {
              this.subscription.disablePlusFeatures()
            }
          }

          this.onLoadFirstMapCallbacks.forEach(fn => fn(map))
        }
        else {
          this.onLoadCallbacks.forEach(fn => fn(map))
        }

        resolve(map);
      })

      // Set active map when it's clicked
      map.on('click', () => {
        this.mapsStore.activeMapId = map.id;
      })

      if(map.id === 0 && ToDesktop.isToDesktop()) {
        ['moveend', 'zoomend', 'rotateend', 'pitchend'].forEach(eventName => {
          map.on(eventName, () => {
            const urlHash = new UrlHash();
            urlHash.save();
          });
        })
      }

      this.mapTable[id] = map
    });
  }

  onLoadFirstMap(fn) {
    // Check if the first map has loaded
    // If so, immediately execute the 'onLoad' method
    if(this.mapsStore !== null && this.mapsStore.maps.length > 0) return fn(this.mapboxMapsFirst());

    // Else, we'll append to a list that will be executed later
    // Once the map has loaded
    this.onLoadFirstMapCallbacks.push(fn)
  }

  addMapPitchRotation(map) {
    // enable map rotation using right click + drag
    map.dragRotate.enable()

    // enable map rotation using touch rotation gesture
    map.touchZoomRotate.enableRotation()

    // enable map rotation via keyboard
    map.keyboard.enableRotation()

    // enable map tilting using two-finger gesture
    map.touchPitch.enable()

    map.setMaxPitch(this.defaultMaxPitch);

    map.setMinPitch(this.defaultMinPitch);
  }

  removeMapPitchRotation(map) {
    // disable map rotation using right click + drag
    map.dragRotate.disable()

    // disable map rotation using touch rotation gesture
    map.touchZoomRotate.disableRotation()

    // disable map rotation via keyboard
    map.keyboard.disableRotation()

    // disable map tilting using two-finger gesture
    map.touchPitch.disable()

    map.setMaxPitch(0);

    map.setMinPitch(0);
  }

  applyFogToMap(map) {
    // Add night fog
    map.setFog({
      'horizon-blend': 0.02,
      'color': '#242B4B',
      'high-color': '#161B36',
      'space-color': '#0B1026',
      'star-intensity': 0.5
    })
  }

  apply3DBuildingsToMap(map) {
    // Insert the layer beneath any symbol layer.
    const layers = map.getStyle().layers;
    const labelLayerId = layers.find(
        (layer) => layer.type === 'symbol' && layer.layout['text-field']
    ).id;

    // The 'building' layer in the Mapbox Streets
    // vector tileset contains building height data
    // from OpenStreetMap.
    if (! this.getLayer('3d-buildings')) {
      map.addLayer(
        {
          'id': '3d-buildings',
          'source': 'composite',
          'source-layer': 'building',
          'filter': ['==', 'extrude', 'true'],
          'type': 'fill-extrusion',
          'minzoom': 15,
          'paint': {
            'fill-extrusion-color': '#aaa',

            // Use an 'interpolate' expression to
            // add a smooth transition effect to
            // the buildings as the user zooms in.
            'fill-extrusion-height': [
              'interpolate',
              ['linear'],
              ['zoom'],
              15,
              0,
              15.05,
              ['get', 'height']
            ],
            'fill-extrusion-base': [
              'interpolate',
              ['linear'],
              ['zoom'],
              15,
              0,
              15.05,
              ['get', 'min_height']
            ],
            'fill-extrusion-opacity': 0.6
          }
        },
        labelLayerId
      );
    }
  }

  map(id) {
    return this.mapTable[id];
  }

  resetNorthPitch() {
    this.forEach(map => map.resetNorthPitch())
  }

  resetNorth() {
    this.forEach(map => map.resetNorth())
  }

  disableMapRotation() {
    if(this.mapboxMapsFirst() === null) return;

    this.forEach(map => {
      this.removeMapPitchRotation(map)

      if (map.hasControl(map.components['nav-control'])) {
        map.removeControl(map.components['nav-control'])
      }
    })
  }

  enableMapRotation() {
    if(this.mapboxMapsFirst() === null) return;

    this.forEach(map => {
      this.addMapPitchRotation(map)

      if (! map.hasControl(map.components['nav-control'])) {
        map.addControl(map.components['nav-control'])
      }
    })
  }

  applyPlusFeaturesToMap(map) {
    this.applyFogToMap(map);

    this.apply3DBuildingsToMap(map);

    // Globe projection can only be applied to the first map
    if(map.id === 0 && this.mapSettingsStore.getProjection === MAP_PROJECTION_GLOBE) {
      map.setProjection(MAP_PROJECTION_GLOBE);
    }
  }

  applyPlusFeatures() {
    if(! this.userHasPlusSubscription()) return;

    this.enableMapRotation();

    this.forEach(map => this.applyPlusFeaturesToMap(map));
  }

  getProjection() {
    return this.mapSettingsStore.getProjection === MAP_PROJECTION_GLOBE && this.userHasPlusSubscription() ?
      MAP_PROJECTION_GLOBE : MAP_PROJECTION_MERCATOR
  }

  userHasPlusSubscription() {
    return this.subscription.isAtleast(this.subscription.tiers.PLUS)
  }

  activeMap() {
    return this.mapTable[this.mapsStore.activeMapId];
  }

  mapsToLoad() {
    return this.mapsStore.mapsToLoad;
  }

  maps() {
    return this.mapsStore.maps;
  }

  mapsIds() {
    return this.mapsStore.maps.map(m => m.id);
  }

  mapboxMaps() {
    return Object.values(this.mapTable);
  }

  mapboxMapsFirst() {
    if(this.mapsStore.maps.length === 0) return null;

    return this.mapTable[this.mapsStore.maps[0].id];
  }

  forEach(fn) {
    this.mapboxMaps().forEach(fn);
  }

  asyncEach(fn) {
    return Promise.allSettled(this.mapboxMaps().map(fn))
  }

  addComponent(key, fn) {
    this.components.push({
      key,
      fn
    });

    this.mapboxMaps().forEach((map) => {
      map.components[key] = fn(map);
    });
  }

  addStore(key, fn) {
    this.stores.push({
      key,
      fn
    });

    this.mapboxMaps().forEach((map) => {
      map.stores[key] = fn(map);
    });
  }

  onLoad(fn) {
    this.onLoadCallbacks.push(fn)
  }

  isUserInteracting() {
    return this.mapsStore.isUserInteracting;
  }

  // Define multi-map implementatons for the Mapbox API
  getSource(...sourceArgs) {
    if (this.mapboxMapsFirst().getSource(...sourceArgs) === undefined) return undefined
    return {
      setData: (...dataArgs) => {
        for(const id in this.mapTable) {
          this.mapTable[id].getSource(...sourceArgs).setData(...dataArgs);
        }
      },
      setTiles: (...dataArgs) => {
        for(const id in this.mapTable) {
          this.mapTable[id].getSource(...sourceArgs).setTiles(...dataArgs);
        }
      }
    }
  }

  addSource(...args) {
    for(const id in this.mapTable) {
      this.mapTable[id].addSource(...args);
    }
  }

  getLayer(...layerArgs) {
    const layers = {};
    for(const id in this.mapTable) {
      const layer = this.mapTable[id].getLayer(...layerArgs);
      if(layer !== undefined) {
        layers[id] = layer;
      }
    }

    if(Object.keys(layers).length === 0) return undefined;

    return layers;
  }

  removeSource(...args) {
    for(const id in this.mapTable) {
      try {
        this.mapTable[id].removeSource(...args);
      }
      catch(e) {
        // console.error(`Failed to remove source for map: ${id}`, e);
      }
    }
  }

  addLayer(...args) {
    for(const id in this.mapTable) {
      this.mapTable[id].addLayer(...args);
    }
  }

  removeLayer(...args) {
    for(const id in this.mapTable) {
      try {
        this.mapTable[id].removeLayer(...args);
      }
      catch(e) {
        // console.error(`Failed to remove layer for map: ${id}`, e);
      }
    }
  }

  hasImage(...args) {
    return Object.keys(this.mapTable).map(id => this.mapTable[id].hasImage(...args)).every(x => x === true);
  }

  addImage(...args) {
    for(const id in this.mapTable) {
      if(this.mapTable[id].hasImage(args[0])) continue;

      this.mapTable[id].addImage(...args);
    }
  }

  removeImage(...args) {
    for(const id in this.mapTable) {
      try {
        this.mapTable[id].removeImage(...args);
      }
      catch(e) {
        // console.error(`Failed to remove image for map: ${id}`, e);
      }
    }
  }

  async asyncLoadAndAddImage(...args) {
    const promises = [];

    for(const id in this.mapTable) {
      promises.push(this.mapTable[id].asyncLoadAndAddImage(...args));
    }

    return await Promise.allSettled(promises)
  }

  async loadImage(...args) {
    const promises = [];

    for(const id in this.mapTable) {
      if(this.mapTable[id].hasImage(args[0])) continue;

      promises.push(new Promise((resolve, reject) => {
         this.mapTable[id].loadImage(args[0], (error, image) => {
            if(error) {
              args[1](error);
              return reject(error)
            }

            args[1](null, image);

            resolve();
          });
      }));
    }

    return await Promise.allSettled(promises)
  }

  setPaintProperty(...args) {
    for(const id in this.mapTable) {
      this.mapTable[id].setPaintProperty(...args);
    }
  }

  setLayoutProperty(...args) {
    for(const id in this.mapTable) {
      try {
        this.mapTable[id].setLayoutProperty(...args);
      }
      catch(e) {
        // console.error(`Failed to set layout property for map: ${id}`, e);
      }
    }
  }

  setFilter(...args) {
    for(const id in this.mapTable) {
      try {
        this.mapTable[id].setFilter(...args);
      }
      catch(e) {
        // console.error(`Failed to set layout property for map: ${id}`, e);
      }
    }
  }

  setFeatureState(...args) {
    for(const id in this.mapTable) {
      this.mapTable[id].setFeatureState(...args);
    }
  }

  setProjection(...args) {
    for(const id in this.mapTable) {
      this.mapTable[id].setProjection(...args);
    }
  }

  fitBounds(...args) {
    // Because the maps are synced, we only need to fit bounds to the first map
    // And the rest will follow
    this.mapTable[Object.keys(this.mapTable)[0]].fitBounds(...args);
  }

  on(...args) {
    for(const id in this.mapTable) {
      this.mapTable[id].on(...args);
    }
  }

  off(...args) {
    for(const id in this.mapTable) {
      this.mapTable[id].off(...args);
    }
  }

  resize() {
    for(const id in this.mapTable) {
      this.mapTable[id].resize();
    }
  }

  flyTo(...args) {
    this.mapboxMapsFirst().flyTo(...args);
  }

  easeTo(...args) {
    this.mapboxMapsFirst().easeTo(...args);
  }

  triggerRepaint() {
    for(const id in this.mapTable) {
      this.mapTable[id].triggerRepaint();
    }
  }

  unproject(...args) {
    return this.mapTable[Object.keys(this.mapTable)[0]].unproject(...args);
  }
}

export default new MapKeeper();
