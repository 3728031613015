export const MODE_NONE = 0;
export const MODE_REALTIME = 1;
export const MODE_HISTORICAL_TO_REALTIME = 2;

export const APPLE_APP_STORE_URL = 'https://apps.apple.com/us/app/weatherwise-app/id6736407724';
export const GOOGLE_PLAY_STORE_URL = 'https://play.google.com/store/apps/details?id=com.interactiveweather.weatherwise';

export const APP_VERSION_1_2_0 = '1.2.0';
export const APP_VERSION_1_3_0 = '1.3.0';

export const MAP_PROJECTION_MERCATOR = 'mercator'
export const MAP_PROJECTION_GLOBE = 'globe'
