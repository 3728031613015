import { toRaw } from 'vue'
import mapboxgl from 'mapbox-gl'
import moment from 'moment'
import { useModal, useModalSlot } from 'vue-final-modal'
import { useHead } from '@unhead/vue'
import { distance } from '@turf/turf'

import { PAGE_TITLE } from '@/brand'

import api from '@/logic/Api'
import socket from '@/logic/Socket'

import { MODE_NONE, MODE_REALTIME, MODE_HISTORICAL_TO_REALTIME } from '@/tools/constants'

import satellites from '@/data/Satellite/list.js'

import { useAppStore } from '@/stores/app'
import { useSatelliteStore } from '@/stores/satellite'
import { useMapSatelliteStore } from '@/stores/map_satellite'

import SimpleModal from './Modals/Templates/Simple.vue'
import SatelliteProductHelpModal from './Satellite/SatelliteProductHelp.vue'

import MapKeeper from '@/logic/MapKeeper'

export default class Satellite {
  constructor() {
    this.appStore = useAppStore();
    this.satelliteStore = useSatelliteStore()

    this.mode = MODE_NONE;

    this.activeSocketRooms = [];

    this.rasterTileSourceId = 'ww-satellite-raster-tile-source'
    this.rasterTileLayerId = 'ww-satellite-raster-tile-layer'

    this.layers = [
      this.rasterTileLayerId
    ];

    MapKeeper.addLayer({
      id: 'ww-top-middle-satellite-layer',
      type: 'line',
      source: {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: []
        }
      }
    }, "land-structure-polygon")

    MapKeeper.addStore('satellite', (map) => {
      const store = useMapSatelliteStore(map.id);

      return store;
    });

    MapKeeper.onLoad((map) => {
      if(! this.appStore.isSatelliteMode) return;
      if(! this.satelliteStore.anyActive) return;

      const satellite = MapKeeper.mapboxMapsFirst().stores['satellite'].activeSatellite

      const loadedProducts = {};
      MapKeeper.forEach(m => {
        const product = m.stores['satellite'].activeProductCode;

        if(!(product === null || product.length === 0)) {
          loadedProducts[product] = true;
        }
      })

      let product = satellite.products.filter(p => loadedProducts[p.id] === undefined)[0]?.id
      if(product === undefined) {
        product = satellite.products[0].id
      }

      this.turnOnSatellite(map, satellite.id, product);
    })
  }

  removeTileLayer(map) {
    if (map.getLayer(this.rasterTileLayerId)) {
      map.removeLayer(this.rasterTileLayerId);
    }

    if(map.getSource(this.rasterTileSourceId)) {
      map.removeSource(this.rasterTileSourceId);
    }
  }

  addTileLayer(map, satellite, product) {
    // console.log(satellite, product)

    const rasterLayer = {
      type: 'raster',
      tiles: product.tiles,
      tileSize: 256,
      maxzoom: product.max_zoom
    };

    const allBounds = [];

    if(satellite.bounds !== undefined) {
      const bounds1 = new mapboxgl.LngLatBounds([satellite.bounds[0], satellite.bounds[1]], [satellite.bounds[2], satellite.bounds[3]]);

      allBounds.push(bounds1);
    }

    if(satellite.id === 'GOES-West') {
      const bounds2 = new mapboxgl.LngLatBounds([180 + (satellite.bounds[0] + 180), satellite.bounds[1]], [180.0, satellite.bounds[3]]);

      allBounds.push(bounds2);
    }

    map.addSource(this.rasterTileSourceId, rasterLayer)

    // Monkey patch the tileBounds.contains method with our own
    // To consider multiple bounding boxes
    // In the case of GOES-West, we have a bbox the left and right
    // side of the international date line

    function mercatorXfromLng(lng) {
      return (180 + lng) / 360;
    }

    function mercatorYfromLat(lat) {
      return (180 - (180 / Math.PI * Math.log(Math.tan(Math.PI / 4 + lat * Math.PI / 360)))) / 360;
    }

    const s = map.getSource(this.rasterTileSourceId)
    s.tileBounds = {
      contains: (tileID) => {
        const worldSize = Math.pow(2, tileID.z);

        for(let i = 0; i < allBounds.length; ++i) {
          const level = {
            minX: Math.floor(mercatorXfromLng(allBounds[i].getWest()) * worldSize),
            minY: Math.floor(mercatorYfromLat(allBounds[i].getNorth()) * worldSize),
            maxX: Math.ceil(mercatorXfromLng(allBounds[i].getEast()) * worldSize),
            maxY: Math.ceil(mercatorYfromLat(allBounds[i].getSouth()) * worldSize)
          };
          const hit = tileID.x >= level.minX && tileID.x < level.maxX && tileID.y >= level.minY && tileID.y < level.maxY;

          if(hit) return true;
        }

        return false;
      }
    }

    map.addLayer({
      id: this.rasterTileLayerId,
      type: 'raster',
      source: this.rasterTileSourceId,
      paint: {
        // "raster-fade-duration": 0,
        // This disables de-alias
        "raster-resampling": "nearest"
      }
    }, "ww-top-middle-satellite-layer")

    const mapSatelliteStore = map.stores['satellite']
    mapSatelliteStore.setScanDatetime(product.last_update_at)
  }

  fetchList() {
    return api.instance().get(`/satellite/tiles1/list.json`)
  }

  getSatellite(id) {
    return JSON.parse(JSON.stringify(satellites.find(s => s.id === id)));
  }

  async loadSatelliteData(map, satellite, product) {
    const list = await this.fetchList()

    const latestSatellite = list.satellites.find(s => s.id === satellite);
    if(latestSatellite === undefined) return console.error('Unable to locate latest satellite', satellite);

    const latestProduct = latestSatellite.products.find(p => p.id === product);
    if(latestProduct === undefined) return console.error('Unable to locate latest product', product);

    this.removeTileLayer(map);
    this.addTileLayer(map, latestSatellite, latestProduct);
  }

  async turnOnSatellite(map, satelliteId, product = null) {
    const satellite = this.getSatellite(satelliteId);

    useHead({
      title: `${satellite.name} - ${PAGE_TITLE}`
    })

    this.mode = MODE_REALTIME;

    const mapSatelliteStore = map.stores['satellite']

    mapSatelliteStore.setActiveSatellite(satellite)

    if(product === null) {
      product = mapSatelliteStore.activeProductCode;
    }
    else {
      mapSatelliteStore.activeProductCode = product;
    }

    this.satelliteStore.satellites = [satellite];

    const room = `satellite:${satelliteId}:${product}`
    if(! this.activeSocketRooms.includes(room)) {
      this.activeSocketRooms.push(room)
      socket.roomJoin(room)
      socket.on(room, async (data) => {
        console.log('Satellite update', room, data, `Mode: ${this.mode}`)

        if(this.mode === MODE_REALTIME) {
          MapKeeper.forEach(m => {
            const store = m.stores['satellite'];

            if(store.activeSatelliteId === satelliteId && store.activeProductCode === product) {
              // Check that the mapbox source exists...
              const tileSource = m.getSource(this.rasterTileSourceId);
              if(tileSource === undefined) return;

              tileSource.setTiles(data.tiles);
              store.setScanDatetime(data.last_update_at)
            }
          });
        }
      });
    }

    try {
      await this.loadSatelliteData(map, satelliteId, product);
    }
    catch(e) {
      console.log('Failed to load latest satellite data', e);
    }
  }

  changeSatelliteProduct(map, id, productCode) {
    // Check if the product is still active for all maps
    const currentMapStore = map.stores['satellite']

    let productStillActive = false;
    MapKeeper.forEach(m => {
      if(m.id !== map.id) {
        const store = m.stores['satellite'];

        if(store.activeSatelliteId === currentMapStore.activeSatelliteId && store.activeProductCode === currentMapStore.activeProductCode) {
          productStillActive = true;
        }
      }
    });

    if(! productStillActive) {
      const room = `satellite:${currentMapStore.activeSatelliteId}:${currentMapStore.activeProductCode}`
      this.stopListeningToRoom(room)
    }

    this.turnOnSatellite(map, id, productCode)
  }

  stopListeningToRoom(room) {
    socket.roomLeave(room)
    socket.removeAllListeners(room)

    this.activeSocketRooms = this.activeSocketRooms.filter(r => r !== room);
  }

  stopListeningToRooms() {
    this.activeSocketRooms.forEach(room => {
      socket.roomLeave(room)
      socket.removeAllListeners(room)
    })
    this.activeSocketRooms = []
  }

  findBestSatelliteFor(location) {
    let closestSat = null;
    let shortestDistance = Infinity;

    satellites.forEach(sat => {
      const dist = distance(location, sat.area_of_coverage.center, { units: "meters" })
      if (dist < shortestDistance && dist <= sat.area_of_coverage.radius_m) {
        shortestDistance = dist;
        closestSat = sat;
      }
    });

    // TODO
    // If a nearby sat does not exist
    // Then use a layer that covers the whole world
    if(closestSat === null) {
      return satellites[0].id;
    }

    return closestSat.id
  }

  openSatelliteHelpModal(satelliteId) {
    const latestSatellite = satellites.find(s => s.id === satelliteId);
    if(latestSatellite === undefined) return;

    const modal = useModal({
      defaultModelValue: true,
      component: SimpleModal,
      attrs: {
        title: latestSatellite.name
      },
      slots: {
        default: useModalSlot({
          component: SatelliteProductHelpModal,
          attrs: {
            text: latestSatellite.help,
            onClose() {
              modal.close()
            },
          }
        })
      },
    })

    return modal;
  }

  openSatelliteProductHelpModal(satelliteId, productCode) {
    const latestSatellite = satellites.find(s => s.id === satelliteId);
    if(latestSatellite === undefined) return;

    const latestProduct = latestSatellite.products.find(p => p.id === productCode);
    if(latestProduct === undefined) return;

    const modal = useModal({
      defaultModelValue: true,
      component: SimpleModal,
      attrs: {
        title: latestProduct.name
      },
      slots: {
        default: useModalSlot({
          component: SatelliteProductHelpModal,
          attrs: {
            text: latestProduct.help,
            onClose() {
              modal.close()
            },
          }
        })
      },
    })

    return modal;
  }

  show() {
    for(const layerId of this.layers) {
      if (MapKeeper.getLayer(layerId)) MapKeeper.setLayoutProperty(layerId, 'visibility', 'visible');
    }

    const activeSatelliteId = MapKeeper.mapboxMapsFirst().stores['satellite'].activeSatelliteId;

    const satelliteId = activeSatelliteId === '0'
      ? satellites[0].id
      : activeSatelliteId;

    const latestSatellite = satellites.find(s => s.id === satelliteId);
    if(latestSatellite === undefined) return;

    const loadedProducts = {};

    MapKeeper.forEach(m => {
      const store = m.stores['satellite'];

      let product = store.activeProductCode;

      if(product === null || product.length === 0) {
        product = latestSatellite.products.filter(p => loadedProducts[p.id] === undefined)[0]?.id
        if(product === undefined) {
          product = latestSatellite.products[0].id
        }
      }

      loadedProducts[product] = true;

      this.turnOnSatellite(m, satelliteId, product)
    })
  }

  hide() {
    MapKeeper.forEach(m => {
     this.removeTileLayer(m);
    })
    
    this.stopListeningToRooms();

    for(const layerId of this.layers) {
      if (MapKeeper.getLayer(layerId)) MapKeeper.setLayoutProperty(layerId, 'visibility', 'none');
    }
  }
}