import { toRaw } from 'vue'
import { defineStore } from 'pinia'
import moment from 'moment'

import UrlHash from '@/tools/url-hash'

import satellites from '@/data/Satellite/list.js'

const DEFAULT_SATELLITE_ID = '0';
const DEFAULT_PRODUCT_CODE = '';

export const useMapSatelliteStore = (mapId) => {
  const myStore = {
    state: () => ({
      activeSatelliteId: DEFAULT_SATELLITE_ID,
      activeProductCode: DEFAULT_PRODUCT_CODE,
      activeSatellite: null,
      scanDatetime: null,
    }),

    getters: {
      anyActive: (state) => state.activeSatelliteId !== DEFAULT_SATELLITE_ID,
      activeProduct(state) {
        return state.activeSatellite.products.find(p => p.id === state.activeProductCode);
      },
      scanLocalTime: (state) => moment.utc(toRaw(state.scanDatetime)).local().format('HH:mm:ss'),
      scanLocalDatetime: (state) => moment.utc(toRaw(state.scanDatetime)).local().format('YYYY-MM-DD HH:mm:ss'),
      scanIsOld: (state) => moment.utc(toRaw(state.scanDatetime)).isBefore(moment().utc().subtract(2, 'hours')),
      scanIsNew: (state) => moment.utc(toRaw(state.scanDatetime)).isAfter(moment.utc().subtract(30, 'minutes')),
    },

    actions: {
      initSatelliteAndProduct(satellite, product) {
        this.$patch({
          activeSatelliteId: satellite,
          activeProductCode: product
        })
      },

      setActiveSatellite(satellite) {
        const p = {
          activeSatellite: satellite,
          activeSatelliteId: satellite.id
        }

        if(! satellite.products.map(p => p.id).includes(this.activeProductCode)) {
          p.activeProductCode = satellite.products.map(p => p.id)[0];
        }

        this.$patch(p)
      },

      setScanDatetime(dt) {
        this.scanDatetime = dt;
      },

      clearScanDatetime() {
        this.setScanDatetime(null)
      },
    },

    persist: {
      // Store some properties related to radar in the url
      storage: {
        getItem: (key) => {
          if(mapId !== 0) return;
          
          const params = new UrlHash();

          const satelliteId = params.has('sc') ? params.get('sc') : DEFAULT_SATELLITE_ID;
          const productCode = params.has('sp') ? params.get('sp') : DEFAULT_PRODUCT_CODE;

          // Check that the satellite exists
          const satellite = satellites.find(s => s.id === satelliteId);
          if(satellite === undefined) return;

          // Check that the satellite has the product available
          if(! satellite.products.map(p => p.id).includes(productCode)) return;

          const s = store();

          s.initSatelliteAndProduct(satelliteId, productCode)
        },
        setItem: (key, value) => {
          if(mapId !== 0) return;

          const s = store();

          const params = new UrlHash();
          if(s.activeSatelliteId === DEFAULT_SATELLITE_ID) {
            params.delete('sc')
            params.delete('sp')
          }
          else {
            params.set('sc', s.activeSatelliteId)
            params.set('sp', s.activeProductCode)
          }
          params.save();
        }
      }
    },
  };

  const store = defineStore(`map_satellite:${mapId}`, myStore)

  return new store()
};
