<script setup>
import { ref, onMounted, computed, watch, nextTick } from 'vue';
import { useDebounceFn, useNetwork } from '@vueuse/core'
import { storeToRefs } from 'pinia';

import MapboxMap from '@/components/MapboxMap.vue'
import AppLogo from '@/components/AppLogo.vue'
import Outlooks from '@/components/Outlooks.vue'
import Sidebar from '@/components/Sidebar.vue'
import RadarDrawer from '@/components/RadarDrawer.vue'
import SatelliteDrawer from '@/components/SatelliteDrawer.vue'
import Menu from '@/components/Menu.vue'
import OnboardingLayout from '@/components/OnboardingLayout.vue'
import RecordingGlowEffect from '@/components/Extra/RecordingGlowEffect.vue'
import DrawingToolbar from '@/components/DrawingToolbar.vue'
import App from '@/logic/App'
import ExpoBridge from '@/logic/Expo/ExpoBridge'
import { hasWeatherWiseBridge } from '@/logic/Extra/helpers'

import { useAppStore } from '@/stores/app'
import { useWarningsStore } from '@/stores/warnings';
import { useMesoscaleDiscussionsStore } from '@/stores/mesoscale_discussions';
import { useRadarTowersStore } from '@/stores/radar_towers';
import { useDrawingStore } from '@/stores/drawing';
import { usePreferencesStore } from '@/stores/settings/preferences';
import { useRecordingStore } from '@/stores/recording'
import { useSubscriptionStore } from '@/stores/subscription'

import { useDeepLink } from '@/logic/Composables/DeepLink'
import { useSubscription } from '@/logic/Composables/Subscription'
import { usePaywall } from '@/logic/Composables/Paywall'
import { useAppUpdates } from '@/logic/Composables/AppUpdates'

import MapKeeper from '@/logic/MapKeeper'
import modals from '@/logic/Modals/Helper'

import { useHazcams } from '@/logic/Hazcams'

import UrlHash from '@/tools/url-hash'
import { isCanvasColorMajority } from '@/tools/map-canvas'
import { APP_VERSION_1_2_0 } from '@/tools/constants'
import { preventZoomAndTextSelection } from '@/tools/helpers'
import Watermark from '@/components/MapOverlay/Watermark.vue';


preventZoomAndTextSelection();

const preferencesStore = usePreferencesStore()
const { isLeftHanded } = storeToRefs(preferencesStore)

const hashParams = new UrlHash();
// Set the location hash early in the process
hashParams.setFromLocalStorage();

// Onboarding logic...
const currentStep = ref(1)
const onboardingComplete = localStorage.getItem('onboardingComplete')
const showOnboarding = ref(onboardingComplete !== 'true' && hashParams.size <= 1)
const handleOnboardingUpgrade = () => {
  if(App.isNativeApp()) {
    usePaywall().open()
  }
  else {
    modals.upgradeThroughMobile()
  }
}
const handleOnboardingCompletion = () => {
  showOnboarding.value = false
  console.log('Onboarding completed!')
  // Any additional logic for completion
}

const isWeatherWiseBridgeDefined = ref(false);

const appStore = useAppStore();
const radarTowersStore = useRadarTowersStore();
const drawingStore = useDrawingStore()
const subscriptionStore = useSubscriptionStore()

useDeepLink()
const subscription = useSubscription()
const appUpdates = useAppUpdates();

// Check if the onboarding modal will appear first
if(! showOnboarding.value) {
  appUpdates.showModal();
}

// Can't use v-bind because the userback element is outside of the app's root element
function updateUserbackButtonStyle() {
  const styleElement = document.createElement('style');
  styleElement.id = 'userback-button-style';

  if (isLeftHanded.value) {
    styleElement.textContent += `
      .userback-button {
        left: 0.75rem !important;
      }
    `;
  }

  const existingStyle = document.getElementById(styleElement.id);
  if (existingStyle) {
    existingStyle.replaceWith(styleElement);
  } else {
    document.head.appendChild(styleElement);
  }
}

onMounted(() => {
  isWeatherWiseBridgeDefined.value = hasWeatherWiseBridge();
  updateUserbackButtonStyle();
});

const refreshData = useDebounceFn(async () => {
  if(MapKeeper.maps().length === 0 || MapKeeper.mapboxMaps().length === 0) {
    window.location.reload();
    return;
  }

  if (App.isNativeApp() && App.isNativeVersionAtleast(APP_VERSION_1_2_0)) {
    try {
      const customerInfo = await ExpoBridge.customer.getInfo()
      subscriptionStore.setRevenueCatCustomerInfo(customerInfo)

      if (subscription.hasActiveSubscription() !== true) {
        subscription.disablePlusFeatures()
      }

    } catch (error) {
      console.error(`unable to set rc customer info`, error)
    }
  } else if (App.isNativeApp() && !App.isNativeVersionAtleast(APP_VERSION_1_2_0)) {
    MapKeeper.resetNorthPitch()
  }

  if (App.isNativeApp()) {
      // On iOS, there appears to be a bug with the Cache API
      // that leads to Internal errors when it's accessed
      // This can crash the map inside the app and seems to typically
      // happen when the app is returned from the background

      // We'll fix this by trying to access the cache
      // after the app is returned to foreground
      // And if a 'Internal error' exception is thrown,
      // we'll reload the page
      try {
        // It appears that simply opening the 'mapbox-tiles' cache works
        // But then interacting with that specific cache, causes an error

        // Cache key is found here: https://github.com/mapbox/mapbox-gl-js/blob/5c1af30a000e9b0c733eb599f6bf5591d4cc819c/src/util/tile_request_cache.ts#L6
        await (await window.caches.open('mapbox-tiles')).keys();
      }
      catch(e) {
        if(e.message === 'Internal error') {
          window.location.reload();
          return;
        }
      }

      if(MapKeeper.maps().length === 0 || MapKeeper.mapboxMaps().length === 0) {
        window.location.reload();
        return;
      }

      // In the case that the cache API is fine, but we still see a blue canvas
      // Look for the two known "blue" colors in the mapbox canvas, and if they're a majority then reload
      // These blues can be seen when all layers are removed from mapbox
      if (isCanvasColorMajority(MapKeeper.mapboxMapsFirst().getCanvas(), [[11, 16, 38, 255], [36, 43, 75, 255]])) {
        window.location.reload();
        return;
      }

      try {
        // This needs to stay as fetch
        const appMetadata = await (await fetch('/app.json')).json()

        if(appMetadata.hash !== App.hash()) {
          console.log('New version available. Reloading...');

          appStore.updating = true;

          nextTick(() => {
            setTimeout(() => {
              window.location.reload(true);
            }, 800);
          })

          return;
        }
      }
      catch(e) {
        console.log('Failed to get app metadata', e)
      }
  }

  useWarningsStore().load()
  useMesoscaleDiscussionsStore().load()

  useHazcams().update()

  if (App.tropical) {
    App.tropical.fetchAndRenderStorms()
    App.tropical.fetchAndRenderDisturbances()
  }

  if(appStore.mode === 'RADAR') {
    if (radarTowersStore.anyActive && ! radarTowersStore.isPlaying) {
      App.radar.loadLatestRadarDataAll()
    }
  }
  else if(appStore.mode === 'SATELLITE') {
    if (App.satellite) {
      MapKeeper.forEach(m => {
        const store = m.stores['satellite']
        App.satellite.changeSatelliteProduct(m, store.activeSatelliteId, store.activeProductCode)
      })
    }
  }
}, 500)

// window.refreshData = refreshData;

const { isOnline } = useNetwork()

watch(isOnline, (online) => {
  if (online) {
    refreshData()
  }
})

watch(isLeftHanded, (leftHanded) => {
  updateUserbackButtonStyle()
})

// watch(() => subscriptionStore.hasActiveSubscription, (hasActiveSubscription) => {
//   if (hasActiveSubscription === true) {
//     subscription.enablePlusFeatures()
//   } else {
//     subscription.disablePlusFeatures()
//   }
// }, { immediate: true })

window.addEventListener('message', (event) => {
  try {
    if (typeof event.data === 'string') {
      const data = JSON.parse(event.data || event.nativeEvent?.data);
      switch (data.type) {
        case 'REFRESH_DATA':
          console.log('REFRESH_DATA')
          refreshData()
          break;

        case 'DEEP_LINK_UPDATE':
          console.log(`Deep link update`, data.url)
          useAppStore().setDeepLink(data.url)
          break;
      }
    }
  } catch (error) {
    console.error(`Error listening for message`, error)
  }
}, true)

// Drawing logic
const { annotating } = storeToRefs(drawingStore)

const handleColorUpdate = (color) => {
  App.drawing.setLineColor(color)
}

const handleBrushSizeUpdate = (size) => {
  App.drawing.setLineWidth(size)
}

const handleUndo = () => {
  App.drawing.undo()
}

const handleRedo = () => {
  App.drawing.redo()
}

const handleEraserToggle = (isEnabled) => {
  console.log('Eraser toggled', isEnabled)
  if (isEnabled) {
    App.drawing.enableEraser()
  } else {
    App.drawing.disableEraser()
  }
}

const handleLockToggle = (isLocked) => {
  if (isLocked) {
    App.drawing.lockMap()
  } else {
    App.drawing.unlockMap()
  }
}

const handleDestroy = () => {
  App.drawing.clearAnnotations()
}

const handleClose = () => {
  App.drawing.disable()
}

MapKeeper.setup()
</script>

<template>
  <div class="map-all grid grid-cols-1" :class="{
    'sm:grid-cols-2': MapKeeper.mapsToLoad().length === 2 || MapKeeper.mapsToLoad().length === 4,
    'sm:grid-cols-3': MapKeeper.mapsToLoad().length === 3 || MapKeeper.mapsToLoad().length === 6 || MapKeeper.mapsToLoad().length === 9,
    'sm:grid-cols-4': MapKeeper.mapsToLoad().length === 16,
  }">
    <div class="map-container" v-for="(map, index) in MapKeeper.mapsToLoad()">
      <MapboxMap :mapMeta="map" :mapIndex="index" />
    </div>
  </div>

  <!-- Need v-if here to ensure the screenshots work correctly -->
  <AppLogo v-if="! useRecordingStore().screenshot" />
  <Sidebar v-if="! useRecordingStore().screenshot" />
  <Watermark v-show="App.isNativeVersionAtleast(APP_VERSION_1_2_0) &&
    (
        (useRecordingStore().screenshot && App.isAppleNative()) ||
        (useRecordingStore().recording && App.isNativeApp())
    )"
  />

  <Outlooks v-if="appStore.isReady() && appStore.mode === 'OUTLOOKS'" />
  <RadarDrawer v-if="appStore.isReady() && appStore.mode === 'RADAR'" />
  <SatelliteDrawer v-if="appStore.isReady() && appStore.mode === 'SATELLITE'" />

  <Menu />
  <RecordingGlowEffect v-if="!App.isNativeApp() || !App.isNativeVersionAtleast(APP_VERSION_1_2_0)" />
  <OnboardingLayout
    v-if="showOnboarding"
    :current-step="currentStep"
    @update:step="currentStep = $event"
    @upgrade="handleOnboardingUpgrade"
    @completion="handleOnboardingCompletion"
    @close="showOnboarding = false"
  />
  <DrawingToolbar
    v-if="annotating"
    @update:color="handleColorUpdate"
    @update:brushSize="handleBrushSizeUpdate"
    @undo="handleUndo"
    @redo="handleRedo"
    @toggleEraser="handleEraserToggle"
    @toggleLock="handleLockToggle"
    @destroy="handleDestroy"
    @close="handleClose"
  />
</template>

<style>
html,
body {
  overflow: hidden;
}
body {
  background-color: #5D757E;
}
#userback_button_container .userback-button-se[wstyle=circle] {
  @apply right-3 bottom-28 mb-1.5 !important;
  @apply right-3 sm:bottom-8 sm:mb-auto !important;
}

</style>

<style scoped>
.map-all {
  height: 100%;
  width: 100%;
  margin: 0 auto;
}
.map-container {
  position: relative;
  height: 100%;
  width: 100%;
  margin: 0 auto;
}
</style>
