<script setup lang="ts">
import { ref } from 'vue';
import RecipientProfile from './ShareLocationWith/RecipientProfile.vue';
import LocationInfo from './ShareLocationWith/LocationInfo.vue';
import SuccessAnimation from './ShareLocationWith/SuccessAnimation.vue';

defineProps<{
  recipient: {
    name: string;
    avatar: string;
  };
}>();

const emit = defineEmits(['close', 'share']);

const sharing = ref(false);
const showSuccess = ref(false);

const shareLocation = async () => {
  sharing.value = true;
  
  setTimeout(() => {
    showSuccess.value = true;
  }, 1500);
};
</script>

<template>
  <div class="py-6">
    <template v-if="!showSuccess">
      <RecipientProfile
        :name="recipient.name"
        :avatar="recipient.avatar"
      />
      <LocationInfo :recipient-name="recipient.shortName" />
    </template>

    <SuccessAnimation :show="showSuccess" :recipient-name="recipient.shortName" />
  </div>

  <div v-if="!showSuccess" class="flex justify-end gap-3 px-6 py-4 bg-gray-50 rounded-b-lg">
    <button
      @click="() => emit('close')"
      class="px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 rounded-md transition-colors"
    >
      Cancel
    </button>
    <button
      @click="shareLocation"
      :disabled="sharing"
      class="px-6 py-2 text-sm font-bold text-white bg-blue-600 hover:bg-blue-700 rounded-md transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
    >
      {{ sharing ? 'Sharing...' : 'Share Location' }}
    </button>
  </div>
</template>