import { BaseRevenueCat } from '../BaseRevenueCat'
import ExpoBridge from '@/logic/Expo/ExpoBridge'
import App from '@/logic/App'
import { APP_VERSION_1_2_0 } from '@/tools/constants'

export class NativeRevenueCat extends BaseRevenueCat {
    constructor() {
        super()
        this.messageHandler = this.handleMessage.bind(this)
    }

    async install(app) {
        if (!App.isNativeApp() || !App.isNativeVersionAtleast(APP_VERSION_1_2_0)) {
            return
        }

        try {
            const customerInfo = await ExpoBridge.customer.getInfo()
            this.handleCustomerInfoUpdate(customerInfo)
        } catch (e) {
            console.error('Failed to fetch/set revenue cat customer info', e)
        }

        window.addEventListener('message', this.messageHandler, true)
        console.log('RevenueCat Native installed')
    }

    async logIn(userId) {
        try {
            await ExpoBridge.customer.setCustomerUUID(userId)
            console.log('Native RevenueCat login successful', userId)
        } catch (error) {
            console.error('Failed to set RevenueCat customer UUID', error)
            throw error
        }
    }

    async logOut() {
        try {
            await ExpoBridge.customer.clearCustomerUUID()
            this.resetSubscriptionState()
            console.log('Native RevenueCat logout successful')
        } catch (error) {
            console.error('Failed to clear RevenueCat customer UUID', error)
            throw error
        }
    }

    handleMessage(event) {
        try {
            if (typeof event.data === 'string') {
                const data = JSON.parse(event.data || event.nativeEvent?.data)
                if (data.type === 'UPDATE_RC_CUSTOMER_INFO') {
                    console.log('UPDATE_RC_CUSTOMER_INFO', data.rcCustomerInfo)
                    this.handleCustomerInfoUpdate(data.rcCustomerInfo)
                }
            }
        } catch (error) {
            console.error('Error listening for message', error)
        }
    }

    cleanup() {
        window.removeEventListener('message', this.messageHandler, true)
    }
} 