import ExpoBridge from '@/logic/Expo/ExpoBridge'
import { useSubscriptionStore } from '@/stores/subscription'

const usePaywall = () => {
    const open = () => {
        ExpoBridge.paywall.show()
    }

    const logCustomer = () => {
        const subscriptionStore = useSubscriptionStore()
        const debug = JSON.stringify(subscriptionStore.revenueCatCustomerInfo, null, 2)
        console.log(debug)
        window._alert(debug)
    }

    return {
        open,
        logCustomer
    }
}

export {
    usePaywall
}