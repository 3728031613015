import { centroid } from '@turf/turf'

import { useWarningsStore } from '@/stores/warnings'
import { useMesoscaleDiscussionsStore } from '@/stores/mesoscale_discussions'
import { useRadarTowersStore } from '@/stores/radar_towers'

import App from '@/logic/App'
import MapKeeper from '@/logic/MapKeeper'

class Robot {
  constructor() {
    this.warningsStore = useWarningsStore()
    this.mesoscaleDiscussionsStore = useMesoscaleDiscussionsStore()
  }

  async focusRadarOnWarning(warningId, radarProduct) {
    // Load latest warnings
    await this.warningsStore.load();

    const raw = true;
    const feature = this.warningsStore.getFeatureById(warningId, raw);

    if(feature === undefined) {
      throw new Error(`Warning ${warningId} does not exist`);
    }

    console.log(feature)

    // TODO
    // Change this
    App.warnings.setRealtimeUpdates(false);
    App.warnings.renderOnly([feature]);

    // TODO
    // Move this method somewhere else
    // As it has nothing to do with warnings...
    App.warnings.fitBounds(feature);

    // TODO
    // We should come up with something better to hide all the other layers?
    App.mesoscaleDiscussions.hide();

    App.tropical.hide();

    // Turn off storm tracks
    App.radar.settings.storm_tracks = 0;

    console.log(feature, radarProduct)

    await App.radar.turnOnBestRadarForGeometry(feature.geometry, radarProduct);

    MapKeeper.triggerRepaint();
  }

  async focusRadarOnMesoscaleDiscussion(mdId, radarProduct) {
    // Load latest discussions
    await this.mesoscaleDiscussionsStore.load();

    const raw = true;
    const feature = this.mesoscaleDiscussionsStore.getFeatureById(mdId, raw);

    if(feature === undefined) {
      throw new Error(`Mesoscale Discussion ${mdId} does not exist`);
    }

    console.log(feature)

    // TODO
    // Change this
    // Hide all warnings
    App.warnings.setRealtimeUpdates(false);
    App.warnings.hide();

    App.mesoscaleDiscussions.setRealtimeUpdates(false);
    App.mesoscaleDiscussions.renderOnly([feature])
    App.mesoscaleDiscussions.fitBounds(feature);

    App.tropical.hide();

    // Turn off storm tracks
    App.radar.settings.storm_tracks = 0;

    await App.radar.turnOnBestRadarForGeometry(feature.geometry, radarProduct);

    MapKeeper.triggerRepaint();
  }

  async warningScreenshot(warningId, radarProduct = 'REF') {
    await this.focusRadarOnWarning(warningId, radarProduct);

    // For some strange reason, a small delay is needed here...
    setTimeout(async () => {
      await App.screenshot.take();
    }, 100)
  }
  
  async warningVideo(warningId, radarProduct = 'REF', numOfScans = 15, repeats = 3) {
    await this.focusRadarOnWarning(warningId, radarProduct);

    const store = MapKeeper.mapboxMapsFirst().stores['radar'];
    const towerId = store.activeTowerId;
    const product = store.activeProductCode;
    
    try {
      await App.radar.loadHistory(towerId, [product], numOfScans);

      await App.mp4.record()

      await App.radar.playScans(towerId, [product], numOfScans * repeats);

      App.mp4.stop();
    } catch(e) {
      console.error('Failed to buffer radar scans', e);
    }
  }

  async warningGif(warningId, radarProduct = 'REF') {
    await this.focusRadarOnWarning(warningId, radarProduct);

    // For some strange reason, a small delay is needed here...
    setTimeout(async () => {
      await App.gif.record();
    }, 100)

    setTimeout(() => {
      App.gif.stop();
    }, 3000)
  }

  async mesoscaleDiscussionScreenshot(mdId, radarProduct = 'REF') {
    await this.focusRadarOnMesoscaleDiscussion(mdId, radarProduct);

    // For some strange reason, a small delay is needed here...
    setTimeout(async () => {
      await App.screenshot.take();
    }, 100)
  }

  async mesoscaleDiscussionVideo(mdId, radarProduct = 'REF', numOfScans = 15, repeats = 3) {
    await this.focusRadarOnMesoscaleDiscussion(mdId, radarProduct);

    const store = MapKeeper.mapboxMapsFirst().stores['radar'];
    const towerId = store.activeTowerId;
    const product = store.activeProductCode;
    
    try {
      await App.radar.loadHistory(towerId, [product], numOfScans);

      await App.mp4.record()

      await App.radar.playScans(towerId, [product], numOfScans * repeats);

      App.mp4.stop();
    } catch(e) {
      console.error('Failed to buffer radar scans', e);
    }
  }
}

export default Robot
