<script setup lang="ts">
import { ref } from 'vue';
import { Icon } from '@iconify/vue'
import { useWestMode } from '@/logic/WestMode';
import { useRobWestStore } from '@/stores/settings/vendors/robwest'
import { vOnClickOutside } from '@vueuse/components'
import { useDebounceFn } from '@vueuse/core'

defineProps<{
}>();

const westMode = useWestMode();
const robWestStore = useRobWestStore();

const emit = defineEmits(['close', 'share']);

const onWestModeChange = useDebounceFn(() => {
  // console.log('onWestModeChange', robWestStore.isWestModeOn)

  if(robWestStore.isWestModeOn) {
    westMode.show()
  }
  else {
    westMode.hide()
  }
}, 250);
</script>

<template>
  <div class="relative" v-on-click-outside="[() => emit('close')]">
    <div class="absolute top-0 right-0 z-50">
      <button @click="emit('close')" class="p-[5px]">
        <Icon icon="clarity:window-close-line" class="size-8 sm:size-9 text-gray-900 dark:text-white" />
      </button>
    </div>
    <!-- Main Content -->
    <main>
      <!-- West Mode Card -->
      <div class="rounded-lg p-8 mb-8 border shadow-xl transition-colors duration-300 bg-gradient-to-b from-white to-gray-50 dark:from-[#17274F] dark:via-[#1a2f5e] dark:to-[#17274F] text-gray-900 dark:text-white border-gray-200 dark:border-white/10">
        <div class="flex flex-col md:flex-row items-center gap-8">
          <div class="w-40 h-40 rounded-full bg-gradient-to-br from-[#F6B91A] to-[#FF5722] p-1">
            <div class="w-full h-full rounded-full flex items-center justify-center relative overflow-hidden bg-white dark:bg-[#17274F]">
              <img
                src="/test/west_mode.webp"
                class="w-full h-full object-cover"
                alt="West Mode"
              />
            </div>
          </div>
          <div class="flex-1 text-center md:text-left">
            <div class="flex items-center gap-2 justify-center md:justify-start">
              <h2 class="text-3xl font-bold mb-2 bg-gradient-to-r from-[#F6B91A] to-[#FF5722] text-transparent bg-clip-text">
                West Mode
              </h2>
            </div>
            <p class="text-xl mb-6 text-gray-700 font-medium dark:text-white/90">
              Flick the switch to enable West Mode!
            </p>

            <!-- Toggle Switch -->
            <label class="relative inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                class="sr-only peer"
                v-model.number="robWestStore.isWestModeOn"
                :true-value="1"
                :false-value="0"
                @change="onWestModeChange"
              >
              <div class="w-28 h-10 rounded-full bg-gray-200 dark:bg-gray-700/50 peer-checked:after:translate-x-[63px] after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:rounded-full after:h-9 after:w-10 after:transition-all peer-checked:bg-gradient-to-r peer-checked:from-[#F6B91A] peer-checked:to-[#FF5722]"></div>
            </label>

            <!-- Features List -->
            <div class="mt-8">
              <h3 class="text-xl font-semibold mb-4 text-[#FF5722] dark:text-[#F6B91A]">Get Robs's Custom Look:</h3>
              <ul class="space-y-3">
                <li class="flex items-center gap-3">
                  <div class="w-6 h-6 rounded-full bg-gradient-to-r from-[#F6B91A] to-[#FF5722] flex items-center justify-center">
                    <svg class="w-4 h-4 text-white" fill="currentColor" viewBox="0 0 20 20">
                      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                    </svg>
                  </div>
                  <span class="text-gray-700 font-medium dark:text-white/90">Map Style</span>
                </li>
                <li class="flex items-center gap-3">
                  <div class="w-6 h-6 rounded-full bg-gradient-to-r from-[#F6B91A] to-[#FF5722] flex items-center justify-center">
                    <svg class="w-4 h-4 text-white" fill="currentColor" viewBox="0 0 20 20">
                      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                    </svg>
                  </div>
                  <span class="text-gray-700 font-medium dark:text-white/90">Radar Colortables</span>
                </li>
                <li class="flex items-center gap-3">
                  <div class="w-6 h-6 rounded-full bg-gradient-to-r from-[#F6B91A] to-[#FF5722] flex items-center justify-center">
                    <svg class="w-4 h-4 text-white" fill="currentColor" viewBox="0 0 20 20">
                      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                    </svg>
                  </div>
                  <span class="text-gray-700 font-medium dark:text-white/90">Weather Alert Colors</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>