import { defineStore } from 'pinia'

export const useSatelliteStore = defineStore('satellite', {
  state: () => ({
    satellites: [],
  }),

  getters: {
    anyActive: (state) => state.satellites.length > 0,
    activeSatellite: (state) => state.satellites[0],
  },

  actions: {
    clear() {
      this.satellites = [];
    }
  }
})
