<script setup>
import Slider from 'primevue/slider';

// Define the v-model binding
const model = defineModel();
</script>

<template>
    <!-- px-1 is an attempt to fix the handle cropping -->
    <Slider v-model="model"
        class="px-1"
        pt:root:class="!bg-slate-200 !h-[8px] !rounded-md"
        pt:handle:class="!bg-blue-600 hover:!bg-blue-700 before:!bg-blue-600 before:hover:!bg-blue-700 before:!w-4 before:!h-4 before:!border-0"
        pt:startHandler:class="!bg-blue-600 hover:!bg-blue-700 before:!bg-blue-600 before:hover:!bg-blue-700 before:!w-4 before:!h-4 before:!border-0 after:!content-['L'] after:!-ml-4 after:text-white"
        pt:endHandler:class="!bg-blue-600 hover:!bg-blue-700 before:!bg-blue-600 before:hover:!bg-blue-700 before:!w-4 before:!h-4 before:!border-0 after:!content-['H'] after:!-ml-4 after:text-white"
        pr:range:class="!rounded-md"
    />
</template>

<style scoped>

.p-slider {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -webkit-tap-highlight-color: transparent;
    touch-action: none;
    --p-slider-track-background: transparent;
    --p-slider-range-background: transparent;
    --p-slider-handle-focus-ring-color: transparent;
}

.p-slider-handle {
    touch-action: none;  /* Prevent any touch action on handles */
}
</style>