<template>
  <div class="overflow-x-auto relative">
    <table class="w-full text-sm text-left text-gray-700 dark:text-gray-300">
      <thead class="text-xs uppercase bg-gray-100 dark:bg-ww-blue-100">
        <tr>
          <th scope="col" class="py-3 px-6">Shortcut</th>
          <th scope="col" class="py-3 px-6">Description</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(shortcut, key) in shortcuts"
            :key="key"
            class="bg-white dark:bg-ww-blue border-b border-gray-200 dark:border-gray-700">
          <td class="py-3 px-6">
            <div class="flex flex-wrap gap-1">
              <span v-for="(combo, index) in formatShortcut(key)" :key="index"
                    class="px-2 py-1 bg-gray-100 dark:bg-ww-blue-100 rounded text-xs font-mono">
                {{ combo }}
              </span>
            </div>
          </td>
          <td class="py-3 px-6">{{ shortcut.description }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup>
import {useSubscription} from '@/logic/Composables/Subscription'
import { inject } from 'vue'

const subscription = useSubscription();

let shortcuts = {
  ...inject('keyboardShortcuts'),
  '+': { description: 'Zoom map in' },
  '-': { description: 'Zoom map out' },
  'shift++': { description: 'Increase the zoom level by 2' },
  'shift+-': { description: 'Decrease the zoom level by 2' },
  '↑': { description: 'Move map north' },
  '↓': { description: 'Move map south' },
  '←': { description: 'Move map west' },
  '→': { description: 'Move map east' },
  'shift+click+drag': { description: 'Zoom to area' },
}

if (subscription.hasActiveSubscription()) {
  shortcuts = {
    ...shortcuts,
    'shift+↑': { description: 'Increase the pitch by 10 degrees' },
    'shift+↓': { description: 'Decrease the pitch by 10 degrees' },
    'shift+←': { description: 'Decrease the rotation by 15 degrees' },
    'shift+→': { description: 'Increase the rotation by 15 degrees' },
  }
}

const formatShortcut = (shortcut) => {
  return shortcut.split(',').map(combo => {
    return combo.split(/[+&]/).map(key => {
      // Capitalize first letter of each key
      return key.charAt(0).toUpperCase() + key.slice(1)
    }).join(' + ')
  })
}
</script>
