const Paywall = {
  show: () => {
    window.ReactNativeWebView.postMessage(JSON.stringify({
        type: 'OPEN_PAYWALL'
    }));
  },
  close: () => {
    console.warn('This method is not implemented. RevenueCat does not support closing the paywall.');
  }
};

export default Paywall;
