import { toRaw } from 'vue'
import mapboxgl from 'mapbox-gl'
import { colord } from 'colord'
import { centroid, point, bearing, destination, bbox } from '@turf/turf'
import moment from 'moment'
import { useModal, useModalSlot } from 'vue-final-modal'
import { formatTimeAgo } from '@vueuse/core'

import UrlHash from '@/tools/url-hash'
import { renderToPopup } from '@/tools/mapbox-map'
import { uniqueList } from '@/tools/helpers'
import { MODE_NONE, MODE_REALTIME, MODE_HISTORICAL_TO_REALTIME } from '@/tools/constants'
import { CARET_SVG } from '@/tools/svgs'

import nwsMDConfig from '@/data/nws_mesoscale_discussion_config.js'

import { useMesoscaleDiscussionsStore } from '@/stores/mesoscale_discussions'
import { useMesoscaleDiscussionsSettingsStore } from '@/stores/settings/mesoscale_discussions'

import socket from '@/logic/Socket'
import api from '@/logic/Api'
import SimpleModal from './Modals/Templates/Simple.vue'
import CenteredModal from './Modals/Templates/Centered.vue'
import MDModal from './MesoscaleDiscussions/Modal.vue'
import MDNotFoundModal from './MesoscaleDiscussions/NotFound.vue'
import MDHelpModal from './MesoscaleDiscussions/MDHelp.vue'
import MDHelpModalTitle from './MesoscaleDiscussions/MDHelpTitle.vue'

import App from '@/logic/App'
import MapKeeper from '@/logic/MapKeeper'

export default class MesoscaleDiscussions {
  constructor() {
    this.mesoscaleDiscussionsStore = useMesoscaleDiscussionsStore()
    this.settings = useMesoscaleDiscussionsSettingsStore()

    // Initialise the store
    this.mesoscaleDiscussionsStore.init()

    this.realtimeUpdatesEnabled = true;

    this.mode = MODE_NONE;

    this.bufferedMesoscaleDiscussions = [];
    this.bufferedMaxAge = 0;

    this.renderOnlyIds = [];

    this.sourceId = 'ww-mesoscale-discussions-source'

    this.lineOutlineLayerId = 'ww-mesoscale-discussions-outline-layer'
    this.lineLayerId = 'ww-mesoscale-discussions-line-layer'
    this.fillLayerId = 'ww-mesoscale-discussions-fill-layer'

    this.FLASH_MAX_TIME = 10 * 1000;
    this.FLASH_FREQUENCY = 750;

    this.layers = [
      this.lineOutlineLayerId,
      this.lineLayerId,
      this.fillLayerId
    ];

    this.addLayer()

    const MDOnClick = renderToPopup((e) => {
      const features = uniqueList(e.features, feature => feature.properties.id)
      if(features.length == 0) return;

      return () => {
        const container = window.document.createElement('div');

        features.forEach((feature, idx) => {
          const div = window.document.createElement('div');
          let html = `<div class='flex justify-between cursor-pointer'><div class='${idx === features.length-1 ? '' : 'mb-2'}'>`;
          html+=`<div class='font-bold'>Mesoscale Discussion #${feature.properties.number}</div>`;
          html+=`<div>Expires ${formatTimeAgo(new Date(feature.properties.expires_at))}</div>`;
          html+=`</div><div>${CARET_SVG}</div></div>`;
          div.innerHTML = html;
          div.addEventListener('click', () => {
            MapKeeper.popups.clear()

            this.openModal(feature);
          });

          container.appendChild(div)
        });

        return container;
      }
    });

    MapKeeper.on('click', this.fillLayerId, MDOnClick)

    // Subscribe to updates
    this.mesoscaleDiscussionsStore.$subscribe((mutation, state) => {
      const geojson = toRaw(state.geojson)

      this.render(geojson)
    })

    // Render mesoscale discussions already in the store (cached offline)
    this.render(toRaw(this.mesoscaleDiscussionsStore.geojson));

    // Request latest mesoscale discussions
    (async () => {
      await this.mesoscaleDiscussionsStore.load()

      this.mode = MODE_REALTIME;
      
      // TODO
      // Refactor code handling params stored in the URL
      
      // Open md from the url
      // After we've loaded the latest mds
      const params = new UrlHash();
      if(params.has('mdid')) {
        const id = params.get('mdid');

        const feature = this.mesoscaleDiscussionsStore.geojson.features.find(f => f.properties.id === id);

        if(feature !== undefined) {
          if(params.has('mdr') && params.get('mdr') == 1) {
            this.fitBounds(feature);
              
            setTimeout(() => {
              const center = centroid(feature.geometry);
              // Don't need to add await here
              App.radar.turnOnClosestOnlineRadar(center);

              const params = new UrlHash();
              params.delete('mdr')
              params.save()
            }, 500);
          }

          return this.openModal(feature);
        }

        // Warning is no longer active...

        // Try and load from the archive
        await this.fetchFromArchive(id)
      }
    })();

    // Subscribe to mesoscale discussions events
    const newDiscussionsMap = {};

    const room = 'mesoscale-discussions';
    socket.roomJoin(room)
    socket.on(room, async (data) => {
      if(! this.realtimeUpdatesEnabled) return console.log(`Incoming mesoscale discussion, but ignoring due to realtime updates disabled...`);

      console.log('Mesoscale discussion', room, data, `Mode: ${this.mode}`);

      if(this.mode === MODE_REALTIME) {
        await this.mesoscaleDiscussionsStore.push(data);

        newDiscussionsMap[data.properties.id] = {
          at: new Date(),
          flash: true
        }
      }
      else if(this.mode === MODE_HISTORICAL_TO_REALTIME) {
        this.bufferedMesoscaleDiscussions.push(data);

        // Remove expired discussions
        const cutOff = moment.utc();
        cutOff.subtract(this.bufferedMaxAge, 'seconds');

        this.bufferedMesoscaleDiscussions = this.bufferedMesoscaleDiscussions.filter(function(f){
          const expiresAt = moment.utc(f.properties.expires_at);

          return ! expiresAt.isBefore(cutOff);
        });
      }
    })

    // newDiscussionsMap['MCD-3385757432749277330990a57323fdb9'] = {
    //   at: new Date(),
    //   flash: true
    // }

    setInterval(() => {
      // console.log(newDiscussionsMap);

      const now = new Date();
      for(const discussionId in newDiscussionsMap) {
        const w = newDiscussionsMap[discussionId];

        const age = now.getTime() - w.at.getTime();
        const source = this.sourceId;

        if(age > this.FLASH_MAX_TIME) {
          // Set the feature to not flash
          MapKeeper.setFeatureState({ source, id: discussionId }, { flash: false });

          // Delete from map
          delete newDiscussionsMap[discussionId];

          // Continue with the rest
          continue;
        }

        MapKeeper.setFeatureState({ source, id: discussionId }, { flash: w.flash });

        w.flash = !w.flash;
      }
    }, this.FLASH_FREQUENCY);

    const manageVisible = (state) => {
      if(state.visible) {
        this.show();
      }
      else {
        this.hide();
      }
    }

    this.settings.$subscribe((mutation, state) => {
      manageVisible(state)
    });

    manageVisible(this.settings);
  }

  flashFeature(feature) {
    this.setWarningFlash(this.sourceId, feature.properties.id, true);

    setTimeout(() => {
      this.setWarningFlash(this.sourceId, feature.properties.id, false);
    }, this.FLASH_FREQUENCY/1.5)
  }

  setWarningFlash(source, discussionId, flash) {
    console.log(source, discussionId, flash)
    MapKeeper.setFeatureState({ source, id: discussionId }, { flash });
  }

  addLayer() {
    MapKeeper.addSource(this.sourceId, {
      type: 'geojson',
      // This will us the id from the 'properties' as the feature ID
      promoteId: 'id',
      data: {
        type: 'FeatureCollection',
        features: []
      }
    })

    MapKeeper.addLayer({
      id: this.lineLayerId,
      type: 'line',
      source: this.sourceId,
      layout: {
        'line-sort-key': ['get', 'line-sort-key'],
        'line-cap': 'round',
        'line-join': 'round'
      },
      paint: {
        'line-color': [
          'case',
          ['boolean', ['feature-state', 'flash'], false],
          '#FFFFFF',
          ['get', 'line-color']
        ],
        'line-opacity': [
          'case',
          ['boolean', ['feature-state', 'flash'], false],
          1,
          ['get', 'line-opacity']
        ],
        'line-width': [
          'case',
          ['boolean', ['feature-state', 'flash'], false],
          ['*', ['get', 'line-width'], 1.75],
          ['get', 'line-width']
        ]
      }
    }, App.warnings.lineLayerId)

    MapKeeper.addLayer({
      id: this.lineOutlineLayerId,
      type: 'line',
      source: this.sourceId,
      layout: {
        'line-sort-key': ['get', 'line-sort-key'],
        'line-cap': 'round',
        'line-join': 'round'
      },
      paint: {
        'line-color': [
          'case',
          ['boolean', ['feature-state', 'flash'], false],
          '#000000',
          '#FFFFFF'
        ],
        'line-opacity': [
          'case',
          ['boolean', ['feature-state', 'flash'], false],
          1,
          ['get', 'line-opacity']
        ],
        'line-width': [
          'case',
          ['boolean', ['feature-state', 'flash'], false],
          ['+', ['*', ['get', 'line-width'], 1.75], 1],
          ['+', ['get', 'line-width'], 2]
        ]
      }
    }, this.lineLayerId);

    MapKeeper.addLayer({
      id: this.fillLayerId,
      type: 'fill',
      source: this.sourceId,
      layout: {
        'fill-sort-key': ['get', 'fill-sort-key']
      },
      paint: {
        'fill-color': ['get', 'fill-color'],
        'fill-opacity': ['get', 'fill-opacity'],
      }
    }, 'ww-bottom-middle-radar-layer')
  }

  applyPropertiesToFeature(f) {
    const config = nwsMDConfig[f.properties.product]

    let color = config.color;
    if(f?.properties?.tags?.CONCERNING?.includes('Heavy snow') || f?.properties?.tags?.CONCERNING?.includes('Winter mixed precipitation') || f?.properties?.tags?.CONCERNING?.includes('Snow Squall') || f?.properties?.tags?.CONCERNING?.includes('Freezing rain') || f?.properties?.tags?.CONCERNING?.includes('Blizzard')) {
      color = '#0002FF';
    }

    f.properties['line-color'] = color
    f.properties['line-background-color'] = color
    f.properties['line-opacity'] = 1
    f.properties['line-width'] = 2;
    f.properties['line-sort-key'] = 1000 - config.priority

    f.properties['fill-color'] = color
    f.properties['fill-opacity'] = 0.1;
    f.properties['fill-sort-key'] = 1000 - config.priority
    return f
  }

  render(geojson) {
    const features = geojson.features.map((f) => {
      return this.applyPropertiesToFeature(f);
    })

    MapKeeper.getSource(this.sourceId).setData({
      type: 'FeatureCollection',
      features: features
    })
  }

  renderOnly(features) {
    this.renderOnlyIds = features.map(f => f.properties.id);

    this.render({
      features
    })
  }

  openMDHelpModal(code) {
    const config = nwsMDConfig[code]

    const modal = useModal({
      defaultModelValue: true,
      component: SimpleModal,
      attrs: {
        title: config.name
      },
      slots: {
        title: useModalSlot({
          component: MDHelpModalTitle,
          attrs: {
            config,
            onClose() {
              modal.close()
            },
          }
        }),
        default: useModalSlot({
          component: MDHelpModal,
          attrs: {
            config,
            onClose() {
              modal.close()
            },
          }
        })
      },
    })

    return modal;
  }

  openModal(feature) {
    const config = nwsMDConfig[feature.properties.product]

    useModal({
      defaultModelValue: true,
      component: SimpleModal,
      attrs: {
        title: `${config.name} #${feature.properties.number}`,
        onOpened() {
          const params = new UrlHash();
          params.set('mdid', feature.properties.id)
          params.save()
        },
        onClosed() {
          const params = new UrlHash();
          params.delete('mdid')
          params.save()
        },
      },
      slots: {
        default: useModalSlot({
          component: MDModal,
          attrs: {
            feature: feature,
          }
        })
      },
    });
  }

  openNotFoundModal() {
    return useModal({
      defaultModelValue: true,
      component: CenteredModal,
      attrs: {
        title: 'Mesoscale Discussion Not Found',
      },
      slots: {
        default: useModalSlot({
          component: MDNotFoundModal
        })
      },
    })
  }

  async loadHistory(secsToLoad) {
    this.mode = MODE_HISTORICAL_TO_REALTIME;

    this.bufferedMesoscaleDiscussions = [];
    this.bufferedMaxAge = secsToLoad;

    try {
      const geojson = await api.instance().get(`/mesoscale-discussions/USA-${secsToLoad}.geojson`);

      this.bufferedMesoscaleDiscussions = geojson.features;

      // console.log(this.bufferedMesoscaleDiscussions)
    } catch (error) {
      console.log('Failed to load mesoscale discussions archive list', error);
    }
  }

  drawHistory(dt, displayFuture) {
    if(typeof dt === 'string') {
      dt = moment.utc(dt);
    }

    // Assume that the historical mesoscale discussions are ordered by issued datetime

    // First we'll filter the mesoscale discussion that are applicable
    // ie the issued at is before dt
    // the dt is before the expires at

    const latestMesoscaleDiscussionId = {};

    let filterFn = displayFuture ? (f) => {
      if(this.renderOnlyIds.length > 0) {
        if(! this.renderOnlyIds.includes(f.properties.id)) return false;
      }

      const expiresAt = moment.utc(f.properties.expires_at);

      const keep = dt.isBefore(expiresAt);

      if(keep) {
        latestMesoscaleDiscussionId[f.properties.common_id] = f.properties.id;
      }
      
      return keep;
    } : (f) => {
      if(this.renderOnlyIds.length > 0) {
        if(! this.renderOnlyIds.includes(f.properties.id)) return false;
      }

      const issuedAt = moment.utc(f.properties.issued_at);
      const expiresAt = moment.utc(f.properties.expires_at);

      const keep = issuedAt.isBefore(dt) && dt.isBefore(expiresAt);

      if(keep) {
        latestMesoscaleDiscussionId[f.properties.common_id] = f.properties.id;
      }

      return keep;
    };

    const filtered = this.bufferedMesoscaleDiscussions.filter(filterFn).filter(f => {
      // We're going to filter out mesoscale discussions where it's the 'same' mesoscale discussion but not the latest
      return latestMesoscaleDiscussionId[f.properties.common_id] === f.properties.id;
    })

    this.mesoscaleDiscussionsStore.geojson.features = filtered;
  }

  clearBufferedHistoricalState() {
    this.bufferedMesoscaleDiscussions = [];

    if(this.mode !== MODE_REALTIME) {
      this.mesoscaleDiscussionsStore.load();
    }

    this.mode = MODE_REALTIME; 
  }

  async fetchFromArchive(id) {
    try {
      const geojson = await api.instance().get(`/mesoscale-discussions/archive/${id}.geojson`);
      const feature = this.applyPropertiesToFeature(geojson);

      return this.openModal(feature);
    } catch (error) {
      console.log(error)
      
      this.openNotFoundModal();
    }
  }

  show() {
    for(const layerId of this.layers) {
      MapKeeper.setLayoutProperty(layerId, 'visibility', 'visible');
    }
  }

  hide() {
    for(const layerId of this.layers) {
      MapKeeper.setLayoutProperty(layerId, 'visibility', 'none');
    }
  }

  setRealtimeUpdates(b) {
    this.realtimeUpdatesEnabled = b;
  }

  fitBounds(feature) {
    const box = bbox(feature.geometry)

    const sw = new mapboxgl.LngLat(box[0], box[1]);
    const ne = new mapboxgl.LngLat(box[2], box[3]);
    const llb = new mapboxgl.LngLatBounds(sw, ne);

    MapKeeper.fitBounds(llb, {
      padding: window.innerWidth / 8,
      duration: 0
    })
  }

  getMode() {
    return this.mode;
  }
}
