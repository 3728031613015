<script setup lang="ts">
import { Icon } from '@iconify/vue'
import { vOnClickOutside } from '@vueuse/components'
import Plus from '@/components/Labels/Plus.vue'
import {
    shareMap,
    screenshot,
    recordGif,
    recordVideo,
    draw,
    locateMe,
    toggleWatchMyPosition,
    toggleDrivingMode,
    startWakeLock,
    stopWakeLock,
    menuActionState,
    LocationMode
} from '@/logic/MenuActions'
import { ref } from 'vue'
import { storeToRefs } from 'pinia';
import { useRecordingStore } from '@/stores/recording';
import { useDrawingSettingsStore } from '@/stores/settings/drawing';
import { usePreferencesStore } from '@/stores/settings/preferences';
import { useAppStore } from '@/stores/app';
import { useHazcams } from '@/logic/Hazcams';
import { useSpotterNetwork } from '@/logic/SpotterNetwork';
import { useMultipleMaps } from '@/logic/Composables/MultipleMaps';
import { useWestMode } from '@/logic/WestMode';
import Mp4Recorder from '@/logic/Extra/Mp4Recorder'
import { useDebounceFn } from '@vueuse/core'
import { usePaywall } from '@/logic/Composables/Paywall'
import App from '@/logic/App'
import MapKeeper from '@/logic/MapKeeper'
import { locationState } from '@/tools/locationUtils';
import {
    APP_VERSION_1_2_0
} from '@/tools/constants'
import {useSubscription, useUpgradeNotifcation} from '@/logic/Composables/Subscription'
import modals from '@/logic/Modals/Helper'
import { useAccountStore } from '@/stores/user/account'
import { isSupported as isUserAccountSupported, useUserAccount } from '@/logic/Composables/UserAccount'
import ToDesktop from '@/logic/ToDesktop'

const appStore = useAppStore()
const subscription = useSubscription()
const userAccount = useUserAccount()

// Vendors
const { toggle: toggleHazcams, isVisible: isHazcamsVisible } = useHazcams()
const { toggle: toggleSpotterNetworkPositions, isVisible: isSpotterNetworkPositionsVisible } = useSpotterNetwork()
const { showSettings: showWestModeSettings, isVisible: isWestModeVisible } = useWestMode()

// Core functions
const multipleMaps = useMultipleMaps();

const drawingStore = useDrawingSettingsStore()
const { annotating } = storeToRefs(drawingStore)

const preferencesStore = usePreferencesStore()
const { isLeftHanded } = storeToRefs(preferencesStore)

const menuButton = ref<HTMLButtonElement | null>(null)
const { menu_open: menuOpen } = storeToRefs(appStore)
const mapLayersMenuOpen = ref(false)
const navigationMenuOpen = ref(false)
const { recording: isRecording, screenshot: isTakingScreenshot, recording_method } = storeToRefs(useRecordingStore())

const closeMenu = () => {
    appStore.toggleMenu(false)
    mapLayersMenuOpen.value = false
    navigationMenuOpen.value = false
}

const toggleMenu = () => {
    appStore.toggleMenu()
    mapLayersMenuOpen.value = false
    navigationMenuOpen.value = false
}

const debounceToggleSpotterNetworkPositions = useDebounceFn(() => toggleSpotterNetworkPositions(), 300)

const debounceToggleHazcams = useDebounceFn(() => toggleHazcams(), 300)

const stopRecording = () => {
    setTimeout(() => App.mp4.stop(), 1000)
    useRecordingStore().stop()
}

const startRecordingMp4 = () => {
    if (isRecording.value || isTakingScreenshot.value) return;

    menuOpen.value = false;
    recordVideo()
}

const openPaywall = () => {
    // Only available from 1.2.0 onwards
    if (! App.shouldShowFeatures(APP_VERSION_1_2_0)) {
        console.warn(`Feature unavailable in this version`);
        return;
    };

    if(App.isNativeApp()) {
        usePaywall().open()
    }
    else {
        modals.upgradeThroughMobile()
    }
}

const debugCustomerInfo = () => {
    // Only available from 1.2.0 onwards
    if (! App.isNativeVersionAtleast(APP_VERSION_1_2_0)) {
        console.warn(`Feature unavailable in this version`);
        return;
    };

    usePaywall().logCustomer()
}

const openUsage = () => {
    // Only available from 1.2.0 onwards
    if (! App.isNativeVersionAtleast(APP_VERSION_1_2_0)) {
        console.warn(`Feature unavailable in this version`);
        return;
    };

    window._alert(`Cache size: ${window.WeatherWiseBridge.storage.usage.cacheDir.toFixed(2)}MB`)
}

const toggleWakeLock = () => {
    // Only available from 1.2.0 onwards
    if (! App.isNativeVersionAtleast(APP_VERSION_1_2_0)) {
        console.warn(`Feature unavailable in this version`);
        return;
    };

    if (!  useAppStore().wakeLock) {
        startWakeLock()
    } else {
        stopWakeLock()
    }
}

const isNavMode = (mode) => {
    return menuActionState.value.locationMode === mode
}

const openBridgeObject = () => {
    alert(JSON.stringify(window.WeatherWiseBridge, null, 2))
}

const debugDeepLink = () => {
    // Only available from 1.2.0 onwards
    if (! App.isNativeVersionAtleast(APP_VERSION_1_2_0)) {
        console.warn(`Feature unavailable in this version`);
        return;
    };

    alert(useAppStore().deepLink)
}

const toggleMultiMap = () => {
    if (! App.shouldShowFeatures(APP_VERSION_1_2_0)) {
        console.warn(`Feature unavailable in this version`);
        return false;
    }

    if (subscription.isAtleast(subscription.tiers.PLUS)) {
        multipleMaps.toggle(1)
    } else {
        useUpgradeNotifcation('SPLIT_VIEW')
    }
}

const openAuth = () => {
    menuOpen.value = false
    modals.auth()
}

const openUserAccount = () => {
    menuOpen.value = false
    modals.userAccount()
}
</script>

<template>
    <div>
        <Transition name="slide-fade">
            <div v-if="menuOpen"
                 v-on-click-outside="[closeMenu, { ignore: [menuButton] }]"
                 class="fixed sm:absolute bottom-[7.5rem] right-3 w-[50vw] max-w-[calc(100vw-2rem)] z-[27] md:w-64 md:top-20 md:left-3 md:bottom-auto bg-white dark:bg-ww-blue rounded-lg shadow ring-1 ring-black/5 dark:ring-white/10 focus:outline-none flex flex-col-reverse md:flex-col py-1"
                 :class="{ 'left-3 right-auto': isLeftHanded }">
                <div class="py-1 flex flex-col-reverse md:flex-col" role="none">
                    <div class="relative" v-if="false">
                        <!-- Example of a menu item with a submenu -->
                        <button @click.prevent="mapLayersMenuOpen = !mapLayersMenuOpen"
                                class="w-full group flex items-center px-3 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-ww-blue-200 active:text-gray-900 dark:active:text-white outline-none"
                                role="menuitem">
                            <Icon icon="fluent:share-ios-24-filled" class="mr-2 size-5" />
                            <span class="">Map Layers</span>
                            <Icon :icon="mapLayersMenuOpen ? 'mingcute:up-line' : 'mingcute:down-line'"
                                  class="ml-auto size-4" />
                        </button>

                        <div v-if="mapLayersMenuOpen"
                                 class="bg-gray-50 dark:bg-ww-blue-200 py-1">
                            <a href="#"
                                @click.prevent="menuOpen = false; debounceToggleHazcams()"
                                class="flex px-3 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-ww-blue-200 active:text-gray-900 dark:active:text-white pl-12">
                                Hazcams
                                <Icon v-if="isHazcamsVisible()" icon="pajamas:check"
                                        class="ml-auto size-4" />
                            </a>
                            <a href="#"
                                @click.prevent="menuOpen = false; debounceToggleSpotterNetworkPositions()"
                                class="flex px-3 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-ww-blue-200 active:text-gray-900 dark:active:text-white pl-12">
                                Spotter Network
                                <Icon v-if="isSpotterNetworkPositionsVisible()" icon="pajamas:check"
                                        class="ml-auto size-4" />
                            </a>
                        </div>
                    </div>

                    <a href="#" @click.prevent="menuOpen = false; screenshot()"
                       class="group flex items-center px-3 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-ww-blue-200 active:text-gray-900 dark:active:text-white outline-none">
                        <Icon icon="ion:camera" class="mr-2 size-5" />
                        Screenshot
                    </a>
                    <a v-if="Mp4Recorder.isSupported()" href="#" @click.prevent="startRecordingMp4" class="group flex items-center px-3 py-2 text-sm text-gray-700 dark:text-gray-200 active:bg-gray-100 dark:active:bg-ww-blue-200 hover:bg-gray-100 dark:hover:bg-ww-blue-200 active:text-gray-900 dark:active:text-white outline-none" role="menuitem" tabindex="-1">

                        <!-- Active: "text-gray-500", Not Active: "" -->
                        <Icon icon="fluent:video-28-filled" class="mr-2 size-5" />
                        Record MP4
                    </a>
                    <a href="#" @click.prevent="menuOpen = false; shareMap()" class="group flex items-center px-3 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-ww-blue-200 active:text-gray-900 dark:active:text-white outline-none" role="menuitem" tabindex="-1">
                        <!-- Active: "text-gray-500", Not Active: "" -->
                        <Icon icon="fluent:share-ios-24-filled" class="mr-2 size-5" />
                        Share Map
                    </a>
                </div>
                <hr class="dark:border-gray-600">
                <div class="py-1 flex flex-col-reverse md:flex-col" role="none">
                    <button @click.prevent="debounceToggleHazcams()"
                        class="flex px-3 pr-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-ww-blue-200 active:text-gray-900 dark:active:text-white items-center">
                        <img src="@/assets/vendors/hazcams/hazcams-dark.svg" class="mr-2 size-5" />
                        <div>
                            Hazcams
                        </div>
                        <Icon v-if="isHazcamsVisible()" icon="pajamas:check"
                                class="ml-auto size-4" />
                    </button>
                    <button @click.prevent="debounceToggleSpotterNetworkPositions()"
                        class="flex px-3 pr-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-ww-blue-200 active:text-gray-900 dark:active:text-white items-center">
                        <div class="border rounded-full mr-2 p-1 bg-gradient-to-tr from-red-500 to-black white">
                            <Icon icon="game-icons:binoculars"
                                class="ml-auto size-4 text-white" />
                        </div>
                        <div>
                            Spotter Network
                        </div>
                        <Icon v-if="isSpotterNetworkPositionsVisible()" icon="pajamas:check"
                                class="ml-auto size-4" />
                    </button>
                    <button v-if="false" @click.prevent="menuOpen = false; showWestModeSettings()"
                        class="flex px-3 pr-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-ww-blue-200 active:text-gray-900 dark:active:text-white items-center">
                        <img src="/test/west_mode.webp" class="mr-2 size-5 rounded" />
                        <div class="">
                            West Mode
                        </div>
                        <Icon v-if="isWestModeVisible()" icon="pajamas:check"
                                class="ml-auto size-4" />
                    </button>
                </div>
                <hr class="dark:border-gray-600">
                <div class="py-1 flex flex-col-reverse md:flex-col" role="none">
                    <a href="#" @click.prevent="menuOpen = false; toggleMultiMap()" v-if="App.shouldShowFeatures(APP_VERSION_1_2_0)" class="group flex items-center px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-ww-blue-200 active:text-gray-900 dark:active:text-white outline-none" role="menuitem" tabindex="-1" id="menu-item-3">
                        <Icon icon="f7:uiwindow-split-2x1" class="mr-2 size-5" />
                        <span>Split View <Plus class="ml-1" /></span>
                        <Icon v-if="MapKeeper.mapsToLoad().length > 1" icon="pajamas:check"
                                class="ml-auto size-4" />
                    </a>
                    <div class="relative" v-if="! ToDesktop.isToDesktop()">
                        <!-- Example of a menu item with a submenu -->
                        <button @click.prevent="navigationMenuOpen = !navigationMenuOpen"
                                class="w-full group flex items-center px-3 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-ww-blue-200 active:text-gray-900 dark:active:text-white outline-none"
                                role="menuitem">
                            <Icon icon="lets-icons:map-light" class="mr-2 size-5" />
                            <span class="">Navigation</span>
                            <Icon :icon="navigationMenuOpen ? 'mingcute:up-line' : 'mingcute:down-line'"
                                  class="ml-auto size-4" />
                        </button>

                        <div v-if="navigationMenuOpen"
                                 class="bg-gray-50 dark:bg-ww-blue-200 py-1">
                            <button v-if="(App.isNativeVersionAtleast(APP_VERSION_1_2_0))"
                                :disabled="locationState.isLocating"
                                @click.prevent="async () => { await toggleWatchMyPosition(); menuOpen = false; }"
                                class="group w-full flex items-center px-3 py-2 text-[0.8rem] text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-ww-blue-200 active:text-gray-900 dark:active:text-white outline-none">
                                <!-- Active: "text-gray-500", Not Active: "" -->
                                <Icon icon="solar:map-arrow-up-outline" class="mr-2 size-5" />
                                <div>
                                    <span v-if="!locationState.isLocating">Locate Me</span>
                                    <span v-else>Locating...</span>
                                </div>
                                <Icon v-show="isNavMode(LocationMode.STATIC)" icon="pajamas:check"
                                    class="ml-auto size-4" />
                            </button>
                            <!-- Deprecated button: doesn't follow the user -->
                            <button v-else :disabled="locationState.isLocating" @click.prevent="async () => { await locateMe(); menuOpen = false; }" class="group w-full flex items-center px-3 py-2 text-[0.8rem] text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-ww-blue-200 active:text-gray-900 dark:active:text-white outline-none">
                                <!-- Active: "text-gray-500", Not Active: "" -->
                                <Icon icon="solar:map-arrow-up-outline" class="mr-2 size-5" />
                                <span v-if="!locationState.isLocating">Locate Me</span>
                                <span v-else>Locating...</span>
                            </button>
                            <button v-if="(App.isNativeVersionAtleast(APP_VERSION_1_2_0))"
                                :disabled="locationState.isLocating"
                                @click.prevent="async () => { await toggleDrivingMode(); menuOpen = false; }"
                                class="group w-full flex items-center px-3 py-2 text-[0.8rem] text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-ww-blue-200 active:text-gray-900 dark:active:text-white outline-none">
                                <!-- Active: "text-gray-500", Not Active: "" -->
                                <Icon icon="prime:car" class="mr-2 size-5" />
                                <div>
                                    <span v-if="!locationState.isLocating">Storm Chaser Mode</span>
                                    <span v-else>Locating...</span>
                                </div>
                                <Icon v-show="isNavMode(LocationMode.DRIVING)" icon="pajamas:check"
                                    class="ml-auto size-4" />
                            </button>
                        </div>
                    </div>

                    <a href="#" @click.prevent="menuOpen = false; draw()" class="group flex items-center px-3 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-ww-blue-200 active:text-gray-900 dark:active:text-white outline-none" role="menuitem" tabindex="-1">
                        <!-- Active: "text-gray-500", Not Active: "" -->
                        <Icon icon="mingcute:pencil-3-line" class="mr-2 size-5" />
                        <span v-if="!annotating">Draw</span>
                        <span v-else>Stop Drawing</span>
                    </a>
                </div>
                <hr v-if="App.shouldShowFeatures(APP_VERSION_1_2_0) || isUserAccountSupported()" class="dark:border-gray-600">
                <div>
                    <div v-if="App.shouldShowFeatures(APP_VERSION_1_2_0)" class="py-1 flex flex-col-reverse md:flex-col" role="none">
                        <button v-if="!subscription.hasActiveSubscription()" @click="menuOpen = false; openPaywall()" class="group flex items-center px-3 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-ww-blue-200 active:text-gray-900 dark:active:text-white outline-none" role="menuitem" tabindex="-1">
                            <Icon icon="ph:shooting-star-light" class="mr-2 size-5" />
                            <span><strong>Subscribe to</strong> <Plus class="ml-1" /></span>
                        </button>
                        <button v-if="subscription.hasActiveSubscription() && App.isNativeApp()" @click="menuOpen = false; subscription.manage()" class="group flex items-center px-3 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-ww-blue-200 active:text-gray-900 dark:active:text-white outline-none" role="menuitem" tabindex="-1">
                            <Icon icon="mdi:subscriptions" class="mr-2 size-5" />
                            <span>Manage Subscription</span>
                        </button>
                    </div>
                    <div class="py-1 flex flex-col-reverse md:flex-col" role="none" v-if="isUserAccountSupported()">
                        <button v-if="!userAccount.isAuthenticated()" href="#" @click.prevent="openAuth" class="group flex items-center px-3 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-ww-blue-200 active:text-gray-900 dark:active:text-white outline-none">
                            <Icon icon="mdi:account-circle" class="mr-2 size-5" />
                            <span>Log In / Register</span>
                        </button>
                        <button v-else href="#" @click.prevent="openUserAccount" class="group flex items-center px-3 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-ww-blue-200 active:text-gray-900 dark:active:text-white outline-none">
                            <Icon icon="mdi:account-circle" class="mr-2 size-5" />
                            <span>My Account</span>
                        </button>
                    </div>
                </div>
                <!-- <template v-if="App.isNativeApp()">
                    <hr class="dark:border-gray-600">
                    <div class="py-1 flex flex-col-reverse md:flex-col" role="none">
                        <button @click="openPaywall()" class="group flex items-center px-3 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-ww-blue-200 active:text-gray-900 dark:active:text-white outline-none" role="menuitem" tabindex="-1">
                            Open Paywall
                        </button>
                        <button @click="debugCustomerInfo()" class="group flex items-center px-3 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-ww-blue-200 active:text-gray-900 dark:active:text-white outline-none" role="menuitem" tabindex="-1">
                            Debug Customer Info
                        </button>
                        <button @click="openUsage()" class="group flex items-center px-3 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-ww-blue-200 active:text-gray-900 dark:active:text-white outline-none" role="menuitem" tabindex="-1">
                            Inspect Disk Usage
                        </button>
                        <button @click="toggleWakeLock()" class="group flex items-center px-3 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-ww-blue-200 active:text-gray-900 dark:active:text-white outline-none" role="menuitem" tabindex="-1">
                            <span v-if="! useAppStore().wakeLock">Turn On Wake Lock</span>
                            <span v-else>Turn Off Wake Lock</span>
                        </button>
                        <button @click="openBridgeObject()" class="group flex items-center px-3 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-ww-blue-200 active:text-gray-900 dark:active:text-white outline-none" role="menuitem" tabindex="-1">
                            <span>Open Bridge</span>
                        </button>
                        <button @click="debugDeepLink()" class="group flex items-center px-3 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-ww-blue-200 active:text-gray-900 dark:active:text-white outline-none" role="menuitem" tabindex="-1">
                            <span>Debug Deep Link</span>
                        </button>
                    </div>
                </template> -->
            </div>
        </Transition>
        <div>
            <button v-if="!isRecording" @click.prevent="toggleMenu"
                    ref="menuButton"
                    type="button"
                    class="bottom-16 right-3 md:top-4 md:right-auto md:left-3 fixed sm:absolute w-12 h-12 rounded-full transition-all duration-300 focus:outline-none primary-button active:scale-90 z-[19]"
                    :class="{ 'left-3 right-auto': isLeftHanded }">
                <Icon v-if="!menuOpen" icon="mingcute:menu-line" class="inline size-8" />
                <Icon v-else icon="clarity:window-close-line" class="inline size-8" />
            </button>
            <button @click.prevent="stopRecording()"
                    v-else
                    class="bottom-16 right-3 md:top-4 md:right-auto md:left-3 fixed sm:absolute w-12 h-12 rounded-full transition-all duration-300 focus:outline-none bg-red-700 hover:bg-red-800 text-white active:scale-90 z-[19]"
                    :class="{ 'left-3 right-auto': isLeftHanded }">
                <Icon icon="bi:stop-fill" class="inline size-8" />
            </button>
        </div>
    </div>
</template>

<style scoped>
.primary-button {
    background-color: #F6B91A;
    color: #17274F;
    transform-origin: center;
    transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

:root.dark .primary-button {
    background-color: #F6B91A;
    color: #17274F;
}

.primary-button:active {
    background-color: #17274F;
    color: #F6B91A;
    box-shadow: 0 0 0 2px #17274F, 0 0 0 4px #F6B91A;
    transform: scale(0.9);
}

:root.dark .primary-button:active {
    background-color: #F6B91A;
    color: #17274F;
    box-shadow: 0 0 0 2px #F6B91A, 0 0 0 4px #17274F;
}

@media (hover: hover) and (pointer: fine) {
    .primary-button:hover {
        background-color: #17274F;
        color: #F6B91A;
    }

    .primary-button:hover {
        background-color: #F6B91A;
        color: #17274F;
    }
}

/* Slide fade transition */
.slide-fade-enter-active,
.slide-fade-leave-active {
    transition: all 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
    transform-origin: top;
}

.slide-fade-enter-from {
    transform: translateY(10px);
    opacity: 0;
}

.slide-fade-leave-to {
    transform: translateY(10px);
    opacity: 0;
}

/* For the share submenu, we want a slightly softer animation */
.relative .slide-fade-enter-active,
.relative .slide-fade-leave-active {
    transition: all 0.25s cubic-bezier(0.34, 1.56, 0.64, 1);
}

.relative .slide-fade-enter-from {
    transform: translateY(5px);
    opacity: 0;
}

.relative .slide-fade-leave-to {
    transform: translateY(5px);
    opacity: 0;
}

/* Accordion slide transition */
.slide-enter-active,
.slide-leave-active {
    transition: all 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
    transform-origin: top;
}

.slide-enter-from {
    transform: scaleY(0);
    opacity: 0;
}

.slide-leave-to {
    transform: scaleY(0);
    opacity: 0;
}
</style>
