import { getShader, ShaderKey } from "./shaders";
import { LngLat } from "mapbox-gl";

interface UniformData {
    type : number,
    value : any
}
interface UniformPayload {
    [key : string] : UniformData
}

interface AttributeData {
  type : GLenum,
  location : number | null | undefined,
  count : number,
  offset : number | null | undefined,
}

class RenderingContext {
  gl: WebGL2RenderingContext;
  missingUcache : Map<string, number>;
  uCache : Map<WebGLProgram, Map<string, WebGLUniformLocation | null>>;
  traits : Array<AttributeData>;
  curStride : number;

  valuesRenderProgram : WebGLProgram | null;
  colorsTextureProgram : WebGLProgram | null;

  constructor(gl: WebGL2RenderingContext) {
    this.gl = gl;
    this.missingUcache = new Map();
    this.uCache = new Map();
    this.traits = [];
    this.curStride = 0;
    this.targetFbo = null;
    this.vxbQuad = null;

    this.valuesRenderProgram = null;
  }

  private createShader(srcCode: string, shaderType: GLenum) {
    const shader = this.gl.createShader(shaderType);
    if (!shader) {
      console.error(`Failed to create shader: ${shaderType.toString()}`);
      return null;
    }
    this.gl.shaderSource(shader, srcCode);
    this.gl.compileShader(shader);
    const log = this.gl.getShaderInfoLog(shader);

    if (log?.length) {
      console.log(log);
    }

    return shader;
  }

  createProgram(vertexSource: string, fragmentSource: string) {
    const vertexShader = this.createShader(vertexSource, this.gl.VERTEX_SHADER);
    const fragmentShader = this.createShader(
      fragmentSource,
      this.gl.FRAGMENT_SHADER
    );

    if (!vertexShader || !fragmentShader) return null;
    const program = this.gl.createProgram();
    if (!program) {
      console.error("Failed to create shader program!");
      return null;
    }

    this.gl.attachShader(program, vertexShader);
    this.gl.attachShader(program, fragmentShader);

    let success = false;
    success = this.gl.getShaderParameter(vertexShader, this.gl.COMPILE_STATUS);
    if (!success) {
      throw (
        "Could not compile shader:" + this.gl.getShaderInfoLog(vertexShader)
      );
    }
    success = this.gl.getShaderParameter(
      fragmentShader,
      this.gl.COMPILE_STATUS
    );
    if (!success) {
      throw (
        "Could not compile shader:" + this.gl.getShaderInfoLog(fragmentShader)
      );
    }

    this.gl.linkProgram(program);
    const log = this.gl.getProgramInfoLog(program);

    success = this.gl.getProgramParameter(program, this.gl.LINK_STATUS);
    if (!success) {
      throw "Could not link shader program: " + log;
    } else if (log?.length) {
      console.log(log);
    }

    this.gl.deleteShader(vertexShader);
    this.gl.deleteShader(fragmentShader);

    return program;
  }

  setTexture(tex : WebGLTexture, unit: number) {
      this.gl.activeTexture(this.gl.TEXTURE0 + unit);
      this.gl.bindTexture(this.gl.TEXTURE_2D, tex);
  }

  setUniforms(program : WebGLProgram, uniforms : UniformPayload) {
    this.gl.useProgram(program);
    Object.keys(uniforms).forEach((key) => {
        var uniform = uniforms[key];

        if(!this.uCache.get(program)) {
            this.uCache.set(program, new Map());
        }
        let uniform_map = this.uCache.get(program);
        if(uniform_map) {
            let location = uniform_map.get(key);
            if(!location) {
                let gl_loc = this.gl.getUniformLocation(program, key);
                uniform_map.set(key, gl_loc);
                location = gl_loc;
            }

            if(location != null && location != -1) {
                switch(uniform.type) {
                    case this.gl.FLOAT:
                        if(uniform.value instanceof Float32Array) {
                            this.gl.uniform1fv(location, uniform.value);
                        }
                        else {
                            this.gl.uniform1f(location, uniform.value);
                        }
                        break;
                    case this.gl.FLOAT_VEC2:
                        this.gl.uniform2fv(location, uniform.value);
                        break;
                    case this.gl.FLOAT_VEC3:
                        this.gl.uniform3fv(location, uniform.value);
                        break;
                    case this.gl.FLOAT_MAT4:
                        this.gl.uniformMatrix4fv(location, false, uniform.value);
                        break;
                    case this.gl.SAMPLER_2D:
                        this.gl.uniform1i(location, uniform.value);
                        // TODO: Record texture unit check if crossing limit
                        break;
                }
            }
            else {
                if(!this.missingUcache.has(key)) {
                    console.error(`Uniform : ${key} not found!`);
                    this.missingUcache.set(key, 1);
                }
            }
        }
    });
  }

  setBuffer(buffer : WebGLBuffer) {
    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, buffer);

    this.traits.forEach((attr) => {
      switch(attr.type) {
        case this.gl.FLOAT:
          this.gl.vertexAttribPointer(attr.location, attr.count, attr.type, false, this.curStride, attr.offset);
          break;
        case this.gl.UNSIGNED_BYTE:
          this.gl.vertexAttribPointer(attr.location, attr.count, attr.type, true, this.curStride, attr.offset);
          break;
        case this.gl.UNSIGNED_SHORT:
          this.gl.vertexAttribPointer(attr.location, attr.count, attr.type, false, this.curStride, attr.offset);
          break;
      } 
    });
  }


  setIndices(elementBuffer : WebGLBuffer) {
    this.gl.bindBuffer(this.gl.ELEMENT_ARRAY_BUFFER, elementBuffer);
  }

  setProgram(program : WebGLProgram, attributes : {[key:string] : AttributeData}, stride : number | undefined, offset : number | undefined) {
    this.gl.useProgram(program);
    this.curStride = 0;
    let newAttribs : Array<AttributeData> = [];
    if(!stride) {
      this.curStride = 0;

      Object.keys(attributes).forEach((key) => {
        let a = attributes[key];
        switch(a.type) {
          case this.gl.FLOAT:
            this.curStride += a.count << 2;
            break;
        }
      });

      if(Object.keys(attributes).length == 1) this.curStride = 0;
    }
    else this.curStride = stride;

    if(!offset) offset = 0;
    Object.keys(attributes).forEach((key) => {
      let loc = this.gl.getAttribLocation(program, key);
      if(loc != -1) {
        let a = attributes[key];
        a.location = loc;
        newAttribs[loc] = a;

        if(!a.offset) {
          a.offset = offset;

          switch(a.type) {
            case this.gl.FLOAT:
              offset += a.count << 2;
              break;
          }
        }
        else offset = a.offset;
      }
      else {
        console.warn(`Attribute ${key} not found!`);
      }
    });

    if(newAttribs.length > this.traits.length) {
      for (var idx = this.traits.length; idx < newAttribs.length; ++idx) {
        this.gl.enableVertexAttribArray (idx);
      }
    } 
    else {
      for (var idx = newAttribs.length; idx < this.traits.length; ++idx) {
        this.gl.disableVertexAttribArray(idx);
      }
    }

    this.traits = newAttribs;
  }

  cleanProgramUniforms(program : WebGLProgram) {
    if(this.uCache.has(program)) {
        this.uCache.get(program)?.clear();
        this.uCache.delete(program);
    }
  }

  getPixelExact(x : number, y : number) {
		var data = new Uint8Array (4);
		this.gl.readPixels (x, y, 1, 1, this.gl.RGBA, this.gl.UNSIGNED_BYTE, data);
		return data;
  }

  getPixel(x : number, y : number) {
		var data = new Uint8Array (4);
		this.gl.readPixels (x, this.gl.drawingBufferHeight - y, 1, 1, this.gl.RGBA, this.gl.UNSIGNED_BYTE, data);
		return data;
  }

  getPixelBounds(startx : number, starty : number, endx: number, endy: number) {
    let width = endx - startx;
    let height = endy - starty;
    var data = new Uint8Array(4 * width * height);
    this.gl.readPixels(startx, starty, width, height, this.gl.RGBA, this.gl.UNSIGNED_BYTE, data);
    return data;
  }

  createTextureSize(w : number, h : number, wrap : GLenum, filter : GLenum) {
    wrap = wrap ? wrap : this.gl.REPEAT;
    filter = filter ? filter : this.gl.LINEAR;

    const tex = this.gl.createTexture();

    this.gl.bindTexture(this.gl.TEXTURE_2D, tex);
    this.gl.texParameteri(this.gl.TEXTURE_2D, this.gl.TEXTURE_MIN_FILTER, filter);
    this.gl.texParameteri(this.gl.TEXTURE_2D, this.gl.TEXTURE_MAG_FILTER, filter);
    this.gl.texParameteri(this.gl.TEXTURE_2D, this.gl.TEXTURE_WRAP_S, wrap);
    this.gl.texParameteri(this.gl.TEXTURE_2D, this.gl.TEXTURE_WRAP_T, wrap);
    this.gl.texImage2D(this.gl.TEXTURE_2D, 0, this.gl.RGBA, w, h, 0, this.gl.RGBA, this.gl.UNSIGNED_BYTE, null);

    return tex;
  }

  targetFbo :  WebGLFramebuffer | null;
  vertexShaderPass : string= `#version 300 es
    in vec2 a_pos;
    in vec2 a_uv;

    out vec2 tex;

    void main() {

      tex = a_uv;
      gl_Position = vec4(a_pos, 0.0, 1.0);
    }
  `;

  vxbQuad : WebGLBuffer | null;

  createPass(fragmentShader : string) {
    return this.createProgram(this.vertexShaderPass, fragmentShader);
  }

  setTarget(target : WebGLTexture, w : number, h : number) {
    if(!this.targetFbo) {
      this.targetFbo = this.gl.createFramebuffer();
    }

    this.gl.bindFramebuffer(this.gl.FRAMEBUFFER, this.targetFbo);
    this.gl.framebufferTexture2D(this.gl.FRAMEBUFFER,
      this.gl.COLOR_ATTACHMENT0, this.gl.TEXTURE_2D,
      target, 0
    );
    this.gl.viewport(0, 0, w, h);
  }

  clearTarget() {
    this.gl.bindFramebuffer(this.gl.FRAMEBUFFER, null);
    this.gl.viewport(0, 0, this.gl.canvas.width, this.gl.canvas.height);
  }

  renderShaderPass(program : WebGLProgram, target : WebGLTexture, w : number, h : number, uniforms : {}) {
    if(!this.targetFbo) {
      this.targetFbo = this.gl.createFramebuffer();
    }
    if(!this.targetFbo) return;

    if(target)
      this.setTarget(target, w, h);
    if(!this.vxbQuad) {
      this.vxbQuad = this.createStaticBuffer(new Float32Array([
          -1, -1, 0, 0,
          1, -1, 1, 0,
          1, 1, 1, 1,
          -1, 1, 0, 1
        ]));
    }



    this.setProgram(program, {
      a_pos : { type : this.gl.FLOAT, count : 2},
      a_uv : { type : this.gl.FLOAT, count : 2},
    }, undefined, undefined);


    if(this.vxbQuad)
      this.setBuffer(this.vxbQuad);


    if(uniforms) {
      this.setUniforms(program, uniforms);
    }

    this.gl.drawArrays(this.gl.TRIANGLE_FAN, 0, 4);
    this.clearTarget();
  }

  renderColorsTextureToScreen(texture : WebGLTexture | null | undefined, uniforms : {}) {
    if(!this.colorsTextureProgram) {
      let vsh = this.vertexShaderPass;
      let fsh = getShader(ShaderKey.TEXTURE_COLOR);
      this.colorsTextureProgram = this.createProgram(vsh, fsh);
    }
    if(!this.colorsTextureProgram) return;
    this.gl.clearColor(0.0, 0.0, 0.0, 0.0);
    this.gl.clear(this.gl.COLOR_BUFFER_BIT | this.gl.DEPTH_BUFFER_BIT);

    if(!this.vxbQuad) {
      this.vxbQuad = this.createStaticBuffer(new Float32Array([
          -1, -1, 0, 0,
          1, -1, 1, 0,
          1, 1, 1, 1,
          -1, 1, 0, 1
        ]));
    }

    this.setProgram(this.colorsTextureProgram, {
      a_pos : { type : this.gl.FLOAT, count : 2},
      a_uv : { type : this.gl.FLOAT, count : 2},
    }, undefined, undefined);

    if(this.vxbQuad)
      this.setBuffer(this.vxbQuad);

    if(texture)
      this.setTexture(texture, 0);

    if(uniforms)
      this.setUniforms(this.colorsTextureProgram, uniforms);

    this.gl.drawArrays(this.gl.TRIANGLE_FAN, 0, 4);
  }

  renderValuesTextureToScreen(texture : WebGLTexture | null | undefined, uniforms : {}) {
    if(!this.valuesRenderProgram) {
      let vsh = this.vertexShaderPass;
      let fsh = getShader(ShaderKey.VALUES_VISUALIZE);
      this.valuesRenderProgram = this.createProgram(vsh, fsh);
    }
    if(!this.valuesRenderProgram) return;
    this.gl.clearColor(0.0, 0.0, 0.0, 0.0);
    this.gl.clear(this.gl.COLOR_BUFFER_BIT | this.gl.DEPTH_BUFFER_BIT);

    if(!this.vxbQuad) {
      this.vxbQuad = this.createStaticBuffer(new Float32Array([
          -1, -1, 0, 0,
          1, -1, 1, 0,
          1, 1, 1, 1,
          -1, 1, 0, 1
        ]));
    }

    this.setProgram(this.valuesRenderProgram, {
      a_pos : { type : this.gl.FLOAT, count : 2},
      a_uv : { type : this.gl.FLOAT, count : 2},
    }, undefined, undefined);

    if(this.vxbQuad)
      this.setBuffer(this.vxbQuad);

    if(texture)
      this.setTexture(texture, 0);

    if(uniforms)
      this.setUniforms(this.valuesRenderProgram, uniforms);

    this.gl.drawArrays(this.gl.TRIANGLE_FAN, 0, 4);
  }

  createStaticBuffer(data : Float32Array) {
    const xb = this.gl.createBuffer();
    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, xb);
    this.gl.bufferData(this.gl.ARRAY_BUFFER, data, this.gl.STATIC_DRAW);

    return xb;
  }

  createIndexBuffer(data : Uint16Array) {
    const ib = this.gl.createBuffer();
    this.gl.bindBuffer(this.gl.ELEMENT_ARRAY_BUFFER, ib);
    this.gl.bufferData(this.gl.ELEMENT_ARRAY_BUFFER, data, this.gl.STATIC_DRAW);

    return ib;
  }
  createIndexBuffer_UInt(data : Uint32Array) {
    const ib = this.gl.createBuffer();
    this.gl.bindBuffer(this.gl.ELEMENT_ARRAY_BUFFER, ib);
    this.gl.bufferData(this.gl.ELEMENT_ARRAY_BUFFER, data, this.gl.STATIC_DRAW);

    return ib;
  }

  recreateTexture(texture : WebGLTexture | null | undefined, w : number, h : number, wrap : GLenum, filter : GLenum) : WebGLTexture {
    if(texture === null || !texture || texture === -1) {
      texture = this.createTextureSize(w, h, wrap, filter);
      return texture;
    }
    else {
      this.gl.deleteTexture(texture);
      texture = this.createTextureSize(w, h, wrap, filter);
      return texture;
    }
  }

}


function hexToNormalizedRgb(hex: string): { r: number, g: number, b: number } | null {
  hex = hex.replace(/^#/, '');

  if (hex.length === 3) {
      hex = hex.split('').map(char => char + char).join('');
  }

  if (hex.length !== 6) {
      return null;
  }

  const r = parseInt(hex.slice(0, 2), 16) / 255;
  const g = parseInt(hex.slice(2, 4), 16) / 255;
  const b = parseInt(hex.slice(4, 6), 16) / 255;

  return { r, g, b };
}

function colorToHexString(r : number, g : number, b : number) {
  return "#" + [r,g,b].map((x) => {
    const hex = x.toString(16);
    if(hex.length === 1) return "0" + hex;
    else return hex;
  }).join("");
}

function colorToInt(r: number, g: number, b: number): number {
  r = Math.min(255, Math.max(0, r));
  g = Math.min(255, Math.max(0, g));
  b = Math.min(255, Math.max(0, b));

  const combined = (b << 16) | (g << 8) | r;

  // Normalize to the range [0, 1]
  return combined;
}


function lerp(a : Array<number>, b : Array<number>, t : number) {
    let dst = [0, 0];
    dst[0] = (1.0 - t) * a[0] + t * b[0];
    dst[1] = (1.0 - t) * a[1] + t * b[1];
    return dst;
}

function resamplePoints(pos: number[][][], len : number): number[][] {
    const p: number[][] = [];
    len = Math.floor(len);

    for (let x = 0; x < len; x++) {
        const wx = (pos[0].length - 1) * (x / (len - 1));
        const ix = Math.floor(wx);

        for (let y = 0; y < len; y++) {
            const wy = (pos.length - 1) * (y / (len - 1));
            const iy = Math.floor(wy);

            let c: number[];

            if (wx !== ix) {
                if (wy !== iy) {
                    const a = lerp(pos[iy][ix], pos[iy + 1][ix], wy - iy);
                    const b = lerp(pos[iy][ix + 1], pos[iy + 1][ix + 1], wy - iy);
                    c = lerp(a, b, wx - ix);
                } else {
                    const a = pos[iy][ix];
                    const b = pos[iy][ix + 1];
                    c = lerp(a, b, wx - ix);
                }
            } else {
                if (wy !== iy) {
                    c = lerp(pos[iy][ix], pos[iy + 1][ix], wy - iy);
                } else {
                    c = pos[iy][ix];
                }
            }

            p.push([c[0], c[1]]);
        }
    }

    return p;
}

function getValueStatistics(values: number[]) {
  if (!values || values.length === 0) {
      return null;
  }

  let min = Infinity;
  let max = -Infinity;
  let sum = 0;

  for (const val of values) {
      if (val < min) min = val;
      if (val > max) max = val;
      sum += val;
  }

  const avg = sum / values.length;

  // Calculate standard deviation in a second loop
  let varianceSum = 0;
  for (const val of values) {
      varianceSum += Math.pow(val - avg, 2);
  }
  const variance = varianceSum / values.length;
  const stdDev = Math.sqrt(variance);

  return {
      min: min,
      max: max,
      average: avg,
      std_dev: stdDev
  };
}


function lngLatToTileUV(lon: number, lat:number, zoom: number): { tileX: number, tileY: number, u: number, v: number, tileXFull: number, tileYFull: number } {
  const latRad = lat * Math.PI / 180.0; // Convert to radian
  const n = Math.pow(2.0, zoom);

  const xtile = n * ((lon + 180.0) / 360.0);
  const ytile = n * (1.0 - (Math.log(Math.tan(latRad) + 1.0 / Math.cos(latRad)) / Math.PI)) / 2.0;

  const tileXInt = Math.floor(xtile);
  const tileYInt = Math.floor(ytile);

  const u = xtile - tileXInt;
  const v = ytile - tileYInt;

  return { tileX: tileXInt, tileY: tileYInt, u, v, tileXFull: xtile, tileYFull: ytile };
}


function calcBoundingLngLat(lngLatArray : Array<LngLat> ): Array<LngLat> {
  let lngMin = Infinity, latMin = Infinity;
  let lngMax = -Infinity, latMax = -Infinity;

  lngLatArray.forEach((lngLat) => {
    if (lngLat.lng < lngMin) lngMin = lngLat.lng;
    if (lngLat.lat < latMin) latMin = lngLat.lat;

    if (lngLat.lng > lngMax) lngMax = lngLat.lng;
    if (lngLat.lat > latMax) latMax = lngLat.lat;
  });

  return [ new LngLat(lngMin, latMin), new LngLat(lngMax, latMax)];
}

function lngLatsNormalized(lngLatArray : Array<LngLat>, minll, maxll) {
  //const [minll, maxll] = calcBoundingLngLat(lngLatArray);

  const normArr : Array<{x : number, y : number}>= [];
  lngLatArray.map((v) => {
    const nlng = (v.lng - minll.lng) / (maxll.lng - minll.lng);
    const nlat = (v.lat - minll.lat) / (maxll.lat - minll.lat);

    normArr.push({x : nlng, y : nlat});
  });

  return normArr;
}


export { RenderingContext, colorToInt, colorToHexString, resamplePoints, lngLatToTileUV, lngLatsNormalized, calcBoundingLngLat, getValueStatistics, hexToNormalizedRgb};