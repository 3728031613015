import { storeToRefs } from 'pinia';

import { useAppStore } from '@/stores/app'
import { useRadarTowersStore } from '@/stores/radar_towers'

import App from '@/logic/App'
import MapKeeper from '@/logic/MapKeeper'

const useMultipleMaps = () => {
    const appStore = useAppStore();
    const radarTowersStore = useRadarTowersStore();

    const toggle = (numOfMaps) => {
        if(MapKeeper.mapsToLoad().length === 1) {
            open(numOfMaps);
        }
        else {
            close();
        }
    };

    const open = async (numOfMaps) => {
        if(appStore.isRadarMode) {
            if(radarTowersStore.hasBufferedScans) {
                await App.radar.resetAndLoadLatestRadarDataAll()
            }
        }

        for(let i = 0; i < numOfMaps; ++i) {
            MapKeeper.push();
        }
    };

    const close = () => {
        for(const map of MapKeeper.mapsToLoad()) {
            if(map.id === 0) continue;
            
            MapKeeper.pop(map.id);
        }
    };

    return {
        toggle,
        open,
        close
    }
}

export { useMultipleMaps }