<script setup>
import ToggleSwitch from 'primevue/toggleswitch';

// Define the v-model binding
const model = defineModel();
</script>

<template>
    <ToggleSwitch v-model="model" pt:handle:class="!top-2.5 !h-5 !w-5 !bg-white border !border-gray-300" pt:root:class="!w-11 !h-6" :pt:slider:class="{
            '!bg-gray-200': !model,
            '!bg-blue-600': model
    }" />
</template>