<script setup>
import { ref } from 'vue'
import { useMouseInElement } from '@vueuse/core'

const target = ref(null)

const { elementX, elementWidth } = useMouseInElement(target)
</script>

<template>
  <Transition name="slide-fade">
    <div v-if="active">
      <div :class="{
        '!h-[20px] !z-[30]': appStore.colortable_bar_open
      }" class="z-[20] h-[10px] w-full top-0 left-0 absolute overflow-hidden map-overlay" @click.prevent="onToggleColorInspector" @mousemove="onMapColorValue(elementWidth, elementX)" @touchmove="onMapColorValue(elementWidth, elementX)" ref="target" :style="cssLinearGradient">
        <div v-show="screenshot" class="text-sm h-3/6 flex justify-between items-center">
          <div v-for="screenshotColor in screenshotColorMapValues" class="px-3 text-center select-none">
            <div class="bg-white/75 h-full text-black font-bold px-2 pt-1 rounded-md text-xs">
              {{ screenshotColor.value }}&nbsp;{{ screenshotColor.unit }}
            </div>
          </div>
        </div>
      </div>
      <div class="absolute top-5 w-full flex justify-center items-center z-30" v-show="appStore.colortable_bar_open && ! screenshot" :style="`background-color: ${colorMapColor}`">
        <div class="bg-white/75 px-3 rounded-md text-center font-bold select-none">
          {{ colorMapValue }}
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import { useRadarTowersStore } from '@/stores/radar_towers'
import { useRecordingStore } from '@/stores/recording'
import { useAppStore } from '@/stores/app'

import MapKeeper from '@/logic/MapKeeper'

import { PRT_VALUE_RANGE, PRT_RAIN_VALUE_MAX, PRT_SNOW_VALUE_MIN, PRT_SNOW_VALUE_MAX, PRT_MIXED_VALUE_MIN, PRT_MIXED_VALUE_MAX } from '@/data/radar_products.js'

import { getHCAClassification } from '@/tools/helpers'

export default {
  name: 'Colortable',
  props: ['mapMeta'],
  data() {
    return {
      colorMapValue: '',
      colorMapColor: '#fff',
      screenshot: false
    }
  },
  computed: {
    radarTowersStore: () => useRadarTowersStore(),
    recordingStore: () => useRecordingStore(),
    appStore: () => useAppStore(),
    mapRadar() {
      return MapKeeper.map(this.mapMeta.id).stores['radar']
    },
    active() {
      return this.appStore.mode === 'RADAR' && this.radarTowersStore.anyActive && this.mapRadar.activeColorMap !== null
    },
    cssLinearGradient() {
      if (! this.mapRadar.activeColorTable) {
        return;
      }

    	const len = this.mapRadar.activeColorTable.length || 0;
    	const colorsAsRgb = this.mapRadar.activeColorTable.map((c, i) => {
    		return `rgb(${c[0]},${c[1]},${c[2]}) ${(i/len)*100}%`
    	});
    	return `background: linear-gradient(90deg, ${colorsAsRgb.join(', ')});`;
    },
    colorMapValues() {
      if (!this.mapRadar.activeColorMap) {
        return []
      }

      const min = this.mapRadar.activeColorMap.min
      const max = this.mapRadar.activeColorMap.max
      const total = Math.abs(min) + max
      const step = total / (this.mapRadar.activeColorTable.length - 1)

      const length = Math.floor((max - min) / step) + 1;
      return Array.from({ length }, (_, i) => +(min + i * step).toFixed(10));
    },
    screenshotColorMapValues() {
      const cm = this.mapRadar.activeColorMap
      const groupId = this.mapRadar.activeProductGroup.id;

      if (! cm) {
        return [{value: 0, unit: '', color: 'rgb(255, 255, 255)'}, {value: 0, unit: '', color: 'rgb(255, 255, 255)'}, {value: 0, unit: '', color: 'rgb(255, 255, 255)'}]
      }

      const min = cm.min
      let max = cm.max
      if(groupId === 'PRT') {
        max = PRT_RAIN_VALUE_MAX;
      }
      const c = cm.colors

      return [
        {
          value: parseFloat(min).toFixed(1),
          unit: this.mapRadar.activeProductGroupUnit,
          color: `rgb(${c[0][0]},${c[0][1]},${c[0][2]})`
        },
        {
          value: parseInt((min + max) / 2).toFixed(1),
          unit: this.mapRadar.activeProductGroupUnit,
          color: `rgb(${c[parseInt(c.length/2-1)][0]},${c[parseInt(c.length/2-1)][1]},${c[parseInt(c.length/2-1)][2]})`
        },
        {
          value: parseFloat(max).toFixed(1),
          unit: this.mapRadar.activeProductGroupUnit,
          color: `rgb(${c[c.length-1][0]},${c[c.length-1][1]},${c[c.length-1][2]})`
        }
      ]
    }
  },
  watch: {
    'active': {
      handler(newVal) {
        this.onCloseColorInspector()
      }
    },
    'recordingStore.screenshot': {
      handler(newVal) {
        if (newVal) {
          this.screenshot = true
          this.onOpenColorInspector()

        } else {
          this.screenshot = false
          this.onCloseColorInspector()
        }
      }
    }
  },
  methods: {
    onMapColorValue(elementX, elementWidth) {
      if (! this.mapRadar.activeColorTable) {
        return null
      }

      const colorTableLength = this.mapRadar.activeColorTable.length
      const colorTableUnitPercentage = 100 / colorTableLength

      const tableRelDistance =  parseInt((((elementWidth / elementX) * 100) / colorTableUnitPercentage))
      const colorValues = this.mapRadar.activeColorTable[tableRelDistance];

      if (! this.colorMapValues[tableRelDistance]) {
        return
      }

      let value = this.colorMapValues[tableRelDistance];

      this.colorMapValue = `${value.toFixed(1)} ${this.mapRadar.activeProductGroupUnit}`

      const groupId = this.mapRadar.activeProductGroup.id;
      if(groupId === 'HCA') {
        const classification = getHCAClassification(value);

        this.colorMapValue = classification
      }
      else if(groupId === 'PRT') {
        let prt = 'Rain';
        if(value > PRT_SNOW_VALUE_MIN && value <= PRT_SNOW_VALUE_MAX) {
          prt = 'Snow';
          value-=(PRT_VALUE_RANGE * 1);
        }
        else if(value > PRT_MIXED_VALUE_MIN && value <= PRT_MIXED_VALUE_MAX) {
          prt = 'Mixed';
          value-=(PRT_VALUE_RANGE * 2);
        }

        this.colorMapValue = `${value.toFixed(1)} ${this.mapRadar.activeProductGroupUnit} (${prt})`
      }

      this.colorMapColor = `rgb(${colorValues[0]}, ${colorValues[1]}, ${colorValues[2]})`
    },
    onToggleColorInspector() {
      this.appStore.colortable_bar_open = ! this.appStore.colortable_bar_open

      if (this.appStore.colortable_bar_open) {
        this.onOpenColorInspector()
      } else {
        this.onCloseColorInspector()
      }
    },
    onOpenColorInspector() {
      this.appStore.colortable_bar_open = true
    },
    onCloseColorInspector() {
      this.appStore.colortable_bar_open = false
      this.colorMapValue = ''
      this.colorMapColor = '#fff'
    }
  }
}
</script>

<style scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from {
  transform: translateX(20px);
  opacity: 0;
}

.slide-fade-leave-to {
  display: none;
}
</style>
