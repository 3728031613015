export default [
  {
    "type": "Feature",
    "geometry": {
      "type": "Point",
      "coordinates": [
        32.54716, 34.77085, 392.0
      ]
    },
    "properties": {
      "id": "CYPFO",
      "code": "CYPFO",
      "secondary": false,
      "products": ["REF", "VEL"],
      "elevations": [0.5]
    }
  }, {
    "type": "Feature",
    "geometry": {
      "type": "Point",
      "coordinates": [
        33.56654, 34.93654, 105.0
      ]
    },
    "properties": {
      "id": "CYLCA",
      "code": "CYLCA",
      "secondary": false,
      "products": ["REF", "VEL"],
      "elevations": [0.5]
    }
  }
]