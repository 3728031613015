export const circle = (options) => {
    const def = {
        size: 128,
        color: '#FFFFFF',
        border_color: "#000000",
        border_size: 4,
        text: '',
        text_font: "bold 16px Arial",
        text_color: '#313439'
    };

    const config = {...def, ...options }
    return {
        width: config.size,
        height: config.size,
        data: new Uint8Array(config.size * config.size * 4),

        // When the layer is added to the map,
        // get the rendering context for the map canvas.
        onAdd: function () {
            const canvas = document.createElement('canvas');
            canvas.width = this.width;
            canvas.height = this.height;
            this.context = canvas.getContext('2d');

            const radius = (config.size / 2) - 2;
            const context = this.context;

            // Draw the inner circle.
            context.beginPath();
            context.arc(
                this.width / 2,
                this.height / 2,
                radius,
                0,
                Math.PI * 2
            );
            context.fillStyle = config.color;
            context.strokeStyle = config.border_color;
            context.lineWidth = config.border_size;
            context.fill();
            context.stroke();

            if(config.text.length > 0) {
                context.textBaseline = "middle";
                context.textAlign = "center";
                context.font = config.text_font;
                context.fillStyle = config.text_color;
                context.fillText(config.text, canvas.width/2, (canvas.height/2)+2);
            }

            // Update this image's data with data from the canvas.
            this.data = context.getImageData(
                0,
                0,
                this.width,
                this.height
            ).data;
        },

        // Call once before every frame where the icon will be used.
        render: function () {
            // Return `true` to let the map know that the image was updated.
            return true;
        }
    };
};

export const rectangle = (options) => {
    const def = {
        width: 128,
        height: 64,
        color: '#FF0000',         // fill color (default red, can be changed)
        border_color: "#FFFFFF",  // white border as requested
        border_size: 4,
        corner_radius: 10,        // corner radius for rounded corners
        text: '',
        text_font: "bold 16px Arial",
        text_color: '#313439'
    };

    const config = { ...def, ...options };

    return {
        width: config.width,
        height: config.height,
        data: new Uint8Array(config.width * config.height * 4),

        onAdd: function () {
            const canvas = document.createElement('canvas');
            canvas.width = this.width;
            canvas.height = this.height;
            this.context = canvas.getContext('2d');
            const ctx = this.context;

            // To ensure the border is fully visible, offset drawing by half the border width.
            const x = config.border_size / 2;
            const y = config.border_size / 2;
            const width = this.width - config.border_size;
            const height = this.height - config.border_size;
            const r = config.corner_radius;

            ctx.beginPath();
            // Draw the top edge
            ctx.moveTo(x + r, y);
            ctx.lineTo(x + width - r, y);
            // Top-right corner
            ctx.quadraticCurveTo(x + width, y, x + width, y + r);
            // Right edge
            ctx.lineTo(x + width, y + height - r);
            // Bottom-right corner
            ctx.quadraticCurveTo(x + width, y + height, x + width - r, y + height);
            // Bottom edge
            ctx.lineTo(x + r, y + height);
            // Bottom-left corner
            ctx.quadraticCurveTo(x, y + height, x, y + height - r);
            // Left edge
            ctx.lineTo(x, y + r);
            // Top-left corner
            ctx.quadraticCurveTo(x, y, x + r, y);
            ctx.closePath();

            // Fill and stroke the rounded rectangle.
            ctx.fillStyle = config.color;
            ctx.strokeStyle = config.border_color;
            ctx.lineWidth = config.border_size;
            ctx.fill();
            ctx.stroke();

            // Optionally, add centered text.
            if (config.text.length > 0) {
                ctx.textBaseline = "middle";
                ctx.textAlign = "center";
                ctx.font = config.text_font;
                ctx.fillStyle = config.text_color;
                ctx.fillText(config.text, this.width / 2, this.height / 2);
            }

            // Update the image data for the canvas.
            this.data = ctx.getImageData(0, 0, this.width, this.height).data;
        },

        render: function () {
            return true;
        }
    };
};


export const hatch = (options) => {
    const def = {
        size: 10,
        color: '#000000'
    };

    const config = {...def, ...options }
    return {
        width: config.size,
        height: config.size,
        data: new Uint8Array(config.size * config.size * 4),

        // When the layer is added to the map,
        // get the rendering context for the map canvas.
        onAdd: function () {
            const canvas = document.createElement('canvas');
            canvas.width = this.width;
            canvas.height = this.height;
            this.context = canvas.getContext('2d');

            this.context.fillStyle = config.color;
            this.context.fillRect( 2, 7, 1, 1 );
            this.context.fillRect( 3, 6, 1, 1 );
            this.context.fillRect( 4, 5, 1, 1 );
            this.context.fillRect( 5, 4, 1, 1 );
            this.context.fillRect( 6, 3, 1, 1 );
            this.context.fillRect( 7, 2, 1, 1 );

            // Update this image's data with data from the canvas.
            this.data = this.context.getImageData(
                0,
                0,
                this.width,
                this.height
            ).data;
        },

        // Call once before every frame where the icon will be used.
        render: function () {
            // Return `true` to let the map know that the image was updated.
            return true;
        }
    };
};
