<script setup lang="ts">
import AlertItem from './AlertItem.vue';

const props = defineProps<{
  section;
  filterAlerts;
}>();

defineEmits<{
  (e: 'update:enabled', value): void;
}>();
</script>

<template>
  <div class="dark:text-gray-700 dark:text-gray-300 dark:bg-gray-800 rounded-lg mb-6 overflow-hidden">
    <div class="dark:bg-gray-700 px-4 py-2">
      <h2 class="text-xl font-semibold dark:text-white">{{ section.title }}</h2>
    </div>
    <div class="divide-y divide-gray-700">
      <AlertItem
        v-for="(alert, index) in section.alerts.filter(props.filterAlerts)"
        :key="alert.id"
        :alert="alert"
        @update:enabled="$emit('update:enabled', $event)"
      />
    </div>
  </div>
</template>