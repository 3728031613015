import hotkeys from 'hotkeys-js';
import { useAppStore } from '@/stores/app';
import { useRadarTowersStore } from '@/stores/radar_towers';
import { useDrawingStore } from '@/stores/drawing';
import { useWarningsSettingsStore } from '@/stores/settings/warnings'
import { useMesoscaleDiscussionsSettingsStore } from '@/stores/settings/mesoscale_discussions'
import { useTropicalSettingsStore } from '@/stores/settings/tropical'
import { useDebounceFn } from '@vueuse/core'

import { useRadarPlayback } from '@/logic/Composables/RadarPlayback';
import { useMultipleMaps } from '@/logic/Composables/MultipleMaps';
import { useSubscription } from '@/logic/Composables/Subscription'
import { firstRefProduct, firstVelProduct } from '@/data/radar_products'

import App from '@/logic/App'
import MapKeeper from '@/logic/MapKeeper'

export default {
    install(app) {
        const appStore = useAppStore();
        const radarTowersStore = useRadarTowersStore();
        const drawingStore = useDrawingStore();

        const warningsSettingsStore = useWarningsSettingsStore();
        const mesoscaleDiscussionsSettingsStore = useMesoscaleDiscussionsSettingsStore();
        const tropicalSettingsStore = useTropicalSettingsStore();

        const multipleMaps = useMultipleMaps();
        const subscription = useSubscription();

        const callShortcut = (cb) => {
            return () => {
                if(! appStore.isReady()) return;

                return cb();
            };
        };

        // Debounced mode switching functions
        const setRadarMode = useDebounceFn(() => {
            appStore.setMode('RADAR');
        }, 300);

        const setSatelliteMode = useDebounceFn(() => {
            appStore.setMode('SATELLITE');
        }, 300);

        const setOutlooksMode = useDebounceFn(() => {
            appStore.setMode('OUTLOOKS');
        }, 300);

        // Debounced radar product changes
        const setReflectivity = useDebounceFn(() => {
            if (!appStore.isRadarMode || !radarTowersStore.anyActive) return;
            const activeMap = MapKeeper.activeMap();
            const towerId = activeMap.stores['radar'].activeTowerId;

            const refProduct = firstRefProduct(radarTowersStore.activeTower.properties.products)

            App.radar.changeRadarProduct(activeMap, towerId, refProduct);
        }, 50);

        const setVelocity = useDebounceFn(() => {
            if (!appStore.isRadarMode || !radarTowersStore.anyActive) return;
            const activeMap = MapKeeper.activeMap();
            const towerId = activeMap.stores['radar'].activeTowerId;

            const velProduct = firstVelProduct(radarTowersStore.activeTower.properties.products)

            App.radar.changeRadarProduct(activeMap, towerId, velProduct);
        }, 50);

        const setCorrelationCoefficient = useDebounceFn(() => {
            if (!appStore.isRadarMode || !radarTowersStore.anyActive) return;
            const activeMap = MapKeeper.activeMap();
            const towerId = activeMap.stores['radar'].activeTowerId;
            App.radar.changeRadarProduct(activeMap, towerId, 'CC0');
        }, 50);

        const setDifferentialReflectivity = useDebounceFn(() => {
            if (!appStore.isRadarMode || !radarTowersStore.anyActive) return;
            const activeMap = MapKeeper.activeMap();
            const towerId = activeMap.stores['radar'].activeTowerId;
            App.radar.changeRadarProduct(activeMap, towerId, 'ZDR0');
        }, 50);

        const setSpectrumWidth = useDebounceFn(() => {
            if (!appStore.isRadarMode || !radarTowersStore.anyActive) return;
            const activeMap = MapKeeper.activeMap();
            const towerId = activeMap.stores['radar'].activeTowerId;
            App.radar.changeRadarProduct(activeMap, towerId, 'SW0');
        }, 50);

        const openMultipleMaps2 = useDebounceFn(() => {
            if (!subscription.isAtleast(subscription.tiers.PLUS)) return;
            multipleMaps.toggle(1);
        }, 50);

        const togglePlayback = () => {
            if (!appStore.isRadarMode || radarTowersStore.isPlaybackBuffering) return;
            useRadarPlayback().togglePlayback();
        };

        const toggleDrawing = () => {
            if (! drawingStore.isAnnotating) {
                App.drawing.enable();
                return;
            }
            App.drawing.disable();
        };

        const toggleInspector = () => {
            if (!appStore.isRadarMode) return;
            radarTowersStore.inspectorActive = !radarTowersStore.inspectorActive;
        };

        const toggleSidebar = () => {
            appStore.toggleWarningsSidebar();
        };

        const toggleWarningsOverlay = () => {
            warningsSettingsStore.toggleVisible();
        };

        const toggleMesoscaleDiscussions = () => {
            mesoscaleDiscussionsSettingsStore.toggleVisible();
        };

        const toggleTropical = () => {
            tropicalSettingsStore.toggleVisible();
        };

        const toggleMenu = () => {
            appStore.toggleMenu();
        };

        const shortcuts = {
            'shift+1': {
                fn: callShortcut(setRadarMode),
                description: 'Set mode to Radar'
            },
            'shift+2': {
                fn: callShortcut(setSatelliteMode),
                description: 'Set mode to Satellite'
            },
            'shift+3': {
                fn: callShortcut(setOutlooksMode),
                description: 'Set mode to Outlooks'
            },
            'shift+r': {
                fn: callShortcut(setReflectivity),
                description: 'Set radar product to Reflectivity'
            },
            'shift+v': {
                fn: callShortcut(setVelocity),
                description: 'Set radar product to Velocity'
            },
            'shift+c': {
                fn: callShortcut(setCorrelationCoefficient),
                description: 'Set radar product to Correlation Coefficient'
            },
            'shift+d': {
                fn: callShortcut(setDifferentialReflectivity),
                description: 'Set radar product to Differential Reflectivity'
            },
            'shift+w': {
                fn: callShortcut(setSpectrumWidth),
                description: 'Set radar product to Spectrum Width'
            },
            'ctrl+2': {
                fn: openMultipleMaps2,
                description: 'Open/Close Split View (2 maps)'
            },
            'space': {
                fn: callShortcut(togglePlayback),
                description: 'Toggle radar playback'
            },
            'd': {
                fn: callShortcut(toggleDrawing),
                description: 'Toggle drawing mode'
            },
            'i': {
                fn: callShortcut(toggleInspector),
                description: 'Toggle radar inspector'
            },
            'a': {
                fn: callShortcut(toggleSidebar),
                description: 'Toggle alerts sidebar'
            },
            'alt+shift+w': {
                fn: callShortcut(toggleWarningsOverlay),
                description: 'Toggle warning overlays'
            },
            'alt+shift+m': {
                fn: callShortcut(toggleMesoscaleDiscussions),
                description: 'Toggle mesoscale discussion overlays'
            },
            'alt+shift+t': {
                fn: callShortcut(toggleTropical),
                description: 'Toggle tropical overlays'
            },
            'tab': {
                fn: callShortcut(toggleMenu),
                description: 'Toggle menu'
            }
        }

        // Bind hotkeys to debounced functions
        for (const [key, fn] of Object.entries(shortcuts)) {
            hotkeys(key, fn.options || {}, fn.fn);
        }

        app.provide('keyboardShortcuts', shortcuts);

        // console.log('KeyboardShortcuts installed', hotkeys.getAllKeyCodes());
    }
}
