<script setup lang="ts">
import { Icon } from '@iconify/vue'
import { vOnClickOutside } from '@vueuse/components'
import { useAccountStore } from '@/stores/user/account'
import { useUserAccount } from '@/logic/Composables/UserAccount'
import { computed, ref } from 'vue'
import { Purchases } from '@revenuecat/purchases-js'
import { useSubscriptionStore } from '@/stores/subscription'
import { useTimeoutFn } from '@vueuse/core'

const emit = defineEmits(['close'])
const accountStore = useAccountStore()
const { logout } = useUserAccount()

const showCopiedTooltip = ref(false)
const { start: startCopiedTooltipTimer } = useTimeoutFn(() => {
  showCopiedTooltip.value = false
}, 1000)

const handleClose = () => {
  emit('close')
}

const copyToClipboard = (text: string) => {
  navigator.clipboard.writeText(text)
  showCopiedTooltip.value = true
  startCopiedTooltipTimer()
}

const handleLogout = async () => {
  logout()
  handleClose()
}

// Use an async computed property for the RevenueCat ID
const revenueCatId = computed(() => useSubscriptionStore().revenueCatCustomerInfo?.originalAppUserId || '')
</script>

<template>
  <div class="relative" v-on-click-outside="handleClose">
    <div class="absolute top-0 right-0 z-50">
      <button @click.prevent="handleClose" class="p-[5px]">
        <Icon icon="clarity:window-close-line" class="size-8 sm:size-9 text-white" />
      </button>
    </div>

    <div class="mb-10 mx-auto hyphens-auto w-full md:max-w-[90%] lg:max-w-[800px]">
      <div class="bg-gradient-to-tr from-[#F6B91A] to-[#17274F] rounded-t-lg p-4 md:p-8 text-white relative overflow-hidden">
        <div class="absolute top-0 right-0 opacity-10">
          <Icon icon="mdi:account-circle" class="w-48 h-48" />
        </div>
        <div class="flex items-center space-x-4">
          <div>
            <h2 class="text-3xl font-bold">My Account</h2>
            <p class="flex items-center mt-2">
              <Icon icon="mdi:account" class="min-w-5 min-h-5 mr-2" />
              Account Details
            </p>
          </div>
        </div>
      </div>

      <!-- Main Content -->
      <div class="bg-white dark:bg-ww-blue shadow-xl rounded-b-lg">
        <div class="p-4 md:p-8">
          <div class="max-w-md mx-auto space-y-6">
            <!-- Name -->
            <div>
              <label class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                Full Name
              </label>
              <div class="relative">
                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Icon icon="mdi:account" class="h-5 w-5 text-gray-400" />
                </div>
                <div class="block w-full pl-10 pr-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm bg-gray-50 dark:bg-ww-blue-200 text-gray-900 dark:text-white sm:text-sm max-w-[300px] overflow-hidden text-ellipsis whitespace-nowrap">
                  {{ accountStore.attributes.name }}
                </div>
              </div>
            </div>

            <!-- Email -->
            <div>
              <label class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                Email Address
              </label>
              <div class="relative">
                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Icon icon="mdi:email" class="h-5 w-5 text-gray-400" />
                </div>
                <div class="block w-full pl-10 pr-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm bg-gray-50 dark:bg-ww-blue-200 text-gray-900 dark:text-white sm:text-sm max-w-[300px] overflow-hidden text-ellipsis whitespace-nowrap">
                  {{ accountStore.attributes.email }}
                </div>
              </div>
            </div>

            <!-- RevenueCat ID -->
            <div>
              <label class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                Customer ID
              </label>
              <div class="relative">
                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Icon icon="mdi:tag" class="h-5 w-5 text-gray-400" />
                </div>
                <div class="flex w-full">
                  <div class="grow block pl-10 pr-3 py-2 border border-gray-300 dark:border-gray-600 rounded-l-md shadow-sm bg-gray-50 dark:bg-ww-blue-200 text-gray-900 dark:text-white sm:text-sm max-w-[200px] overflow-hidden text-ellipsis whitespace-nowrap">
                    <span v-if="revenueCatId">{{ revenueCatId }}</span>
                    <span v-else>Loading...</span>
                  </div>
                  <div class="relative">
                    <button 
                      @click="copyToClipboard(revenueCatId || '')"
                      class="h-full flex items-center px-3 border border-gray-300 dark:border-gray-600 border-l-0 rounded-r-md shadow-sm bg-gray-50 dark:bg-ww-blue-200 text-gray-900 dark:text-white hover:bg-gray-100 dark:hover:bg-ww-blue-300"
                      title="Copy to clipboard"
                    >
                      <Icon icon="mdi:content-copy" class="h-5 w-5" />
                    </button>
                    <!-- Tooltip -->
                    <div v-if="showCopiedTooltip" class="absolute right-0 top-full mt-2 py-1 px-2 bg-gray-800 text-white text-xs font-medium rounded shadow z-10">
                      Copied!
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Logout Button -->
            <button
              @click.once="handleLogout"
              class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-[#17274F] bg-[#F6B91A] hover:bg-[#F6B91A]/90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#F6B91A]"
            >
              <Icon icon="mdi:logout" class="mr-2 size-5" />
              Logout
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.bg-gradient-to-r {
  position: relative;
}

.bg-gradient-to-r::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
}
</style> 