export default {
  enabled: import.meta.env.PROD,
  accessToken: '4f77d36d2d5a47679f065769618623b6',
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: import.meta.env.PROD ? 'prod' : 'dev',
    client: {
      javascript: {
        code_version: __COMMIT_HASH__,
        source_map_enabled: true,
      }
    }
  },
  ignoredMessages: ['(unknown): Script error.', '(unknown): {"isTrusted":true}', 'jQuery is missing'],
  checkIgnore: function(isUncaught, args, payload) {
    // console.log(isUncaught, args, payload);

    if(isUncaught === true) {
      if(Array.isArray(args) && args.length > 0) {
        const errString = args[0].toString();

        const isFacebookBrowser = window.navigator.userAgent.includes('Mozilla/5.0') || window.navigator.userAgent.includes('facebookexternalhit')

        // Ignore this error from the FB bot: https://github.com/mapbox/mapbox-gl-js/issues/9488#issuecomment-610786721
        if(isFacebookBrowser && errString.includes('Invalid LngLat object')) {
          console.log('Ignoring fake Facebook click...');
          return true;
        }
        if(isFacebookBrowser && errString.includes('window.Userback.init is not a function')) {
          console.log('Ignoring fake Facebook click...');
          return true;
        }

        const isAppleMailBrowser = window.navigator.userAgent.includes('Apple Mail');
        if(isAppleMailBrowser) {
          return true;
        }
      }

      // Ignore errors that originate from a Chrome extension
      if(payload?.body?.trace?.frames?.at(0)?.filename?.includes('chrome-extension://')) {
        return true;
      }
    }

    return false;
  }
}
