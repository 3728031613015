import { toRaw } from 'vue'
import { defineStore } from 'pinia'
import moment from 'moment'

import { warningConfig } from '@/data/nws_warning_config.js'
import { removeExpired } from './helpers'
import { useWarningsSettingsStore } from './settings/warnings'

import api from '@/logic/Api'

import { allSettledLimit } from '@/tools/helpers'
import { MODE_REALTIME } from '@/tools/constants'

const CHECK_FOR_EXPIRED_INTERVAL = 60 * 1000

let init = false

let featureList = [];
let featureMap = {};
const geometryCache = {};

import App from '@/logic/App'

export const useWarningsStore = defineStore('warnings', {
  state: () => ({
    list: [],
    last_update_at: null,
    loading: false,
  }),

  getters: {
    emergencyExists: (state) => state.features.find(f => f.properties.emergency) !== undefined,
    features: (state) => {
      const settings = useWarningsSettingsStore();

      const features = state.list.map(id => {
        return featureMap[id];
      }).filter(f => {
        const key = `${f.properties.product}.${f.properties.significance}`;

        const settingConfig = settings.config[f.properties.country_iso][key];

        return settingConfig.enabled;
      }).map(f => {
        const key = `${f.properties.product}.${f.properties.significance}`;
        const config = warningConfig[key];

        const settingConfig = settings.config[f.properties.country_iso][key];

        let priority = config.priority;

        // This is a special case, where if a Special Weather Statement is severe
        // We'll increase the priority
        if(f.properties.product === 'SP' && f.properties.significance === 'S' && f.properties.tags.TIME_MOT_LOC === 'object') {
          priority = 7;
        }

        f.properties['line-color'] = f.properties.emergency ? '#FFFFFF' : settingConfig.color;
        f.properties['line-background-color'] = settingConfig.color;
        f.properties['line-opacity'] = 0.7;
        f.properties['line-width'] = f.properties.emergency ? 4 : settingConfig.line_width;
        f.properties['line-sort-key'] = 1000 - priority;

        f.properties['fill-color'] = settingConfig.color;
        f.properties['fill-opacity'] = settingConfig.fill_opacity;
        f.properties['fill-sort-key'] = 1000 - priority;
        
        return f
      });

      if(settings.filter_active) {
        const now = moment.utc()

        return features.filter((f) => {
          const startsAt = moment.utc(f.properties.starts_at)

          return now.isAfter(startsAt);
        });
      }

      return features;
    },
    sidebarFeatures: (state) => {
      return state.features;

      // TODO
      const settings = useWarningsSettingsStore();

      const compareFnMap = {
        'ISSUANCE-A-Z': (a, b) => {
          const issuedAtA = moment.utc(a.properties.issued_at);
          const issuedAtB = moment.utc(b.properties.issued_at);

          return issuedAtB.isBefore(issuedAtA) ? 1 : -1;
        },
        'ISSUANCE-Z-A': (a, b) => {
          const issuedAtA = moment.utc(a.properties.issued_at);
          const issuedAtB = moment.utc(b.properties.issued_at);

          return issuedAtA.isBefore(issuedAtB) ? 1 : -1;
        },
      };

      // TODO
      // Add filter to only show features inside the viewport

      return state.features.toSorted(compareFnMap[settings.sort_by]);
    },
    filter: (state) => {
      return (significance, product) => {
        return state.sidebarFeatures.filter((f) => {
          const matchSig = f.properties.significance === significance;

          if (product === undefined) return matchSig;

          return matchSig && f.properties.product === product;
        })
      }
    }
  },

  actions: {
    init() {
      if (init) return
      init = true

      setInterval(() => {
        if(App.warnings.getMode() !== MODE_REALTIME) return;

        const now = moment.utc()

        const featuresToRemove = featureList.filter(function(f){
          const expiresAt = moment.utc(f.properties['expires_at'])

          return expiresAt.isBefore(now);
        });

        // console.log('cleanup', featuresToRemove.length)

        featuresToRemove.forEach(f => {
          if(featureMap[f.properties.id] !== undefined) {
            delete featureMap[f.properties.id];
          }
          if(geometryCache[f.properties.id] !== undefined) {
            delete geometryCache[f.properties.id];
          }

          featureList = featureList.filter(ff => ff.properties.id !== f.properties.id);
        });

        this.refreshList();
      }, CHECK_FOR_EXPIRED_INTERVAL);
    },

    setFeatureList(features) {
      featureList = features;
      featureMap = Object.fromEntries(
         features.map(e => [e.properties.id, e])
      )

      this.last_update_at = moment.utc().toISOString()

      this.refreshList();
    },

    refreshList() {
      this.list = featureList.map(f => f.properties.id);
    },

    async load() {
      if(this.loading) return;

      try {
        this.loading = true;
        const geojson = await api.instance().get(`/warnings/USA.geojson?_=${(new Date()).getTime()}`);

        // When we pull warnings, we need to:
        // - Filter out expired warnings that may still exist in the list
        // - Filter out unsupported warnings
        const features = removeExpired(geojson.features).filter(f => {
          const key = `${f.properties.product}.${f.properties.significance}`;

          const supported = warningConfig[key] !== undefined;

          if(! supported) {
            console.warn(`Warning: ${key} is not supported.`);
          }

          return supported;
        });

        this.setFeatureList(features);

        // Now handle the non-GeoJSON features
        const nonGeoJsonFeatures = features.filter(f => f.geometry === null);

        // console.log('add non-geojson features', nonGeoJsonFeatures.length)

        if(nonGeoJsonFeatures.length > 0) {
          const tasks = nonGeoJsonFeatures.filter(f => geometryCache[f.properties.id] === undefined).map(feature => this.processNonGeoJsonFeature(feature));
          
          if(tasks.length > 0) {
            console.time("Load warning geometry data for realtime");
            await allSettledLimit(tasks, 6);
            console.timeEnd("Load warning geometry data for realtime");
          }

          console.time("Apply warning geometry data for realtime");
          nonGeoJsonFeatures.forEach(feature => {
            const geometry = geometryCache[feature.properties.id];
            if(geometry === undefined) return;

            if(featureMap[feature.properties.id] === undefined) return;

            featureMap[feature.properties.id].geometry = geometry.geometry;
          });
          console.timeEnd("Apply warning geometry data for realtime");

          // Refresh list to reflect new underlying data (new geometry)
          this.refreshList();
        }
      } catch (error) {
        console.log('Failed to load from warnings store', error)
        return error
      } finally {
        this.loading = false;
      }
    },

    async fetchGeometry(feature) {
      const warningId = feature.properties.id;

      if(feature.properties.geometry === undefined || feature.properties.geometry === null || typeof feature.properties.geometry !== 'object')
        return console.log('Warning feature is missing extra geometry', warningId)

      const extraGeometry = feature.properties.geometry;

      if(extraGeometry.type !== 'REMOTE') return console.log("Warning feature uses unsupported geometry", warningId)

      let geometry = geometryCache[warningId];
      if(geometryCache[warningId] === undefined) {
        try {
          geometry = await api.instance().get(`/warnings/archive/${warningId}-geometry.geojson`);

          // console.log(warningId, geometry)

          geometryCache[warningId] = geometry;
        }
        catch (e) {
          console.error(`Failed to fetch geometry for warning: ${warningId}`, e);

          return null;
        }
      }

      return geometry.geometry;
    },

    processNonGeoJsonFeature(feature) {
      return async () => {
        const warningId = feature.properties.id;

        return await this.fetchGeometry(feature);
      }
    },

    async push(feature) {
      // Check that the warning is not expired
      const now = moment.utc()
      const expiresAt = moment.utc(feature.properties.expires_at)

      if(expiresAt.isBefore(now)) return;

      // Check if new feature is supported
      const key = `${feature.properties.product}.${feature.properties.significance}`;
      if(warningConfig[key] === undefined) return;

      // Check that we don't already have a warning with the same ID
      // If there is, then we'll update it
      const idx = this.list.findIndex(fId => fId === feature.properties.id);
      if(idx >= 0) {
        featureList[idx] = feature;
        featureMap[feature.properties.id] = feature;
      }
      else {
        featureList.push(feature)
        featureMap[feature.properties.id] = feature;

        // We need to out warnings with the same common_id where the issued_at is than the current issued_at
        const featuresToRemove = featureList.filter(function(f){
          return f.properties.common_id == feature.properties.common_id && moment.utc(f.properties.issued_at).isBefore(moment.utc(feature.properties.issued_at));
        });

        featuresToRemove.forEach(f => {
          if(featureMap[f.properties.id] !== undefined) {
            delete featureMap[f.properties.id];
          }
          if(geometryCache[f.properties.id] !== undefined) {
            delete geometryCache[f.properties.id];
          }

          featureList = featureList.filter(ff => ff.properties.id !== f.properties.id);
        });
      }

      // If feature has no geometry, then load it
      if(feature.geometry === null) {
        await this.processNonGeoJsonFeature(feature)();
        const geometry = geometryCache[feature.properties.id];
        if(geometry === undefined) return;

        if(featureMap[feature.properties.id] === undefined) return;

        featureMap[feature.properties.id].geometry = geometry.geometry;
      }

      this.refreshList();
    },

    delete(id) {
      const feature = this.features.find(f => f.properties.id === id);

      if(feature === undefined) return false;

      this.list = this.list.filter(fId => fId !== id);

      featureList = featureList.filter(f => f.properties.id !== id);

      if(geometryCache[id] !== undefined) {
        delete geometryCache[id];
      }
      if(featureMap[id] !== undefined) {
        delete featureMap[id];
      }

      return true;
    },

    deleteByCommonId(commonId) {
      const feature = this.features.find(f => f.properties.common_id === commonId);

      if(feature === undefined) return false;

      this.delete(feature.properties.id);

      return true;
    },

    getFeatureById(id, raw = false) {
      const manip = raw ? toRaw : (f) => { return f; };
      return manip(this.features.find(f => f.properties.id === id));
    }
  }
})
