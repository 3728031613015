const Screen = {
  async request(tag) {
    window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'ACTIVATE_KEEP_AWAKE' }));
    return {
      release: async () => {
        window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'DEACTIVATE_KEEP_AWAKE' }));
      }
    }
  },
}

export default Screen