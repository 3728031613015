import { storeToRefs } from 'pinia';


import { useRadarTowersStore } from '@/stores/radar_towers';

import App from '@/logic/App'
import MapKeeper from '@/logic/MapKeeper'

const useRadarPlayback = () => {
    const radarTowersStore = useRadarTowersStore()

    const {isPlaybackBuffering} = storeToRefs(radarTowersStore);

    const togglePlayback = async () => {
        const isPlaying = radarTowersStore.isPlaying;

        const mapRadar = MapKeeper.activeMap().stores['radar'];
        const towerId = mapRadar.activeTowerId;
        const products = MapKeeper.mapboxMaps().map(m => m.stores['radar'].activeProductCode);

        if (isPlaying) {
            radarTowersStore.isPlaying = false;
        } else {
            try {
                isPlaybackBuffering.value = true;
                await App.radar.loadHistory(towerId, products);
                App.radar.playScans(towerId, products);
            } catch(e) {
                console.error('Failed to buffer radar scans', e);
            } finally {
                isPlaybackBuffering.value = false;
            }
        }
    }

    return {
        togglePlayback
    }
}

export { useRadarPlayback }