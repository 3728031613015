import { ref, readonly } from 'vue'
import { useTimeoutFn } from '@vueuse/core'
import MapKeeper from '@/logic/MapKeeper'
import App from '@/logic/App'

const state = ref({
  userPosition: null,
  isLocating: false,
  isWatching: false,
  error: null,
})

let watchId = null

// Pulsing dot configuration
const size = 128
const pulsingDot = {
  width: size,
  height: size,
  data: new Uint8Array(size * size * 4),
  onAdd: function () {
    const canvas = document.createElement('canvas')
    canvas.width = this.width
    canvas.height = this.height
    this.context = canvas.getContext('2d')
  },
  render: function () {
    const duration = 1000
    const t = (performance.now() % duration) / duration
    const radius = (size / 2) * 0.3
    const outerRadius = (size / 2) * 0.7 * t + radius
    const context = this.context
    context.clearRect(0, 0, this.width, this.height)
    context.beginPath()
    context.arc(this.width / 2, this.height / 2, outerRadius, 0, Math.PI * 2)
    context.fillStyle = `rgba(255, 100, 100, ${1 - t})`
    context.fill()
    context.beginPath()
    context.arc(this.width / 2, this.height / 2, radius, 0, Math.PI * 2)
    context.fillStyle = 'rgba(255, 87, 34, 1)'
    context.strokeStyle = 'white'
    context.lineWidth = 2 + 4 * (1 - t)
    context.fill()
    context.stroke()
    this.data = context.getImageData(0, 0, this.width, this.height).data
    MapKeeper.triggerRepaint()
    return true
  }
}

const setPositionSymbol = (coords) => {
  const data = {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [coords.longitude, coords.latitude]
        }
      }
    ]
  }

  if (!MapKeeper.hasImage('pulsing-dot')) {
    MapKeeper.addImage('pulsing-dot', pulsingDot, { pixelRatio: 2 })
  }

  if (!MapKeeper.getSource('ww-dot-point')) {
    MapKeeper.addSource('ww-dot-point', {
      type: 'geojson',
      data
    })
  } else {
    MapKeeper.getSource('ww-dot-point').setData(data)
  }

  if (!MapKeeper.getLayer('ww-location-pulsing-dot')) {
    MapKeeper.addLayer({
      id: 'ww-location-pulsing-dot',
      type: 'symbol',
      source: 'ww-dot-point',
      layout: {
        'icon-image': 'pulsing-dot'
      }
    })
  }
}

export const locateUser = async () => {
  if (!navigator._geolocation) {
    state.value.error = new Error('Geolocation is not supported.')
    return
  }

  state.value.isLocating = true
  state.value.error = null

  try {
    const permission = await navigator._geolocation.requestPermission()
    if (permission === 'denied') {
      throw new Error('Please enable location permissions in your settings to use this feature.')
    }

    const position = await Promise.race([
      navigator._geolocation.getCurrentPosition({
        enableHighAccuracy: true,
        maximumAge: 0
      }),
      new Promise((_, reject) => {
        setTimeout(() => {
          reject(new Error('Location request timed out. Please try again.'))
        }, 15000)
      })
    ])

    // Update location state
    state.value.userPosition = position
    const coords = position.coords

    // First move the map
    await new Promise(resolve => {
      MapKeeper.flyTo({
        center: [coords.longitude, coords.latitude],
        zoom: 8,
        essential: true
      })
      // Only need this event on one map
      MapKeeper.mapboxMapsFirst().once('moveend', resolve)
    })

    // Then set the position symbol
    setPositionSymbol(coords)

    // Try to turn on closest radar
    try {
      await App.radar.turnOnClosestRadar([coords.longitude, coords.latitude])
    } catch (e) {
      console.warn('Failed to turn on radar:', e)
    }

    return position
  } catch (error) {
    state.value.error = error
    console.error('Error getting location:', error)
    throw error
  } finally {
    state.value.isLocating = false
    state.value.userPosition = null
  }
}

export const watchUserPosition = async () => {
  if (!navigator._geolocation) {
    state.value.error = new Error('Geolocation is not supported.')
    return
  }

  // Clear any existing watch before starting a new one
  stopWatchingPosition()

  state.value.isLocating = true
  state.value.error = null

  try {
    const permission = await navigator._geolocation.requestPermission()
    if (permission === 'denied') {
      throw new Error('Please enable location permissions in your settings to use this feature.')
    }

    const watchPromise = new Promise((resolve) => {
      const id = navigator._geolocation.watchPosition(
        {
          enableHighAccuracy: true,
          maximumAge: 0
        },
        (position) => {
          state.value.userPosition = position
          setPositionSymbol(position.coords)
          state.value.isWatching = true
        }
      )
      resolve(id)
    })

    watchId = await Promise.race([
      watchPromise,
      new Promise((_, reject) => {
        useTimeoutFn(() => {
          reject(new Error('Location request timed out. Please try again.'))
        }, 15000)
      })
    ])

  } catch (error) {
    state.value.error = error
    console.error('Error getting location:', error)
  } finally {
    state.value.isLocating = false
  }
}

export const stopWatchingPosition = () => {
  if (watchId) {
    navigator._geolocation?.clearWatch(watchId)
    watchId = null
    state.value.isWatching = false

    // Clean up map layers
    if (MapKeeper.getLayer('ww-location-pulsing-dot')) {
      MapKeeper.removeLayer('ww-location-pulsing-dot')
      MapKeeper.removeSource('ww-dot-point')
    }
  }
}

// Export readonly state for external use
export const locationState = readonly(state)

