<script setup>
import { ref } from 'vue';
import {APPLE_APP_STORE_URL, GOOGLE_PLAY_STORE_URL} from '@/tools/constants'

const iosCopied = ref(false);
const androidCopied = ref(false);

const iosUrl = APPLE_APP_STORE_URL
const androidUrl = GOOGLE_PLAY_STORE_URL

const onCopyiOSUrl = async (url) => {
    try {
        await navigator.clipboard.writeText(url)
        iosCopied.value = true;
    } catch (err) {
        console.error('Async: Could not copy text: ', err);
    }

    setTimeout(() => {
        iosCopied.value = false;
    }, 2000)
}

const onCopyAndroidUrl = async (url) => {
    try {
        await navigator.clipboard.writeText(url)
        androidCopied.value = true;
    } catch (err) {
        console.error('Async: Could not copy text: ', err);
    }

    setTimeout(() => {
        androidCopied.value = false;
    }, 2000)
}
</script>

<template>
    <hr class="h-4">
    <p class="text-gray-800 dark:text-gray-200 text-center">WeatherWise Plus can be purchased through our iOS and Android apps.</p>
    <div class="flex flex-row gap-8 sm:gap-18 pt-4">
        <div class="flex flex-col gap-4">
            <div class="text-center dark:text-gray-200">iOS App</div>
            <img src="@/assets/mobile/ios-qr.png" alt="App Store">
            <a :href="iosUrl" target="_blank">
                <img src="@/assets/mobile/app-store-badge.svg" alt="App Store" class="w-[128px] sm:w-[168px] h-auto object-contain mx-auto flex justify-center">
            </a>
            <div class="relative">
                <div @click="onCopyiOSUrl(iosUrl)">
                    <input type="text"
                           class="col-span-6 bg-gray-50 dark:bg-ww-blue-100 border border-gray-300 dark:border-gray-600
                                  text-black dark:text-slate-300 opacity-100 text-sm rounded-lg
                                  focus:ring-blue-500 focus:border-blue-500 block w-full px-2.5 py-4"
                           :value="iosUrl"
                           disabled
                           readonly>
                </div>
                <button @click="onCopyiOSUrl(iosUrl)"
                        class="absolute end-2.5 top-1/2 -translate-y-1/2 text-gray-900 dark:text-gray-200
                               hover:bg-gray-100 dark:hover:bg-ww-blue-100 rounded-lg py-2 px-2.5
                               inline-flex items-center justify-center bg-white dark:bg-ww-blue-200
                               border-gray-200 dark:border-gray-600 border">
                    <span v-if="!iosCopied" class="inline-flex items-center">
                        <svg class="w-3 h-3 me-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
                            <path d="M16 1h-3.278A1.992 1.992 0 0 0 11 0H7a1.993 1.993 0 0 0-1.722 1H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2Zm-3 14H5a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2Zm0-4H5a1 1 0 0 1 0-2h8a1 1 0 1 1 0 2Zm0-5H5a1 1 0 0 1 0-2h2V2h4v2h2a1 1 0 1 1 0 2Z"/>
                        </svg>
                        <span class="text-xs font-semibold">Copy</span>
                    </span>
                    <span v-else class="inline-flex items-center">
                        <svg class="w-3 h-3 text-blue-700 me-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                        </svg>
                        <span class="text-xs font-semibold text-blue-700">Copied</span>
                    </span>
                </button>
            </div>
        </div>
        <div class="flex flex-col gap-4">
            <div class="text-center dark:text-gray-200">Android App</div>
            <img src="@/assets/mobile/android-qr.png" alt="Google Play">
            <a :href="androidUrl" target="_blank">
                <img src="@/assets/mobile/google-play-badge.svg" alt="Google Play" class="w-[138px] sm:w-[178px] h-auto object-contain mx-auto flex justify-center">
            </a>
            <div class="relative">
                <div @click="onCopyAndroidUrl(androidUrl)">
                    <input type="text"
                           class="col-span-6 bg-gray-50 dark:bg-ww-blue-100 border border-gray-300 dark:border-gray-600
                                  text-black dark:text-slate-300 opacity-100 text-sm rounded-lg
                                  focus:ring-blue-500 focus:border-blue-500 block w-full px-2.5 py-4"
                           :value="androidUrl"
                           disabled
                           readonly>
                </div>
                <button @click="onCopyAndroidUrl(androidUrl)"
                        class="absolute end-2.5 top-1/2 -translate-y-1/2 text-gray-900 dark:text-gray-200
                               hover:bg-gray-100 dark:hover:bg-ww-blue-100 rounded-lg py-2 px-2.5
                               inline-flex items-center justify-center bg-white dark:bg-ww-blue-200
                               border-gray-200 dark:border-gray-600 border">
                    <span v-if="!androidCopied" class="inline-flex items-center">
                        <svg class="w-3 h-3 me-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
                            <path d="M16 1h-3.278A1.992 1.992 0 0 0 11 0H7a1.993 1.993 0 0 0-1.722 1H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2Zm-3 14H5a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2Zm0-4H5a1 1 0 0 1 0-2h8a1 1 0 1 1 0 2Zm0-5H5a1 1 0 0 1 0-2h2V2h4v2h2a1 1 0 1 1 0 2Z"/>
                        </svg>
                        <span class="text-xs font-semibold">Copy</span>
                    </span>
                    <span v-else class="inline-flex items-center">
                        <svg class="w-3 h-3 text-blue-700 me-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                        </svg>
                        <span class="text-xs font-semibold text-blue-700">Copied</span>
                    </span>
                </button>
            </div>
        </div>
    </div>
</template>