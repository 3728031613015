import modals from '@/logic/Modals/Helper'

import U1_PlusInBrowser from '@/components/Updates/1_PlusInBrowser.vue'
import U2_WindowsMacApp from '@/components/Updates/2_WindowsMacApp.vue'
import U3_WindAirWeatherAlerts from '@/components/Updates/3_WindAirWeatherAlerts.vue'

const updateRow = (version, component, released_at) => {
    return {
        version,
        component,
        released_at
    }
};

const UPDATES = [
    updateRow(1, U1_PlusInBrowser, "2025-03-25 16:00:00"),
    updateRow(2, U2_WindowsMacApp, "2025-03-27 15:50:00"),
    updateRow(3, U3_WindAirWeatherAlerts, "2025-04-03 16:00:00")
]

const useAppUpdates = () => {
    const showModal = async () => {
        // Get from local storage
        let rawVersion = window.localStorage.getItem('seenAppUpdateVersion');
        if(typeof rawVersion !== 'string') {
            rawVersion = '0'
        }

        const version = parseInt(rawVersion)

        let updates = UPDATES

        // If they haven't seen any updates yet, show only the last 3
        if(version === 0) {
            // Show only last 3 updates
            updates = updates.slice(-3)
        }

        // Now filter out any updates that they have already seen
        updates = updates.filter(update => update.version > version).reverse()

        // Check if user has seen all the updates already
        if(updates.length === 0) return;

        modals.appUpdates(updates);
    };

    const seenAll = () => {
        const lastUpdate = UPDATES.at(-1);
        window.localStorage.setItem('seenAppUpdateVersion', lastUpdate.version);
    }

    return {
        showModal,
        seenAll,
    }
}

export {
    useAppUpdates
}
