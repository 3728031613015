import { defineStore } from 'pinia'

export const useMapsStore = defineStore('maps', {
  state: () => ({
    isUserInteracting: false,
    mapsToLoad: [],
    maps: [],
    activeMapId: 0
  }),

  actions: {
    
  }
})
