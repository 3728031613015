import { toRaw } from 'vue'
import { defineStore } from 'pinia'
import moment from 'moment'

import UrlHash from '@/tools/url-hash'

import radarTowers from '@/data/radar_towers.js'
import { polarProducts, products, productGroups } from '@/data/radar_products'

const DEFAULT_TOWER_ID = '0';
const DEFAULT_PRODUCT_CODE = '';

export const useMapRadarStore = (mapId) => {
  const myStore = {
    state: () => ({
      towers: [],
      activeTowerId: DEFAULT_TOWER_ID,
      activeProductCode: DEFAULT_PRODUCT_CODE,
      availableProducts: [],
      availablePolarProducts: [],
      activeColorMap: null,
      scanDatetime: null,
      scanVcp: null,
    }),

    getters: {
      anyActive: (state) => state.towers.length > 0,
      activeTower: (state) => state.towers[0],
      scanLocalTime: (state) => moment.utc(toRaw(state.scanDatetime)).local().format('HH:mm:ss'),
      scanLocalDatetime: (state) => moment.utc(toRaw(state.scanDatetime)).local().format('YYYY-MM-DD HH:mm:ss'),
      scanIsOld: (state) => moment.utc(toRaw(state.scanDatetime)).isBefore(moment().utc().subtract(1, 'hours')),
      scanIsNew: (state) => moment.utc(toRaw(state.scanDatetime)).isAfter(moment.utc().subtract(10, 'minutes')),
      productName: (state) => products[state.activeProductCode] ?? null,
      towerProducts: (state) => {
        return productGroups.map(item => ({
          ...item,
          tilts: item.tilts.filter(tilt => state.availablePolarProducts.includes(tilt.product))
        }));
      },
      activeProductGroup(state) {
        const active = this.towerProducts.find(item => {
          return item.tilts.some(subItem => subItem.product === state.activeProductCode)
        })

        return active ?? null
      },
      activeProductGroupUnit(state) {
        if (! state.activeProductGroup?.unit) {
          return '';
        }

        return state.activeProductGroup.unit
      },
      activeColorTable(state) {
        return state.activeColorMap?.colors
      }
    },

    actions: {
      setActiveTower(feature, product) {
        const products = feature.properties.products;

        const map = {};
        polarProducts.forEach(p => map[p] = 1)

        const p = {
          towers: [feature],
          activeTowerId: feature.properties.id,
          activeProductCode: product,
          availableProducts: products,
          availablePolarProducts: products.filter(p => map[p] !== undefined)
        }

        // console.log(this.activeProductGroup, this.activeProductCode)

        this.$patch(p);
      },

      setScanDatetime(dt) {
        this.scanDatetime = dt;
      },

      clearScanDatetime() {
        this.setScanDatetime(null)
      },

      setScanVcp(vcp) {
        // Check that the VCP is 'valid'
        if(vcp !== null) {
          if(vcp < 1) {
            vcp = null;
          }
        }
        this.scanVcp = vcp;
      },

      clearScanVcp() {
        this.setScanVcp(null)
      },

      setColorMap(map) {
        this.activeColorMap = map;
      },

      clearColorMap() {
        this.setColorMap();
      },

      clear() {
        this.towers = [];
        this.activeTowerId = DEFAULT_TOWER_ID;
        // Don't reset active product code...
        this.activeColorMap = null;
        this.availableProducts = [];
        this.availablePolarProducts = [];
        this.scanDatetime = null;
      }
    },

    persist: {
      // Store some properties related to radar in the url
      storage: {
        getItem: (key) => {
          const params = new UrlHash();

          const towerId = params.has('rt') ? params.get('rt') : DEFAULT_TOWER_ID;
          const productCode = params.has('rp') ? params.get('rp') : DEFAULT_PRODUCT_CODE;

          // console.log(towerId, productCode)

          // Check that the tower exists
          const feature = radarTowers.features.find(f => f.properties.id === towerId);
          if(feature === undefined) return;

          // Check that the tower has the product available
          if(! feature.properties.products.includes(productCode)) return;

          const s = store()

          s.$patch({
            activeTowerId: towerId,
            activeProductCode: productCode
          });
        },
        setItem: (key, value) => {
          if(mapId !== 0) return;
          
          const s = store()

          const params = new UrlHash();
          if(s.activeTowerId === DEFAULT_TOWER_ID) {
            params.delete('rt')
            params.delete('rp')
          }
          else {
            params.set('rt', s.activeTowerId)
            params.set('rp', s.activeProductCode)
          }
          params.save();
        }
      }
    },
  };

  const store = defineStore(`map_radar:${mapId}`, myStore)

  return new store()
};