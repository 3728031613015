import { render } from 'vue'

import { useMapsStore } from '@/stores/maps';
import MapKeeper from '@/logic/MapKeeper'

export const debounce = (func, timeout) => {
	let timer;
	return (...args) => {
		clearTimeout(timer);
		timer = setTimeout(() => { func.apply(this, args); }, timeout);
	};
};

export const stopPropagation = (fn) => {
	return (...args) => {
		const e = args[0];

		if(e.ww_stopPropagation_ === true) return;

		e.ww_stopPropagation_ = true;

		return fn.apply(this, args);
	};
};

let bufferedPopupContentFns = [];

const createPopup = debounce((e) => {
	MapKeeper.popups.clear();

	const closePopup = debounce(() => {
		MapKeeper.popups.clear();
	}, 1);

	MapKeeper.mapboxMaps().forEach(m => {
		const div = window.document.createElement('div');

		const bufferedPopupContent = bufferedPopupContentFns.map(fn => fn());

		bufferedPopupContent.forEach((d, i) => {
			div.appendChild(d);
			if(i+1 !== bufferedPopupContent.length) {
				const hr = document.createElement('hr');
				hr.style = 'margin-top: 5px; margin-bottom: 3px;'

				div.appendChild(hr)
			}
		})

		const p = MapKeeper.popups.create()
	        .setLngLat(e.lngLat)
	        .setDOMContent(div)
	        .on('close', () => {
	        	bufferedPopupContent.forEach(d => render(null, d));

	        	closePopup();
	        })
	        .addTo(m);
	    MapKeeper.popups.push(p);
	});

	bufferedPopupContentFns = [];
}, 50);

export const renderToPopup = (fn) => {
	return (...args) => {
		const e = args[0];

		if(e.ww_stopPropagation_ === true) return;

		const content = fn.apply(this, args);

		if(typeof content !== 'function') {
			console.error('Content for popup needs to be a function');
			return;
		}

		bufferedPopupContentFns.push(content)

		// if(content instanceof HTMLElement) {
		// 	bufferedPopupContent.push(content);
		// }
		// else if(content.constructor === Array) {
		// 	content.forEach(c => bufferedPopupContent.push(c));
		// }
		// else {
		// 	if(!(content.content instanceof HTMLElement)) {
		// 		throw new Error('Missing content for popup');
		// 	}

		// 	bufferedPopupContent.push(content.content);

		// 	if(content.propagate === false) {
		// 		e.ww_stopPropagation_ = true;
		// 	}
		// }		

		createPopup(e);
	};
};

export const applyIsInteracting = (map) => {
	const mapsStore = useMapsStore();

	// List of events that indicate user starts interacting
	const interactionStartEvents = ['mousedown', 'dragstart', 'zoomstart', 'rotatestart', 'pitchstart'];

	// List of events that indicate user stops interacting
	const interactionEndEvents = ['mouseup', 'dragend', 'zoomend', 'rotateend', 'pitchend'];

	// Set up listeners for start events
	interactionStartEvents.forEach(event => {
		map.on(event, () => {
			mapsStore.isUserInteracting = true;
		});
	});

	// Set up listeners for end events
	interactionEndEvents.forEach(event => {
		map.on(event, () => {
			mapsStore.isUserInteracting = false;
		});
	});
};