<script setup lang="ts">
import { onUnmounted, ref } from 'vue';
import { Icon } from '@iconify/vue';

import Marketing from '@/logic/Marketing'

const marketingAssetsBaseUrl = Marketing.assetsBaseUrl();
const videoSrcRef = `${marketingAssetsBaseUrl}/plus/upgrade-through-mobile.mp4`
const videoHasLoaded = ref(false)
const videoRef = ref(null);

const handleVideoHasLoaded = () => {
  videoHasLoaded.value = true;
}

onUnmounted(() => {
  if (videoRef.value) {
    videoRef.value.pause();
    videoRef.value.src = '';
  }
});
</script>
<template>
  <div class="text-gray-800 dark:text-gray-200">
    <p class="mt-5">We currently only support the purchase of a WeatherWise Plus Subscription through our Android and iOS apps. Search your App Store for: WeatherWise.app</p>

    <div class="relative">
      <video
        ref="videoRef"
        :src="videoSrcRef"
        @loadeddata="handleVideoHasLoaded"
        class="video mx-auto mt-4 max-h-[70vh]"
        :class="{
          'invisible': !videoHasLoaded
        }"
        autoplay
        muted
        loop
        playsinline
        preload="metadata"
      ></video>

      <div v-if="!videoHasLoaded" class="absolute top-[50%] ml-[-26px] left-[50%] mt-[-26px]">
        <Icon icon="line-md:loading-twotone-loop" class="size-14 text-gray-900 dark:text-white" />
      </div>
    </div>
  </div>
</template>