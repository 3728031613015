import ExpoBridge from '../Expo/ExpoBridge';
import { hasWeatherWiseBridge } from '@/logic/Extra/helpers'
import moment from 'moment'
import mime from 'mime-types';

const DownloadWrapper = {
  blob: (blob, mimeType) => {
    if (hasWeatherWiseBridge()) {
      return ExpoBridge.download.blob(blob, mimeType);
    } else {
      const extension = mime.extension(mimeType)
      const dt = moment.utc().format("YYYY_MM_DD_HH_mm_ss");
      const filename = `WeatherWise_${dt}.${extension}`;

      let url = window.URL.createObjectURL(blob)
      let a = document.createElement('a')
      a.style.display = 'none'
      a.href = url
      a.target = '_blank'
      a.download = filename;
      document.body.appendChild(a)
      a.click()
      window.URL.revokeObjectURL(url)
    }
  }
}

export default DownloadWrapper
