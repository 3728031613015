<template>
    <h1 class="text-lg font-bold">Introducing Wind, Dust, Fog, Smoke and Air Quality related Weather Alerts!</h1>

    <p class="mt-3">Alerts issued by the NWS appear instantly inside WeatherWise. Customize their look and feel like you can with other weather alerts.</p>

    <p class="mt-3">Don't get caught off guard:</p>
    <ul class="list-inside">
        <li>💨 Wind – hold onto your hats!</li>
        <li>🌫️ Fog – mystery movie mode: ON</li>
        <li>🌪️ Dust – not today, allergies</li>
        <li>🔥 Smoke – nope, not a BBQ</li>
        <li>😮‍💨 Air Quality – because breathing should be chill</li>
    </ul>
</template>