import { defineStore } from 'pinia'
import { useHead } from '@unhead/vue'

import UrlHash from '@/tools/url-hash'

import { PAGE_TITLE } from '@/brand'

const DEFAULT_MODE = 'RADAR';

import App from '@/logic/App'
import ToDesktop from '@/logic/ToDesktop'

export const useAppStore = defineStore('app', {
  state: () => ({
    booted: false,
    mode: (() => {
      const params = new UrlHash(ToDesktop.isToDesktop());
      return params.has('m') ? params.get('m') : DEFAULT_MODE;
    })(),
    updating: false,
    radar_drawer_open: false,
    colortable_bar_open: false,
    warnings_sidebar_open: false,
    warnings_sidebar_scroll_top: 0,
    menu_open: false,
    wakeLock: null as { release: () => Promise<void> } | null,
    deepLink: null
  }),

  getters: {
    isRadarMode: (state) => state.mode === 'RADAR',
    isSatelliteMode: (state) => state.mode === 'SATELLITE',
  },

  actions: {
    ready() {
      this.booted = true
    },

    isReady() {
      return this.booted
    },

    setDeepLink(link) {
      this.deepLink = link
    },

    setMode(mode) {
      this.hideAllModes()

      this.mode = mode;

      useHead({
        title: PAGE_TITLE
      })

      this.showMode()

      const params = new UrlHash();
      params.set('m', this.mode);
      params.save();
    },

    hideAllModes() {
      for (const feature of ['radar', 'satellite', 'outlooks']) {
        App[feature]?.hide();
      }
    },

    showMode() {
      App[this.mode.toLowerCase()]?.show();
    },

    toggleWarningsSidebar(override = null) {
      this.warnings_sidebar_open = override !== null ? override : !this.warnings_sidebar_open;
    },

    toggleMenu(override = null) {
      this.menu_open = override !== null ? override : !this.menu_open;
    },

    async activateWakeLock() {
      try {
        this.wakeLock = await navigator._wakeLock.request('screen')
      } catch (error) {
        console.error('Failed to activate wake lock:', error)
      }
    },

    async deactivateWakeLock() {
      if (!this.wakeLock) return

      try {
        await this.wakeLock.release()
        this.wakeLock = null
      } catch (error) {
        console.error('Failed to release wake lock:', error)
      }
    }
  }
})
