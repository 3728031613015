import mapboxgl from 'mapbox-gl'
import moment from 'moment'
import { useModal, useModalSlot } from 'vue-final-modal'
import { bezierSpline, lineString, centroid } from '@turf/turf'

import { stopPropagation, renderToPopup } from '@/tools/mapbox-map'
import { debounce } from '@/tools/helpers'
import { circle } from '@/tools/graphics'
import { tropicalStormWindSpeedToColor, tropicalStormWindSpeedToCategory } from '@/tools/helpers'
import { CARET_SVG } from '@/tools/svgs'

import socket from '@/logic/Socket'
import api from '@/logic/Api'
import SimpleModal from './Modals/Templates/Simple.vue'
import TropicalStormInfoModal from './Tropical/StormInfo.vue'
import TropicalStormProductHelpModal from './Tropical/StormProductHelp.vue'
import TropicalDisturbanceInfoModal from './Tropical/DisturbanceInfo.vue'

import { useTropicalStore } from '@/stores/tropical'
import { useTropicalSettingsStore } from '@/stores/settings/tropical'
import { useAppStore } from '@/stores/app'

import UrlHash from '@/tools/url-hash'

import App from '@/logic/App'
import MapKeeper from '@/logic/MapKeeper'

export default class Outlooks {
  constructor() {
    this.tropicalStore = useTropicalStore()
    this.settings = useTropicalSettingsStore()
    this.appStore = useAppStore()

    // Storms
    this.forecastTrackPointSourceId = 'ww-tropical-forecast-track-point-source'
    this.forecastTrackSourceId = 'ww-tropical-forecast-track-source'
    this.forecastTrackConeSourceId = 'ww-tropical-forecast-track-cone-source'
    this.bestTrackSourceId = 'ww-tropical-best-track-source'
    this.peakStormSurgeSourceId = 'ww-tropical-peak-storm-surge-source'

    // Disturbances
    this.disturbancesSourceId = 'ww-tropical-disturbances-source';

    // TOA (Time of Arrival)
    this.toaSourceId = 'ww-tropical-toa-source';

    // WSP (Wind Speed Probabilites)
    this.wspSourceId = 'ww-tropical-wsp-source';

    // FWR (Forecast Wind Radii)
    this.fwrSourceId = 'ww-tropical-fwr-source';

    this.sources = [
      this.forecastTrackPointSourceId,
      this.forecastTrackSourceId,
      this.forecastTrackConeSourceId,
      this.bestTrackSourceId,
      this.peakStormSurgeSourceId,
      this.disturbancesSourceId,
      this.toaSourceId,
      this.wspSourceId,
      this.fwrSourceId
    ];

    this.sources.forEach(s => {
      MapKeeper.addSource(s, {
        type: 'geojson',
        generateId: true,
        data: {
          type: 'FeatureCollection',
          features: []
        }
      })
    })

    // Storms
    this.symbolForecastTrackPointLayerId = 'ww-tropical-forecast-track-point-layer'
    this.lineForecastTrackLayerId = 'ww-tropical-forecast-track-line-layer'
    this.lineForecastTrackConeLayerId = 'ww-tropical-forecast-track-cone-line-layer'
    this.fillForecastTrackConeLayerId = 'ww-tropical-forecast-track-cone-fill-layer'
    this.lineBestTrackLayerId = 'ww-tropcal-best-track-line-layer';
    this.linePeakStormSurgeLayerId = 'ww-tropical-peak-storm-surge-line-layer';

    // Disturbances
    this.lineDisturbancesLayerId = 'ww-tropical-disturbances-line-layer'
    this.fillDisturbancesLayerId = 'ww-tropical-disturbances-fill-layer'

    // TOA
    this.lineToaLayerId = 'ww-tropical-toa-line-layer';
    this.symbolToaLayerId = 'ww-tropical-toa-symbol-layer';

    // WSP
    this.fillWspLayerId = 'ww-tropical-wsp-fill-layer';

    // FWR
    this.lineForecastWindRadiiLayerId = 'ww-tropical-forecast-wind-radii-line-layer';
    this.fillForecastWindRadiiLayerId = 'ww-tropical-forecast-wind-radii-fill-layer';

    this.layers = [
      this.symbolForecastTrackPointLayerId,
      this.lineForecastTrackLayerId,
      this.lineForecastTrackConeLayerId,
      this.fillForecastTrackConeLayerId,
      this.lineBestTrackLayerId,
      this.linePeakStormSurgeLayerId,
      this.lineDisturbancesLayerId,
      this.fillDisturbancesLayerId,
      this.lineToaLayerId,
      this.symbolToaLayerId,
      this.fillWspLayerId,
      this.lineForecastWindRadiiLayerId,
      this.fillForecastWindRadiiLayerId
    ];

    this.activeSocketRooms = [];
    this.storms = null;
    this.disturbances = null;

    this.addStormsLayer();
    this.addToaLayer();
    this.addFwrLayer();
    this.addWspLayer();
    this.addDisturbancesLayer();

    // Storms
    const onForecastTrackPointClick = renderToPopup((e) => {
      if(e.features.length == 0) return;
      // console.log(e.features);

      const validFeatures = e.features.filter(f => f.source.startsWith('ww-tropical-'));

      if(validFeatures.length === 0) return

      if(this.storms === null) return false;

      return () => {
        const point = validFeatures[0];

        // 4 Valid at: 12:00 PM CST September 25, 2024  valid_at 2024-09-25T18:00:00.000Z
        // 5 Location: 16.5 N, -101.4 W  location 16.5 N, -101.4 W
        // 6 Maximum Wind: 35 knots (45 mph)  maximum_wind 35 knots (45 mph)
        // 7 Wind Gusts: 45 knots (50 mph)  wind_gusts 45 knots (50 mph)
        // 8 Motion: NNE  motion NNE
        // 9 Minimum Pressure: 999 mb  minimum_pressure 999 mb

        // console.log(point)

        const storm = this.storms.find(s => s.id === point.properties.storm_id)

        // console.log({point}, storm, points)

        const div = window.document.createElement('div');
        let html = `<div class='flex cursor-pointer'>`;
        html+=`<div>`;

        html+=`<div class='text-center'><strong>${storm.name}</strong></div>`;

        if(point.properties.maximum_wind_mph) {
          html+=`<div>Wind Speed: <strong>${point.properties.maximum_wind_mph.toFixed(0)}mph</strong></div>`;
        }

        if(point.properties.wind_gusts_mph) {
          html+=`<div>Wind Gusts: <strong>${point.properties.wind_gusts_mph.toFixed(0)}mph</strong></div>`;
        }

        if(point.properties.maximum_wind_kt && tropicalStormWindSpeedToCategory(point.properties.maximum_wind_kt) > 0) {
          html+=`<div>Category: <strong>${tropicalStormWindSpeedToCategory(point.properties.maximum_wind_kt)}</strong></div>`;
        }

        if(point.properties.motion) {
          html+=`<div>Heading: <strong>${point.properties.motion}</strong></div>`;
        }

        if(point.properties.minimum_pressure) {
          html+=`<div>Min. Pressure: <strong>${point.properties.minimum_pressure}</strong></div>`;
        }

        if(point.properties.valid_at) {
          html+=`<div class='text-center mt-1 text-xs'>${moment.utc(point.properties.valid_at).local().format('MMM Do, h A')}</div>`;
        }

        html+=`</div>`;

        html+=`<div>${CARET_SVG}</div>`;

        html+=`</div>`;

        div.innerHTML = html;
        div.addEventListener('click', () => {
          MapKeeper.popups.clear();

          this.openStormInfoModel(storm);
        });

        const container = window.document.createElement('div');
        container.appendChild(div)

        if(this.appStore.mode !== 'SATELLITE') {
          const satelliteButton = window.document.createElement('button')
          satelliteButton.innerText = 'Show on Satellite';
          satelliteButton.classList.add("w-full", "px-4", "py-1", "font-bold", "rounded-lg", "text-white", "bg-blue-600", "hover:bg-blue-700", "mt-2")
          satelliteButton.addEventListener('click', (e) => {
            MapKeeper.popups.clear();

            // Determine satellite with best coverage
            const center = centroid(point.geometry);
            const satelliteId = App.satellite.findBestSatelliteFor(center.geometry.coordinates);

            App.satellite.turnOnSatellite(MapKeeper.activeMap(), satelliteId);

            this.appStore.setMode('SATELLITE');

            return false;
          })
          container.appendChild(satelliteButton)
        }

        return container;
      }
    });

    MapKeeper.on('click', this.symbolForecastTrackPointLayerId, onForecastTrackPointClick);

    const onPeakStormSurgeLinkClick = stopPropagation((e) => {
      if(e.features.length == 0) return;

      const validFeatures = e.features.filter(f => f.source.startsWith('ww-tropical-'));

      if(validFeatures.length === 0) return

      // console.log(validFeatures)

      if(this.storms === null) return false;

      const line = validFeatures[0];

      // console.log(line)

      const storm = this.storms.find(s => s.id === line.properties.storm_id)

      // console.log({point}, storm, points)

      const div = window.document.createElement('div');
      let html = `<div class='cursor-pointer'>`;

      const nameParts = line.properties.name.split('...').map(p => p.trim());

      html+=`<div class='text-center mb-1'><strong>Peak Storm Surge Forecast</strong></div>`;
      html+=`<div class='text-center'><strong>${nameParts[0]}</strong></div>`;
      if(nameParts.length > 1) {
        html+=`<div class='text-center'><u><strong>${nameParts[1]}</strong></u></div>`;
      }

      html+=`</div>`;

      div.innerHTML = html;
      div.addEventListener('click', () => {
        MapKeeper.popups.clear();
      });

      const p = MapKeeper.popups.create()
        .setLngLat(e.lngLat)
        .setDOMContent(div);

      MapKeeper.popups.render(p);
    });

    MapKeeper.on('click', this.linePeakStormSurgeLayerId, onPeakStormSurgeLinkClick);

    const onForecastConeClick = renderToPopup((e) => {
      if(e.features.length == 0) return;
      // console.log(e.features);

      const validFeatures = e.features.filter(f => f.source.startsWith('ww-tropical-'));

      if(validFeatures.length === 0) return

      return () => {
        const bufferedFeatures = [validFeatures[0]];

        // TODO
        // Fix this hack
        const stormId = bufferedFeatures[0].properties.id;
        const storm = this.storms.find(s => s.id === stormId)

        const titleHtml = bufferedFeatures.map(f => `<div class='text-center'><strong>${storm.name}</strong></div>`).join('') + `<div class='text-center text-xs italic'>Cone of Uncertainty</div>`;

        const div = window.document.createElement('div');
        div.innerHTML = `<div class='flex cursor-pointer'><div class='flex-1'>${titleHtml}</div><div>${CARET_SVG}</div></div>`;
        div.addEventListener('click', () => {
          MapKeeper.popups.clear();

          // console.log(bufferedFeatures[0])

          if(this.storms === null) return false;

          // console.log(storm)

          this.openStormInfoModel(storm);
        });

        const container = window.document.createElement('div');
        container.appendChild(div)

        if(this.appStore.mode !== 'SATELLITE') {
          const satelliteButton = window.document.createElement('button')
          satelliteButton.innerText = 'Show on Satellite';
          satelliteButton.classList.add("w-full", "px-4", "py-1", "font-bold", "rounded-lg", "text-white", "bg-blue-600", "hover:bg-blue-700", "mt-2")
          satelliteButton.addEventListener('click', (e) => {
            MapKeeper.popups.clear();

            // Determine satellite with best coverage
            const center = centroid(bufferedFeatures[0].geometry);
            const satelliteId = App.satellite.findBestSatelliteFor(center.geometry.coordinates);

            App.satellite.turnOnSatellite(MapKeeper.activeMap(), satelliteId);

            this.appStore.setMode('SATELLITE');

            return false;
          })
          container.appendChild(satelliteButton)
        }

        return container;
      }
    });

    MapKeeper.on('click', this.fillForecastTrackConeLayerId, onForecastConeClick);

    // Disturbances
    const onDisturbanceClick = renderToPopup((e) => {
      if(e.features.length == 0) return;
      // console.log(e.features);

      const validFeatures = e.features.filter(f => f.source.startsWith('ww-tropical-'));

      if(validFeatures.length === 0) return

      return () => {
        const feature = validFeatures[0];

        let titleHtml = `<div class='text-center mb-1 text-gray-900 dark:text-slate-300'><strong>Tropical Disturbance</strong></div>`;
        titleHtml+= `<div>Day 2: <strong>${feature.properties.day_2_percentage} (${feature.properties.day_2_category})</strong></div>`;
        titleHtml+= `<div>Day 7: <strong>${feature.properties.day_7_percentage} (${feature.properties.day_7_category})</strong></div>`;

        const div = window.document.createElement('div');
        div.innerHTML = `<div class='flex cursor-pointer'><div>${titleHtml}</div><div>${CARET_SVG}</div></div>`;
        div.addEventListener('click', () => {
          MapKeeper.popups.clear();

          this.openDisturbanceInfoModel(feature);
        });

        const container = window.document.createElement('div');
        container.appendChild(div)

        if(this.appStore.mode !== 'SATELLITE') {
          const satelliteButton = window.document.createElement('button')
          satelliteButton.innerText = 'Show on Satellite';
          satelliteButton.classList.add("w-full", "px-4", "py-1", "font-bold", "rounded-lg", "text-white", "bg-blue-600", "hover:bg-blue-700", "mt-2")
          satelliteButton.addEventListener('click', (e) => {
            MapKeeper.popups.clear();

            // Determine satellite with best coverage
            const center = centroid(feature.geometry);
            const satelliteId = App.satellite.findBestSatelliteFor(center.geometry.coordinates);

            App.satellite.turnOnSatellite(MapKeeper.activeMap(), satelliteId);

            this.appStore.setMode('SATELLITE');

            return false;
          })
          container.appendChild(satelliteButton)
        }

        return container;
      }
    });

    MapKeeper.on('click', this.fillDisturbancesLayerId, onDisturbanceClick);

    // FWR
    const onFwrClick = renderToPopup((e) => {
      if(e.features.length == 0) return;
      // console.log(e.features);

      const validFeatures = e.features.filter(f => f.source.startsWith('ww-tropical-fwr'));

      if(validFeatures.length === 0) return

      return () => {
        const feature = validFeatures[0];

        // console.log(feature)

        let titleHtml = `<div class='text-center mb-1'><strong>Forecast Wind Radii</strong></div>`;
        titleHtml+= `<div>Wind speed: <strong>${feature.properties.name}</strong></div>`;

        const div = window.document.createElement('div');
        div.innerHTML = `<div class='flex cursor-pointer'><div>${titleHtml}</div></div>`;
        div.addEventListener('click', () => {
          MapKeeper.popups.clear();
        });

        return div;
      }
    });

    MapKeeper.on('click', this.fillForecastWindRadiiLayerId, onFwrClick);

    // WSP
    const onWspClick = renderToPopup((e) => {
      if(e.features.length == 0) return;
      // console.log(e.features);

      const validFeatures = e.features.filter(f => f.source.startsWith('ww-tropical-wsp'));

      if(validFeatures.length === 0) return

      return () => {
        const feature = validFeatures[0];

        // console.log(feature)

        let titleHtml = `<div class='text-center mb-1'><strong>${this.tropicalStore.wsp} Wind Speed</strong></div>`;
        titleHtml+= `<div>Probability: <strong>${feature.properties.name}</strong></div>`;

        const div = window.document.createElement('div');
        div.innerHTML = `<div class='flex cursor-pointer'><div>${titleHtml}</div></div>`;
        div.addEventListener('click', () => {
          MapKeeper.popups.clear();
        });

        return div;
      }
    });

    MapKeeper.on('click', this.fillWspLayerId, onWspClick);

    // let hoveredPolygonId = null;
    // MapKeeper.on('mousemove', this.fillWspLayerId, stopPropagation((e) => {
    //   if(e.features.length === 0) return;

    //   if (hoveredPolygonId !== null && hoveredPolygonId !== clickedPolygonId) {
    //     MapKeeper.setFeatureState(
    //       { source: this.wspSourceId, id: hoveredPolygonId },
    //       { hover: false }
    //     );
    //   }
    //   hoveredPolygonId = e.features[0].id;
    //   MapKeeper.setFeatureState(
    //     { source: this.wspSourceId, id: hoveredPolygonId },
    //     { hover: true }
    //   );
    // }));
    // MapKeeper.on('mouseleave', this.fillWspLayerId, () => {
    //   if (hoveredPolygonId !== null && hoveredPolygonId !== clickedPolygonId) {
    //     MapKeeper.setFeatureState(
    //       { source: this.wspSourceId, id: hoveredPolygonId },
    //       { hover: false }
    //     );
    //   }
    //   hoveredPolygonId = null;
    // });

    const h5 = circle({
      size: 36,
      color: tropicalStormWindSpeedToColor(157),
      border_color: '#FFFFFF',
      border_size: 2,
      text: 'H5'
    });
    MapKeeper.addImage('tropical-forecast-point-h5', h5, { pixelRatio: 2 });

    const h4 = circle({
      size: 36,
      color: tropicalStormWindSpeedToColor(130),
      border_color: '#FFFFFF',
      border_size: 2,
      text: 'H4'
    });
    MapKeeper.addImage('tropical-forecast-point-h4', h4, { pixelRatio: 2 });

    const h3 = circle({
      size: 36,
      color: tropicalStormWindSpeedToColor(111),
      border_color: '#FFFFFF',
      border_size: 2,
      text: 'H3'
    });
    MapKeeper.addImage('tropical-forecast-point-h3', h3, { pixelRatio: 2 });

    const h2 = circle({
      size: 36,
      color: tropicalStormWindSpeedToColor(96),
      border_color: '#FFFFFF',
      border_size: 2,
      text: 'H2'
    });
    MapKeeper.addImage('tropical-forecast-point-h2', h2, { pixelRatio: 2 });

    const h1 = circle({
      size: 36,
      color: tropicalStormWindSpeedToColor(74),
      border_color: '#FFFFFF',
      border_size: 2,
      text: 'H1'
    });
    MapKeeper.addImage('tropical-forecast-point-h1', h1, { pixelRatio: 2 });

    const ts = circle({
      size: 36,
      color: tropicalStormWindSpeedToColor(39),
      border_color: '#FFFFFF',
      border_size: 2,
      text: 'TS'
    });
    MapKeeper.addImage('tropical-forecast-point-ts', ts, { pixelRatio: 2 });

    const td = circle({
      size: 36,
      color: tropicalStormWindSpeedToColor(0),
      border_color: '#FFFFFF',
      border_size: 2,
      text: 'TD'
    });
    MapKeeper.addImage('tropical-forecast-point-td', td, { pixelRatio: 2 });

    // Load data
    (async () => {
      try {
        await this.fetchAndRenderStorms();
      }
      catch(e) {
        console.error('Failed to fetch or render tropical storms', e);
      }

      const room = `tropical:USA-NHC-storms`;
      this.activeSocketRooms.push(room)
      socket.roomJoin(room)
      socket.on(room, async (data) => {
        console.log('tropical storm update', room, data)

        await this.fetchAndRenderStorms();
      });

      const params = new UrlHash();
      if(params.has('trs')) {
        const id = params.get('trs');

        params.delete('trs')
        params.save()

        const storm = this.storms.find(s => s.id === id);

        if(storm === undefined) return;

        setTimeout(() => {
          this.fitBoundsForForecast(storm);
        }, 200);
      }
    })();

    (async () => {
      try {
        await this.fetchAndRenderDisturbances();
      }
      catch(e) {
        console.error('Failed to fetch or render tropical disturbances', e);
      }

      const room = `tropical:USA-NHC-disturbances`;
      this.activeSocketRooms.push(room)
      socket.roomJoin(room)
      socket.on(room, async (data) => {
        console.log('tropical disturbance update', room, data)

        await this.fetchAndRenderDisturbances();
      });

      const params = new UrlHash();
      if(params.has('trd')) {
        const id = params.get('trd');

        // const storm = this.storms.find(s => s.id === id);

        // if(storm === undefined) return;

        // this.fitBoundsForForecast(storm);
      }
    })();

    const manageVisible = (state) => {
      if(state.visible) {
        this.show();
      }
      else {
        this.hide();
      }
    }

    this.settings.$subscribe((mutation, state) => {
      manageVisible(state)
    });

    manageVisible(this.settings);
  }

  addStormsLayer() {
    MapKeeper.addLayer({
      id: this.symbolForecastTrackPointLayerId,
      type: 'symbol',
      source: this.forecastTrackPointSourceId,
      layout: {
        'icon-image': ['get', 'icon-image'],
        'icon-size': ['get', 'icon-size'],
        'icon-padding': 1,
        'symbol-sort-key': ['get', 'symbol-sort-key'],
        'icon-pitch-alignment': 'map'
      }
    }, 'ww-bottom-top-radar-layer')

    MapKeeper.addLayer({
      id: this.lineForecastTrackLayerId,
      type: 'line',
      source: this.forecastTrackSourceId,
      layout: {
        'line-sort-key': ['get', 'line-sort-key'],
        'line-cap': 'round',
        'line-join': 'round'
      },
      paint: {
        'line-color': '#0F224E',
        'line-opacity': 0.8,
        'line-width': 2,
      }
    }, this.symbolForecastTrackPointLayerId)

    MapKeeper.addLayer({
      id: this.lineForecastTrackConeLayerId,
      type: 'line',
      source: this.forecastTrackConeSourceId,
      layout: {
        // 'line-sort-key': ['get', 'line-sort-key'],
        'line-cap': 'round',
        'line-join': 'round'
      },
      paint: {
        'line-color': '#FFFFFF',
        'line-opacity': 0.4,
        'line-width': 2
      }
    }, "ww-bottom-middle-radar-layer")

    MapKeeper.addLayer({
      id: this.fillForecastTrackConeLayerId,
      type: 'fill',
      source: this.forecastTrackConeSourceId,
      layout: {
        // 'fill-sort-key': ['get', 'fill-sort-key']
      },
      paint: {
        'fill-color': '#FFFFFF',
        'fill-opacity': 0.1
      }
    }, this.lineForecastTrackConeLayerId)

    MapKeeper.addLayer({
      id: this.lineBestTrackLayerId,
      type: 'line',
      source: this.bestTrackSourceId,
      layout: {
        // 'line-sort-key': ['get', 'line-sort-key'],
        'line-cap': 'round',
        'line-join': 'round'
      },
      paint: {
        'line-color': '#0F224E',
        'line-opacity': 0.5,
        'line-width': 1,
        'line-dasharray': [0, 4, 3]
      }
    }, this.lineForecastTrackLayerId)

    MapKeeper.addLayer({
      id: this.linePeakStormSurgeLayerId,
      type: 'line',
      source: this.peakStormSurgeSourceId,
      layout: {
        'line-sort-key': ['get', 'line-sort-key'],
        'line-cap': 'round',
        'line-join': 'round'
      },
      paint: {
        'line-color': ['get', 'line-color'],
        'line-opacity': ['get', 'line-opacity'],
        'line-width': ['get', 'line-width']
      }
    }, 'ww-top-middle-radar-layer-2')
  }

  addToaLayer() {
    MapKeeper.addLayer({
      id: this.symbolToaLayerId,
      type: 'symbol',
      source: this.toaSourceId,
      layout: {
        'symbol-placement': 'line',
        'text-allow-overlap': true,
        'text-field': ['get', 'name'],
        'text-size': 15,
        'text-ignore-placement': false
      },
      paint: {
        'text-color': "#EEEEEE",
        'text-halo-width': 2,
        'text-halo-color': "#333333"
      }
    }, this.lineBestTrackLayerId)

    MapKeeper.addLayer({
      id: this.lineToaLayerId,
      type: 'line',
      source: this.toaSourceId,
      layout: {
        'line-sort-key': ['get', 'line-sort-key'],
        'line-cap': 'round',
        'line-join': 'round'
      },
      paint: {
        'line-color': ['get', 'line-color'],
        'line-opacity': ['get', 'line-opacity'],
        'line-width': ['get', 'line-width']
      }
    }, this.symbolToaLayerId)
  }

  addFwrLayer() {
    MapKeeper.addLayer({
      id: this.lineForecastWindRadiiLayerId,
      type: 'line',
      source: this.fwrSourceId,
      layout: {
        // 'line-sort-key': ['get', 'line-sort-key'],
        'line-cap': 'round',
        'line-join': 'round'
      },
      paint: {
        'line-color': ['get', 'stroke'],
        'line-opacity': 0.1,
        'line-width': 2
      }
    }, this.lineToaLayerId)

    MapKeeper.addLayer({
      id: this.fillForecastWindRadiiLayerId,
      type: 'fill',
      source: this.fwrSourceId,
      layout: {
        'fill-sort-key': ['get', 'fill-sort-key']
      },
      paint: {
        'fill-color': ['get', 'fill'],
        'fill-opacity': ['get', 'fill-opacity']
      }
    }, this.lineForecastWindRadiiLayerId)
  }

  addWspLayer() {
    MapKeeper.addLayer({
      id: this.fillWspLayerId,
      type: 'fill',
      source: this.wspSourceId,
      layout: {
        // 'fill-sort-key': ['get', 'fill-sort-key']
      },
      paint: {
        'fill-color': ['get', 'fill'],
        'fill-opacity': [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          0.35,
          0.15
        ]
      }
    }, this.linePeakStormSurgeLayerId)
  }

  addDisturbancesLayer() {
    MapKeeper.addLayer({
      id: this.lineDisturbancesLayerId,
      type: 'line',
      source: this.disturbancesSourceId,
      layout: {
        // 'line-sort-key': ['get', 'line-sort-key'],
        'line-cap': 'round',
        'line-join': 'round'
      },
      paint: {
        'line-color': ['get', 'stroke'],
        'line-opacity': 0.7,
        'line-width': 2
      }
    }, this.fillWspLayerId)

    MapKeeper.addLayer({
      id: this.fillDisturbancesLayerId,
      type: 'fill',
      source: this.disturbancesSourceId,
      layout: {
        // 'fill-sort-key': ['get', 'fill-sort-key']
      },
      paint: {
        'fill-color': ['get', 'fill'],
        'fill-opacity': 0.1
      }
    }, this.lineDisturbancesLayerId)
  }

  async fetchAndRenderStorms() {
    let storms;

    try {
      storms = await this.fetchStorms();
    }
    catch(e) {
      return console.error('Failed to fetch tropical storms', e);
    }

    this.storms = storms;

    // Put only what we need reactive into the store
    this.tropicalStore.setStorms(storms.map(s => {
      return {
        id: s.id,
        name: s.name,
        classification: s.classification,
        wind_speed_kt: s.wind_speed_kt,
        wind_speed_mph: s.wind_speed_mph,
        pressure: s.pressure,
        position: s.position,
        movement_direction: s.movement_direction,
        movement_speed: s.movement_speed,
        ocean: s.ocean,
        last_update_at: s.last_update_at
      };
    }))

    // Render the larger storm object from the api
    this.renderStorms(storms);
  }

  async fetchStorms() {
    const json = await api.instance().get(`/tropical/USA/NHC/storms.json?_=${(new Date()).getTime()}`);

    return json;
  }

  async renderStorms(storms) {
    // console.log({storms})

    const forecastTrackFeatures = [];
    const forecastTrackPointsFeatures = [];
    const forecastTrackConeFeatures = [];
    const bestTrackFeatures = [];
    const peakStormSurgeFeatures = [];

    for(const storm of storms) {
      // console.log(storm)
      if(storm.forecast_track) {
        const forecastTrackGeojson = storm.forecast_track;

        const forecastTrackPointsGeojson = forecastTrackGeojson.features.filter(f => f.geometry.type === "Point").map((f, i) => {
          // console.log(f)
          f.properties['id'] = `${i}`
          f.properties['storm_id'] = storm.id;
          // f.properties['line-sort-key'] = 1;
          // f.properties['fill-sort-key'] = 1;
          // f.properties['line-color'] = f.properties['stroke'];
          // f.properties['fill-color'] = f.properties['fill'];
          // f.properties['line-opacity'] = 1;
          // f.properties['fill-opacity'] = 1;
          // f.properties['line-width'] = 2;

          f.properties['icon-image'] = 'tropical-forecast-point-h5'

          f.properties['icon-image'] = (() => {
            const windSpeedMph = f.properties.maximum_wind_mph;

            if(windSpeedMph >= 157) return 'tropical-forecast-point-h5';
            if(windSpeedMph >= 130) return 'tropical-forecast-point-h4';
            if(windSpeedMph >= 111) return 'tropical-forecast-point-h3';
            if(windSpeedMph >= 96) return 'tropical-forecast-point-h2';
            if(windSpeedMph >= 74) return 'tropical-forecast-point-h1';
            if(windSpeedMph >= 39) return 'tropical-forecast-point-ts';

            return 'tropical-forecast-point-td';
          })();
          f.properties['icon-size'] = 1;

          return f;
        });

        forecastTrackPointsFeatures.push(...forecastTrackPointsGeojson)

        const forecastTrackLinesGeojson = forecastTrackGeojson.features.filter(f => {
          return f.geometry.type === "LineString" && f.geometry.coordinates.length === forecastTrackPointsGeojson.length
        }).map(f => {
          // console.log(f)
          f.properties['id'] = storm.id;
          f.properties['line-sort-key'] = 1;
          f.properties['fill-sort-key'] = 1;
          f.properties['line-color'] = f.properties['stroke'];
          f.properties['fill-color'] = f.properties['fill'];
          f.properties['line-opacity'] = 1;
          f.properties['fill-opacity'] = 1;
          f.properties['line-width'] = 2;

          // console.log(storm.id, f.geometry.coordinates)

          f.geometry.coordinates = bezierSpline(lineString(f.geometry.coordinates)).geometry.coordinates

          return f;
        });

        // console.log(storm.id, {forecastTrackPointsFeatures})

        forecastTrackFeatures.push(...forecastTrackLinesGeojson)

        if(storm.best_track) {
          const bestTrackGeojson = storm.best_track;

          const firstForecastPoint = forecastTrackGeojson.features.find(f => f.geometry.type === 'LineString')

          const bestTrackLineGeojson = bestTrackGeojson.features.reduce((result, item, index) => {
            result.geometry.coordinates.push(item.geometry.coordinates)

            return result;
          }, {
            "type": "Feature",
            "geometry": {
              "type": "LineString",
              "coordinates": [],
            },
            "properties": {
              'id': storm.id,
              'line-color': '#000000',
              'line-width': 1,
              'line-opacity': 0.5
            }
          });

          bestTrackLineGeojson.geometry.coordinates.push(firstForecastPoint.geometry.coordinates[0])

          bestTrackLineGeojson.geometry.coordinates = bezierSpline(lineString(bestTrackLineGeojson.geometry.coordinates)).geometry.coordinates

          bestTrackFeatures.push(bestTrackLineGeojson)
        }
      }

      if(storm.forecast_track_cone) {
        const forecastTrackConeGeojson = storm.forecast_track_cone;

        forecastTrackConeGeojson.features = forecastTrackConeGeojson.features.map(f => {
          // console.log(f)
          f.properties['id'] = storm.id;
          f.properties['line-sort-key'] = 1;
          f.properties['fill-sort-key'] = 1;
          f.properties['line-color'] = f.properties['stroke'];
          f.properties['fill-color'] = f.properties['fill'];
          f.properties['line-opacity'] = 1;
          f.properties['fill-opacity'] = 1;
          f.properties['line-width'] = 2;

          return f;
        });

        // console.log(forecastTrackConeGeojson)

        forecastTrackConeFeatures.push(...forecastTrackConeGeojson.features)
      }

      if(storm.peak_storm_surge) {
        // console.log(storm.peak_storm_surge)

        const peakStormSurgeFeat = storm.peak_storm_surge.features.map(f => {
          f.properties['storm_id'] = storm.id;
          f.properties['line-color'] = f.properties['stroke'];
          f.properties['line-width'] = 7;
          f.properties['line-opacity'] = 1;
          return f;
        });

        peakStormSurgeFeatures.push(...peakStormSurgeFeat);
      }
    }

    MapKeeper.getSource(this.forecastTrackSourceId).setData({
      type: 'FeatureCollection',
      features: forecastTrackFeatures
    })

    MapKeeper.getSource(this.forecastTrackPointSourceId).setData({
      type: 'FeatureCollection',
      features: forecastTrackPointsFeatures
    })

    MapKeeper.getSource(this.forecastTrackConeSourceId).setData({
      type: 'FeatureCollection',
      features: forecastTrackConeFeatures
    })

    MapKeeper.getSource(this.bestTrackSourceId).setData({
      type: 'FeatureCollection',
      features: bestTrackFeatures
    })

    MapKeeper.getSource(this.peakStormSurgeSourceId).setData({
      type: 'FeatureCollection',
      features: peakStormSurgeFeatures
    })
  }

  // Wsp
  async fetchAndRenderWsp(key) {
    let wsp;

    try {
      wsp = await this.fetchWsp(key);
    }
    catch(e) {
      return console.error('Failed to fetch WSP', e);
    }

    this.stopListeningToWspUpdates();

    this.listenToWspUpdates(key);

    this.tropicalStore.setWsp(key);

    this.renderWsp(wsp);
  }

  async fetchWsp(key) {
    const json = await api.instance().get(`/tropical/USA/NHC/wsp_${key}.geojson?_=${(new Date()).getTime()}`);

    return json;
  }

  async renderWsp(wsp) {
    MapKeeper.getSource(this.wspSourceId).setData(wsp)
  }

  listenToWspUpdates(key) {
    const room = `tropical:USA-NHC-wsp_${key}`;
    this.activeSocketRooms.push(room)
    socket.roomJoin(room)
    socket.on(room, async (data) => {
      console.log('tropical wsp update', room, data)

      await this.fetchAndRenderWsp(key);
    });
  }

  stopListeningToWspUpdates() {
    if(this.tropicalStore.wsp !== null) {
      const room = `tropical:USA-NHC-wsp_${this.tropicalStore.wsp}`;
      this.activeSocketRooms = this.activeSocketRooms.filter(r => r !== room);
      socket.roomLeave(room)
      socket.removeAllListeners(room)
    }
  }

  clearWsp() {
    if(this.tropicalStore.wsp === null) return;

    this.stopListeningToWspUpdates();

    this.tropicalStore.clearWsp();

    MapKeeper.getSource(this.wspSourceId).setData({
      type: 'FeatureCollection',
      features: []
    })
  }

  // TOA (Time of Arrival)
  async fetchAndRenderToa(storm, kind) {
    const url = (() => {
      if(kind === 'earliest') return storm.time_of_arrival_earliest_url;
      if(kind === 'most_likely') return storm.time_of_arrival_most_likely_url;

      throw new Error('Unknown TOA kind');
    })();

    let toa;

    try {
      toa = await this.fetchToa(url);
    }
    catch(e) {
      return console.error('Failed to fetch TOA', e);
    }

    toa.features = toa.features.map(f => {
      if(f.properties.arrival_at.length == 0) return f;

      const name = moment.utc(f.properties.arrival_at).local().format('ddd h a')

      f.properties.name = name;
      return f;
    });

    // TODO
    // Listen to updates

    // this.stopListeningToWspUpdates();
    // this.listenToWspUpdates(key);

    this.renderToa(toa);
  }

  async fetchToa(url) {
    const json = await api.instance().get(url);

    return json;
  }

  async renderToa(toa) {
    MapKeeper.getSource(this.toaSourceId).setData(toa)
  }

  clearToa() {
    if(this.tropicalStore.toa === null) return;

    this.tropicalStore.clearToa();

    MapKeeper.getSource(this.toaSourceId).setData({
      type: 'FeatureCollection',
      features: []
    })
  }

  // Forecast Wind Radii
  async fetchAndRenderFwr(storm) {
    let polygons;

    try {
      polygons = await this.fetchFwr(storm.forecast_wind_radii_url);
    }
    catch(e) {
      return console.error('Failed to fetch FWR', e);
    }

    polygons.features = polygons.features.map(f => {
      const speed = f.properties.wind_speed_kt;

      f.properties['fill-sort-key'] = speed;

      let opacity = 0.15;
      if(speed > 60) {
        opacity = 0.4;
      }
      else if(speed > 46) {
        opacity = 0.4;
      }

      f.properties['fill-opacity'] = opacity;

      return f;
    })

    // TODO
    // Listen to updates

    // this.stopListeningToWspUpdates();
    // this.listenToWspUpdates(key);

    this.renderFwr(polygons);
  }

  async fetchFwr(url) {
    const json = await api.instance().get(url);

    return json;
  }

  async renderFwr(toa) {
    MapKeeper.getSource(this.fwrSourceId).setData(toa)
  }

  clearFwr() {
    if(this.tropicalStore.fwr === null) return;

    this.tropicalStore.clearFwr();

    MapKeeper.getSource(this.fwrSourceId).setData({
      type: 'FeatureCollection',
      features: []
    })
  }

  // Disturbances
  async fetchAndRenderDisturbances() {
    let disturbances;

    try {
      disturbances = await this.fetchDisturbances();
    }
    catch(e) {
      return console.error('Failed to fetch tropical disturbances', e);
    }

    this.disturbances = disturbances;

    this.tropicalStore.setDisturbances(disturbances.features)

    this.renderDisturbances(disturbances);
  }

  async fetchDisturbances() {
    const json = await api.instance().get(`/tropical/USA/NHC/disturbances.geojson?_=${(new Date()).getTime()}`);

    return json;
  }

  async renderDisturbances(disturbances) {
    MapKeeper.getSource(this.disturbancesSourceId).setData(disturbances)
  }

  stopListeningToRooms() {
    this.activeSocketRooms.forEach(room => {
      socket.roomLeave(room)
      socket.removeAllListeners(room)
    })
    this.activeSocketRooms = []
  }

  clear() {
    this.stopListeningToRooms();
  }

  openStormInfoModel(storm) {
    const modal = useModal({
      defaultModelValue: true,
      component: SimpleModal,
      attrs: {
        title: storm.name,
        onOpened() {
          const params = new UrlHash()
          params.set('trs', storm.id)
          params.save()
        },
        onClosed() {
          const params = new UrlHash()
          params.delete('trs')
          params.save()
        },
      },
      slots: {
        default: useModalSlot({
          component: TropicalStormInfoModal,
          attrs: {
            storm,
            onClose() {
              modal.close();
            }
          }
        })
      },
    });
  }

  openDisturbanceInfoModel(feature) {
    useModal({
      defaultModelValue: true,
      component: SimpleModal,
      attrs: {
        title: 'Tropical Disturbance'
      },
      slots: {
        default: useModalSlot({
          component: TropicalDisturbanceInfoModal,
          attrs: {
            feature
          }
        })
      },
    });
  }

  openTropicalHelpModal(code) {
    let title = '';
    let text = '';

    if(code === 'storms') {
      title = 'Tropical Storms'
      text = `The National Weather Service (NWS) along with the National Hurricane Center (NHC) categorize tropical storms based on their intensity, wind speeds, and structure. These storms are classified within a broader framework of tropical cyclones, which are organized, rotating storm systems originating over tropical or subtropical waters. Here are the various classifications of tropical storms and related phenomena:

<strong>Tropical Depression</strong>
    Wind Speeds: Less than 39 mph (34 knots or 63 km/h).
    Description: A tropical depression is the weakest type of tropical cyclone, with maximum sustained winds below 39 mph. It is characterized by organized thunderstorms and a defined low-pressure center but lacks the well-defined spiral structure of more intense storms. Tropical depressions can still produce heavy rainfall and localized flooding.

<strong>Tropical Storm</strong>
    Wind Speeds: 39-73 mph (34-63 knots or 63-118 km/h).
    Description: A tropical storm is a stronger tropical cyclone with sustained wind speeds ranging from 39 to 73 mph. It has a more developed structure, including organized thunderstorms and, often, a defined center or "eye." Tropical storms can cause significant damage from winds, heavy rainfall, and storm surges, though they are not as destructive as hurricanes. These storms receive official names from a pre-designated list maintained by the NHC.

<strong>Hurricane (Category 1-5)</strong>
    Wind Speeds: 74 mph or higher (64 knots or 119 km/h).
    Description: Hurricanes are the strongest type of tropical cyclone, divided into five categories based on the Saffir-Simpson Hurricane Wind Scale. Hurricanes form when a tropical storm intensifies, with sustained wind speeds exceeding 74 mph. They are characterized by a well-defined eye, eyewall, and spiral rainbands. Hurricanes can cause widespread damage through strong winds, storm surges, and flooding.

    Categories of Hurricanes:
        Category 1: Wind speeds between 74-95 mph (64-82 knots or 119-153 km/h). Minimal damage, but still dangerous.
        Category 2: Wind speeds between 96-110 mph (83-95 knots or 154-177 km/h). Moderate damage.
        Category 3: Wind speeds between 111-129 mph (96-112 knots or 178-208 km/h). Extensive damage, classified as a major hurricane.
        Category 4: Wind speeds between 130-156 mph (113-136 knots or 209-251 km/h). Catastrophic damage.
        Category 5: Wind speeds of 157 mph or higher (137 knots or 252 km/h). Catastrophic, widespread devastation.

<strong>Post-Tropical Cyclone</strong>
    Wind Speeds: Variable.
    Description: A post-tropical cyclone is a former tropical cyclone that no longer possesses the characteristics of a tropical system, having lost its organized deep convection. These systems may transition into extra-tropical cyclones or remnants but can still bring strong winds and heavy rain.

<strong>Subtropical Storm</strong>
    Wind Speeds: 39-73 mph (34-63 knots or 63-118 km/h).
    Description: A subtropical storm has characteristics of both tropical and extra-tropical cyclones. Unlike tropical storms, subtropical storms typically have a broader wind field, less defined centers, and less concentrated thunderstorms near the center. These storms can evolve into fully tropical systems under favorable conditions.

<strong>Tropical Disturbance</strong>
    Wind Speeds: Below 39 mph (34 knots or 63 km/h).
    Description: A tropical disturbance is an organized area of thunderstorms over tropical or subtropical waters, showing signs of low-pressure development but without the well-defined circulation of a depression or storm. This stage can lead to further development into more intense tropical systems.

<strong>Tropical Wave (Easterly Wave)</strong>
    Wind Speeds: N/A (as it’s not a storm).
    Description: A tropical wave is a type of atmospheric trough characterized by a kink or bend in the normally straight flow of air in the tropics. It’s a precursor to more significant tropical systems and often leads to the development of tropical disturbances and, eventually, tropical storms or hurricanes.

Each of these storm classifications helps the NWS and NHC provide accurate forecasts and warnings, allowing communities to prepare and respond appropriately based on the storm’s intensity and potential impact.`
    }
    else if(code === 'disturbances') {
      title = 'Tropical Disturbances'
      text = `A Tropical Disturbance refers to a weather system characterized by a collection of organized thunderstorms, typically over tropical or subtropical waters, that exhibit some degree of cyclonic rotation but have not yet developed into a fully formed tropical cyclone (such as a tropical depression, tropical storm, or hurricane).

A tropical disturbance often has the following features:

- Thunderstorm Activity: The presence of persistent, organized thunderstorms.
- Cyclonic Rotation: Low-level winds may start to show signs of rotation, though typically it is weak and poorly defined.
- Potential for Development: While disturbances may vary in intensity, some can develop into more intense systems depending on favorable environmental conditions, such as warm sea surface temperatures, low vertical wind shear, and high atmospheric moisture.

The National Hurricane Center (NHC) closely monitors these disturbances because they can rapidly intensify under the right conditions. Disturbances are assigned an "invest" label and tracked for potential development into a tropical depression, tropical storm, or hurricane.`
    }

    const modal = useModal({
      defaultModelValue: true,
      component: SimpleModal,
      attrs: {
        title
      },
      slots: {
        default: useModalSlot({
          component: TropicalStormProductHelpModal,
          attrs: {
            text,
            onClose() {
              modal.close()
            },
          }
        })
      },
    })

    return modal;
  }

  getStorm(id) {
    return this.storms.find(s => s.id === id);
  }

  fitBoundsForForecast(storm) {
    const forecastTrackConeGeojson = storm.forecast_track_cone;

    if(storm.forecast_track_cone == null) return;

    const polygon = storm.forecast_track_cone.features[0]

    const bounds = new mapboxgl.LngLatBounds(
      polygon.geometry.coordinates[0][0],
      polygon.geometry.coordinates[0][0]
    );

    // Extend the 'LngLatBounds' to include every coordinate in the bounds result.
    for (const coord of polygon.geometry.coordinates[0]) {
        bounds.extend(coord);
    }

    MapKeeper.fitBounds(bounds, {
      padding: window.innerWidth / 8,
      duration: 0
    });
  }

  fitBoundsForDisturbance(feature) {
    const bounds = new mapboxgl.LngLatBounds(
      feature.geometry.coordinates[0][0],
      feature.geometry.coordinates[0][0]
    );

    // Extend the 'LngLatBounds' to include every coordinate in the bounds result.
    for (const coord of feature.geometry.coordinates[0]) {
        bounds.extend(coord);
    }

    MapKeeper.fitBounds(bounds, {
      padding: window.innerWidth / 8,
      duration: 0
    });
  }

  show() {
    for(const layerId of this.layers) {
      MapKeeper.setLayoutProperty(layerId, 'visibility', 'visible');
    }
  }

  hide() {
    for(const layerId of this.layers) {
      MapKeeper.setLayoutProperty(layerId, 'visibility', 'none');
    }
  }
}
