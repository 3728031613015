import { render, h } from 'vue'

import { renderToPopup } from '@/tools/mapbox-map'
import { uniqueList } from '@/tools/helpers'
import api from '@/logic/Api'
import socket from '@/logic/Socket'
import moment from 'moment'

import { useSpotterNetworkStore } from '@/stores/settings/vendors/spotter_network'
import SpotterNetworkPositionModel from './SpotterNetwork/SpotterNetworkLocationModal.vue'
import SpotterNetworkPositionPopup from './SpotterNetwork/SpotterNetworkPositionPopup.vue'
import { useModal, useModalSlot } from 'vue-final-modal'
import WrapperModal from './Modals/Templates/Wrapper.vue'

import MapKeeper from '@/logic/MapKeeper'

const ICON_OPACITY_SETTINGS = [
    'interpolate',
    ['linear'],
    ['get', 'age'],
    0, 1.0,    // current time = full opacity
    3600, 0.2  // 60 minutes ago
]

class SpotterNetworkPositions {
    constructor() {
        this.sourceId = 'ww-spotter-network-source'
        this.pointLayerId = 'ww-spotter-network-point-layer'
        this.boundPointOnClick = null
        this.positions = {
            type: 'FeatureCollection',
            features: []
        };

        this.pointOnClick = renderToPopup((e) => {
            const features = uniqueList(e.features, feature => feature.properties.id)
            if(features.length == 0) return;

            return () => {
                const container = window.document.createElement('div');

                features.forEach((feature, idx) => {
                    const popupContainer = window.document.createElement('div');

                    render(h(SpotterNetworkPositionPopup, {
                        feature,
                        isLast: idx === features.length - 1,
                        onClick: () => {
                            MapKeeper.popups.clear()
                            this.openModal(feature);
                        }
                    }), popupContainer);

                    container.appendChild(popupContainer);
                });

                return container;
            }
        });
    }

    async draw() {
        try {
            await MapKeeper.asyncLoadAndAddImage(new URL('../assets/vendors/spotter-network/spotter-network-point.png', import.meta.url).href, 'spotter-network-dot')

            this.addLayer()
            await this.addData()

            this.boundPointOnClick = this.pointOnClick.bind(this)
            MapKeeper.on('click', this.pointLayerId, this.boundPointOnClick)
        } catch(e) {
            console.log('Failed to draw Spotter Network:', e)
            
            // Cleanup any partial state if drawing fails
            this.clear()
        }
    }

    addLayer() {
        MapKeeper.addSource(this.sourceId, {
            type: 'geojson',
            data: {
                type: 'FeatureCollection',
                features: []
            }
        });

        MapKeeper.addLayer({
            'id': this.pointLayerId,
            'type': 'symbol',
            'source': this.sourceId,
            'layout': {
                'icon-image': 'spotter-network-dot',
                'icon-size': 0.3,
                'icon-allow-overlap': false,
                'icon-anchor': 'center',
                'icon-offset': [0, 0],
                'icon-pitch-alignment': 'map',
                'symbol-sort-key': ["get", "symbol-sort-key"]
            },
            'paint': {
                'icon-opacity': ICON_OPACITY_SETTINGS
            }
        }, 'ww-radar-towers-dot-layer');
    }

    async fetchAndSetData() {
        await this.fetchData();

        const now = moment().unix()
        this.positions.features.forEach(position => {
            position.properties.age = now - position.properties.unix;
            position.properties['symbol-sort-key'] = position.properties.unix;
        });

        MapKeeper.getSource(this.sourceId).setData(this.positions);

        MapKeeper.setPaintProperty(this.pointLayerId, 'icon-opacity', ICON_OPACITY_SETTINGS);
    }

    async addData() {
        try {
            await this.fetchAndSetData();

            const room = 'vendors:spotter-network:positions'
            socket.roomJoin(room)
            socket.on(room, async (data) => {
                console.log('Spotter Network Positions update', room, data);

                try {
                    await this.fetchAndSetData();
                } catch(e) {
                    console.error('Failed to load Spotter Network Positions data after update', e)
                }
            });
        } catch(e) {
            console.error('Failed to load Spotter Network Positions data', e)
        }
    }

    async fetchData() {
        const positions = await api.instance().get(`/vendors/spotter-network/positions.geojson`)

        const hourAgo = moment().utc().subtract(1, 'hour')

        positions.features = positions.features.filter(position => {
            return moment.unix(position.properties.unix).isSameOrAfter(hourAgo)
        })

        this.positions = positions
    }

    show() {
        for(const layerId of [this.pointLayerId]) {
            MapKeeper.setLayoutProperty(layerId, 'visibility', 'visible');
        }
    }

    hide() {
        for(const layerId of [this.pointLayerId]) {
            MapKeeper.setLayoutProperty(layerId, 'visibility', 'none');
        }
    }

    clear() {
        try {
            MapKeeper.popups.clear()

            if (this.boundPointOnClick) {
                MapKeeper.off('click', this.pointLayerId, this.boundPointOnClick)
                this.boundPointOnClick = null
            }

            if (MapKeeper.hasImage('spotter-network-dot')) {
                MapKeeper.removeImage('spotter-network-dot')
            }

            // Check if layers/source exist before removing
            if (MapKeeper.getLayer(this.pointLayerId)) {
                MapKeeper.removeLayer(this.pointLayerId)
            }

            if (MapKeeper.getSource(this.sourceId)) {
                MapKeeper.removeSource(this.sourceId)
            }

            const room = 'vendors:spotter-network:positions'
            socket.roomLeave(room)
            socket.removeAllListeners(room)

        } catch(e) {
            throw new Error(`Failed to clear Spotter Network: ${e.message}`)
        }
    }

    openModal(feature) {
        const modal = useModal({
            defaultModelValue: true,
            component: WrapperModal,
            slots: {
                default: useModalSlot({
                    component: SpotterNetworkPositionModel,
                    attrs: {
                        feature: feature,
                        onClose() {
                            modal.close()
                        },
                    }
                })
            },
        });
    }
}

const spotNetPos = new SpotterNetworkPositions()

const useSpotterNetwork = () => {

    const toggle = async () => {
        try {
            if(isVisible()) {
                await hide()
            } else {
                await show()
            }
        } catch(e) {
            console.error('Failed to toggle Spotter Network:', e)
            throw new Error('Failed to toggle Spotter Network visibility')
        }
    }

    const show = async () => {
        await spotNetPos.draw()
        useSpotterNetworkStore().turnOnPositionsMapLayer()
    }

    const hide = async () => {
        spotNetPos.clear()
        useSpotterNetworkStore().turnOffPositionsMapLayer()
    }

    const update = async () => {
        if (! isVisible()) {
            return;
        }
        try {
            await spotNetPos.fetchAndSetData()
        } catch (e) {
            console.log(`Failed to update Spotter Network positions`, e)
        }
    }

    const isVisible = () => useSpotterNetworkStore().positionsMapLayerOn

    const showIfVisible = () => {
        if (isVisible()) {
            show()
        }
    }

    return {
        toggle,
        show,
        hide,
        isVisible,
        showIfVisible,
        spotNetPos,
        update
    }
}

export { useSpotterNetwork }
