<script setup lang="ts">
import { ref, useTemplateRef } from 'vue';
import { storeToRefs } from 'pinia';
import { Icon } from '@iconify/vue'

import { tabs } from '@/data/weather_alerts';
import AlertSection from './AlertSettings/AlertSection.vue';
import TabSelector from './AlertSettings/TabSelector.vue';
import AlertToggle from './AlertSettings/AlertToggle.vue';

import { useAlertsStore } from '@/stores/alerts';
import { useWarningsSettingsStore } from '@/stores/settings/warnings';

const alertsStore = useAlertsStore();
const settingsStore = useWarningsSettingsStore();

const { activeTab } = storeToRefs(alertsStore);

// Desktop search
const showDesktopSearch = ref(false);
const desktopSearchInput = useTemplateRef<HTMLInputElement>('desktop-search-input');
const desktopSearchInputValue = ref('');

// Mobile search
const showMobileSearch = ref(false);
const mobileSearchInput = useTemplateRef<HTMLInputElement>('mobile-search-input');
const mobileSearchInputValue = ref('');

// Common search value used for filtering
const searchInputValue = ref('');

const toggleDesktopSearch = () => {
  showDesktopSearch.value = !showDesktopSearch.value;

  if(showDesktopSearch.value) {
    setTimeout(() => {
      desktopSearchInput.value?.focus();
    }, 100);
  }
};

const toggleMobileSearch = () => {
  showMobileSearch.value = !showMobileSearch.value;

  if(showMobileSearch.value) {
    setTimeout(() => {
      mobileSearchInput.value?.focus();
    }, 100);
  }
};

const searchKeyup = (type: 'desktop' | 'mobile', e: KeyboardEvent) => {
  const input = type === 'desktop' ? desktopSearchInput.value : mobileSearchInput.value;
  const text = input?.value || '';
  
  if (type === 'desktop') {
    desktopSearchInputValue.value = text;
  } else {
    mobileSearchInputValue.value = text;
  }
  
  // Update the common search value used for filtering
  searchInputValue.value = text;
};

const searchBlur = (type: 'desktop' | 'mobile', e: FocusEvent) => {
  const input = type === 'desktop' ? desktopSearchInput.value : mobileSearchInput.value;
  const text = input?.value || '';

  console.log(text, type)
  
  if(text.length === 0) {
    if (type === 'desktop') {
      showDesktopSearch.value = false;
    } else {
      showMobileSearch.value = false;
    }
  }
};

const clearSearch = (type: 'desktop' | 'mobile') => {
  const input = type === 'desktop' ? desktopSearchInput.value : mobileSearchInput.value;
  
  if (input) {
    input.value = '';
    
    if (type === 'desktop') {
      desktopSearchInputValue.value = '';
      showDesktopSearch.value = false;
    } else {
      mobileSearchInputValue.value = '';
      showMobileSearch.value = false;
    }
    
    searchInputValue.value = '';
  }
};

const setEnabled = (value: any) => {
  settingsStore.config[value.alert.country_iso][value.alert.id].enabled = value.enabled;
};
</script>

<template>
  <div class="mt-3">
    <div class="max-w-3xl mx-auto space-y-6">
      <div class="flex justify-center mb-6" :class="{
        'hidden sm:flex': tabs.length > 2
      }">
        
        <TabSelector
          v-show="!showDesktopSearch"
          :tabs="tabs"
          :active-tab="activeTab"
          @update:active-tab="alertsStore.setActiveTab"
        />
        <div class="flex flex-wrap gap-1 p-1.5 dark:bg-gray-800/40 backdrop-blur-lg rounded-2xl border border-white/5" :class="{
          'ml-2': !showDesktopSearch
        }">
          <div v-show="showDesktopSearch">
            <div class="relative">
              <input type="text" ref="desktop-search-input" @keyup="(e) => searchKeyup('desktop', e)" @blur="(e) => searchBlur('desktop', e)" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search..." size="50" />
              <button 
                v-show="desktopSearchInputValue.length > 0"
                @click="clearSearch('desktop')" 
                class="absolute right-2.5 top-1/2 -translate-y-1/2 text-gray-400 hover:text-gray-600 dark:hover:text-gray-300"
                type="button"
              >
                <Icon icon="material-symbols:close" class="size-5" />
              </button>
            </div>
          </div>

          <button
            class="relative px-4 py-2 font-bold rounded-lg transition-all duration-300 z-10"
            @click="toggleDesktopSearch"
            :class="{
              'text-white bg-blue-700 hover:bg-blue-800 active': showDesktopSearch,
              'hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-ww-blue-200': !showDesktopSearch
            }"
          >
            <Icon icon="material-symbols:search-rounded" class="size-6" />
          </button>
        </div>
      </div>

      <div v-if="tabs.length > 2" class="sm:hidden">
        <div class="flex">
          <select v-show="!showMobileSearch" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5" v-model="activeTab">
            <option v-for="t in tabs" :value="t.id" v-text="t.label"></option>
          </select>
          <div v-show="showMobileSearch">
            <div class="relative mr-2">
              <input type="text" ref="mobile-search-input" @keyup="(e) => searchKeyup('mobile', e)" @blur="(e) => searchBlur('mobile', e)" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search..." size="50" />
              <button 
                v-show="mobileSearchInputValue.length > 0"
                @click="clearSearch('mobile')" 
                class="absolute right-2.5 top-1/2 -translate-y-1/2 text-gray-400 hover:text-gray-600 dark:hover:text-gray-300"
                type="button"
              >
                <Icon icon="material-symbols:close" class="size-5" />
              </button>
            </div>
          </div>

          <button
            class="relative px-4 py-2 font-bold rounded-lg transition-all duration-300 z-10"
            @click="toggleMobileSearch"
            :class="{
              'text-white bg-blue-700 hover:bg-blue-800 active': showMobileSearch,
              'hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-ww-blue-200': !showMobileSearch
            }"
          >
            <Icon icon="material-symbols:search-rounded" class="size-6" />
          </button>
        </div>
      </div>

      <!-- Alert Sections -->
      <template v-if="(showDesktopSearch || showMobileSearch) && searchInputValue.length > 0">
        <template v-for="(section, index) in alertsStore.sections" :key="section.significance">
          <AlertSection
            v-if="section.alerts.some((alert: any) => { return alert.name.toLowerCase().includes(searchInputValue.toLowerCase()); })"
            :section="section"
            :filter-alerts="(alert: any) => { return alert.name.toLowerCase().includes(searchInputValue.toLowerCase()); }"
            @update:enabled="(value) => setEnabled(value)"
          />
        </template>
      </template>
      <template v-else>
        <template v-for="(section, index) in alertsStore.sections" :key="section.significance">
          <AlertSection
            v-if="section.alerts.some((alert: any) => alert.category === activeTab)"
            :section="section"
            :filter-alerts="(alert: any) => { return alert.category === activeTab; }"
            @update:enabled="(value) => setEnabled(value)"
          />
        </template>
      </template>

      <div 
        v-if="false"
        class="flex items-center justify-between py-3 px-4 cursor-pointer group"
      >
        <div class="flex items-center gap-4">
          <span class="text-white text-lg font-medium">Show only active alerts</span>
        </div>
        <div class="flex items-center gap-4">
          <AlertToggle
            :enabled="settingsStore.filter_active"
            @update:enabled="settingsStore.toggleFilterActive()"
            @click.stop
          />
        </div>
      </div>
    </div>
  </div>
</template>