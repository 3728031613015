import { defineStore } from 'pinia'


export const useSubscriptionStore = defineStore('subscription', {
  state: () => ({
    revenueCatCustomerInfo: null,
    isLoading: false,
    error: null
  }),

  getters: {
    hasActiveSubscription: (state) => {
      if(state.revenueCatCustomerInfo === null || typeof (state.revenueCatCustomerInfo?.entitlements?.all) !== 'object') return false;

      return Object.values(state.revenueCatCustomerInfo.entitlements.all).some((entitlement: any) => entitlement.isActive) === true;
    },
    activeSubscription: (state) => {
      if (!state.revenueCatCustomerInfo?.entitlements?.active) return null
      const activeEntitlements = state.revenueCatCustomerInfo.entitlements.active
      return Object.values(activeEntitlements)[0] ?? null
    }
  },

  actions: {
    setRevenueCatCustomerInfo(customerInfo: any) {
      this.revenueCatCustomerInfo = customerInfo
    }
  }
})
