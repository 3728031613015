import { toRaw } from 'vue'
import mapboxgl from 'mapbox-gl'

import { distance, centroid, destination, point as turfPoint, polygon as turfPolygon, booleanPointInPolygon } from '@turf/turf'
import { useHead } from '@unhead/vue'
import moment from 'moment'
import { useModal, useModalSlot } from 'vue-final-modal'
import { useNProgress } from '@vueuse/integrations/useNProgress'

const { progress, isLoading } = useNProgress(1)

import { PAGE_TITLE } from '@/brand'
import rollbar from '@/rollbar'

import { stopPropagation } from '@/tools/mapbox-map'
import { findClosestDate, wait, allSettledLimit } from '@/tools/helpers'
import { circle, rectangle } from '@/tools/graphics'
import { MODE_NONE, MODE_REALTIME, MODE_HISTORICAL_TO_REALTIME } from '@/tools/constants'

import radarTowers from '@/data/radar_towers.js'
import { productGroups, DEFAULT_RADAR_REF_PRODUCT_CODES, DEFAULT_RADAR_VEL_PRODUCT_CODES } from '@/data/radar_products.js'
import RadarColortable from '@/data/Colortables/Radar.js'

import StormTracks from './Radar/StormTracks'
import Lightning from './Radar/Lightning'
import { RadarRenderer } from "./Radar/Renderer/";

import SimpleModal from './Modals/Templates/Simple.vue'
import RadarProductHelpModal from './Radar/RadarProductHelp.vue'

import api from '@/logic/Api'
import socket from '@/logic/Socket'
import Packet from '@/logic/WxBin/Packet'

import { useAppStore } from '@/stores/app'
import { useRadarTowersStore } from '@/stores/radar_towers'
import { useRadarSettingsStore } from '@/stores/settings/radar'
import { useMapRadarStore } from '@/stores/map_radar'

import App from '@/logic/App'
import {useSubscription} from '@/logic/Composables/Subscription'
import MapKeeper from '@/logic/MapKeeper'

export default class Radar {
  constructor() {
    this.appStore = useAppStore()
    this.radarTowersStore = useRadarTowersStore()
    this.settings = useRadarSettingsStore()
    this.subscription = useSubscription()

    // Import colortables in settings into RadarColortable
    for(const groupId in this.settings.colortable_pal_upload) {
      const tables = toRaw(this.settings.colortable_pal_upload[groupId]);

      tables.forEach(t => {
        // Add a try/catch here just in case
        try {
          RadarColortable.parse(groupId, t.id, t.text);
        }
        catch(e) {
          console.error(`Failed to parse user radar colortable: ${groupId} ${t.id}`, t.text, e);

          rollbar.error(e, {
            groupId,
            tableId: t.id,
            tableText: t.text
          });
        }
      })
    }

    this.mode = MODE_NONE;

    this.sourceId = 'ww-radar-towers-source'
    this.dotLayerId = 'ww-radar-towers-dot-layer'
    this.pillLayerId = 'ww-radar-towers-pill-layer'
    this.radarRendererLayerId = 'ww-radar-renderer';

    this.towerIconLayers = [this.dotLayerId, this.pillLayerId];
    this.layers = [this.radarRendererLayerId]

    MapKeeper.addLayer({
      id: 'ww-top-top-radar-layer',
      type: 'line',
      source: {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: []
        }
      }
    })

    MapKeeper.addLayer({
      id: 'ww-bottom-top-radar-layer',
      type: 'line',
      source: {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: []
        }
      }
    }, "ww-top-top-radar-layer")

    MapKeeper.addLayer({
      id: 'ww-top-middle-radar-layer-2',
      type: 'line',
      source: {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: []
        }
      }
    }, "land-structure-polygon")

    MapKeeper.addLayer({
      id: 'ww-top-middle-radar-layer',
      type: 'line',
      source: {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: []
        }
      }
    }, "ww-top-middle-radar-layer-2")

    MapKeeper.addLayer({
      id: 'ww-bottom-middle-radar-layer',
      type: 'line',
      source: {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: []
        }
      }
    }, "ww-top-middle-radar-layer")

    this.stormTracks = new StormTracks(this);
    this.lightning = new Lightning(this);

    MapKeeper.addComponent('radar-renderer', (map) => {
      return new RadarRenderer(map, this.radarRendererLayerId, "ww-top-middle-radar-layer");
    });

    MapKeeper.addStore('radar', (map) => {
      const store = useMapRadarStore(map.id);

      // Copy some props from the primary store
      if(map.id > 0) {
        const primary = MapKeeper.mapboxMapsFirst().stores['radar']

        let product = primary.activeProductCode

        const tower = toRaw(primary.activeTower)
        if(tower !== undefined) {
          const availablePolarProducts = toRaw(primary.availablePolarProducts)

          const loadedGroups = {};
          MapKeeper.forEach(m => {
            const product = m.stores['radar'].activeProductCode;

            if(product === null || product.length === 0) return;

            const group = this.getGroupForProduct(product)
            if(group === undefined) return;

            loadedGroups[group.id] = true
          })

          let groupsForTower = this.getGroupsForTower(tower);

          let remainingGroup = groupsForTower.filter(g => loadedGroups[g.id] === undefined)[0]
          if(remainingGroup === undefined) {
            remainingGroup = groupsForTower[0]
          }

          const firstTilt = remainingGroup.tilts.find(t => {
            return tower.properties.products.includes(t.product)
          });

          product = firstTilt.product;
        }

        store.$patch({
          towers: primary.towers,
          activeTowerId: primary.activeTowerId,
          activeProductCode: product,
          availableProducts: primary.availableProducts,
          availablePolarProducts: primary.availablePolarProducts
        });
      }

      return store;
    });

    MapKeeper.onLoad((map) => {
      if(! this.appStore.isRadarMode) return;
      if(! this.radarTowersStore.anyActive) return;

      const store = map.stores['radar']
      const tower = toRaw(store.activeTower)

      this.turnOnRadar(map, tower);
    })

    this.bufferedRadarScans = {};
    this.bufferedRadarScansLimit = 0;
    this.playbackIdx = 0;

    this.activeSocketRooms = [];

    const mapRadarStore = MapKeeper.mapboxMapsFirst().stores['radar'];

    // console.log(mapRadarStore.activeTowerId)

    const copy = JSON.parse(JSON.stringify(radarTowers));
    copy.features = copy.features.map(f => {
      f.properties.active = mapRadarStore.activeTowerId === f.properties.id;

      return f;
    })

    this.towers = copy;

    // Generate and load icons
    const dotPrimary = circle({
      size: 21*2,
      color: '#10234E',
      border_color: '#FFFFFF',
      border_size: 3
    });
    MapKeeper.addImage('dot-radar-tower-primary', dotPrimary, { pixelRatio: 2 });

    const dotSecondary = circle({
      size: 21*2,
      color: '#F6BA12',
      border_color: '#FFFFFF',
      border_size: 3
    });
    MapKeeper.addImage('dot-radar-tower-secondary', dotSecondary, { pixelRatio: 2 });

    const dotActive = circle({
      size: 21*2,
      color: '#018101',
      border_color: '#FFFFFF',
      border_size: 3
    });
    MapKeeper.addImage('dot-radar-tower-active', dotActive, { pixelRatio: 2 });

    const pillPrimary = rectangle({
      width: 96,
      height: 44,
      color: '#10234E',
      border_color: "#FFFFFF",
      border_size: 3,
      corner_radius: 20,
    });
    MapKeeper.addImage('pill-radar-tower-primary', pillPrimary, { pixelRatio: 2 });

    const pillSecondary = rectangle({
      width: 96,
      height: 44,
      color: '#F6BA12',
      border_color: "#FFFFFF",
      border_size: 3,
      corner_radius: 20,
    });
    MapKeeper.addImage('pill-radar-tower-secondary', pillSecondary, { pixelRatio: 2 });

    const pillActive = rectangle({
      width: 96,
      height: 44,
      color: '#018101',
      border_color: "#FFFFFF",
      border_size: 3,
      corner_radius: 20,
    });
    MapKeeper.addImage('pill-radar-tower-active', pillActive, { pixelRatio: 2 });

    // Render radar markers
    MapKeeper.addSource(this.sourceId, {
      type: 'geojson',
      // This will us the id from the 'properties' as the feature ID
      promoteId: 'id',
      data: this.towers
    })

    MapKeeper.addLayer({
      id: this.dotLayerId,
      type: 'symbol',
      source: this.sourceId,
      layout: {
        'icon-image': [
          'case',
          ['==', ['get', 'active'], true],
          'dot-radar-tower-active',
          ['==', ['get', 'secondary'], true],
          'dot-radar-tower-secondary',
          'dot-radar-tower-primary'
        ],
        'icon-size': [
          'case',
          ['==', ['get', 'secondary'], true],
          0.8,
          1
        ],
        'icon-padding': 1,
        'symbol-sort-key': [
          'case',
          ['==', ['get', 'active'], true],
          0,
          ['==', ['get', 'secondary'], true],
          2,
          1
        ],
        'icon-pitch-alignment': 'map',
      }
    }, 'ww-top-top-radar-layer')

    MapKeeper.addLayer({
      id: this.pillLayerId,
      type: 'symbol',
      source: this.sourceId,
      layout: {
        'visibility': 'none',
        'icon-image': [
          'case',
          ['==', ['get', 'active'], true],
          'pill-radar-tower-active',
          ['==', ['get', 'secondary'], true],
          'pill-radar-tower-secondary',
          'pill-radar-tower-primary'
        ],
        'icon-size': [
          'case',
          ['==', ['get', 'secondary'], true],
          0.8,
          1
        ],
        'icon-padding': 1,
        'symbol-sort-key': [
          'case',
          ['==', ['get', 'active'], true],
          0,
          ['==', ['get', 'secondary'], true],
          2,
          1
        ],
        'icon-pitch-alignment': 'map',
        'text-field': ['get', 'code'],
        'text-size': [
          'case',
          ['==', ['get', 'secondary'], true],
          11,
          12
        ],
        'text-font': [
          'Arial Unicode MS Bold'
        ],
      },
      'paint': {
        'text-color': 'white',
        'text-halo-width': 0.75,
        'text-halo-color': 'hsl(215, 20%, 25%)',
        'text-halo-blur': 0.5,
      }
    }, 'ww-top-top-radar-layer')

    MapKeeper.on('click', [this.dotLayerId, this.pillLayerId], stopPropagation(async (e) => {
      // console.log('click',e)
      if(e.features.length === 0) return;

      const feature = this.towers.features.find((f) => f.properties.id === e.features[0].properties.id);
      if (feature === undefined) return;

      // console.log(feature)

      const inspectorActive = this.radarTowersStore.inspectorActive;

      if (feature.properties.active) {
        this.closeAllRadarTowers()
      } else {
        // Close all other radar towers
        this.closeAllRadarTowers()

        MapKeeper.forEach(m => {
          this.turnOnRadar(m, feature)
        })

        if(inspectorActive) {
          this.radarTowersStore.inspectorActive = true;
        }
      }
    }))

    const activeTower = this.towers.features.find(f => f.properties.active);
    if(activeTower !== undefined) {
      const mapRadarStore = MapKeeper.mapboxMapsFirst().stores['radar'];
      mapRadarStore.setActiveTower(activeTower, '')
    }
  }

  getRenderer() {
    return this.renderer;
  }

  clearPlaybackState() {
    // Return mdoe back to realtime
    this.mode = MODE_REALTIME;

    // Stop playing incase it is
    this.radarTowersStore.isPlaying = false;

    // Reset playback scan index
    this.playbackIdx = 0;

    // Clear radar buffered state
    this.clearBufferedHistoricalState();

    // Return lightning back to realtime (if not)
    this.lightning.clearBufferedHistoricalState();

    // Return warnigns back to realtime (if not)
    App.warnings.clearBufferedHistoricalState();

    // Return warnigns back to realtime (if not)
    App.mesoscaleDiscussions.clearBufferedHistoricalState();
  }

  closeAllRadarTowers() {
    this.clearPlaybackState();

    this.radarTowersStore.inspectorActive = false;

    this.towers.features.forEach((f) => {
      if (! f.properties.active) return

      this.removeRadar(f)
      f.properties.active = false
    });

    MapKeeper.getSource(this.sourceId).setData(toRaw(this.towers))
  }

  async turnOnRadar(map, feature, product = null) {
    useHead({
      title: `${feature.properties.code} - ${PAGE_TITLE}`
    })

    // console.log(map, feature, product)

    const mapRadarStore = map.stores['radar'];

    // console.log(product, mapRadarStore.activeProductCode)

    // If no product has been provided
    // And there is product code defined in the store
    // Let's default to reflectivty.
    // Failing that, default to velocity
    if(product === null && mapRadarStore.activeProductCode.length === 0) {
      // GAWX is a special case where it's lowest elevation is not desirable...
      if(feature.properties.id === 'GAWX') {
        product = 'REF1'
      }
      else {
        for(const code of DEFAULT_RADAR_REF_PRODUCT_CODES) {
          if(feature.properties.products.includes(code)) {
            product = code;
            break;
          }
        }

        // If still null, try velocity
        if(product === null) {
          for(const code of DEFAULT_RADAR_VEL_PRODUCT_CODES) {
            if(feature.properties.products.includes(code)) {
              product = code;
              break;
            }
          }
        }
      }

      // If still null, throw exception
      if(product === null) {
        throw new Error(`Failed to locate a default radar product for: ${feature.properties.id}`);
      }
    }
    else if(product === null && mapRadarStore.activeProductCode.length > 0) {
      const group = this.getGroupForProduct(mapRadarStore.activeProductCode)

      if(group === undefined) {
        return console.error(`Failed to locate product group for product: ${product}`);
      }

      const newTowerFilteredTilts = group.tilts.filter(t => feature.properties.products.includes(t.product))

      if(newTowerFilteredTilts.length === 0) {
        const towerGroups = this.getGroupsForTower(feature)
        const group2 = towerGroups.filter(g => g.id === group.id)[0]

        if(group2 === undefined) {
          product = towerGroups[0].tilts[0].product
        }
        else {
          const newTowerFilteredTilts2 = group2.tilts.filter(t => feature.properties.products.includes(t.product))

          product = newTowerFilteredTilts2[0].product
        }
      }
      else {
        product = newTowerFilteredTilts[0].product
      }
    }

    // console.log(feature.properties.products, product)

    if(! feature.properties.products.includes(product)) {
      // GAWX is a special case where it's lowest elevation is not desirable...
      if(feature.properties.id === 'GAWX') {
        product = 'REF1'
      }
      else {
        const group = this.getGroupForProduct(product)

        if(group === undefined) {
          return console.error(`Failed to locate product group for product: ${product}`);
        }

        const groups = productGroups.filter(g => g.id === group.id);

        for(const g of groups) {
          const firstProductFromSameGroup = g.tilts.find(t => {
            return feature.properties.products.includes(t.product);
          });

          if(firstProductFromSameGroup !== undefined) {
            product = firstProductFromSameGroup.product;
            break;
          }
        }

        if(! feature.properties.products.includes(product)) {
          for(const code of DEFAULT_RADAR_REF_PRODUCT_CODES) {
            if(feature.properties.products.includes(code)) {
              product = code;
              break;
            }
          }

          if(! feature.properties.products.includes(product)) {
            for(const code of DEFAULT_RADAR_VEL_PRODUCT_CODES) {
              if(feature.properties.products.includes(code)) {
                product = code;
                break;
              }
            }
          }
        }
      }
    }

    // console.log(product, this.radarTowersStore.activeProductCode)

    // console.log('a', feature, product)

    this.mode = MODE_REALTIME;

    feature.properties.active = true
    MapKeeper.getSource(this.sourceId).setData(toRaw(this.towers))

    this.radarTowersStore.towers = [feature];

    mapRadarStore.setActiveTower(feature, product)
    mapRadarStore.clearScanDatetime()
    mapRadarStore.clearScanVcp()

    const room = `radar:${feature.properties.id}:${product}`
    this.activeSocketRooms.push(room)
    socket.roomJoin(room)
    socket.on(room, async (data) => {
      console.log('Radar update', room, data, `Mode: ${this.mode}`)

      if(this.mode === MODE_REALTIME) {
        // Load the url provided in the data
        await this.loadScanByUrl(feature, product, data.url)
      }
      else if(this.mode === MODE_HISTORICAL_TO_REALTIME) {
        if(this.bufferedRadarScans[product] === undefined) return false;

        try {
          const scan = await this.fetchFileByUrl(data.url);

          if(this.bufferedRadarScans[product] === undefined) return false;

          this.bufferedRadarScans[product].push(scan);

          while(this.bufferedRadarScans[product].length > this.bufferedRadarScansLimit) {
            this.bufferedRadarScans[product].shift();
          }
        }
        catch(e) {
          console.error(`Failed to fetch scan in historical to realtime mode`, e);
        }
      }
    })

    console.time("Load radar data " + map.id);
    await this.loadLatestRadarData(map);
    console.timeEnd("Load radar data " + map.id);
  }

  isLightningSupported(feature) {
    // This is roughly the bounds of GOES-16 (East)
    const goesEastBounds = [-139.8023604410725,-81.5046269257671,-39.0234375,72.8001832];
    const bounds = new mapboxgl.LngLatBounds([goesEastBounds[0], goesEastBounds[1]], [goesEastBounds[2], goesEastBounds[3]]);
    return bounds.contains(feature.geometry.coordinates);
  }

  async loadLatestRadarData(map) {
    const tower = map.stores['radar'].activeTower;
    const promises = []

    const store = map.stores['radar']

    const loadLatestScan = async (map, feature, product) => {
      const towerId = feature.properties.id

      // TODO: Add proper parser to check if extension is .wise
      const filename = await this.determineLatestFile(towerId, product);
      const url = this.buildUrlForFile(towerId, product, filename);

      // Check the browser is capable of streaming data
      // And that they have atleast Plus
      if(Packet.streamIsSupported() && this.subscription.isAtleast(this.subscription.tiers.PLUS) && url.endsWith('.wise')) {
        const response = await api.instance().get(url, {
          responseType: 'stream'
        });

        const mapRadarStore = map.stores['radar']
        const renderer = map.components['radar-renderer']

        const group = this.getGroupForProduct(product);

        if(group === undefined) {
          console.error(`Failed to locate product group for product: ${product}`);

          return null;
        }

        const colormap = this.getColortableForGroup(group);

        if(colormap === null) {
          console.error(`Failed to any colortables for group: ${group.id}`);

          return null;
        }

        this.setupRadarRenderer(renderer, group, colormap);

        const reader = response.getReader();

        const scan = await Packet.stream(reader, (scan) => {
          // If the tower or product changes
          // Then abort...
          if(mapRadarStore.activeTowerId !== towerId || mapRadarStore.activeProductCode !== product) {
            reader.cancel();
            return;
          }

          // Update
          if(mapRadarStore.scanDatetime === null) {
            mapRadarStore.setScanDatetime(scan.datetime)
          }

          renderer.draw(scan);
        });

        return this.drawScan(map, scan, towerId, product)
      }

      const scan = await this.fetchFileByUrl(url);

      this.drawScan(map, scan, towerId, product)
    };

    promises.push(loadLatestScan(map, store.activeTower, store.activeProductCode))

    if (tower.properties.products.includes('NST') && this.settings.storm_tracks > 0) {
      promises.push(this.loadLatestStormTracks(tower))
    }

    if(this.isLightningSupported(tower) && this.settings.lightning > 0) {
      promises.push(this.lightning.load(tower.properties.id));
    }

    const results = await Promise.allSettled(promises);

    // Log out any errors here
    results.filter(r => r.status === 'rejected').forEach((result) => console.error(result.reason))
  }

  async loadLatestRadarDataAll() {
    const promises = MapKeeper.mapboxMaps().map(m => this.loadLatestRadarData(m));

    const results = await Promise.allSettled(promises);

    // Log out any errors here
    results.filter(r => r.status === 'rejected').forEach((result) => console.error(result.reason))
  }

  async resetAndLoadLatestRadarDataAll() {
    this.clearPlaybackState();

    await this.loadLatestRadarDataAll();
  }

  async changeRadarProduct(map, towerId, product) {
    const feature = this.towers.features.find(f => f.properties.id === towerId)

    if(feature === undefined) return false

    this.clearPlaybackState()

    // Check if the product is still active for all maps
    const currentMapStore = map.stores['radar']

    let productStillActive = false;
    MapKeeper.forEach(m => {
      if(m.id !== map.id) {
        const store = m.stores['radar'];

        if(store.activeTowerId === currentMapStore.activeTowerId && store.activeProductCode === currentMapStore.activeProductCode) {
          productStillActive = true;
        }
      }
    });

    if(! productStillActive) {
      const room = `radar:${currentMapStore.activeTowerId}:${currentMapStore.activeProductCode}`
      this.stopListeningToRoom(room)
    }

    await this.turnOnRadar(map, feature, product)
  }

  stopListeningToRoom(room) {
    socket.roomLeave(room)
    socket.removeAllListeners(room)

    this.activeSocketRooms = this.activeSocketRooms.filter(r => r !== room);
  }

  stopListeningToRooms() {
    this.activeSocketRooms.forEach(room => {
      socket.roomLeave(room)
      socket.removeAllListeners(room)
    })
    this.activeSocketRooms = []
  }

  removeRadar(feature) {
    useHead({
      title: PAGE_TITLE
    })

    this.radarTowersStore.clear()

    const towerId = feature.properties.id;

    this.stopListeningToRooms();

    MapKeeper.forEach(map => {
      const store = map.stores['radar'];
      store.clear()

      const renderer = map.components['radar-renderer'];
      renderer.clear();
    })

    this.stormTracks.clear(towerId);

    this.lightning.clear(towerId);
  }

  getClosestTowers(position) {
    const majorTowers = this.towers.features.filter(f => !f.properties.secondary).map(f => {
      return {
        feature: f,
        distance: distance(f.geometry.coordinates, position, { units: "kilometers" })
      }
    })

    majorTowers.sort((a, b) => {
      return a.distance - b.distance;
    })

    return majorTowers;
  }

  async turnOnClosestRadar(position) {
    const majorTowers = this.getClosestTowers(position);

    if(majorTowers.length == 0) return;

    const map = MapKeeper.mapboxMapsFirst()
    const store = map.stores['radar'];

    if(store.activeTowerId === majorTowers[0].feature.properties.id) return;

    this.closeAllRadarTowers()

    await MapKeeper.asyncEach((map) => this.turnOnRadar(map, majorTowers[0].feature))
  }

  async turnOnClosestOnlineRadar(position, product = 'REF') {
    // Now locate the nearest ONLINE radar (within 300 kilometers)
    // And turn on the lowest elevation ref product
    const closestTowers = this.getClosestTowers(position).filter(f => f.distance < 300);

    if(closestTowers.length === 0) {
      throw new Error('Could not locate a nearby radar tower.');
    }

    const defaultProductCodes = (() => {
      if(product === 'REF') return DEFAULT_RADAR_REF_PRODUCT_CODES;
      else if(product === 'VEL') return DEFAULT_RADAR_VEL_PRODUCT_CODES;

      return [];
    })();

    // Loop through each close tower until we find a ref. scan
    // That is not too old (max 10 mins.)
    for (const t of closestTowers) {
      const tower = t.feature;
      const towerId = tower.properties.id;
      const product = (() => {
        for(const p of defaultProductCodes) {
          if(tower.properties.products.includes(p)) return p;
        }

        return null;
      })();

      if(product === null) continue;

      const scan = await this.loadLatestFile(towerId, product);

      const age = moment.utc().diff(moment.utc(scan.datetime), 'seconds');

      // Check if scan is too old
      if(age < 60 * 10) {
        this.closeAllRadarTowers();
        await this.turnOnRadar(MapKeeper.mapboxMapsFirst(), tower, product);
        break;
      }
    }
  }

  async turnOnBestRadarForGeometry(geometry, product = 'REF') {
    // Now locate the nearest ONLINE radar (within 300 kilometers)
    // Loop through all the available 'product' scans
    // Find the 'best' which has the highest average value for
    // gates inside the geometry
    const center = centroid(geometry)
    const closestTowers = this.getClosestTowers(center).filter(f => f.distance < 300).slice(0, 4);

    if(closestTowers.length === 0) {
      throw new Error('Could not locate a nearby radar tower.')
    }

    const tPolygon = turfPolygon(geometry.coordinates)

    const defaultProductCodes = (() => {
      if(product === 'REF') return DEFAULT_RADAR_REF_PRODUCT_CODES;
      else if(product === 'VEL') return DEFAULT_RADAR_VEL_PRODUCT_CODES;

      return [];
    })();

    const scoredScans = [];

    // Loop through each close tower until we find a ref. scan
    // That is not too old (max 10 mins.)
    for (const t of closestTowers) {
      const tower = t.feature;
      const towerId = tower.properties.id;

      const availableProducts = tower.properties.products.filter(value => defaultProductCodes.includes(value));

      if(availableProducts.length === 0) continue;

      for (const product of availableProducts) {
        const scan = await this.loadLatestFile(towerId, product);

        // console.log(scan)

        const age = moment.utc().diff(moment.utc(scan.datetime), 'seconds');

        // If older than 10 minutes, ignore...
        if(age > 60 * 10) continue;

        let inside = 0;
        let insideSum = 0;
        let outside = 0;

        const bitsMax = scan.precision - 1;
        const bitsValueMax = (1 << bitsMax) - 1;

        let e = 0;
        let len = scan.data.length;
        let val = 0;
        let tval = 0;
        let fval = 0;
        let skip = 0;
        let idx = 0;
        let r = 0;
        let g = 0;

        const degreePerRow = 360 / scan.dims[0];

        // Loop through each 16 bit value
        while(e < len) {
          val = scan.data[e];

          // Check if the value is higher than the max value for 15 bits
          if(val > bitsValueMax) {
            skip = val - bitsValueMax;

            ++e;

            idx+=skip;

            continue;
          }

          // Convert 15 bits to 16 bits...
          fval = scan.range.min + (val / bitsValueMax) * (scan.range.max - scan.range.min);

          r = Math.floor(idx / scan.dims[1]);
          g = idx % scan.dims[1];

          // Require a min. of 15dbz
          if(fval >= 10) {
            const radius_m = scan.meters_to_center_of_first_gate + ((g + 0.5) * scan.meters_between_gates);
            const azimuth = (scan.azimuth_start + ((r + 0.5) * degreePerRow)) % 360.0;

            // console.log(r, g, radius_m, azimuth, scan.meters_to_center_of_first_gate, scan.meters_between_gates)

            const gatePosition = destination(scan.location, radius_m, azimuth, { units: 'meters' });

            // console.log(towerId, product, idx, r, g, scan.azimuth_start, radius_m, azimuth, fval, gatePosition)
            // MapKeeper.mapboxMapsFirst().setCenter(gatePosition.geometry.coordinates)
            // MapKeeper.mapboxMapsFirst().setZoom(20)
            // break;

            if(booleanPointInPolygon(gatePosition, tPolygon)) {
              ++inside
              insideSum+=fval
            }
            else {
              ++outside
            }
          }

          idx+=1;

          ++e;
        }

        if(inside > 0) {
          const insideMean = insideSum/inside;

          scoredScans.push([insideMean, [towerId, product]])
        }

        break;
      }

      break;
    }

    // console.log(scoredScans)

    if(scoredScans.length === 0) {
      throw new Error('Unable to find any available (interesting) radar scan');
    }

    scoredScans.sort((a, b) => {
      return b[0] - a[0]
    })

    const highestScore = scoredScans[0][1]

    this.closeAllRadarTowers();

    const [towerId, productCode] = highestScore;
    const tower = closestTowers.find(f => f.feature.properties.id === towerId).feature;

    // console.log('Best combo', towerId, productCode)

    await this.turnOnRadar(MapKeeper.mapboxMapsFirst(), tower, productCode)
  }

  async loadScanByUrl(feature, product, url) {
    const towerId = feature.properties.id

    try {
      const scan = await this.fetchFileByUrl(url);

      MapKeeper.forEach(m => {
        const store = m.stores['radar'];

        if(store.activeProductCode === product) {
          this.drawScan(m, scan, towerId, product)
        }
      });
    } catch (error) {
      console.error(error.message);
    }
  }

  getGroupsForTower(tower) {
    const towerProducts = tower.properties.products;
    return productGroups.filter(g => {
      const groupProducts = g.tilts.map(t => t.product);
      for(const product of towerProducts) {
        if(groupProducts.includes(product)) return true;
      }

      return false;
    });
  }

  getGroupForProduct(product) {
    return productGroups.find(g => {
      const tilt = g.tilts.find(t => {
        return t.product === product;
      })

      return tilt !== undefined;
    });
  }

  getColortableForGroup(group) {
    const colormapKey = this.settings.colortable[group.id];

    return RadarColortable.get(group.id, colormapKey)
  }

  setupRadarRenderer(renderer, group, colormap) {
    renderer.setColormap(colormap);

    if (this.subscription.isAtleast(this.subscription.tiers.PLUS) && this.settings.three_d === true) {
      renderer.setRender3D(true)
      renderer.setHeightMultiplier(18.0)
    }
    else {
      renderer.setRender3D(false)
    }

    if (this.subscription.isAtleast(this.subscription.tiers.PLUS) && this.settings.smoothing[group.id] > 0) {
      renderer.setColorSmoothing(true)
      renderer.setSmoothingEnabled(true)
      // renderer.setSmoothDegree(this.settings.smoothing[group.id], Math.sqrt(this.settings.smoothing[group.id]))
      // renderer.setSmoothDegree(this.settings.smoothing[group.id], this.settings.smoothing[group.id] / 2)
      renderer.setSmoothDegree(this.settings.smoothing[group.id] / 2, this.settings.smoothing[group.id])
    } else {
      renderer.setColorSmoothing(false)
      renderer.setSmoothingEnabled(false)
    }

    if(this.settings.gate_filter[group.id] !== undefined) {
      renderer.setFilter(this.settings.gate_filter[group.id][0], this.settings.gate_filter[group.id][1])
    }
    else {
      renderer.setFilter(-Infinity, Infinity)
    }
  }

  drawScan(map, scan, towerId, product) {
    if(scan === undefined || scan === null || typeof scan !== 'object') {
      return console.log(`Bad radar scan: ${towerId} ${product}`, scan);
    }

    const mapRadarStore = map.stores['radar'];

    // console.log(mapRadarStore.activeTowerId, mapRadarStore.activeProductCode, towerId, product)

    // Check that the tower is still active
    if(!(mapRadarStore.activeTowerId === towerId && mapRadarStore.activeProductCode === product)) {
      return console.log(`Loaded radar scan for a tower that is no longer active: ${towerId} ${product}. Currently active: ${mapRadarStore.activeTowerId} ${mapRadarStore.activeProductCode}.`)
    }

    // We need to convert low level product code to higher level
    // e.g. N0B is REF
    const group = this.getGroupForProduct(product);

    if(group === undefined) {
      console.error(`Failed to locate product group for product: ${product}`);

      return null;
    }

    const colormap = this.getColortableForGroup(group);

    if(colormap === null) {
      console.error(`Failed to any colortables for group: ${group.id}`);

      return null;
    }

    // console.log({colormap, product})

    mapRadarStore.setColorMap(colormap)
    mapRadarStore.setScanDatetime(scan.datetime)
    mapRadarStore.setScanVcp(scan?.metadata?.vcp ?? null);

    const renderer = map.components['radar-renderer'];

    this.setupRadarRenderer(renderer, group, colormap)

    renderer.draw(scan);
  }

  redrawScan(map) {
    const store = map.stores['radar']

    const renderer = map.components['radar-renderer']

    return this.drawScan(map, renderer.getData(), store.activeTowerId, store.activeProductCode)
  }

  redrawScanAll() {
    MapKeeper.forEach(m => this.redrawScan(m));
  }

  async loadLatestStormTracks(feature) {
    const towerId = feature.properties.id

    const product = 'NST'
    const room = `radar:${towerId}:${product}`
    if(! this.activeSocketRooms.includes(room)) {
      this.activeSocketRooms.push(room)
      socket.roomJoin(room)
      socket.on(room, async (data) => {
        console.log('Radar update', room, data, `Mode: ${this.mode}`)

        if(this.mode === MODE_REALTIME) {
          // Load the url provided in the data
          await this.loadStormTracksByUrl(feature, data.url)
        }
        else if(this.mode === MODE_HISTORICAL_TO_REALTIME) {
          if(this.bufferedRadarScans[product] === undefined) return false;

          try {
            const scan = await this.fetchFileByUrl(data.url);

            if(this.bufferedRadarScans[product] === undefined) return false;

            this.bufferedRadarScans[product].push(scan);

            while(this.bufferedRadarScans[product].length > this.bufferedRadarScansLimit) {
              this.bufferedRadarScans[product].shift();
            }
          }
          catch(e) {
            console.error(`Failed to fetch scan in historical to realtime mode`, e);
          }
        }
      })
    }

    const tracks = await this.loadLatestFile(towerId, 'NST')

    // Check that we should still render the storm tracks after loading the data
    const activeTowerId = MapKeeper.mapboxMapsFirst().stores['radar'].activeTowerId
    if(activeTowerId !== towerId) {
      return console.log(`Loaded radar scan for a tower that is no longer active: ${towerId} ${product}`)
    }

    this.drawStormTracks(towerId, tracks);
  }

  async loadStormTracksByUrl(feature, url) {
    const towerId = feature.properties.id

    try {
      const tracks = await api.instance().get(url);

      this.drawStormTracks(towerId, tracks);
    } catch (error) {
      console.error(error.message);
    }
  }

  drawStormTracks(towerId, tracks) {
    // Check that the tracks are actually 'new'
    const ageMins = moment.utc().diff(moment.utc(tracks.datetime), 'minutes');

    // If older than 20 minutes, don't draw
    if(ageMins > 20) return;

    this.stormTracks.draw(towerId, tracks)
  }

  clearStormTracks(towerId) {
    this.stormTracks.clear(towerId);

    const product = 'NST'
    const room = `radar:${towerId}:${product}`

    socket.roomLeave(room)
    socket.removeAllListeners(room)

    this.activeSocketRooms = this.activeSocketRooms.filter(r => r !== room);
  }

  async loadHistory(towerId, products, limit) {
    if(limit === undefined) {
      limit = this.settings.max_scans;
    }

    // Ensure the products list is unique
    products = [...new Set(products)];

    // Check if we're already buffered data for this product
    // If so, exit early
    const allLoadedAlready = products.every(p => {
      return typeof this.bufferedRadarScans[p] === 'object' && this.bufferedRadarScans[p].length <= limit
    });
    if(allLoadedAlready) return false;

    this.bufferedRadarScansLimit = limit;

    const feature = this.towers.features.find((f) => f.properties.id === towerId)
    if (feature === undefined) return;

    // Clear previously buffered state
    this.clearPlaybackState();

    // Now set the back
    // The clear function above will set it back to realtime
    this.mode = MODE_HISTORICAL_TO_REALTIME;

    const bufferNST = feature.properties.products.includes('NST') && this.settings.storm_tracks > 0;
    const bufferLightning = this.isLightningSupported(feature) && this.settings.lightning > 0;
    const bufferWarnings = true;
    const bufferMesoscaleDiscussions = true;

    let requestsToMake = 0;

    let percentIncrement = 0;

    progress.value = 0.1;

    const requestsProduct = {};

    for(const product of products) {
      const limitedProductList = await this.fetchLatestList(towerId, product, limit);
      requestsToMake+=limitedProductList.length;
      requestsProduct[product] = limitedProductList.map((file) => {
        return () => {
          return new Promise(async (resolve, reject) => {
            try {
              const r = await this.fetchFile(towerId, product, file);
              progress.value+=percentIncrement
              resolve(r)
            }
            catch(e) {
              reject(e);
            }
          })
        };
      });
    }

    // console.log({requestsProduct})
    // return

    let requestsNST = null;
    if(bufferNST) {
      try {
        const limitedNSTList = await this.fetchLatestList(towerId, 'NST', limit);
        requestsToMake+=limitedNSTList.length;
        requestsNST = limitedNSTList.map((file) => {
          return () => {
            return new Promise(async (resolve, reject) => {
              try {
                const r = await this.fetchFile(towerId, 'NST', file);
                progress.value+=percentIncrement
                resolve(r)
              }
              catch(e) {
                reject(e);
              }
            })
          }
        });
      }
      catch(e) {
        console.error('Failed to fetch NST list', e);
      }
    }

    progress.value = 0.2;

    // Subtract 0.1 for the requests made below the radar products (warnings, etc.)
    percentIncrement = (1.0 - progress.value - 0.1) / requestsToMake;

    // console.log(requestsToMake, percentIncrement)

    // This will need a rethink once the limit is higher
    for(const product of products) {
      const response = await allSettledLimit(requestsProduct[product], 6)

      this.bufferedRadarScans[product] = response.filter(r => r.status === 'fulfilled').map(r => r.value);
    }

    if(bufferNST && requestsNST !== null) {
      // This will need a rethink once the limit is higher
      const response = await allSettledLimit(requestsNST, 6)

      this.bufferedRadarScans['NST'] = response.filter(r => r.status === 'fulfilled').map(r => r.value);
    }

    // We'll assume there is a radar scan every 6 mins. on average
    // NEXRAD is every 3-6
    // CAN is every 6
    let secsToLoad = 60 * 60;
    if(limit > 15) {
      secsToLoad = 24 * 60 * 60;
    }

    const promises = [];

    if(bufferLightning) {
      promises.push(this.lightning.loadHistory(towerId, secsToLoad));
    }

    if(bufferWarnings) {
      promises.push(App.warnings.loadHistory(secsToLoad));
    }

    if(bufferMesoscaleDiscussions) {
      promises.push(App.mesoscaleDiscussions.loadHistory(secsToLoad));
    }

    await Promise.allSettled(promises);

    this.radarTowersStore.hasBufferedScans = true;

    progress.value = 1.0
  }

  clearBufferedHistoricalState() {
    for(const key in this.bufferedRadarScans) {
      delete this.bufferedRadarScans[key]
    }

    this.radarTowersStore.hasBufferedScans = false;
  }

  async playScans(towerId, products, maxSteps = Infinity) {
    // console.log({towerId, products, maxSteps})

    // Ensure the products list is unique
    products = [...new Set(products)];

    // If data has no been buffered, then exit early
    const allLoadedAlready = products.some(p => {
      return this.bufferedRadarScans[p] !== undefined;
    });
    if(! allLoadedAlready) return false;

    const feature = this.towers.features.find((f) => f.properties.id === towerId)
    if (feature === undefined) return;

    const bufferedHistoricalToRealtime = true;

    let playNST = feature.properties.products.includes('NST') && typeof this.bufferedRadarScans['NST'] === 'object';
    let playLightning = true;
    let playWarnings = true;
    let playMesoscaleDiscussions = true;

    if(window.location.href.includes('test-radar-scans')) {
      playNST = false;
      playLightning = false;
      playWarnings = false;
      playMesoscaleDiscussions = false;
    }

    this.radarTowersStore.isPlaying = true;

    const productToMap = {};
    MapKeeper.forEach(m => {
      const store = m.stores['radar'];

      if(productToMap[store.activeProductCode] === undefined) {
        productToMap[store.activeProductCode] = [];
      }

      productToMap[store.activeProductCode].push(m.id);
    });

    let currentStep = 0;

    const firstProduct = products[0];
    const length = this.bufferedRadarScans[firstProduct].length;

    const product = products[0]

    while(this.radarTowersStore.isPlaying && currentStep < maxSteps) {
      // const start = (new Date()).getTime();

      if(! MapKeeper.isUserInteracting()) {
        if(this.playbackIdx < 0) {
          this.playbackIdx = 0;
        }
        else if(this.playbackIdx >= length) {
          this.playbackIdx = length - 1;
        }

        const scan = this.bufferedRadarScans[firstProduct][this.playbackIdx];

        // Ensure that there is a scan here...
        if(scan) {
          const dt = scan.datetime;
          const momentDt = moment.utc(dt);

          for(const product of products) {
            const scan2 = this.bufferedRadarScans[product][this.playbackIdx];
            if(scan2 === undefined) continue;

            if(productToMap[product] === undefined) continue;

            for(const mapId of productToMap[product]) {
              const map = MapKeeper.map(mapId)

              // Map could suddenly become undefined if in split view
              // And split view is closed during playback
              if(map === undefined) continue

              this.drawScan(map, scan2, towerId, product)
                
              // TODO
              // Use a more optimised path
              
              // const mapRadarStore = map.stores['radar']
              // const renderer = map.components['radar-renderer']

              // mapRadarStore.setScanDatetime(scan.datetime)
              // mapRadarStore.setScanVcp(scan?.metadata?.vcp ?? null);

              // renderer.draw(scan2)
            }
          }

          const isLastScan = (this.playbackIdx + 1) === length;
          const displayFuture = bufferedHistoricalToRealtime && isLastScan;

          if(playNST) {
            const NSTdates = this.bufferedRadarScans['NST'].map(s => s.datetime)

            const closest = findClosestDate(NSTdates, momentDt);

            // Check if the closest date is within 15 minutes
            const closestNST = (closest !== null && Math.abs(moment.utc(closest).diff(momentDt, 'minutes')) <= 15) ? closest : null;

            if(closestNST !== null) {
              const tracks = this.bufferedRadarScans['NST'].find(s => s.datetime === closestNST);

              this.stormTracks.draw(towerId, tracks);
            }
          }

          if(playLightning) {
            this.lightning.drawHistory(momentDt, displayFuture);
          }

          if(playWarnings) {
            App.warnings.drawHistory(momentDt, displayFuture);
          }

          if(playMesoscaleDiscussions) {
            App.mesoscaleDiscussions.drawHistory(momentDt, displayFuture);
          }
        }

        ++currentStep;
        ++this.playbackIdx;
        if(this.playbackIdx >= length) this.playbackIdx = 0;
      }

      let delay = this.settings.playback_speed_ms;
      if(this.playbackIdx === 0) {
        delay*=4;
      }

      // const end = (new Date()).getTime();

      // console.log('play latency', end - start);

      await wait(delay);
    }
  }

  openRadarProductHelpModal(productGroup) {
    const modal = useModal({
      defaultModelValue: true,
      component: SimpleModal,
      attrs: {
        title: productGroup.name
      },
      slots: {
        default: useModalSlot({
          component: RadarProductHelpModal,
          attrs: {
            text: productGroup.help,
            onClose() {
              modal.close()
            },
          }
        })
      },
    })

    return modal;
  }

  changeTowerIcon() {
    for(const layerId of this.towerIconLayers) {
      MapKeeper.setLayoutProperty(layerId, 'visibility', 'none');
    }

    switch(this.settings.tower_icon_design) {
      case 'pill':
        MapKeeper.setLayoutProperty(this.pillLayerId, 'visibility', 'visible');
        break;
      default:
        MapKeeper.setLayoutProperty(this.dotLayerId, 'visibility', 'visible');
    }
  }

  show() {
    this.changeTowerIcon();
    
    MapKeeper.setLayoutProperty(this.radarRendererLayerId, 'visibility', 'visible');

    (async () => {
      await this.lightning.loadIcon()

      MapKeeper.forEach(m => {
        const store = m.stores['radar']

        if (store.activeTower === undefined) return;

        this.turnOnRadar(m, store.activeTower)
      })
    })()

    this.lightning.show();
    this.stormTracks.show();
  }

  hide() {
    for(const layerId of this.towerIconLayers) {
      MapKeeper.setLayoutProperty(layerId, 'visibility', 'none');
    }
    for(const layerId of this.layers) {
      MapKeeper.setLayoutProperty(layerId, 'visibility', 'none');
    }

    this.stopListeningToRooms();

    this.clearPlaybackState();

    this.lightning.hide();
    this.stormTracks.hide();
  }

  // data server API methods
  async determineLatestFile(id, product) {
    const list = await this.fetchLatestList(id, product)

    // console.log(list)

    if (list.length == 0) {
      return null
    }

    return list[list.length - 1];
  }

  async loadLatestFile(id, product) {
    const latestScanFilename = await this.determineLatestFile(id, product);

    if(latestScanFilename === null) {
      // TODO
      return;
    }

    // console.log(latestScanFilename)

    return await this.fetchFile(id, product, latestScanFilename)
  }

  async fetchLatestList(id, product, limit = -1) {
    let list;

    const now = (new Date()).getTime();

    if(window.location.href.includes('test-radar-scans')) {
      list = await (await fetch('/test-radar-scans/dir.list')).text()
    }
    else {
      list = await (
        await api.instance().get(`/radar/processed/${id}/${product}/dir.list?_=${now}`)
      )
    }

    const cleanList = list.split('\n').filter((l) => l.length > 0);

    if(limit === -1) return cleanList;

    return cleanList.slice(Math.max(cleanList.length - limit, 0))
  }

  buildUrlForFile(id, product, filename) {
    let url = `/radar/processed/${id}/${product}/${filename}`;

    if(window.location.href.includes('test-radar-scans')) {
      url =`http://${window.location.host}/test-radar-scans/${filename}`;
    }

    return url;
  }

  async fetchFile(id, product, filename) {
    const url = this.buildUrlForFile(id, product, filename);

    return await this.fetchFileByUrl(url);
  }

  async fetchFileByUrl(url) {
    const response = await api.instance().get(url, {
      responseType: 'arraybuffer'
    });

    const packet = new Packet(response);

    if (packet.isBinary()) {
      return packet.unpack();
    }

    // If it's not binary, then we can assume it's JSON
    return packet.json();
  }
}
