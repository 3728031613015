import '../node_modules/mapbox-gl/dist/mapbox-gl.css'
import '../node_modules/nprogress/nprogress.css'
import 'vue-final-modal/style.css'
import './assets/main.css'

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { createPersistedState } from 'pinia-plugin-persistedstate'
import { createVfm } from 'vue-final-modal'
import VueSocialSharing from 'vue-social-sharing'
import { createPlausible } from 'v-plausible/vue'
import { createHead } from '@unhead/vue'
import PrimeVue from 'primevue/config';

import KeyboardShortcuts from '@/logic/Extra/KeyboardShortcuts';
import UserbackPlugin from '@/logic/Extra/UserbackPlugin';
import { install as RevenueCatInstall } from '@/logic/RevenueCat';

import { createHazcams } from '@/logic/Hazcams'

import App from './App.vue'
import router from './router'

import RollbarPlugin from './rollbar.plugin'
import { useAppStore } from './stores/app'

if (import.meta.env.DEV) {
  console.log('env', import.meta.env)
}

const plausible = createPlausible({
  init: {
    domain: 'web.weatherwise.app',
    apiHost: 'https://plausible.io',
    trackLocalhost: false,
    hashMode: false
  },
  settings: {
    enableAutoOutboundTracking: false,
    enableAutoPageviews: true,
  },
  partytown: false,
})

const pinia = createPinia()
pinia.use(
  createPersistedState({
    key: (id) => `ww:store:${id}`
  })
)

const app = createApp(App)

app.use(pinia)

if (window.ReactNativeWebView !== undefined && window.ReactNativeWebView.injectedObjectJson !== undefined) {
  try {
    window.WeatherWiseBridge = JSON.parse(window.ReactNativeWebView.injectedObjectJson()).WeatherWiseBridge;

    useAppStore().setDeepLink(window.WeatherWiseBridge.deepLink !== undefined ? window.WeatherWiseBridge.deepLink : null)
  } catch (e) {
    console.warn(`Failed to setup bridge`)
  }
}

import Aura from '@primevue/themes/aura';
app.use(PrimeVue, {
  theme: {
    preset: Aura,
    options: {
      darkModeSelector: false
    }
  }
})
app.use(router)
app.use(createVfm())
app.use(RollbarPlugin)
app.use(UserbackPlugin, {
  token: 'A-zwpuyIZmAr2ke0wULbwde3lA9'
});
app.use(KeyboardShortcuts)
app.use(createHazcams)
app.use(RevenueCatInstall)
app.use(plausible)

app.use(VueSocialSharing)


const head = createHead()
app.use(head)

app.mount('#app')
