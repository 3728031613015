import { useSubscriptionStore } from '@/stores/subscription';

export default {
    install(app, config) {
        const subscriptionStore = useSubscriptionStore()

        window.Userback = window.Userback || {};
        Userback.access_token = config.token;

        if (subscriptionStore.revenueCatCustomerInfo) {
            Userback.user_data = {
                id: subscriptionStore.revenueCatCustomerInfo.originalAppUserId,
                info: subscriptionStore.revenueCatCustomerInfo
            }
        }

        app.config.globalProperties.$Userback = window.Userback
    }
}