import ExpoBridge from '../Expo/ExpoBridge';
import { hasWeatherWiseBridge } from '@/logic/Extra/helpers'

const WakeLockWrapper = {
    wakeLock: null,

    async request(tag) {
        if (this.wakeLock !== null) {
            console.warn(`Attempting to request the wake lock when it is already set`)
            throw new Error('Wake lock already set')
        }

        try {
            if (hasWeatherWiseBridge()) {
                this.wakeLock = await ExpoBridge.wakeLock.request(tag)
            } else {
                this.wakeLock = await navigator.wakeLock.request(tag)
            }

            // Create a wrapper object that ensures the release method exists
            const wrapper = {
                release: async () => {
                    if (!this.wakeLock) return
                    await this.wakeLock.release()
                    this.wakeLock = null
                }
            }

            return wrapper
        } catch (error) {
            console.error('Error requesting wake lock:', error)
            throw error
        }
    }
}

export default WakeLockWrapper