<template>
    <div class="fixed top-7 right-2 z-[99]">
        <img src="/ww-watermark-300.png" class="transform origin-top-right scale-50" />
        
        <span v-if="userHasPlus" class="absolute top-[1px] right-[5px] z-[100] transform scale-75 bg-gradient-to-r from-[#F6B91A] to-[#FF5722] rounded px-1 font-bold text-white border border-[#FF5722] text-sm" :class="[textSizeClass]">Plus</span>
    </div>
</template>

<script>
import { useSubscription } from '@/logic/Composables/Subscription'

export default {
  name: 'Watermark',
  computed: {
    userHasPlus() {
        const sub = useSubscription();

        return sub.activeTier() === sub.tiers.PLUS
    }
  },
};
</script>