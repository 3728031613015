<template>
<div v-if="satelliteStore.anyActive" class="fixed bottom-1 left-0 px-1 z-[26] w-full sm:w-auto sm:ml-2 sm:bottom-2">
  <div class="drawer-container transition-all duration-300 ease-in-out bg-white dark:bg-ww-blue rounded-2xl shadow-lg flex flex-col text-black dark:text-white sm:w-[375px] md:w-[425px]"
       :class="{
        'drawer-open': isOpen,
        'drawer-closed': !isOpen,
        'drawer-fullscreen': isNavigatorStandalone()
       }">
    <div @click="toggleDraw($event)" ref="drawTab" class="flex relative items-center justify-center cursor-pointer rounded-t-2xl px-4" :class="{
      'py-2': isOpen,
      'h-full': ! isOpen,
      'pb-4': (! isOpen) && isNavigatorStandalone()
    }">
      <div class="w-full text-left">
        <div v-if="mapsStore.maps.length === 1">
          <div class="truncate"><span class="font-bold" v-text="mapSatellite.activeSatellite.name"></span><span class="mx-1">&bull;</span><span class="font-bold" v-text="mapSatellite.activeProduct.name"></span></div>
          <div class="text-xs">
            <template v-if="mapSatellite.scanDatetime === null">Loading...</template>
            <template v-else>
              <span class="">Scan At: </span>
              <span v-text="mapSatellite.scanLocalTime" :title="`UTC: ${mapSatellite.scanDatetime}`" class="font-bold" :class="{
                'bg-red-100 text-red-800 px-2.5 py-0.5 rounded-full': mapSatellite.scanIsOld
              }"></span>
              <!-- <span v-if="mapSatellite.scanIsNew" class="font-black text-red-800 live-text ml-2">Live</span> -->
            </template>
          </div>
        </div>
        <div v-else>
          <div class="truncate">
              <span class="font-bold" v-text="mapSatellite.activeSatellite.name"></span>
              <span class="mx-1">&bull;</span>
              <span v-for="(ms, index) in mapSatellites">
                <span class="font-bold" v-text="ms.activeProduct.name"></span><span v-if="index+1 !== mapSatellites.length">,&nbsp;</span>
              </span>
            </div>
            <div class="text-xs">
              <template v-for="(ms, index) in mapSatellites">
                <template v-if="ms.scanDatetime === null">
                  <span>Loading...</span>
                  <span v-if="index+1 !== mapSatellites.length">,&nbsp;</span>
                </template>
                <template v-else>
                  <span v-text="ms.scanLocalTime" :title="`UTC: ${ms.scanDatetime}`" class="font-bold" :class="{
                    'bg-red-100 text-red-800 px-2.5 py-0.5 rounded-full': !satelliteStore.isPlaying && ms.scanIsOld && !satelliteStore.hasBufferedScans
                  }"></span>
                  <span v-if="index+1 !== mapSatellites.length">,&nbsp;</span>
                </template>
              </template>
              <!-- <span v-if="!satelliteStore.isPlaying && !satelliteStore.hasBufferedScans" class="font-black text-red-800 dark:text-red-500 live-text ml-2">Live</span> -->
            </div>
        </div>
      </div>
      <Icon :icon="isOpen ? 'gravity-ui:chevron-down-wide' : 'gravity-ui:chevron-up-wide'" class="absolute" :class="{
          'bottom-2': mapsStore.maps.length > 1
        }" />
      <div class="hidden text-right" ref="controls"></div>
    </div>
    <div v-show="isOpen" class="flex-grow flex flex-col border-t border-gray-200 dark:border-gray-700 overflow-y-auto">
      <div class="px-2 mt-2" v-if="mapsStore.maps.length > 1">
        <ul class="text-sm font-medium text-center text-gray-500 rounded-lg shadow flex">
          <li class="w-full focus-within:z-10" v-for="(map, idx) in mapsStore.maps">
            <a href="#" class="inline-block w-full p-2 font-bold border-gray-200 hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-ww-blue-200" :class="{
              'rounded-s-lg': idx === 0,
              'border-s-0 rounded-e-lg': idx === mapsStore.maps.length - 1,
              'border-r': idx !== mapsStore.maps.length - 1,
              'text-white bg-blue-700 hover:!bg-blue-800 hover:text-white active': map.id === mapsStore.activeMapId,
              'hover:text-gray-700 hover:bg-gray-50': idx !== 0,
            }" @click.prevent="onMapTabClick(map)"><span v-if="mapsStore.maps.length <= 4" class="font-bold">Map</span> {{ idx+1 }}</a>
          </li>
        </ul>
      </div>

      <div class="flex justify-start items-start m-3 select-none">
        <div class="text-sm">
          <template v-if="mapSatellite.scanDatetime === null">Loading...</template>
          <template v-else>
            <div class="text-xs sm:text-sm">
              <span>Scan At: </span>
              <span v-text="mapSatellite.scanLocalDatetime" :title="`UTC: ${mapSatellite.scanDatetime}`" class="font-bold" :class="{
                'bg-red-100 text-red-800 px-2.5 py-0.5 rounded-full': mapSatellite.scanIsOld
              }"></span>
              <a href="#" class="ml-2 inline" @click.prevent="showProductHelp($event)"><Icon icon="carbon:help" class="size-4 inline" /></a>
            </div>
          </template>
        </div>
      </div>
      <div class="mx-3">
        <div class="flex">
          <select class="bg-gray-50 dark:bg-ww-blue-100 border border-gray-300 dark:border-gray-600 text-gray-900 dark:text-gray-100 text-sm rounded-lg block w-full p-1" v-model="mapSatellite.activeSatelliteId" @change="onSatelliteChange($event)">
            <option v-for="sat in satellites" :value="sat.id" v-text="sat.name"></option>
          </select>
          <a href="#"class="inline-flex items-center justify-center p-2 pl-4" @click.prevent="showSatelliteHelp($event)"><Icon icon="carbon:help" class="size-5 align-middle" /></a>
        </div>
      </div>
      <div>
      <div>
        <div class="grid grid-cols-3 gap-4">
          <template v-for="(product, index) in mapSatellite.activeSatellite.products">
            <div class="flex-1 min-w-0 p-2 text-center select-none" @click="onProductClick($event, product)">
              <div style="height: 60px; width: 60px;" class="ml-auto mr-auto rounded-lg overflow-hidden cursor-pointer" :class="{
                'border-sky-400 dark:border-sky-500 border border-4 rounded-lg': mapSatellite.activeProductCode === product.id
              }">
                <img :src="getImageUrl(product.id)" :alt="product.name">
              </div>
              <div v-text="product.name" class="text-sm mt-1"></div>
            </div>
          </template>
        </div>
      </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { ref, onMounted, getCurrentInstance } from 'vue'
import { Icon } from '@iconify/vue'
import { useSwipe } from '@vueuse/core'

import satellites from '@/data/Satellite/list.js'

import { useSatelliteStore } from '@/stores/satellite'
import { useMapSatelliteStore } from '@/stores/map_satellite'
import { useMapsStore } from '@/stores/maps'
import { useAppStore } from '@/stores/app'

import { isNavigatorStandalone } from '@/logic/Extra/helpers'

import App from '@/logic/App'
import MapKeeper from '@/logic/MapKeeper'

export default {
  name: 'SatelliteDrawer',
  setup() {
    const drawTab = ref(null)
    const instance = getCurrentInstance()

    onMounted(() => {
      const { direction } = useSwipe(drawTab, {
        onSwipe(e) {
          if (direction.value === 'down' && instance.proxy.isOpen) {
            instance.proxy.toggleDraw(e)
          }

          if (direction.value === 'up' && ! instance.proxy.isOpen) {
            instance.proxy.toggleDraw(e)
          }

          e.stopPropagation()
        }
      })
    })

    return {
      drawTab,
      isNavigatorStandalone
    }
  },
  data() {
    return {
      isOpen: false,
    };
  },
  components: {
    Icon
  },
  computed: {
    satelliteStore() {
      return useSatelliteStore()
    },
    appStore() {
      return useAppStore()
    },
    mapsStore() {
      return useMapsStore()
    },
    mapSatellite() {
      return MapKeeper.activeMap().stores['satellite'];
    },
    mapSatellites() {
      return MapKeeper.mapboxMaps().map(m => m.stores['satellite']);
    },
    satellites() {
      return satellites;
    }
  },
  watch: {
    'satelliteStore.anyActive': {
      handler(newValue) {
        if (! newValue) {
          this.isOpen = false
        }
      }
    }
  },
  methods: {
    openDraw() {
      this.isOpen = true;
    },
    closeDraw() {
      this.isOpen = false;
    },
    toggleDraw(e) {
      if(!document.body.contains(e.target) || (this.$refs.controls.contains(e.target) && this.$refs.controls !== e.target)) return false;

      if (!this.isOpen) {
        this.openDraw();
      } else {
        this.closeDraw();
      }
    },
    showProductHelp(e) {
      App.satellite.openSatelliteProductHelpModal(this.mapSatellite.activeSatelliteId, this.mapSatellite.activeProductCode);
    },
    showSatelliteHelp(e) {
      App.satellite.openSatelliteHelpModal(this.mapSatellite.activeSatelliteId);
    },
    onSatelliteChange(e) {
      MapKeeper.forEach(m => {
        App.satellite.turnOnSatellite(m, this.mapSatellite.activeSatelliteId);
      })
    },
    onProductClick(e, product) {
      if(this.mapSatellite.activeProductCode === product.id) return;

      App.satellite.changeSatelliteProduct(MapKeeper.activeMap(), this.mapSatellite.activeSatelliteId, product.id)
    },
    onMapTabClick(map) {
      this.mapsStore.activeMapId = map.id;
    },
    getImageUrl(name) {
      return new URL(`../assets/satellite-products/${name}.png`, import.meta.url).href
    }
  }
}
</script>

<style scoped>
.drawer-container {
  max-height: 400px;
}

.drawer-closed {
  height: 3.3rem;
  transform: translateY(calc(100% - 3.3rem));
}

.drawer-fullscreen {
  height: 5rem;
  transform: translateY(calc(100% - 5rem));
}

.drawer-open {
  height: 50vh;
  transform: translateY(0);
}

.live-text {
  animation: text-pulsate 3s ease-out infinite;
}

@keyframes text-pulsate {
  0% {
    opacity: 1.0;
  }
  20% {
    opacity: 0.3;
  }
  30%, 100% {
    opacity: 1.0;
  }
}
</style>
