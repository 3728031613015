<script setup>
import { computed } from 'vue'
import { Icon } from '@iconify/vue'
import { useRadarPlayback } from '@/logic/Composables/RadarPlayback'
import { useRadarTowersStore } from '@/stores/radar_towers'
import App from '@/logic/App'

// Extract the radar towers store
const radarTowersStore = useRadarTowersStore()

// Encapsulate dependencies using computed
const isInspectorActive = computed(() => radarTowersStore.inspectorActive)
const isPlaybackBuffering = computed(() => radarTowersStore.isPlaybackBuffering)
const isPlaying = computed(() => radarTowersStore.isPlaying)
const hasBufferedScans = computed(() => radarTowersStore.hasBufferedScans)

// Create derived state for the play button icon
const playButtonIcon = computed(() =>
  isPlaybackBuffering.value
    ? 'eos-icons:three-dots-loading'
    : !isPlaying.value
    ? 'ph:play-fill'
    : 'ph:pause-fill'
)

// Actions
const { togglePlayback } = useRadarPlayback()

const onPlayClick = (e) => {
  const button = e.target.closest('button')
  button?.blur()

  if (isPlaying.value) {
    radarTowersStore.isPlaying = false
    return
  }
  togglePlayback()
}

const onDataPickerClick = () => {
  radarTowersStore.inspectorActive = !radarTowersStore.inspectorActive
}

const onStopClick = async () => {
  await App.radar.resetAndLoadLatestRadarDataAll()
}
</script>

<template>
  <button
    @click.prevent="onDataPickerClick"
    type="button"
    class="text-white font-medium rounded-lg text-sm px-2.5 py-2 pt-1.5 mr-2"
    :class="{
      'bg-blue-900 hover:bg-blue-950': isInspectorActive,
      'bg-blue-700 hover:bg-blue-800': !isInspectorActive
    }"
  >
    <Icon icon="zondicons:target" class="inline size-4" />
  </button>

  <button
    @click.prevent="onPlayClick"
    type="button"
    class="text-white bg-blue-700 hover:bg-blue-800 font-medium rounded-lg text-sm px-2.5 py-2 pt-1.5"
    :disabled="isPlaybackBuffering"
  >
    <Icon :icon="playButtonIcon" class="inline size-4" />
  </button>

  <button
    v-show="hasBufferedScans"
    @click.prevent="onStopClick"
    type="button"
    class="ml-2 text-white bg-blue-700 hover:bg-blue-800 font-medium rounded-lg text-sm px-2.5 py-2 pt-1.5"
    :disabled="isPlaybackBuffering"
  >
    <Icon icon="ph:stop-fill" class="inline size-4" />
  </button>
</template>