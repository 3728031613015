// GeolocationWrapper.js
import ExpoBridge from '../Expo/ExpoBridge';
import { hasWeatherWiseBridge } from '@/logic/Extra/helpers'

const GeolocationWrapper = {
  watchPositionId: null,

  requestPermission: async () => {
    console.log('Checking if ExpoBridge is available...');
    if (hasWeatherWiseBridge()) {
      console.log('ExpoBridge is available, requesting permission...');
      return ExpoBridge.location.requestPermission();
    } else {
      console.log('ExpoBridge is not available, querying navigator permissions...');
      return new Promise((resolve, reject) => {
        navigator.permissions.query({ name: 'geolocation' }).then((result) => {
          console.log('Geolocation permission query result:', result.state);
          if (result.state === 'granted') {
            console.log('Geolocation permission granted.');
            resolve('granted');
          } else if (result.state === 'prompt') {
            console.log('Geolocation permission prompt shown.');
            resolve('prompt');
          } else {
            console.error('Geolocation permission denied.');
            reject(new Error('Permission denied'));
          }
        }).catch((error) => {
          console.error('Error querying geolocation permissions:', error);
          reject(error);
        });
      });
    }
  },

  getCurrentPosition: async (options) => {
    console.log('Requesting location permission...');
    const permissionStatus = await GeolocationWrapper.requestPermission();
    if (permissionStatus === 'denied') {
      console.error('Location permission denied.');
      throw new Error('Location permission denied');
    }

    try {
      console.log('Getting current position...');
      if (hasWeatherWiseBridge()) {
        console.log('Using ExpoBridge to get current position...');
        try {
          const expoLocation = await ExpoBridge.location.getCurrentPosition();
          return Promise.resolve({
            coords: {
              latitude: expoLocation.latitude,
              longitude: expoLocation.longitude,
            },
            _expo: expoLocation
          })
        } catch (error) {
          return Promise.reject(error);
        }
      } else {
        console.log('Using navigator.geolocation to get current position...');
        return new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject, options);
        });
      }
    } catch (error) {
      console.error('Error getting current position:', error);
      throw error;
    }
  },

  async watchPosition (options, successCb) {
    console.log('Requesting location permission...');
    const permissionStatus = await GeolocationWrapper.requestPermission();
    if (permissionStatus === 'denied') {
      console.error('Location permission denied.');
      throw new Error('Location permission denied');
    }

    if (this.watchPositionId) {
      console.warn(`Watch position id is already set. Run 'clearWatch' before attempting to watch`);
      throw new Error(`Already watching position`)
    }

    if (hasWeatherWiseBridge()) {
      console.log('Using ExpoBridge to get watch position...');
      this.watchPositionId = 'native';
      ExpoBridge.location.watchPosition(successCb)
    } else {
      console.log('Using navigator.geolocation to get watch position...');
      this.watchPositionId = navigator.geolocation.watchPosition(successCb, (e) => { throw e; }, options);
    }

    return Promise.resolve(this.watchPositionId)
  },

  clearWatch() {
    if (! this.watchPositionId) {
      console.warn(`No watch position id set`);
      return;
    }

    if (this.watchPositionId === 'native') {
      // instruct the device to stop watching the position
      ExpoBridge.location.clearWatch()
    } else if (Number.isInteger(this.watchPositionId)) {
      navigator.geolocation.clearWatch(this.watchPositionId)
    }

    this.watchPositionId = null
  }
};

export default GeolocationWrapper;
