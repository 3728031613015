import Radar from '@/logic/Radar'
import Satellite from '@/logic/Satellite'
import Warnings from '@/logic/Warnings'
import MesoscaleDiscussions from '@/logic/MesoscaleDiscussions'
import Outlooks from '@/logic/Outlooks'
import Tropical from '@/logic/Tropical'
import Robot from '@/logic/Robot'
import Mp4Recorder from '@/logic/Extra/Mp4Recorder'
import GifRecorder from '@/logic/Extra/GifRecorder'
import Screenshot from '@/logic/Extra/Screenshot'
import { compareSemver } from '@/tools/helpers'
import UrlHash from '@/tools/url-hash'
import { hasWeatherWiseBridge } from '@/logic/Extra/helpers'

import modals from '@/logic/Modals/Helper'

import MapKeeper from '@/logic/MapKeeper'

import { useAppStore } from '@/stores/app'

import { MapboxDrawingPlugin } from '@/logic/Extra/drawing'

import { useHazcams } from '@/logic/Hazcams'
import { useSpotterNetwork } from '@/logic/SpotterNetwork'

const mapLayers = [
  useHazcams,
  useSpotterNetwork,
]

class App {
  init() {
    this.appStore = useAppStore();

    this.modals = modals;

    this.mapkeeper = MapKeeper;

    this.satellite = new Satellite();

    this.radar = new Radar();

    this.warnings = new Warnings();

    this.mesoscaleDiscussions = new MesoscaleDiscussions();

    this.outlooks = new Outlooks();

    this.tropical = new Tropical();

    this.mp4 = new Mp4Recorder()
    this.gif = new GifRecorder()
    this.screenshot = new Screenshot();

    this.drawing = new MapboxDrawingPlugin();

    this.robot = new Robot();

    mapLayers.forEach(layer => layer().showIfVisible())

    this.appStore.ready();
    this.appStore.hideAllModes();
    this.appStore.showMode();
  }

  isNativeApp() {
    return hasWeatherWiseBridge();
  }

  getNativeVersion() {
    if (import.meta.env.DEV) {
      const params = new UrlHash()
      if(params.has('native_version')) return params.get('native_version')
    }
    if(! this.isNativeApp()) return null;

    const ua = window.navigator.userAgent.trim();
    if(! ua.startsWith('WeatherWise/')) return null;

    return ua.split(/[ ,]+/)[0].split('/')[1];
  }

  isAppleNative() {
    return this.isNativeApp() && window.navigator.userAgent.includes('Apple')
  }

  isNativeVersionAtleast(version) {
    const currentVersion = this.getNativeVersion();
    if(currentVersion === null) return false;

    return compareSemver(currentVersion, version) >= 0;
  }

  isNativeVersionEqualTo(version) {
    const currentVersion = this.getNativeVersion();
    if(currentVersion === null) return false;
    
    return compareSemver(currentVersion, version) === 0;
  }

  shouldShowFeatures(nativeVersion) {
    let isNative = this.isNativeApp()
    if (import.meta.env.DEV) {
      if((new UrlHash()).has('native_version')) {
        isNative = true;
      }
    }
    if(isNative) return this.isNativeVersionAtleast(nativeVersion);

    return true;
  }

  hash() {
    return __COMMIT_HASH__;
  }
}

const app = new App();
window.app = app;

export default app;
