import { MAP_PROJECTION_MERCATOR } from '@/tools/constants'
import UrlHash from '@/tools/url-hash'
import { useRadarSettingsStore } from '@/stores/settings/radar'
import { useSubscriptionStore } from '@/stores/subscription'
import modalHelper from '@/logic/Modals/Helper'
import MapKeeper from '@/logic/MapKeeper';
import { useMapStyle } from './MapStyle'

const TIERS = {
    FREE: 0,
    PLUS: 1,
}

const TIER_MAP = {
    [TIERS.PLUS]: [
        'plus'
    ]
}

export function useSubscription() {

    const subscriptionStore = useSubscriptionStore()
    const radarSettingsStore = useRadarSettingsStore()

    // console.log(111, subscriptionStore.revenueCatCustomerInfo)

    const hasActiveSubscription = () => {
        if (import.meta.env.DEV) {
            const params = new UrlHash()
            if(params.has('subscription_tier') && parseInt(params.get('subscription_tier')) > TIERS.FREE) return true;
        }
        return subscriptionStore.hasActiveSubscription
    }

    const activeTier = () => {
        if (import.meta.env.DEV) {
            const params = new UrlHash()
            if(params.has('subscription_tier')) {
                return parseInt(params.get('subscription_tier'))
            }
        }
    
        const activeSubscription = subscriptionStore.activeSubscription;
        if (!activeSubscription) return 0;
        
        // Check if the product identifier matches any in our tier map
        for (let tier in TIER_MAP) {
            if (TIER_MAP[tier].includes(activeSubscription.identifier)) {
                return Number.parseInt(tier)
            }
        }

        return 0;
    }

    const isAtleast = (tierNumber) => {
        if (!hasActiveSubscription()) return false;

        const activeTierNumber = activeTier();
    
        return activeTierNumber >= tierNumber
    }

    const manage = () => {
        console.log(subscriptionStore.revenueCatCustomerInfo)

        if (!hasActiveSubscription()) return;

        window.open(subscriptionStore.revenueCatCustomerInfo.managementURL, '_blank')
    }

    const enablePlusFeatures = () => {
        console.log(`enabling plus features`)

        MapKeeper.applyPlusFeatures()
    }

    const disablePlusFeatures = () => {
        // console.log(`disabling plus features`)

        MapKeeper.resetNorthPitch()
        MapKeeper.disableMapRotation()
        useMapStyle().setProjection(MAP_PROJECTION_MERCATOR)
        radarSettingsStore.resetMaxScans()
        radarSettingsStore.resetLightning()
        radarSettingsStore.reset3D()
    }

    return {
        activeTier,
        isAtleast,
        hasActiveSubscription,
        tiers: TIERS,
        manage,
        enablePlusFeatures,
        disablePlusFeatures
    }
}

export function useUpgradeNotifcation(featureKey) {
    modalHelper.upgradePrompt(featureKey)
}
