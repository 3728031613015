import { createHttpClient } from './http'

class AppServer {
  constructor() {
    this.baseUrl = import.meta.env.VITE_APP_SERVER_URL
    this.instance = createHttpClient({
      urls: [this.baseUrl],
      axiosConfig: {
        headers: {
          'Accept': 'application/json',
          'X-Requested-With': 'XMLHttpRequest'
        }
      }
    })
  }

  getInstance() {
    return this.instance
  }

  getBaseUrl() {
    return this.baseUrl
  }

  async register(data) {
    try {
      const response = await this.instance.post('/api/register', data)
      return { data: response, error: null }
    } catch (error) {
      return {
        data: null,
        error: error.response?.data || { message: 'An error occurred during registration' }
      }
    }
  }

  async login(data) {
    try {
      const response = await this.instance.post('/api/login', data)
      return { data: response, error: null }  
    } catch (error) {
      return {
        data: null,
        error: error.response?.data || { message: 'An error occurred during login' }
      }
    }
  }

  async logout(token) {
    try {
      const response = await this.instance.post('/api/logout', {}, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      return { data: response, error: null }
    } catch (error) {
      return {
        data: null,
        error: error.response?.data || { message: 'An error occurred during logout' }
      }
    }
  }

  async getUser(token) {
    try {
      const response = await this.instance.get('/api/user', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      return { data: response, error: null }
    } catch (error) {
      return {
        data: null,
        error: error.response?.data || { message: 'An error occurred during user retrieval' }
      }
    }
  }

  async forgotPassword(data) {
    try {
      const response = await this.instance.post('/api/forgot-password', data)
      return { data: response, error: null }
    } catch (error) {
      return {
        data: null,
        error: error.response?.data || { message: 'An error occurred while processing your request' }
      }
    }
  }

  async resetPassword(data) {
    try {
      const response = await this.instance.post('/api/reset-password', data)
      return { data: response, error: null }
    } catch (error) {
      // Properly extract error data from the response
      if (error.response?.status === 400) {
        return {
          data: null,
          error: {
            message: error.response.data.message || 'Invalid or expired password reset token. Please try resetting your password again.',
            errors: error.response.data.errors || { token: ['The password reset token is invalid or has expired. Please try resetting your password again.'] }
          }
        }
      }
      return {
        data: null,
        error: error.response?.data || { message: 'An error occurred while resetting your password' }
      }
    }
  }
}

export default new AppServer() 