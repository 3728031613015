<script setup>
import { Icon } from '@iconify/vue'
import { vOnClickOutside } from '@vueuse/components'
import { ref, onMounted } from 'vue'
import { useUserAccount } from '@/logic/Composables/UserAccount'
import { useRoute } from 'vue-router'
import modals from '@/logic/Modals/Helper'
import { useAppStore } from '@/stores/app'

const emit = defineEmits(['close'])
const { loading, errors, success, resetPassword } = useUserAccount()
const route = useRoute()

const showPassword = ref(false)
const resetSuccess = ref(false)
const tokenError = ref(false)
const errorMessage = ref('')

const formData = ref({
  email: '',
  password: '',
  password_confirmation: '',
  token: ''
})

const handleClose = () => {
  emit('close')
}

onMounted(() => {
  // Get token and email from URL parameters or app deep link
  // Check route query params first
  if (route.query.token && route.query.email) {
    formData.value.token = route.query.token
    formData.value.email = route.query.email
  }
  // Fallback to store deep link if available
  else {
    const appStore = useAppStore();
    if (appStore.deepLink) {
      try {
        const url = new URL(appStore.deepLink);
        const params = new URLSearchParams(url.search);
        const token = params.get('token');
        const email = params.get('email');

        if (token && email) {
          formData.value.token = token;
          formData.value.email = email;
        } else {
          tokenError.value = true;
          errorMessage.value = 'Missing required token or email parameters.';
        }
      } catch (error) {
        console.error('Error parsing deep link URL', error);
        tokenError.value = true;
        errorMessage.value = 'Invalid password reset link format.';
      } finally {
        // Clear the deep link from the app store after using it
        appStore.setDeepLink(null);
      }
    } else {
      // Missing required parameters
      tokenError.value = true;
      errorMessage.value = 'Missing password reset information.';
    }
  }
})

const handleSubmit = async (e) => {
  e.preventDefault()

  const success = await resetPassword(formData.value)
  if (success) {
    resetSuccess.value = true
    setTimeout(() => {
      handleClose()
    }, 3000)
  } else if (errors.value.token || errors.value.message?.toLowerCase().includes('token')) {
    // Handle specific token errors
    tokenError.value = true;
    errorMessage.value = errors.value.token?.[0] || errors.value.message || 'The password reset token is invalid or has expired.  Please try resetting your password again.';
  }
}
</script>

<template>
  <div class="relative" v-on-click-outside="handleClose">
    <div class="absolute top-0 right-0 z-50">
      <button @click.prevent="handleClose" class="p-[5px]">
        <Icon icon="clarity:window-close-line" class="size-8 sm:size-9 text-gray-900 dark:text-white" />
      </button>
    </div>

    <div class="mb-10 mx-auto hyphens-auto w-full md:max-w-[90%] lg:max-w-[800px]">
      <div class="bg-gradient-to-tr from-[#F6B91A] to-[#17274F] rounded-t-lg p-4 md:p-8 text-white relative overflow-hidden">
        <div class="absolute top-0 right-0 opacity-10">
          <Icon icon="mdi:lock-reset" class="w-48 h-48" />
        </div>
        <div class="flex items-center space-x-4">
          <div>
            <h2 class="text-3xl font-bold">Reset Password</h2>
            <p class="flex items-center mt-2">
              <Icon icon="mdi:lock" class="min-w-5 min-h-5 mr-2" />
              Create a new password for your account
            </p>
          </div>
        </div>
      </div>

      <!-- Main Content -->
      <div class="bg-white dark:bg-ww-blue shadow-xl rounded-b-lg">
        <div class="p-4 md:p-8">
          <div class="max-w-md mx-auto">
            <!-- Token Error Message -->
            <div v-if="tokenError" class="text-center py-6">
              <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100 dark:bg-red-900">
                <Icon icon="mdi:alert-circle" class="h-6 w-6 text-red-600 dark:text-red-300" />
              </div>
              <h3 class="mt-3 text-lg font-medium text-gray-900 dark:text-white">Password Reset Error</h3>
              <p class="mt-2 text-sm text-gray-600 dark:text-gray-300">
                {{ errorMessage }}
              </p>
              <div class="mt-6">
                <button
                  type="button"
                  @click="() => { handleClose(); modals.auth(); }"
                  class="flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-[#17274F] bg-[#F6B91A] hover:bg-[#F6B91A]/90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#F6B91A]"
                >
                  Back to Login
                </button>
              </div>
            </div>

            <!-- Reset Password Form -->
            <form v-else-if="!resetSuccess" class="space-y-6" @submit="handleSubmit">
              <div>
                <label for="reset-email" class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                  Email address
                </label>
                <div class="relative">
                  <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <Icon icon="mdi:email" class="h-5 w-5 text-gray-400" />
                  </div>
                  <input
                    id="reset-email"
                    v-model="formData.email"
                    type="email"
                    disabled
                    class="block w-full pl-10 pr-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-[#F6B91A] focus:border-[#F6B91A] dark:bg-ww-blue-200 dark:text-white sm:text-sm bg-gray-100 dark:bg-ww-blue-300"
                    :class="{ 'border-red-500': errors.email }"
                    placeholder="you@example.com"
                  />
                </div>
                <p v-if="errors.email" class="mt-1 text-sm text-red-600">{{ errors.email[0] }}</p>
              </div>

              <div>
                <label for="reset-password" class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                  New Password
                </label>
                <div class="relative">
                  <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <Icon icon="mdi:lock" class="h-5 w-5 text-gray-400" />
                  </div>
                  <input
                    id="reset-password"
                    v-model="formData.password"
                    :type="showPassword ? 'text' : 'password'"
                    required
                    class="block w-full pl-10 pr-10 py-2 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-[#F6B91A] focus:border-[#F6B91A] dark:bg-ww-blue-200 dark:text-white sm:text-sm"
                    :class="{ 'border-red-500': errors.password }"
                    placeholder="••••••••"
                  />
                  <div
                    class="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                    @click="showPassword = !showPassword"
                  >
                    <Icon
                      :icon="showPassword ? 'mdi:eye' : 'mdi:eye-off'"
                      class="h-5 w-5 text-gray-400 hover:text-gray-600 dark:hover:text-gray-300 transition-colors"
                      aria-label="Toggle password visibility"
                    />
                  </div>
                </div>
                <p v-if="errors.password" class="mt-1 text-sm text-red-600">{{ errors.password[0] }}</p>
              </div>

              <div>
                <label for="reset-password-confirm" class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                  Confirm New Password
                </label>
                <div class="relative">
                  <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <Icon icon="mdi:lock-check" class="h-5 w-5 text-gray-400" />
                  </div>
                  <input
                    id="reset-password-confirm"
                    v-model="formData.password_confirmation"
                    :type="showPassword ? 'text' : 'password'"
                    required
                    class="block w-full pl-10 pr-10 py-2 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-[#F6B91A] focus:border-[#F6B91A] dark:bg-ww-blue-200 dark:text-white sm:text-sm"
                    :class="{ 'border-red-500': errors.password_confirmation }"
                    placeholder="••••••••"
                  />
                  <div
                    class="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                    @click="showPassword = !showPassword"
                  >
                    <Icon
                      :icon="showPassword ? 'mdi:eye' : 'mdi:eye-off'"
                      class="h-5 w-5 text-gray-400 hover:text-gray-600 dark:hover:text-gray-300 transition-colors"
                      aria-label="Toggle password visibility"
                    />
                  </div>
                </div>
                <p v-if="errors.password_confirmation" class="mt-1 text-sm text-red-600">{{ errors.password_confirmation[0] }}</p>
              </div>

              <input type="hidden" v-model="formData.token" />

              <div v-if="errors.message && !tokenError" class="text-sm text-red-600">
                {{ errors.message }}
              </div>

              <button
                type="submit"
                :disabled="loading"
                class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-[#17274F] bg-[#F6B91A] hover:bg-[#F6B91A]/90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#F6B91A] disabled:opacity-50 disabled:cursor-not-allowed"
              >
                <span v-if="loading">Resetting password...</span>
                <span v-else>Reset Password</span>
              </button>
            </form>

            <!-- Success Message -->
            <div v-else class="text-center py-6">
              <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100 dark:bg-green-900">
                <Icon icon="mdi:check" class="h-6 w-6 text-green-600 dark:text-green-300" />
              </div>
              <h3 class="mt-3 text-lg font-medium text-gray-900 dark:text-white">Password Reset Successful</h3>
              <p class="mt-2 text-sm text-gray-600 dark:text-gray-300">
                Your password has been successfully reset. You can now log in with your new password.
              </p>
              <div class="mt-6">
                <button
                  type="button"
                  @click="() => { handleClose(); modals.auth(); }"
                  class="flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-[#17274F] bg-[#F6B91A] hover:bg-[#F6B91A]/90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#F6B91A]"
                >
                  Log In
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.bg-gradient-to-r {
  position: relative;
}

.bg-gradient-to-r::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
}
</style>