<script setup>
import { Icon } from '@iconify/vue'
import { vOnClickOutside } from '@vueuse/components'
import { ref, useTemplateRef, watchEffect } from 'vue'
import { useUserAccount } from '@/logic/Composables/UserAccount'

const emit = defineEmits(['close'])
const { loading, errors, success, register, login, forgotPassword } = useUserAccount()

const isLoginView = ref(true)
const isForgotPasswordView = ref(false)
const showPassword = ref(false)
const resetSuccess = ref(false)
const captchaEl = useTemplateRef('captcha-el')
const captchaToken = ref(null)

const formData = ref({
  name: '',
  email: '',
  password: '',
  password_confirmation: ''
})

const toggleView = () => {
  isLoginView.value = !isLoginView.value
  isForgotPasswordView.value = false
  resetSuccess.value = false
  formData.value = {
    name: '',
    email: '',
    password: '',
    password_confirmation: ''
  }
  errors.value = {}
  showPassword.value = false
}

const showForgotPassword = () => {
  isForgotPasswordView.value = true
  resetSuccess.value = false
  formData.value = {
    name: '',
    email: '',
    password: '',
    password_confirmation: ''
  }
  errors.value = {}
}

const returnToLogin = () => {
  isForgotPasswordView.value = false
  resetSuccess.value = false
  errors.value = {}
}

const handleClose = () => {
  emit('close')
}

const handleSubmit = async (e) => {
  e.preventDefault()
  let success = false

  if (isForgotPasswordView.value) {
    success = await forgotPassword(formData.value.email)
    if (success) {
      resetSuccess.value = true
    }
  } else if (isLoginView.value) {
    success = await login({
      email: formData.value.email,
      password: formData.value.password
    })
    if (success) {
      handleClose()
    }
  } else {
    success = await register({
      ...formData.value,
      captcha_token: captchaToken.value
    })
    if (success) {
      handleClose()
    }
  }
}

watchEffect(() => {
  if (captchaEl.value) {
      window.hcaptcha.render(captchaEl.value, {
        sitekey: import.meta.env.VITE_HCAPTCHA_KEY,
        callback(token) {
          captchaToken.value = token
        }
      })
  }
})
</script>

<template>
  <div class="relative" v-on-click-outside="handleClose">
    <div class="absolute top-0 right-0 z-50">
      <button @click.prevent="handleClose" class="p-[5px]">
        <Icon icon="clarity:window-close-line" class="size-8 sm:size-9 text-white" />
      </button>
    </div>

    <div class="mb-10 mx-auto hyphens-auto w-full md:max-w-[90%] lg:max-w-[800px]">
      <div class="bg-gradient-to-tr from-[#F6B91A] to-[#17274F] rounded-t-lg p-4 md:p-8 text-white relative overflow-hidden">
        <div class="absolute top-0 right-0 opacity-10">
          <Icon icon="mdi:account-circle" class="w-48 h-48" />
        </div>
        <div class="flex items-center space-x-4">
          <div>
            <h2 class="text-3xl font-bold">
              <template v-if="isForgotPasswordView">Reset Password</template>
              <template v-else>{{ isLoginView ? 'Welcome Back' : 'Create Account' }}</template>
            </h2>
            <p class="flex items-center mt-2">
              <Icon icon="mdi:account" class="min-w-5 min-h-5 mr-2" />
              <template v-if="isForgotPasswordView">Recover your account access</template>
              <template v-else>{{ isLoginView ? 'Log in to your account' : 'Join our community' }}</template>
            </p>
          </div>
        </div>
      </div>

      <!-- Main Content -->
      <div class="bg-white dark:bg-ww-blue shadow-xl rounded-b-lg">
        <div class="p-4 md:p-8">
          <!-- Split View Toggle (hide when in forgot password view) -->
          <div v-if="!isForgotPasswordView" class="flex justify-center mb-8">
            <div class="bg-gray-100 dark:bg-ww-blue-200 rounded-full p-1">
              <button
                @click="toggleView"
                class="px-6 py-2 rounded-full text-sm font-medium transition-all duration-200"
                :class="isLoginView ? 'bg-white dark:bg-ww-blue text-gray-900 dark:text-white shadow' : 'text-gray-600 dark:text-gray-400'"
              >
                Log in
              </button>
              <button
                @click="toggleView"
                class="px-6 py-2 rounded-full text-sm font-medium transition-all duration-200"
                :class="!isLoginView ? 'bg-white dark:bg-ww-blue text-gray-900 dark:text-white shadow' : 'text-gray-600 dark:text-gray-400'"
              >
                Register
              </button>
            </div>
          </div>

          <!-- Forms -->
          <div class="max-w-md mx-auto">
            <!-- Forgot Password Form -->
            <form v-if="isForgotPasswordView" class="space-y-6" @submit="handleSubmit">
              <div v-if="!resetSuccess">
                <p class="text-sm text-gray-600 dark:text-gray-300 mb-4">
                  Enter your email address below and we'll send you a link to reset your password.
                </p>
                <div>
                  <label for="reset-email" class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                    Email address
                  </label>
                  <div class="relative">
                    <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <Icon icon="mdi:email" class="h-5 w-5 text-gray-400" />
                    </div>
                    <input
                      id="reset-email"
                      v-model="formData.email"
                      type="email"
                      required
                      class="block w-full pl-10 pr-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-[#F6B91A] focus:border-[#F6B91A] dark:bg-ww-blue-200 dark:text-white sm:text-sm"
                      :class="{ 'border-red-500': errors.email }"
                      placeholder="you@example.com"
                    />
                  </div>
                  <p v-if="errors.email" class="mt-1 text-sm text-red-600">{{ errors.email[0] }}</p>
                </div>

                <div v-if="errors.message" class="text-sm text-red-600 mt-3">
                  {{ errors.message }}
                </div>

                <div class="flex justify-between items-center mt-6">
                  <button
                    type="button"
                    @click="returnToLogin"
                    class="text-sm font-medium text-[#F6B91A] hover:text-[#F6B91A]/80 dark:text-[#F6B91A] dark:hover:text-[#F6B91A]/80"
                  >
                    Back to login
                  </button>
                  <button
                    type="submit"
                    :disabled="loading"
                    class="flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-[#17274F] bg-[#F6B91A] hover:bg-[#F6B91A]/90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#F6B91A] disabled:opacity-50 disabled:cursor-not-allowed"
                  >
                    <span v-if="loading">Sending...</span>
                    <span v-else>Send Reset Link</span>
                  </button>
                </div>
              </div>

              <!-- Success Message -->
              <div v-else class="text-center py-6">
                <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100 dark:bg-green-900">
                  <Icon icon="mdi:check" class="h-6 w-6 text-green-600 dark:text-green-300" />
                </div>
                <h3 class="mt-3 text-lg font-medium text-gray-900 dark:text-white">Password Reset Email Sent</h3>
                <p class="mt-2 text-sm text-gray-600 dark:text-gray-300">
                  We've sent a link to your email address. Please check your inbox and follow the instructions to reset your password.
                </p>
                <div class="mt-6">
                  <button
                    type="button"
                    @click="returnToLogin"
                    class="flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-[#17274F] bg-[#F6B91A] hover:bg-[#F6B91A]/90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#F6B91A]"
                  >
                    Return to Login
                  </button>
                </div>
              </div>
            </form>

            <!-- Login Form -->
            <form v-else-if="isLoginView" class="space-y-6" @submit="handleSubmit">
              <div>
                <label for="login-email" class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                  Email address
                </label>
                <div class="relative">
                  <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <Icon icon="mdi:email" class="h-5 w-5 text-gray-400" />
                  </div>
                  <input
                    id="login-email"
                    v-model="formData.email"
                    type="email"
                    required
                    class="block w-full pl-10 pr-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-[#F6B91A] focus:border-[#F6B91A] dark:bg-ww-blue-200 dark:text-white sm:text-sm"
                    :class="{ 'border-red-500': errors.email }"
                    placeholder="you@example.com"
                  />
                </div>
                <p v-if="errors.email" class="mt-1 text-sm text-red-600">{{ errors.email[0] }}</p>
              </div>

              <div>
                <label for="login-password" class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                  Password
                </label>
                <div class="relative">
                  <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <Icon icon="mdi:lock" class="h-5 w-5 text-gray-400" />
                  </div>
                  <input
                    id="login-password"
                    v-model="formData.password"
                    :type="showPassword ? 'text' : 'password'"
                    required
                    class="block w-full pl-10 pr-10 py-2 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-[#F6B91A] focus:border-[#F6B91A] dark:bg-ww-blue-200 dark:text-white sm:text-sm"
                    :class="{ 'border-red-500': errors.password }"
                    placeholder="••••••••"
                  />
                  <div
                    class="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                    @click="showPassword = !showPassword"
                  >
                    <Icon
                      :icon="showPassword ? 'mdi:eye' : 'mdi:eye-off'"
                      class="h-5 w-5 text-gray-400 hover:text-gray-600 dark:hover:text-gray-300 transition-colors"
                      aria-label="Toggle password visibility"
                    />
                  </div>
                </div>
                <p v-if="errors.password" class="mt-1 text-sm text-red-600">{{ errors.password[0] }}</p>
              </div>

              <div class="flex items-center justify-between">
                <div class="text-sm">
                  <a @click.prevent="showForgotPassword" href="#" class="font-medium text-[#F6B91A] hover:text-[#F6B91A]/80 dark:text-[#F6B91A] dark:hover:text-[#F6B91A]/80 cursor-pointer">
                    Forgot your password?
                  </a>
                </div>
              </div>

              <div v-if="errors.message" class="text-sm text-red-600">
                {{ errors.message }}
              </div>

              <button
                type="submit"
                :disabled="loading"
                class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-[#17274F] bg-[#F6B91A] hover:bg-[#F6B91A]/90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#F6B91A] disabled:opacity-50 disabled:cursor-not-allowed"
              >
                <span v-if="loading">Logging in...</span>
                <span v-else>Log in</span>
              </button>
            </form>

            <!-- Register Form -->
            <form v-else class="space-y-6" @submit="handleSubmit">
              <div>
                <label for="register-name" class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                  Full Name
                </label>
                <div class="relative">
                  <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <Icon icon="mdi:account" class="h-5 w-5 text-gray-400" />
                  </div>
                  <input
                    id="register-name"
                    v-model="formData.name"
                    type="text"
                    required
                    class="block w-full pl-10 pr-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-[#F6B91A] focus:border-[#F6B91A] dark:bg-ww-blue-200 dark:text-white sm:text-sm"
                    :class="{ 'border-red-500': errors.name }"
                    placeholder="John Doe"
                  />
                </div>
                <p v-if="errors.name" class="mt-1 text-sm text-red-600">{{ errors.name[0] }}</p>
              </div>

              <div>
                <label for="register-email" class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                  Email address
                </label>
                <div class="relative">
                  <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <Icon icon="mdi:email" class="h-5 w-5 text-gray-400" />
                  </div>
                  <input
                    id="register-email"
                    v-model="formData.email"
                    type="email"
                    required
                    class="block w-full pl-10 pr-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-[#F6B91A] focus:border-[#F6B91A] dark:bg-ww-blue-200 dark:text-white sm:text-sm"
                    :class="{ 'border-red-500': errors.email }"
                    placeholder="you@example.com"
                  />
                </div>
                <p v-if="errors.email" class="mt-1 text-sm text-red-600">{{ errors.email[0] }}</p>
              </div>

              <div>
                <label for="register-password" class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                  Password
                </label>
                <div class="relative">
                  <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <Icon icon="mdi:lock" class="h-5 w-5 text-gray-400" />
                  </div>
                  <input
                    id="register-password"
                    v-model="formData.password"
                    :type="showPassword ? 'text' : 'password'"
                    required
                    class="block w-full pl-10 pr-10 py-2 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-[#F6B91A] focus:border-[#F6B91A] dark:bg-ww-blue-200 dark:text-white sm:text-sm"
                    :class="{ 'border-red-500': errors.password }"
                    placeholder="••••••••"
                  />
                  <div
                    class="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                    @click="showPassword = !showPassword"
                  >
                    <Icon
                      :icon="showPassword ? 'mdi:eye' : 'mdi:eye-off'"
                      class="h-5 w-5 text-gray-400 hover:text-gray-600 dark:hover:text-gray-300 transition-colors"
                      aria-label="Toggle password visibility"
                    />
                  </div>
                </div>
                <p v-if="errors.password" class="mt-1 text-sm text-red-600">{{ errors.password[0] }}</p>
              </div>

              <div>
                <label for="register-password-confirm" class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                  Confirm Password
                </label>
                <div class="relative">
                  <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <Icon icon="mdi:lock-check" class="h-5 w-5 text-gray-400" />
                  </div>
                  <input
                    id="register-password-confirm"
                    v-model="formData.password_confirmation"
                    :type="showPassword ? 'text' : 'password'"
                    required
                    class="block w-full pl-10 pr-10 py-2 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-[#F6B91A] focus:border-[#F6B91A] dark:bg-ww-blue-200 dark:text-white sm:text-sm"
                    :class="{ 'border-red-500': errors.password_confirmation }"
                    placeholder="••••••••"
                  />
                  <div
                    class="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                    @click="showPassword = !showPassword"
                  >
                    <Icon
                      :icon="showPassword ? 'mdi:eye' : 'mdi:eye-off'"
                      class="h-5 w-5 text-gray-400 hover:text-gray-600 dark:hover:text-gray-300 transition-colors"
                      aria-label="Toggle password visibility"
                    />
                  </div>
                </div>
                <p v-if="errors.password_confirmation" class="mt-1 text-sm text-red-600">{{ errors.password_confirmation[0] }}</p>
              </div>

              <div v-if="errors.message" class="text-sm text-red-600">
                {{ errors.message }}
              </div>

              <div class="flex items-center">
                <input
                  id="terms"
                  type="checkbox"
                  required
                  class="h-4 w-4 text-[#F6B91A] focus:ring-[#F6B91A] border-gray-300 rounded"
                />
                <label for="terms" class="ml-2 block text-sm text-gray-700 dark:text-gray-300">
                  I agree to the <a href="https://www.weatherwise.app/eula.html" target="_blank" class="font-medium text-[#F6B91A] hover:text-[#F6B91A]/80 dark:text-[#F6B91A] dark:hover:text-[#F6B91A]/80">Terms of Service</a> and <a href="https://www.weatherwise.app/privacy-policy.html" target="_blank" class="font-medium text-[#F6B91A] hover:text-[#F6B91A]/80 dark:text-[#F6B91A] dark:hover:text-[#F6B91A]/80">Privacy Policy</a>
                </label>
              </div>
              <div ref="captcha-el" class="mx-auto"></div>
              <p v-if="errors.captcha_token" class="mt-1 text-sm text-red-600">{{ errors.captcha_token[0] }}</p>
              <button
                type="submit"
                :disabled="loading"
                class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-[#17274F] bg-[#F6B91A] hover:bg-[#F6B91A]/90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#F6B91A] disabled:opacity-50 disabled:cursor-not-allowed"
              >
                <span v-if="loading">Creating Account...</span>
                <span v-else>Create Account</span>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.bg-gradient-to-r {
  position: relative;
}

.bg-gradient-to-r::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
}
</style>
