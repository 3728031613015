<template>
  <div v-on-click-outside="[() => App.isNativeApp() && closeDrawer(), {ignore: [controls]}]" v-if="radarTowersStore.anyActive" class="fixed bottom-1 left-0 px-1 z-[28] w-full sm:w-auto sm:ml-2 sm:bottom-2">
    <div class="drawer-container transition-all duration-300 ease-in-out bg-white  dark:bg-ww-blue rounded-2xl shadow-lg flex flex-col text-black dark:text-white w-full sm:w-[375px] md:w-[425px]"
       :class="{
        'drawer-open': isOpen,
        'drawer-closed': !isOpen,
        'drawer-fullscreen': isNavigatorStandalone()
       }">
      <div @click="toggleDrawer($event)" ref="drawerTab" class="flex relative items-center justify-center cursor-pointer rounded-t-2xl px-2" :class="{
        'py-2': isOpen,
        'h-full': ! isOpen,
        'pb-4': (! isOpen) && isNavigatorStandalone()
      }">
        <div class="w-7/12 sm:w-8/12 text-left">
          <div v-if="mapsStore.maps.length === 1">
            <div class="truncate">
              <span class="font-bold" v-text="radarTowersStore.activeTower.properties.code"></span>
              <span class="mx-1">&bull;</span>
              <span class="font-bold" v-text="productGroup.name"></span>
            </div>
            <div class="text-xs">
              <template v-if="mapRadar.scanDatetime === null">Loading...</template>
              <template v-else>
                <span class="">Scan At: </span>
                <span v-text="mapRadar.scanLocalTime" :title="`UTC: ${mapRadar.scanDatetime}`" class="font-bold" :class="{
                  'bg-red-100 text-red-800 px-2.5 py-0.5 rounded-full': !radarTowersStore.isPlaying && mapRadar.scanIsOld && !radarTowersStore.hasBufferedScans
                }"></span>
                <span v-if="!radarTowersStore.isPlaying && mapRadar.scanIsNew && !radarTowersStore.hasBufferedScans" class="font-black text-red-800 dark:text-red-500 live-text ml-2">Live</span>
              </template>
            </div>
          </div>
          <div v-else>
            <div class="truncate">
              <span class="font-bold" v-text="radarTowersStore.activeTower.properties.code"></span>
              <span class="mx-1">&bull;</span>
              <span v-for="(mr, index) in mapRadars">
                <span class="font-bold" v-text="mr.activeProductGroup.name"></span><span v-if="index+1 !== mapRadars.length">,&nbsp;</span>
              </span>
            </div>
            <div class="text-xs">
              <template v-for="(mr, index) in mapRadars">
                <template v-if="mr.scanDatetime === null">
                  <span>Loading...</span>
                  <span v-if="index+1 !== mapRadars.length">,&nbsp;</span>
                </template>
                <template v-else>
                  <span v-text="mr.scanLocalTime" :title="`UTC: ${mr.scanDatetime}`" class="font-bold" :class="{
                    'bg-red-100 text-red-800 px-2.5 py-0.5 rounded-full': !radarTowersStore.isPlaying && mr.scanIsOld && !radarTowersStore.hasBufferedScans
                  }"></span>
                  <span v-if="index+1 !== mapRadars.length">,&nbsp;</span>
                </template>
              </template>
              <span v-if="!radarTowersStore.isPlaying && !radarTowersStore.hasBufferedScans" class="font-black text-red-800 dark:text-red-500 live-text ml-2">Live</span>
            </div>
          </div>
        </div>
        <Icon :icon="isOpen ? 'gravity-ui:chevron-down-wide' : 'gravity-ui:chevron-up-wide'" class="absolute" :class="{
          'bottom-2': mapsStore.maps.length > 1
        }" />
        <div class="w-5/12 sm:w-4/12 text-right" ref="controls">
          <RadarPlaybackControls />
        </div>
      </div>
      <div ref="scrollable-content" v-if="isOpen" class="flex-grow flex flex-col border-t border-gray-200 overflow-y-auto">
        <div v-if="view === 'PRODUCTS'">
          <div class="px-2 mt-2" v-if="mapsStore.maps.length > 1">
            <ul class="text-sm font-medium text-center text-gray-500 rounded-lg shadow flex">
              <li class="w-full focus-within:z-10" v-for="(map, idx) in mapsStore.maps">
                <a href="#" class="inline-block w-full p-2 font-bold border-gray-200 hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-ww-blue-200" :class="{
                  'rounded-s-lg': idx === 0,
                  'border-s-0 rounded-e-lg': idx === mapsStore.maps.length - 1,
                  'border-r': idx !== mapsStore.maps.length - 1,
                  'text-white bg-blue-700 hover:!bg-blue-800 hover:text-white active': map.id === mapsStore.activeMapId,
                  'hover:text-gray-700 hover:bg-gray-50': idx !== 0,
                }" @click.prevent="onMapTabClick(map)"><span v-if="mapsStore.maps.length <= 4" class="font-bold">Map</span> {{ idx+1 }}</a>
              </li>
            </ul>
          </div>

          <div class="flex justify-start items-start m-3 select-none">
            <div class="w-7/12 text-sm">
              <template v-if="mapRadar.scanDatetime === null">Loading...</template>
              <template v-else>
                <div class="text-xs sm:text-sm">
                  <span>Scan At: </span>
                  <span v-text="mapRadar.scanLocalDatetime" :title="`UTC: ${mapRadar.scanDatetime}`" class="font-bold" :class="{
                    'bg-red-100 text-red-800 px-2.5 py-0.5 rounded-full': !radarTowersStore.isPlaying && mapRadar.scanIsOld && !radarTowersStore.hasBufferedScans
                  }"></span>
                  <a href="#" class="ml-2 inline" @click.prevent="showProductHelp($event)"><Icon icon="carbon:help" class="size-4 inline" /></a>
                </div>
                <div v-if="mapRadar.scanVcp !== null" class="text-xs sm:text-sm mt-1">
                  <span>VCP: </span>
                  <span class="font-bold">{{ mapRadar.scanVcp }} <span v-if="getVCPName(mapRadar.scanVcp)" class="font-bold">({{ getVCPName(mapRadar.scanVcp) }})</span></span>
                  <a href="#" class="ml-2 inline" @click.prevent="showExtraHelp('VCP')"><Icon icon="carbon:help" class="size-4 inline" /></a>
                </div>
              </template>
            </div>

            <div class="text-right relative w-5/12">
              <RadarTilts :product-group="productGroup" />
            </div>
          </div>
          <div>
            <div v-for="row in productsGrid" :key="row" class="flex flex-nowrap space-x-4">
              <template v-for="col in row" :key="col">
                <div class="flex-1 min-w-0 p-2 text-center select-none" @click="onProductClick($event, col)">
                  <div style="height: 60px; width: 60px;" class="ml-auto mr-auto rounded-lg overflow-hidden cursor-pointer" :class="{
                    'border-sky-400 border border-4 rounded-lg': col.tilts.map(t => t.product).includes(mapRadar.activeProductCode)
                  }">
                    <DynamicProductTile v-if="dynamicProductTiles.includes(col.id)" :groupId="col.id" :tableId="radarSettings.colortable[col.id]" />
                    <img v-else :src="getImageUrl(col.id)" :alt="col.name">
                  </div>
                  <div v-text="col.name" class="text-sm mt-1"></div>
                </div>
              </template>
            </div>
          </div>
          <div>
            <div class="text-xs mt-3 mb-1 text-center" v-if="this.radarTowersStore.activeTower.properties.id === 'GAWX'">
              <p><a href="#" @click.prevent="showExtraHelp('GAWX')" class="font-medium text-blue-600 dark:text-blue-500 hover:underline" target="_blank">Learn more about GAWX</a></p>
            </div>
            <div class="text-xs mt-3 mb-1 text-center" v-if="(['CYPFO', 'CYLCA']).includes(this.radarTowersStore.activeTower.properties.id)">
              <p><a href="https://www.dom.org.cy/" target="_blank" class="font-medium text-blue-600 dark:text-blue-500 hover:underline">Credits: Cyprus Department of Meteorology</a></p>
              <div class="mt-3">
                <a href="https://www.dom.org.cy/" target="_blank"><img class="rounded-xl" src="/credits/cyp-dom-logo.png"></a>
              </div>
            </div>
          </div>
          <div class="p-5 pt-1">
            <RadarSettings />
          </div>
        </div>
        <div v-if="view === 'COLORTABLES'">
          <div class="m-4">
            <div>
              <button @click="onBackToProducts" type="button" class="text-white bg-slate-400 dark:bg-ww-blue-200 dark:hover:bg-ww-blue-100 dark:text-white font-medium rounded-lg text-sm px-2 py-1 border border-gray-400 dark:border-gray-500"><Icon icon="octicon:chevron-left-12" class="inline" />Back</button>
              <a href="#" class="ml-2 inline float-right" @click.prevent="showExtraHelp('COLORTABLES')"><Icon icon="carbon:help" class="size-6 inline" /></a>
            </div>
            <div>
              <RadarColortables />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, getCurrentInstance, useTemplateRef } from 'vue'
import { Icon } from '@iconify/vue'
import { useModal, useModalSlot } from 'vue-final-modal'
import { useSwipe } from '@vueuse/core'
import { vOnClickOutside } from '@vueuse/components'

import { useMapsStore } from '@/stores/maps';
import { useRadarTowersStore } from '@/stores/radar_towers'
import { useRadarSettingsStore } from '@/stores/settings/radar'
import { useAppStore } from '@/stores/app'

import RadarTilts from './RadarDrawer/RadarTilts.vue'
import DynamicProductTile from './RadarDrawer/DynamicProductTile.vue'
import RadarSettings from './RadarDrawer/RadarSettings.vue'
import RadarColortables from './RadarDrawer/RadarColortables.vue'
import RadarPlaybackControls from './RadarDrawer/RadarPlaybackControls.vue'

import { isNavigatorStandalone } from '@/logic/Extra/helpers'

import SimpleModal from '@/logic/Modals/Templates/Simple.vue'
import RadarProductHelpModal from '@/logic//Radar/RadarProductHelp.vue'
import { useRadarPlayback } from '@/logic/Composables/RadarPlayback'

import modals from '@/logic/Modals/Helper'

import { getVCPName } from '@/tools/helpers'

import App from '@/logic/App'
import MapKeeper from '@/logic/MapKeeper'

export default {
  name: 'RadarDrawer',
  setup() {
    const drawerTab = ref(null)
    const controls = useTemplateRef(null)
    const instance = getCurrentInstance()

    onMounted(() => {
      const { direction } = useSwipe(drawerTab, {
        onSwipe(e) {
          if (direction.value === 'down' && instance.proxy.isOpen) {
            instance.proxy.toggleDrawer(e)
          }

          if (direction.value === 'up' && ! instance.proxy.isOpen) {
            instance.proxy.toggleDrawer(e)
          }

          e.stopPropagation()
        }
      })
    })

    return {
      drawerTab,
      isNavigatorStandalone,
      controls,
      App
    }
  },
  data() {
    return {
      isOpen: false,
      dynamicProductTiles: ['REF', 'VEL', 'CC', 'SW'],
      view: 'PRODUCTS'
    };
  },
  directives: {
    'on-click-outside': vOnClickOutside,
  },
  components: {
    Icon,
    RadarTilts,
    DynamicProductTile,
    RadarSettings,
    RadarColortables,
    DynamicProductTile,
    RadarPlaybackControls
  },
  computed: {
    radarTowersStore() {
      return useRadarTowersStore()
    },
    radarSettings() {
      return useRadarSettingsStore()
    },
    appStore() {
      return useAppStore()
    },
    mapsStore() {
      return useMapsStore()
    },
    mapRadar() {
      return MapKeeper.activeMap().stores['radar'];
    },
    mapRadars() {
      return MapKeeper.mapboxMaps().map(m => m.stores['radar']);
    },
    productsGrid() {
      return this.mapRadar.towerProducts.filter(item => item.tilts.length).reduce((result, item, index) => {
        const groupIndex = Math.floor(index / 3);
        (result[groupIndex] = result[groupIndex] || []).push(item);
        return result;
      }, [])
    },
    productGroup() {
      return this.mapRadar.activeProductGroup
    },
    playButtonIcon() {
      if(this.isPlaybackBuffering) return 'eos-icons:three-dots-loading';

      return !this.radarTowersStore.isPlaying ? 'ph:play-fill' : 'ph:pause-fill';
    },
    isPlaybackBuffering() {
      return this.radarTowersStore.isPlaybackBuffering;
    }
  },
  watch: {
    'radarTowersStore.anyActive': {
      handler(newValue) {
        if (! newValue) {
          this.isOpen = false
        }
      }
    },
    'isOpen': {
      handler(newValue) {
        this.appStore.radar_drawer_open = newValue;
      }
    }
  },
  unmounted() {
    this.radarTowersStore.inspectorActive = false;
  },
  methods: {
    getImageUrl(name) {
      return new URL(`../assets/product-tiles/${name}.png`, import.meta.url).href
    },
    openDrawer() {
      this.isOpen = true;
    },
    closeDrawer() {
      this.isOpen = false;
    },
    toggleDrawer(e) {
      if(!document.body.contains(e.target) || (this.$refs.controls.contains(e.target) && this.$refs.controls !== e.target)) return false;

      if (!this.isOpen) {
        this.openDrawer();
      } else {
        this.closeDrawer();
      }
    },
    async onProductClick(e, productGroup) {
      this.productGroup = productGroup;

      // TODO
      const productCode = productGroup.tilts[0].product;

      if (this.mapRadar.activeProductCode === productCode) {
        return;
      }

      await App.radar.changeRadarProduct(MapKeeper.activeMap(), this.mapRadar.activeTowerId, productCode)
    },
    onSliceClick(e) {
      // TODO
    },
    onMapTabClick(map) {
      this.mapsStore.activeMapId = map.id;
    },
    showProductHelp(e) {
      const productGroup = this.productGroup;

      if(typeof productGroup.help !== 'string') {
        return alert('Unable to locate help information');
      }

      App.radar.openRadarProductHelpModal(productGroup);
    },
    showExtraHelp(feature) {
      let title;
      let text;

      if(feature === 'VCP') {
        title = 'Volume Coverage Pattern (VCP)'
        text = `To capture as much atmospheric data as possible, the WSR-88D uses a volumetric scanning strategy. This involves the radar antenna scanning not just near the ground but also at various elevation angles, allowing it to gather vertical data. This approach helps forecasters better assess the depth and intensity of storms, especially when storms are close to the radar site.

To understand how Volume Coverage Patterns (VCPs) work, it's essential to know the two main operating modes: clear air mode and precipitation mode. Clear air mode, as the name implies, is used during calm weather. In this mode, the radar scans fewer elevation angles at a slower pace, reducing wear on its components. When the radar detects precipitation while in clear air mode, it automatically switches to precipitation mode. In precipitation mode, the radar scans 14 or 15 elevation angles, depending on the VCP being used, at a faster rate.`;

        // More detailed information can be found <a href='https://www.noaa.gov/jetstream/vcp_max' target='_blank' rel="noreferrer" class='font-medium text-blue-600 hover:underline'>here</a> and <a href='https://en.wikipedia.org/wiki/NEXRAD#Scan_strategies' target='_blank' rel="noreferrer" class='font-medium text-blue-600 hover:underline'>here</a>.
      }
      else if(feature === 'STORM_TRACKS') {
        title = 'Storm Tracks';
        text = `The Storm Tracks product is a powerful radar-based tool used for tracking the movement and intensity of storms in real-time. It utilizes data from a weather radar system to monitor severe weather events, including thunderstorms, hail, and tornadoes. By analyzing storm motion and characteristics, the Storm Tracks product provides detailed information on storm paths, estimated speed, direction, and projected areas affected. This product is essential for meteorologists, emergency responders, and weather enthusiasts, enabling more accurate predictions and timely warnings to help safeguard communities from severe weather impacts.`;
      }
      else if(feature === 'LIGHTNING') {
        title = 'Lightning';
        text = `This overlay displays precise locations of recent lightning strikes, with lightning strike markers that gradually fade out as time elapses, helping you stay aware of developing storms and potential lightning threats.`;
      }
      else if(feature === 'COLORTABLES') {
        title = 'Color Tables';
        text = `The colortable feature gives you powerful customization options to enhance radar imagery interpretation. By adjusting color scales, you can represent different intensities of reflectivity, wind speeds, and more, using color gradients that best fit their needs. In addition to selecting from predefined color schemes, you can upload their own .pal colortables, adding a further level of personalization and flexibility.`;
      }
      else if(feature === 'PLAYBACK_SPEED') {
        title = 'Playback Speed';
        text = `Customize how quickly the weather radar animations display. Choose from Slow, Normal, or Fast to view precipitation and storm movements at a pace that suits your preference.`;
      }
      else if (feature === 'SMOOTHING') {
        title = 'Smoothing';
        text = `Radar smoothing reduces visual noise in radar imagery by averaging adjacent pixels to produce a clearer display of weather patterns. This enhancement makes it easier to see the general trend/weather pattern.`
      }
      else if (feature === 'GATE_FILTERING') {
        title = 'Reflectivity Gate Filtering'
        text = `Reflectivity gate filtering removes spurious radar echoes by applying threshold criteria to individual radar gates. This reduces clutter and interference, resulting in cleaner radar imagery.`
      }
      else if (feature === 'MAX_SCANS') {
        title = 'Max Scans'
        text = `Max scans lets you set the maximum number of radar scans displayed during playback, providing a longer historical view of the storm. This feature enables you to see more of the storm’s evolution in one continuous overview.`
      }
      else if (feature === '3D_RADAR') {
        title = '3D Radar'
        text = `3D Radar displays radar scans in a lifelike, three-dimensional format, simulating the beam’s gradual rise from the tower into the sky. By pitching the map, you gain an immersive view that mirrors how radar scans extend upward in the real world.`
      }
      else if(feature === 'GAWX') {
        return modals.gawx();
      }
      else if(feature === 'UNIT_REFLECTIVITY') {
        title = 'Reflectivity Unit'
        text = `- <strong>dBZ</strong> (decibels relative to Z) is a logarithmic unit that quantifies radar reflectivity, indicating precipitation intensity—higher values mean heavier rainfall.<br>- <strong>dBZ with Rainfall Rate (RR)</strong>, is derived from dBZ using a Z-R relationship, directly estimating how much rain falls over a specific period.`
      }
      else if(feature === 'UNIT_VELOCITY') {
        title = 'Velocity Unit'
        text = `Velocity data, typically measured in meters per second (m/s), represents the speed at which precipitation or targets move along the radar beam. Derived from the Doppler effect, positive values indicate motion away from the radar while negative values indicate motion toward it, helping to identify wind patterns and storm rotation.`
      }
      else if(feature === 'TOWER_ICON_DESIGN') {
        title = 'Tower Icon'
        text = `Customize the radar tower icon on the map with either a standard dot or a pill that shows the tower code.`
      }
      else {
        return alert('Unable to locate help information');
      }

      const modal = useModal({
        defaultModelValue: true,
        component: SimpleModal,
        attrs: {
          title
        },
        slots: {
          default: useModalSlot({
            component: RadarProductHelpModal,
            attrs: {
              text,
              onClose() {
                modal.close()
              },
            }
          })
        },
      })
    },
    getVCPName,
    onBackToProducts() {
      this.view = 'PRODUCTS';

      this.scrollToTop();
    },
    onColortableChangeClick() {
      this.view = 'COLORTABLES';

      this.scrollToTop();
    },
    scrollToTop() {
      this.$refs['scrollable-content'].scrollTop = 0;
    }
  }
}
</script>

<style scoped>
.drawer-container {
  max-height: 400px;
}

.drawer-closed {
  height: 3.3rem;
  transform: translateY(calc(100% - 3.3rem));
}

.drawer-fullscreen {
  height: 5rem;
  transform: translateY(calc(100% - 5rem));
}

.drawer-open {
  height: 50vh;
  transform: translateY(0);
}

.live-text {
  animation: text-pulsate 3s ease-out infinite;
}

@keyframes text-pulsate {
  0% {
    opacity: 1.0;
  }
  20% {
    opacity: 0.3;
  }
  30%, 100% {
    opacity: 1.0;
  }
}
</style>
