import { useRecordingStore } from '../../stores/recording';
import { toCanvas } from 'html-to-image';
import { wait } from '@/tools/helpers'
import App from '@/logic/App'
import {APP_VERSION_1_2_0} from '@/tools/constants'
import {useSubscription} from '@/logic/Composables/Subscription'

import MapKeeper from '@/logic/MapKeeper'

const MAX_MP4_LENGTH = 10 * 1000;
const FPS = 25;
const OVERLAY_WAIT_TIME = 100; // Wait 100ms for overlays to appear

export default class Mp4Recorder {
  constructor() {
    this.maps = MapKeeper.mapboxMaps();
    this.canvasRects = this.maps.map((map) => {
      const canvas = map.getCanvas();
      const rect = canvas.getBoundingClientRect();
      return {
        canvas,
        left: rect.left,
        top: rect.top,
        width: rect.width,
        height: rect.height,
      };
    });

    this.calculateCompositeLayout();
    this.recording = false;
    this.stopRecordingTimeout = null;
    this.recordingStore = useRecordingStore();
    this.worker = null;
    this.lastFrameTime = 0;
    this.captureCanvas = null;
    this.captureContext = null;

    this.calculateOutputDimensions();
  }

  static isSupported() {
    return window.OffscreenCanvas !== undefined;
  }

  calculateCompositeLayout() {
    let minLeft = Infinity;
    let minTop = Infinity;
    let maxRight = -Infinity;
    let maxBottom = -Infinity;

    // First pass: find the bounding box
    this.canvasRects.forEach(rect => {
      const bounds = rect.canvas.getBoundingClientRect();
      minLeft = Math.min(minLeft, bounds.left);
      minTop = Math.min(minTop, bounds.top);
      maxRight = Math.max(maxRight, bounds.right);
      maxBottom = Math.max(maxBottom, bounds.bottom);
    });

    // Calculate total dimensions
    const totalWidth = maxRight - minLeft;
    const totalHeight = maxBottom - minTop;

    // Store layout info
    this.canvasLayout = {
      totalWidth,
      totalHeight,
      offsetLeft: minLeft,
      offsetTop: minTop
    };

    // Second pass: calculate relative positions and store canvas info
    this.canvasRects = this.canvasRects.map(rect => {
      const bounds = rect.canvas.getBoundingClientRect();
      return {
        canvas: rect.canvas,
        relativeLeft: bounds.left - minLeft,
        relativeTop: bounds.top - minTop,
        width: bounds.width,
        height: bounds.height,
        sourceWidth: rect.canvas.width,
        sourceHeight: rect.canvas.height
      };
    });
  }

  calculateOutputDimensions() {
    const MAX_CODED_AREA = 921600; // Maximum area for AVC Level 3.1
    const TARGET_AREA = MAX_CODED_AREA * 0.99; // Use 99% of max area for safety
    const maxWidth = 1280;
    const maxHeight = 720;
    const minWidth = 480;
    const minHeight = 360;

    let width = this.canvasLayout.totalWidth;
    let height = this.canvasLayout.totalHeight;
    const aspectRatio = width / height;

    // Try to get as close to the maximum allowed area while maintaining aspect ratio
    const currentArea = width * height;
    if (currentArea > TARGET_AREA) {
      const scale = Math.sqrt(TARGET_AREA / currentArea);
      width = Math.floor(width * scale);
      height = Math.floor(height * scale);
    }

    // Ensure we don't exceed max dimensions
    if (width > maxWidth || height > maxHeight) {
      if (width / maxWidth > height / maxHeight) {
        width = maxWidth;
        height = Math.round(width / aspectRatio);
      } else {
        height = maxHeight;
        width = Math.round(height * aspectRatio);
      }
    }

    // Ensure dimensions are even
    width = Math.floor(width / 2) * 2;
    height = Math.floor(height / 2) * 2;

    this.output = {
      width: width,
      height: height
    };
  }

  async startNativeRecording() {
    // Wait for the menu to disappear
    await wait(500);

    return new Promise((resolve, reject) => {
      const messageHandler = (event) => {
        try {
          const data = JSON.parse(event.data || event.nativeEvent?.data);
          // console.log('Parsed data:', data);
          if (data.type === 'ERROR') {
            reject(data.message)
          } else if (data.type === 'STARTED_MP4_RECORDING') {
            this.stopRecordingTimeout = setTimeout(() => {
              this.stop()
            }, this.getMaxRecordingLength());

            resolve()
          }
        } catch (error) {
          reject(error)
        } finally {
          window.removeEventListener('message', messageHandler)
        }
      }

      window.addEventListener('message', messageHandler, true);
      window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'START_MP4_RECORDING' }));
    })
  }

  getMaxRecordingLength() {
    const subscription = useSubscription();

    if (subscription.isAtleast(subscription.tiers.PLUS)) {
      return 20 * 1025
    }

    return 10 * 1050
  }

  async startBrowserRecording() {
    // Recalculate canvas layout since all maps might not have been available during construction
    this.maps = MapKeeper.mapboxMaps();
    this.canvasRects = this.maps.map((map) => {
      const canvas = map.getCanvas();
      const rect = canvas.getBoundingClientRect();
      return {
        canvas,
        left: rect.left,
        top: rect.top,
        width: rect.width,
        height: rect.height,
      };
    });

    this.calculateCompositeLayout();
    this.calculateOutputDimensions();

    await new Promise(resolve => setTimeout(resolve, OVERLAY_WAIT_TIME));

    // Get all map overlays
    const overlays = document.querySelectorAll('.map-overlay');
    this.overlayData = await Promise.all(
      Array.from(overlays).map(async overlay => {
        const canvas = await toCanvas(overlay);
        const rect = overlay.getBoundingClientRect();
        return {
          canvas,
          left: rect.left - this.canvasLayout.offsetLeft,
          top: rect.top - this.canvasLayout.offsetTop,
          width: rect.width,
          height: rect.height
        };
      })
    );

    this.stopRecordingTimeout = setTimeout(() => this.stop(), this.getMaxRecordingLength());

    this.worker = new Worker(new URL('./recordingWorker.js', import.meta.url), { type: 'module' });

    // Create capture canvas with the composite dimensions and optimize rendering
    this.captureCanvas = document.createElement('canvas');
    this.captureCanvas.width = this.canvasLayout.totalWidth;
    this.captureCanvas.height = this.canvasLayout.totalHeight;
    this.captureContext = this.captureCanvas.getContext('2d', { 
      willReadFrequently: true,
      alpha: true,
      imageSmoothingEnabled: true,
      imageSmoothingQuality: 'high'
    });

    // Load and prepare the watermark
    const watermark = await this.loadWatermark('ww-watermark.png');
    this.watermarkCanvas = new OffscreenCanvas(150, 75);
    const ctx = this.watermarkCanvas.getContext('2d');
    ctx.drawImage(watermark, 0, 0, 150, 75);

    this.worker.postMessage({
      type: 'start',
      canvasWidth: this.canvasLayout.totalWidth,
      canvasHeight: this.canvasLayout.totalHeight,
      outputWidth: this.output.width,
      outputHeight: this.output.height,
      fps: FPS
    });

    this.worker.onmessage = (event) => {
      if (event.data.type === 'done') {
        this.downloadBlob(event.data.blob, event.data.filename);
      }
    };

    this.lastFrameTime = performance.now();
    this.renderFrame();
  }

  async record() {
    this.recording = true;
    this.recordingStore.startScreenshot();
    this.recordingStore.start();

    if (App.isNativeApp() && App.isNativeVersionAtleast(APP_VERSION_1_2_0)) {
      try {
        await this.startNativeRecording();
      } catch (error) {
        this.markStopped()
        console.error(`Failed to start native screen recording`, error)
        window._alert(`Failed to start screen recording. Please enable screen recording/camera permission in your settings to use this feature.`, `Screen Recording Failed`)
      }

      return;
    }    
    
    try {
      console.log(`Recording using browser`)
      await this.startBrowserRecording()
    } catch (error) {
      this.markStopped()
      console.error(`Failed to start browser screen recording`, error)
    }
  }

  renderFrame() {
    if (!this.recording) return;

    const now = performance.now();
    const elapsed = now - this.lastFrameTime;

    if (elapsed >= (1000 / FPS)) {
      try {
        // Enable high-quality image smoothing
        this.captureContext.imageSmoothingEnabled = true;
        this.captureContext.imageSmoothingQuality = 'high';

        // Clear the previous frame with white background
        this.captureContext.fillStyle = '#ffffff';
        this.captureContext.fillRect(0, 0, this.captureCanvas.width, this.captureCanvas.height);

        // Draw each canvas in its correct position with high quality
        this.canvasRects.forEach(rect => {
          this.captureContext.drawImage(
            rect.canvas,
            0, 0,
            rect.sourceWidth,
            rect.sourceHeight,
            Math.round(rect.relativeLeft),
            Math.round(rect.relativeTop),
            Math.round(rect.width),
            Math.round(rect.height)
          );
        });

        // Draw overlays
        if (this.overlayData) {
          this.overlayData.forEach(overlay => {
            this.captureContext.drawImage(
              overlay.canvas,
              Math.round(overlay.left),
              Math.round(overlay.top),
              Math.round(overlay.width),
              Math.round(overlay.height)
            );
          });
        }

        // Draw watermark in top right corner with padding
        const rightPadding = 10;
        const topPadding = 25;
        const watermarkWidth = 150;
        const watermarkHeight = 75;
        const watermarkX = this.captureCanvas.width - watermarkWidth - rightPadding;
        const watermarkY = topPadding;

        this.captureContext.drawImage(
          this.watermarkCanvas,
          watermarkX,
          watermarkY,
          watermarkWidth,
          watermarkHeight
        );

        const imageData = this.captureContext.getImageData(
          0, 0,
          this.captureCanvas.width,
          this.captureCanvas.height
        );

        this.worker.postMessage({ type: 'frame', imageData }, [imageData.data.buffer]);
        this.lastFrameTime = now;
      } catch (error) {
        console.error('Error capturing frame:', error);
        this.stop();
        return;
      }
    }

    requestAnimationFrame(() => this.renderFrame());
  }

  stopNativeRecording() {
    this.markStopped()

    window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'STOP_MP4_RECORDING' }));
  }

  stopBrowserRecording() {
    this.markStopped()

    if (this.worker) {
      this.worker.postMessage({ type: 'stop' });
    }
    // Clean up capture canvas
    this.captureCanvas = null;
    this.captureContext = null;
  }

  markStopped() {
    this.recording = false;
    this.recordingStore.stopScreenshot();
    this.recordingStore.stop();

    if (this.stopRecordingTimeout != null) {
      clearTimeout(this.stopRecordingTimeout);
      this.stopRecordingTimeout = null;
    }
  }

  stop() {
    console.log('Stopping recording...')
    if (App.isNativeApp() && App.isNativeVersionAtleast(APP_VERSION_1_2_0)) {
      this.stopNativeRecording()

      return;
    }

    this.stopBrowserRecording()
  }

  async downloadBlob(blob, filename) {
    navigator._download.blob(blob, 'video/mp4');
  }

  async loadWatermark(src) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(img);
      img.onerror = reject;
      img.src = src;
    });
  }
}
