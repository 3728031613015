<script setup>
import Colortable from '@/components/Colortable.vue'
import RadarValueInspector from '@/components/RadarValueInspector.vue'
</script>

<template>
  <div class="map mapboxgl-map" :class="{
    'split-view': MapKeeper.mapsToLoad().length > 1,
    'is-top-right': isTopRightMap,
    'is-not-top-right': !isTopRightMap,
    'is-last-map': isLastMap,
    'is-not-last-map': !isLastMap
  }" ref="map"></div>
  <template v-if="mapIsReady">
    <RadarValueInspector :mapMeta="mapMeta" v-if="radarTowersStore.inspectorActive" />
    <Colortable :mapMeta="mapMeta" />
  </template>
  <div v-if="isCypTowerOpen" class="absolute bottom-16 left-2 md:left-auto md:right-[65px] md:bottom-7 z-[19] pointer-events-none">
    <img class="rounded-xl" style="max-width: 120px" src="/credits/cyp-dom-logo.png" >
  </div>
</template>

<script>
import { useAppStore } from '@/stores/app'
import { useRadarTowersStore } from '@/stores/radar_towers'
import MapKeeper from '@/logic/MapKeeper'

export default {
  name: 'MapboxMap',
  props: ['mapMeta', 'mapIndex'],
  data() {
    return {
      map: null,
      mapIsReady: false
    }
  },
  computed: {
    appStore: () => useAppStore(),
    radarTowersStore: () => useRadarTowersStore(),
    isTopRightMap() {
      const mapsLength = MapKeeper.mapsToLoad().length;
      if(mapsLength === 1) return true;

      return this.mapIndex === 1;
    },
    isLastMap() {
      const mapsLength = MapKeeper.mapsToLoad().length;
      if(mapsLength === 1) return true;
      return this.mapIndex + 1 == MapKeeper.mapsToLoad().length;
    },
    isCypTowerOpen() {
      if(this.map === null) return false;

      const lastMapId = MapKeeper.maps().at(-1).id;

      return this.appStore.isRadarMode && this.map.id === lastMapId && ['CYPFO', 'CYLCA'].includes(this.map.stores['radar'].activeTowerId)
    }
  },
  async mounted() {
    // create the map after the component is mounted
    await this.createMap()
  },
  unmounted() {
    MapKeeper.resize()

    this.map = null;
  },
  methods: {
    async createMap() {
      MapKeeper.resize()
      
      this.map = await MapKeeper.create(this.mapMeta.id, this.$refs["map"]);
      this.mapIsReady = true;

      MapKeeper.resize()
    }
  }
}
</script>

<style scoped>
.map {
  height: 100%;
  width: 100%;
  margin: 0 auto;
}
</style>

<style>
.map .mapboxgl-ctrl-top-right .mapboxgl-ctrl-group {
  margin-right: 13px;
}

@media (max-width: 640px) {
  .map.is-not-last-map .mapboxgl-ctrl-bottom-left,
  .map.is-not-last-map .mapboxgl-ctrl-bottom-right {
    display: none !important;
  }
  .map.split-view.is-last-map .mapboxgl-ctrl-top-right .mapboxgl-ctrl-group {
    display: none !important;
  }
}
@media (min-width: 640px) {
  .map.split-view.is-top-right .mapboxgl-ctrl-bottom-left {
    display: none !important;
  }
  .map.split-view.is-not-top-right .mapboxgl-ctrl-bottom-right {
    display: none !important;
  }
  .map.is-not-top-right .mapboxgl-ctrl-top-right .mapboxgl-ctrl-group {
    display: none !important;
  }
}
</style>