import { useModal, useModalSlot } from 'vue-final-modal'

import SimpleModal from './Templates/Simple.vue'
import SimpleBottom50Modal from './Templates/SimpleBottom50.vue'
import CenteredModal from './Templates/Centered.vue'
import WrapperModal from './Templates/Wrapper.vue'

import DisclaimerModal from './Disclaimer.vue'
import CreditsModal from './Credits.vue'
import SupportModal from './Support.vue'
import ShareModal from './Share.vue'
import AppDownload from './AppDownload.vue'
import KeyboardShortcuts from './KeyboardShortcuts.vue'
import GAWX from './GAWX.vue'
import ShareLocationWith from './ShareLocationWith.vue'
import WestMode from './WestMode.vue'
import UpgradePrompt from './UpgradePrompt.vue'
import AuthModal from '@/logic/Modals/Account/AuthModal.vue'
import UserAccountModal from '@/logic/Modals/Account/UserAccountModal.vue'
import ResetPasswordModal from '@/logic/Modals/Account/ResetPasswordModal.vue'
import UpgradeThroughMobile from './UpgradeThroughMobile.vue'
import AppUpdates from './AppUpdates.vue'

import MapSettings from '@/components/MapSettings.vue'
import UserPreferences from '@/components/UserPreferences.vue'


import { useAppStore } from '@/stores/app'

import App from '@/logic/App'
import { APP_VERSION_1_2_0 } from '@/tools/constants'

import { usePaywall } from '@/logic/Composables/Paywall'
import { useAppUpdates } from '@/logic/Composables/AppUpdates'

class Helper {
  disclaimer() {
    return useModal({
      defaultModelValue: true,
      component: SimpleModal,
      attrs: {
        title: 'Disclaimer',
      },
      slots: {
        default: useModalSlot({
          component: DisclaimerModal
        })
      },
    })
  }

  credits() {
    return useModal({
      defaultModelValue: true,
      component: SimpleModal,
      attrs: {
        title: 'Credits',
      },
      slots: {
        default: useModalSlot({
          component: CreditsModal
        })
      },
    })
  }

  support() {
    return useModal({
      defaultModelValue: true,
      component: SimpleModal,
      attrs: {
        title: 'Support',
      },
      slots: {
        default: useModalSlot({
          component: SupportModal
        })
      },
    })
  }

  share() {
    return useModal({
      defaultModelValue: true,
      component: CenteredModal,
      attrs: {
        title: 'Share',
      },
      slots: {
        default: useModalSlot({
          component: ShareModal
        })
      },
    })
  }

  apps() {
    return useModal({
      defaultModelValue: true,
      component: CenteredModal,
      attrs: {
        title: 'Download our Apps',
      },
      slots: {
        default: useModalSlot({
          component: AppDownload
        })
      },
    })
  }

  mapSettings() {
    return useModal({
      defaultModelValue: true,
      component: SimpleBottom50Modal,
      attrs: {
        title: 'Map Settings',
      },
      slots: {
        default: useModalSlot({
          component: MapSettings
        })
      },
    })
  }

  userPreferences() {
    return useModal({
      defaultModelValue: true,
      component: CenteredModal,
      attrs: {
        title: 'My Preferences',
      },
      slots: {
        default: useModalSlot({
          component: UserPreferences
        })
      },
    })
  }

  keyboardShortcuts() {
    return useModal({
      defaultModelValue: true,
      component: SimpleModal,
      attrs: {
        title: 'Keyboard Shortcuts',
      },
      slots: {
        default: useModalSlot({
          component: KeyboardShortcuts
        })
      },
    })
  }

  gawx() {
    return useModal({
      defaultModelValue: true,
      component: SimpleModal,
      attrs: {
        title: 'GAWX',
      },
      slots: {
        default: useModalSlot({
          component: GAWX
        })
      },
    })
  }

  shareLocationWith() {
    const modal = useModal({
      defaultModelValue: true,
      component: CenteredModal,
      attrs: {
        title: 'Share Your Location',
        onClosed() {
          useAppStore().setDeepLink(null)
        }
      },
      slots: {
        default: useModalSlot({
          component: ShareLocationWith,
          attrs: {
            recipient: {
              name: 'Rob West',
              shortName: 'Rob',
              avatar: '/test/robwest_avatar.webp'
            },
            onClose() {
              modal.close()
            },
          }
        })
      },
    });

    return modal;
  }

  westMode() {
    const modal = useModal({
      defaultModelValue: true,
      component: WrapperModal,
      attrs: {
        title: ''
      },
      slots: {
        default: useModalSlot({
          component: WestMode,
          attrs: {
            onClose() {
              modal.close()
            },
          }
        })
      },
    });

    return modal;
  }

  upgradePrompt(featureKey) {
    const me = this;

    const modal = useModal({
      defaultModelValue: true,
      component: WrapperModal,
      attrs: {
        title: ''
      },
      slots: {
        default: useModalSlot({
          component: UpgradePrompt,
          attrs: {
            featureKey: featureKey,
            onClose() {
              modal.close()
            },
            onUpgrade() {
              if(App.isNativeApp()) {
                if (!App.isNativeVersionAtleast(APP_VERSION_1_2_0)) {
                  console.warn(`Feature unavailable in this version`);
                  return;
                };

                usePaywall().open()
              }
              else {
                me.upgradeThroughMobile()
              }
              
              modal.close()
            },
          }
        })
      },
    });

    return modal;
  }

  auth() {
    const modal = useModal({
      defaultModelValue: true,
      component: WrapperModal,
      attrs: {
        title: '',
      },
      slots: {
        default: useModalSlot({
          component: AuthModal,
          attrs: {
            onClose() {
              modal.close()
            },
          }
        })
      },
    })

    return modal;
  }

  userAccount() {
    const modal = useModal({
      defaultModelValue: true,
      component: WrapperModal,
      attrs: {
        title: '',
      },
      slots: {
        default: useModalSlot({
          component: UserAccountModal,
          attrs: {
            onClose() {
              modal.close()
            },
          }
        })
      },
    })

    return modal;
  }

  resetPassword() {
    const modal = useModal({
      defaultModelValue: true,
      component: WrapperModal,
      attrs: {
        title: '',
      },
      slots: {
        default: useModalSlot({
          component: ResetPasswordModal,
          attrs: {
            onClose() {
              modal.close()
            },
          }
        })
      },
    })

    return modal;
  }

  upgradeThroughMobile() {
    return useModal({
      defaultModelValue: true,
      component: SimpleModal,
      attrs: {
        title: 'Upgrade through our App',
      },
      slots: {
        default: useModalSlot({
          component: UpgradeThroughMobile
        })
      },
    })
  }

  appUpdates(updates) {
    const modal = useModal({
      defaultModelValue: true,
      component: WrapperModal,
      slots: {
        default: useModalSlot({
          component: AppUpdates,
          attrs: {
            updates: updates,
            onClose() {
              modal.close()

              useAppUpdates().seenAll();
            },
          }
        })
      },
    });

    return modal;
  }
}

export default new Helper()
