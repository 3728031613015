import { defineStore } from 'pinia'

export const useRadarTowersStore = defineStore('radar_towers', {
  state: () => ({
    towers: [],
    isPlaying: false,
    isPlaybackBuffering: false,
    hasBufferedScans: false,
    inspectorActive: false
  }),

  getters: {
    anyActive: (state) => state.towers.length > 0,
    activeTower: (state) => state.towers[0],
  },

  actions: {
    clear() {
      this.towers = [];
    }
  }
})
