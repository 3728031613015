export default [
  {
    "type": "Feature",
    "geometry": {
      "type": "Point",
      "coordinates": [
        10.2192,
        48.0421,
        667
      ]
    },
    "properties": {
      "id": "DEMEM",
      "code": "DEMEM",
      "secondary": false,
      "elevations": [
        0.6,
        1.6,
        2.6,
        3.6,
        4.6,
        5.6,
        8,
        12,
        17,
        25
      ],
      "products": [
        "REF",
        "VEL"
      ]
    }
  },
  {
    "type": "Feature",
    "geometry": {
      "type": "Point",
      "coordinates": [
        8.7129,
        49.9847,
        198
      ]
    },
    "properties": {
      "id": "DEOFT",
      "code": "DEOFT",
      "secondary": false,
      "elevations": [
        0.5,
        1.5,
        2.5,
        3.5,
        4.5,
        5.5,
        8,
        12,
        17.1,
        25.1
      ],
      "products": [
        "REF",
        "VEL"
      ]
    }
  },
  {
    "type": "Feature",
    "geometry": {
      "type": "Point",
      "coordinates": [
        6.7482,
        53.564,
        4
      ]
    },
    "properties": {
      "id": "DEASB",
      "code": "DEASB",
      "secondary": false,
      "elevations": [
        0.5,
        1.5,
        2.5,
        3.5,
        4.5,
        5.5,
        8,
        12,
        17,
        25
      ],
      "products": [
        "REF",
        "VEL"
      ]
    }
  },
  {
    "type": "Feature",
    "geometry": {
      "type": "Point",
      "coordinates": [
        10.0468,
        54.0043,
        92
      ]
    },
    "properties": {
      "id": "DEBOO",
      "code": "DEBOO",
      "secondary": false,
      "elevations": [
        0.5,
        1.5,
        2.5,
        3.5,
        4.5,
        5.5,
        8,
        12,
        17,
        24.9
      ],
      "products": [
        "REF",
        "VEL"
      ]
    }
  },
  {
    "type": "Feature",
    "geometry": {
      "type": "Point",
      "coordinates": [
        12.058,
        54.1757,
        1
      ]
    },
    "properties": {
      "id": "DEROS",
      "code": "DEROS",
      "secondary": false,
      "elevations": [
        0.5,
        1.5,
        2.5,
        3.5,
        4.5,
        5.5,
        7.9,
        12,
        17,
        25
      ],
      "products": [
        "REF",
        "VEL"
      ]
    }
  },
  {
    "type": "Feature",
    "geometry": {
      "type": "Point",
      "coordinates": [
        9.6945,
        52.46,
        51
      ]
    },
    "properties": {
      "id": "DEHNR",
      "code": "DEHNR",
      "secondary": false,
      "elevations": [
        0.5,
        1.4,
        2.5,
        3.5,
        4.5,
        5.5,
        8,
        12,
        17,
        25
      ],
      "products": [
        "REF",
        "VEL"
      ]
    }
  },
  {
    "type": "Feature",
    "geometry": {
      "type": "Point",
      "coordinates": [
        13.858,
        52.6486,
        136
      ]
    },
    "properties": {
      "id": "DEPRO",
      "code": "DEPRO",
      "secondary": false,
      "elevations": [
        0.5,
        1.5,
        2.5,
        3.5,
        4.5,
        5.5,
        8,
        12,
        17,
        25
      ],
      "products": [
        "REF",
        "VEL"
      ]
    }
  },
  {
    "type": "Feature",
    "geometry": {
      "type": "Point",
      "coordinates": [
        6.9669,
        51.4055,
        152
      ]
    },
    "properties": {
      "id": "DEESS",
      "code": "DEESS",
      "secondary": false,
      "elevations": [
        0.5,
        1.5,
        2.5,
        3.5,
        4.5,
        5.5,
        8,
        12,
        17,
        25
      ],
      "products": [
        "REF",
        "VEL"
      ]
    }
  },
  {
    "type": "Feature",
    "geometry": {
      "type": "Point",
      "coordinates": [
        8.802,
        51.3112,
        530
      ]
    },
    "properties": {
      "id": "DEFLD",
      "code": "DEFLD",
      "secondary": false,
      "elevations": [
        0.5,
        1.5,
        2.5,
        3.5,
        4.5,
        5.5,
        8,
        12,
        17,
        25
      ],
      "products": [
        "REF",
        "VEL"
      ]
    }
  },
  {
    "type": "Feature",
    "geometry": {
      "type": "Point",
      "coordinates": [
        11.1761,
        52.1601,
        159
      ]
    },
    "properties": {
      "id": "DEUMD",
      "code": "DEUMD",
      "secondary": false,
      "elevations": [
        0.5,
        1.5,
        2.5,
        3.5,
        4.5,
        5.5,
        8,
        12,
        17,
        25
      ],
      "products": [
        "REF",
        "VEL"
      ]
    }
  },
  {
    "type": "Feature",
    "geometry": {
      "type": "Point",
      "coordinates": [
        11.1351,
        50.5001,
        846
      ]
    },
    "properties": {
      "id": "DENEU",
      "code": "DENEU",
      "secondary": false,
      "elevations": [
        0.5,
        1.5,
        2.5,
        3.5,
        4.5,
        5.5,
        8,
        12,
        17,
        25
      ],
      "products": [
        "REF",
        "VEL"
      ]
    }
  },
  {
    "type": "Feature",
    "geometry": {
      "type": "Point",
      "coordinates": [
        13.7686,
        51.1246,
        225
      ]
    },
    "properties": {
      "id": "DEDRS",
      "code": "DEDRS",
      "secondary": false,
      "elevations": [
        0.4,
        1.4,
        2.5,
        3.5,
        4.5,
        5.5,
        7.9,
        11.9,
        16.9,
        24.9
      ],
      "products": [
        "REF",
        "VEL"
      ]
    }
  },
  {
    "type": "Feature",
    "geometry": {
      "type": "Point",
      "coordinates": [
        6.5483,
        50.1097,
        552
      ]
    },
    "properties": {
      "id": "DENHB",
      "code": "DENHB",
      "secondary": false,
      "elevations": [
        0.5,
        1.5,
        2.5,
        3.5,
        4.5,
        5.5,
        8,
        12,
        17,
        25
      ],
      "products": [
        "REF",
        "VEL"
      ]
    }
  },
  {
    "type": "Feature",
    "geometry": {
      "type": "Point",
      "coordinates": [
        9.7828,
        48.5853,
        733
      ]
    },
    "properties": {
      "id": "DETUR",
      "code": "DETUR",
      "secondary": false,
      "elevations": [
        0.5,
        1.5,
        2.5,
        3.5,
        4.5,
        5.5,
        8,
        12,
        17,
        25
      ],
      "products": [
        "REF",
        "VEL"
      ]
    }
  },
  {
    "type": "Feature",
    "geometry": {
      "type": "Point",
      "coordinates": [
        12.4028,
        49.5407,
        771
      ]
    },
    "properties": {
      "id": "DEEIS",
      "code": "DEEIS",
      "secondary": false,
      "elevations": [
        0.5,
        1.5,
        2.5,
        3.5,
        4.5,
        5.5,
        8,
        12,
        17,
        25
      ],
      "products": [
        "REF",
        "VEL"
      ]
    }
  },
  {
    "type": "Feature",
    "geometry": {
      "type": "Point",
      "coordinates": [
        8.0039,
        47.8736,
        1495
      ]
    },
    "properties": {
      "id": "DEFBG",
      "code": "DEFBG",
      "secondary": false,
      "elevations": [
        0.4,
        1.4,
        2.4,
        3.4,
        4.4,
        5.4,
        7.9,
        11.9,
        16.9,
        24.9
      ],
      "products": [
        "REF",
        "VEL"
      ]
    }
  },
  {
    "type": "Feature",
    "geometry": {
      "type": "Point",
      "coordinates": [
        12.1017,
        48.1747,
        630
      ]
    },
    "properties": {
      "id": "DEISN",
      "code": "DEISN",
      "secondary": false,
      "elevations": [
        0.5,
        1.5,
        2.5,
        3.5,
        4.5,
        5.5,
        8,
        12,
        17,
        25
      ],
      "products": [
        "REF",
        "VEL"
      ]
    }
  }
]