<script setup lang="ts">
import { onUnmounted, ref } from 'vue';
import { Icon } from '@iconify/vue';
import { useSubscription } from '@/logic/Composables/Subscription';
import { vOnClickOutside } from '@vueuse/components';

import App from '@/logic/App'
import Marketing from '@/logic/Marketing'

const props = defineProps<{
  featureKey?: string;
}>();

const subscription = useSubscription();
const emit = defineEmits(['close', 'upgrade']);

const featureName = ref(null)
const videoSrcRef = ref(null)
const videoHasLoaded = ref(false)

const handleUpgrade = () => {
  emit('upgrade');
};

const handleVideoHasLoaded = () => {
  videoHasLoaded.value = true;
}

const marketingAssetsBaseUrl = Marketing.assetsBaseUrl();
const device = App.isNativeApp() ? 'mobile' : 'desktop';

switch(props.featureKey) {
case "RADAR_3D":
  featureName.value = '3D Radar'
  videoSrcRef.value = `${marketingAssetsBaseUrl}/plus/${device}/3d-radar.mp4`;
  break;
case "RADAR_SMOOTHING":
  featureName.value = 'Radar Smoothing'
  videoSrcRef.value = `${marketingAssetsBaseUrl}/plus/${device}/radar-smoothing.mp4`;
  break;
case "RADAR_MAX_SCANS":
  featureName.value = 'up to 100 Radar Scans'
  videoSrcRef.value = `${marketingAssetsBaseUrl}/plus/${device}/radar-100-frames.mp4`;
  break;
case "RADAR_ENHANCED_LIGHTNING":
  featureName.value = 'the Plus level Ligtning'
  videoSrcRef.value = `${marketingAssetsBaseUrl}/plus/${device}/radar-lightning-plus.mp4`;
  break;
case "GLOBE_PROJECTION":
  featureName.value = 'Globe Projection'
  videoSrcRef.value = `${marketingAssetsBaseUrl}/plus/${device}/globe-projection.mp4`;
  break;
case "SPLIT_VIEW":
  featureName.value = 'Split View'
  videoSrcRef.value = `${marketingAssetsBaseUrl}/plus/${device}/split-view.mp4`;
  break;
}

const videoRef = ref(null);

onUnmounted(() => {
  if (videoRef.value) {
    videoRef.value.pause();
    videoRef.value.src = '';
  }
});
</script>

<template>
  <div class="relative md:max-w-[60%] md:mx-auto md:pb-24" v-on-click-outside="[() => emit('close')]">
    <div class="absolute top-0 right-0 z-50">
      <button @click="emit('close')" class="p-[5px]">
        <Icon icon="clarity:window-close-line" class="size-8 sm:size-9 text-gray-900 dark:text-white" />
      </button>
    </div>
    <!-- Main Content -->
    <main>
      <!-- Upgrade Card -->
      <div class="rounded-lg p-8 mb-8 border shadow-xl transition-colors duration-300 bg-gradient-to-b from-white to-gray-50 dark:from-[#17274F] dark:via-[#1a2f5e] dark:to-[#17274F] text-gray-900 dark:text-white border-gray-200 dark:border-white/10">
        <div class="flex flex-col items-center gap-8">
          <div class="flex-1 text-center">
            <div class="flex items-center gap-2 justify-center">
              <h2 class="text-2xl font-bold mb-2 bg-gradient-to-r from-[#F6B91A] to-[#FF5722] text-transparent bg-clip-text">
                Upgrade Your Experience
              </h2>
            </div>
            <p class="text-xl mt-2 mb-6 text-gray-700 font-medium dark:text-white/90">
              {{ featureName ? `Unlock ${featureName} and more with ` : 'Unlock premium features with ' }}<strong>WeatherWise</strong><span class="ml-1 bg-gradient-to-r from-[#F6B91A] to-[#FF5722] rounded px-1 font-bold text-white border border-[#FF5722]">Plus</span>
            </p>

            <!-- Upgrade Button -->
            <button
              @click="handleUpgrade"
              class="px-8 py-3 rounded-full bg-gradient-to-r from-[#F6B91A] to-[#FF5722] text-white font-bold text-lg hover:shadow-lg transition-all duration-300 hover:scale-105"
            >
              Upgrade Now
            </button>

            <div v-if="videoSrcRef" class="relative">
              <video
                ref="videoRef"
                :src="videoSrcRef"
                @loadeddata="handleVideoHasLoaded"
                class="video mt-4"
                :class="{
                  'invisible': !videoHasLoaded
                }"
                autoplay
                muted
                loop
                playsinline
                preload="metadata"
              ></video>

              <div v-if="!videoHasLoaded" class="absolute top-[50%] ml-[-26px] left-[50%] mt-[-26px]">
                <Icon icon="line-md:loading-twotone-loop" class="size-14 text-gray-900 dark:text-white" />
              </div>
            </div>

            <!-- Features List -->
            <div class="mt-4">
              <h3 class="text-xl font-bold mb-4 text-[#FF5722] dark:text-[#F6B91A]">All Premium Benefits:</h3>
              <ul class="space-y-3">
                <li class="flex items-center gap-3">
                  <div class="w-6 h-6 rounded-full bg-gradient-to-r from-[#F6B91A] to-[#FF5722] flex items-center justify-center">
                    <svg class="w-4 h-4 text-white" fill="currentColor" viewBox="0 0 20 20">
                      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                    </svg>
                  </div>
                  <span class="text-gray-700 font-semibold dark:text-white/90">Globe Projection: 3D Map</span>
                </li>
                <li class="flex items-center gap-3">
                  <div class="w-6 h-6 rounded-full bg-gradient-to-r from-[#F6B91A] to-[#FF5722] flex items-center justify-center">
                    <svg class="w-4 h-4 text-white" fill="currentColor" viewBox="0 0 20 20">
                      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                    </svg>
                  </div>
                  <span class="text-gray-700 font-semibold dark:text-white/90">Split View Radar Visuals</span>
                </li>
                <li class="flex items-center gap-3">
                  <div class="w-6 h-6 rounded-full bg-gradient-to-r from-[#F6B91A] to-[#FF5722] flex items-center justify-center">
                    <svg class="w-4 h-4 text-white" fill="currentColor" viewBox="0 0 20 20">
                      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                    </svg>
                  </div>
                  <span class="text-gray-700 font-semibold dark:text-white/90">Radar Smoothing</span>
                </li>
                <li class="flex items-center gap-3">
                  <div class="w-6 h-6 rounded-full bg-gradient-to-r from-[#F6B91A] to-[#FF5722] flex items-center justify-center">
                    <svg class="w-4 h-4 text-white" fill="currentColor" viewBox="0 0 20 20">
                      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                    </svg>
                  </div>
                  <span class="text-gray-700 font-semibold dark:text-white/90">Playback with up to 100 Radar Scans</span>
                </li>
                <li class="flex items-center gap-3">
                  <div class="w-6 h-6 rounded-full bg-gradient-to-r from-[#F6B91A] to-[#FF5722] flex items-center justify-center">
                    <svg class="w-4 h-4 text-white" fill="currentColor" viewBox="0 0 20 20">
                      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                    </svg>
                  </div>
                  <span class="text-gray-700 font-semibold dark:text-white/90">RenderStream Technology</span>
                </li>
                <li class="flex items-center gap-3">
                  <div class="w-6 h-6 rounded-full bg-gradient-to-r from-[#F6B91A] to-[#FF5722] flex items-center justify-center">
                    <svg class="w-4 h-4 text-white" fill="currentColor" viewBox="0 0 20 20">
                      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                    </svg>
                  </div>
                  <span class="text-gray-700 font-semibold dark:text-white/90">Enhanced Lightning Data</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>