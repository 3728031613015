import App from '@/logic/App'
import { WebRevenueCat } from './platforms/WebRevenueCat'
import { NativeRevenueCat } from './platforms/NativeRevenueCat'

let instance = null

/**
 * Get the appropriate RevenueCat instance based on the platform
 * @returns {import('./types').RevenueCatInstance}
 */
function getInstance() {
    if (!instance) {
        instance = App.isNativeApp() ? new NativeRevenueCat() : new WebRevenueCat()
    }
    return instance
}

/**
 * Install RevenueCat into the Vue app
 * @param {import('vue').App} app - The Vue app instance
 */
export async function install(app) {
    const revenueCat = getInstance()
    await revenueCat.install(app)
}

// /**
//  * Log in a user to RevenueCat
//  * @param {string} userId - The user's unique identifier
//  */
// export async function logIn(userId) {
//     const revenueCat = getInstance()
//     await revenueCat.logIn(userId)
// }

// /**
//  * Log out the current user from RevenueCat
//  */
// export async function logOut() {
//     const revenueCat = getInstance()
//     await revenueCat.logOut()
// }

/**
 * Get the current RevenueCat instance
 * @returns {import('./types').RevenueCatInstance}
 */
export function useRevenueCat() {
    return getInstance()
} 