import { io } from 'socket.io-client'

import api from '@/logic/Api'

class Socket {
  constructor() {
    this.currentServer = api.url()

    this.initSocket()

    this.rooms = [];

    this.serverCheckInterval = setInterval(() => {
      const newServer = api.url()
      if (newServer !== this.currentServer) {
        console.log('API server changed, reconnecting socket...')
        this.currentServer = newServer
        this.socket.disconnect()
        this.initSocket()
      }
    }, 5000)
  }

  createSocket() {
    this.socket = io(this.currentServer, {
      autoConnect: false,
      path: '/ws/socket.io'
    })
  }

  initSocket() {
    this.createSocket()

    this.connect()
  }

  connect() {
    this.socket.io.on("error", (error) => {
      console.log('Socket error', error)
    });

    this.socket.io.on("reconnect", (attempt) => {
      console.log('Socket reconnect')
    });

    this.socket.io.on("reconnect_attempt", (attempt) => {
      console.log('Socket reconnect attempt', attempt)
    });

    this.socket.io.on("reconnect_error", (error) => {
      console.log('Socket reconnect error', error)
    });

    this.socket.io.on("reconnect_failed", () => {
      console.log('Socket reconnect failed')
    });

    this.socket.io.on("ping", () => {
      // console.log('Socket ping')
    });

    this.socket.on("connect_error", (error) => {
      console.log('Socket connect error', error)
    });

    this.socket.on('connect', () => {
      console.log('Socket connected')

      if(this.rooms.length > 0) {
        console.log('Joining rooms', this.rooms)

        this.roomJoinEmit(this.rooms);
      }

      // this.socket.emit("room:join", "all");
    })

    this.socket.on('disconnect', () => {
      console.log('Socket disconnected')
    })

    // this.socket.on("all", (data) => {
    //   console.log(data)
    // });

    this.socket.connect()
  }

  on(event, cb) {
    this.socket.on(event, cb)
  }

  off() {
    this.socket.off()
  }

  removeAllListeners(event) {
    this.socket.removeAllListeners(event)
  }

  instance() {
    return this.socket
  }

  roomJoin(id) {
    console.log(`Socket join room: ${id}`)

    if(this.rooms.includes(id)) {
      return console.log(`Already joined room: ${id}`)
    }

    this.rooms.push(id)

    this.roomJoinEmit(id);
  }

  roomJoinEmit(rooms) {
    this.socket.emit('room:join', rooms)
  }

  roomLeave(id) {
    console.log(`Socket leave room: ${id}`)

    if(! this.rooms.includes(id)) {
      return console.log(`Not part of room: ${id}`)
    }

    this.rooms = this.rooms.filter(r => r !== id)

    this.socket.emit('room:leave', id)
  }
}

export {
  Socket as SocketBase
}

export default new Socket()
