import { defineStore } from 'pinia';

interface UserAccount {
  token: string;
  attributes: {
    id: string;
    name: string;
    email: string;
  }
}

const defaults: UserAccount = {
  token: '',
  attributes: {
    id: '',
    name: '',
    email: ''
  }
};

export const useAccountStore = defineStore('user/account', {
  state: (): UserAccount => ({
    ...JSON.parse(JSON.stringify(defaults))
  }),

  getters: {
    isAuthenticated: (state) => state.token !== ''
  },

  persist: true
});
