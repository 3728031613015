import { SocketBase } from './Socket';
import { useAccountStore } from '@/stores/user/account'

class UserSocket extends SocketBase {

    constructor() {
        super()
        clearInterval(this.serverCheckInterval)
    }

    initSocket() {
        this.currentServer = 'https://data2.weatherwise.app'
        super.createSocket()

        // TODO: Move this to a better place. Don't want to wait for the setTimeout
        setTimeout(() => {
            const accountStore = useAccountStore();

            if (accountStore.isAuthenticated) {
                this.connectAs(accountStore.token);
            }
        }, 10);
    }

    connectAs(token) {
        console.log(`connecting to user socket`)
        this.socket = this.socket.io.socket('/users', {
            auth: {
                token: token
            }
        })

        this.connect()
    }

    disconnect() {
        this.socket.disconnect()
    }

    updateLocation(location) {
        this.socket.emit('user:location', location)
    }
}

export default new UserSocket();