<template>
  <div class="mt-3">
    <label class="inline-flex items-center cursor-pointer w-full"><span class="text-sm font-bold">Reflectivity</span></label>
    <RadarColortableButton v-for="table in tables['REF']" :table="table" groupId="REF" :name="table.name" />
    <RadarColortableButton v-for="table in radarSettings.colortable_pal_upload['REF']" :table="table" groupId="REF" :name="table.filename" />
  </div>
  <div class="mt-3">
    <label class="inline-flex items-center cursor-pointer w-full"><span class="text-sm font-bold">Velocity</span></label>
    <RadarColortableButton v-for="table in tables['VEL']" :table="table" groupId="VEL" :name="table.name" />
    <RadarColortableButton v-for="table in radarSettings.colortable_pal_upload['VEL']" :table="table" groupId="VEL" :name="table.filename" />
  </div>
  <div class="mt-3">
    <label class="inline-flex items-center cursor-pointer w-full"><span class="text-sm font-bold">Correlation Coefficient</span></label>
    <RadarColortableButton v-for="table in tables['CC']" :table="table" groupId="CC" :name="table.name" />
    <RadarColortableButton v-for="table in radarSettings.colortable_pal_upload['CC']" :table="table" groupId="CC" :name="table.filename" />
  </div>
  <div class="mt-3">
    <label class="inline-flex items-center cursor-pointer w-full"><span class="text-sm font-bold">Spectrum Width</span></label>
    <RadarColortableButton v-for="table in tables['SW']" :table="table" groupId="SW" :name="table.name" />
    <RadarColortableButton v-for="table in radarSettings.colortable_pal_upload['SW']" :table="table" groupId="SW" :name="table.filename" />
  </div>
  <div class="mt-3">
    <label class="inline-flex items-center cursor-pointer w-full"><span class="text-sm font-bold">Precipitation Type</span></label>
    <RadarColortableButton v-for="table in tables['PRT']" :table="table" groupId="PRT" :name="table.name" />
    <RadarColortableButton v-for="table in radarSettings.colortable_pal_upload['PRT']" :table="table" groupId="PRT" :name="table.filename" />
  </div>
  <div class="relative inline-flex items-center justify-center w-full my-6">
    <hr class="w-full h-px bg-gray-300 border-0">
    <span class="absolute px-3 font-medium text-gray-900 rounded-lg -translate-x-1/2 bg-white left-1/2 text-xs">Upload</span>
  </div>
  <div>
    <div v-if="canUpload">
      <FileUpload :on-file-content="handleFileContent" />
    </div>
    <div v-else>
      <p class="text-xs text-center font-bold">You need to update your app to upload Color Tables.</p>
    </div>
  </div>
  <p class="mt-5 text-xs text-center">All colortables are kindly provided by <a href="https://www.wxtools.org/" target="_blank" class="text-blue-600 hover:underline">Wx Tools</a>.</p>
</template>

<script>
import { Icon } from '@iconify/vue'

import App from '@/logic/App'
import MapKeeper from '@/logic/MapKeeper'

import { parsePal } from '@/tools/pal.js'
import RadarColortable from '@/data/Colortables/Radar.js'

import { cyrb53 } from '@/tools/helpers.js'

import FileUpload from '@/components/Extra/FileUpload.vue'
import RadarColortableButton from './RadarColortableButton.vue'

import { useRadarTowersStore } from '@/stores/radar_towers'
import { useRadarSettingsStore } from '@/stores/settings/radar'

export default {
  name: 'RadarColortables',
  components: {
    Icon,
    FileUpload,
    RadarColortableButton
  },
  data() {
    return {
      tables: {
        'REF': [
          {
            name: 'Default',
            id: 'DEFAULT'
          },
          {
            name: 'Alternative 1',
            id: 'OTHER1'
          },
          {
            name: 'Alternative 2',
            id: 'OTHER2'
          }
        ],
        'VEL': [
          {
            name: 'Default',
            id: 'DEFAULT'
          },
          {
            name: 'Alternative 1',
            id: 'OTHER1'
          },
          {
            name: 'Alternative 2',
            id: 'OTHER2'
          }
        ],
        'CC': [
          {
            name: 'Default',
            id: 'DEFAULT'
          },
          {
            name: 'Alternative 1',
            id: 'OTHER1'
          },
          {
            name: 'Alternative 2',
            id: 'OTHER2'
          }
        ],
        'SW': [
          {
            name: 'Default',
            id: 'DEFAULT'
          },
          {
            name: 'Alternative 1',
            id: 'OTHER1'
          },
          {
            name: 'Alternative 2',
            id: 'OTHER2'
          }
        ],
        'PRT': [
          {
            name: 'Default',
            id: 'DEFAULT'
          },
          {
            name: 'Alternative 1',
            id: 'OTHER1'
          },
          {
            name: 'Alternative 2',
            id: 'OTHER2'
          }
        ],
      }
    };
  },
  computed: {
    radarTowersStore() {
      return useRadarTowersStore()
    },
    radarSettings() {
      return useRadarSettingsStore()
    },
    canUpload() {
      if(! App.isNativeApp()) return true;

      return App.isNativeVersionAtleast('1.1.1');
    },
  },
  methods: {
    async handleFileContent(text, filename) {
      // console.log(text, filename)

      const grLevelProductMap = {
        'BR': 'REF',
        'BV': 'VEL',
        'CC': 'CC',
        'SW': 'SW',
        'PM': 'PRT'
      }

      try {
        const parsed = parsePal(text);

        // console.log(parsed)

        const groupId = grLevelProductMap[parsed.pal.product];

        if(groupId === undefined) {
          throw new Error(`Unable to locate product: ${parsed.pal.product}`);
        }

        const id = cyrb53(text);

        const uploadId = `UPLOAD:${id}`

        this.radarSettings.colortable[groupId] = uploadId;
        // Override the current array of uploaded colortables
        // We can support more later
        this.radarSettings.colortable_pal_upload[groupId] = [{
          id: uploadId,
          filename,
          text
        }];

        RadarColortable.parse(groupId, uploadId, text);

        await this.onColortableChange(groupId, uploadId)
      }
      catch(e) {
        alert(e.message);
      }
    },
    async onColortableChange(groupId, tableId) {
      // console.log(groupId);
      this.radarSettings.colortable[groupId] = tableId;

      // This allows the colortable to be changed while playing
      if(! this.radarTowersStore.isPlaying) {
        MapKeeper.forEach(m => {
          const store = m.stores['radar'];
          if(store.activeProductGroup.id === groupId) {
            const renderer = m.components['radar-renderer'];

            App.radar.drawScan(m, renderer.getData(), store.activeTowerId, store.activeProductCode);
          }
        });
      }
    }
  }
}
</script>

<style scoped>

</style>
