import { createRouter, createWebHistory } from 'vue-router'

import HomeView from '@/views/HomeView.vue'
import LoginView from '@/views/LoginView.vue'
import ShareLocation from '@/views/ShareLocation.vue'
import HazcamsView from '@/views/HazcamsView.vue'

import ToDesktop from '@/logic/ToDesktop'
import { useAppStore } from '@/stores/app'
import { useUserAccount } from '@/logic/Composables/UserAccount'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomeView,
      meta: { requiresAuth: ToDesktop.isToDesktop() }
    },
    {
      path: '/login',
      name: 'login',
      component: LoginView
    },
    {
      path: '/d/reset-password',
      name: 'reset-password',
      beforeEnter: (to) => {
        // Force this into the deep link router
        const { setDeepLink } = useAppStore()
        const currentUrl = window.location.href
        setDeepLink(currentUrl)
        return {
          name: 'home'
        }
      }
    },
    {
      path: '/d/share-location',
      name: 'share-location',
      component: ShareLocation,
      meta: {
        pageScroll: true
      }
    },
    {
      path: '/hazcams/:id',
      name: 'hazcams',
      component: HazcamsView,
      meta: {
        bodyClass: 'page-hazcams-station'
      }
    }
  ]
})

router.beforeEach((to, from, next) => {
  // Remove the old body class (if any) from the previous route
  if (from.meta.bodyClass) {
    document.body.classList.remove(from.meta.bodyClass)
  }

  // Add a new body class from the incoming route
  if (to.meta.bodyClass) {
    document.body.classList.add(to.meta.bodyClass)
  }

  if (to.meta.pageScroll && to.meta.pageScroll === true) {
    document.querySelector(':root')?.classList.add('has-scroll')
  } else {
    document.querySelector(':root')?.classList.remove('has-scroll')
  }

  // Check if the route requires authentication
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // Check if the required item exists in local storage
    const isAuthenticated = useUserAccount().isAuthenticated()
    if (!isAuthenticated) {
      // Redirect to login page if item is missing
      return next({
        path: '/login',
        query: { redirect: to.fullPath } // Optional: Pass along the intended route for after login
      });
    }
  }

  next()
})


export default router
