import { render, h, ref, inject } from 'vue'
import { useModal, useModalSlot } from 'vue-final-modal'
import { useHazcamsStore } from '@/stores/settings/vendors/hazcams'

import UrlHash from '@/tools/url-hash'
import { renderToPopup } from '@/tools/mapbox-map'
import { allSettledLimit, uniqueList } from '@/tools/helpers'
import api from '@/logic/Api'
import socket from '@/logic/Socket'

import VideoModal from './Modals/Templates/Video.vue'
import HazcamPlaybackModal from './Hazcams/PlaybackModal.vue'
import HazcamsPopup from './Hazcams/HazcamsPopup.vue'

import App from '@/logic/App'
import MapKeeper from '@/logic/MapKeeper'
import { APP_VERSION_1_2_0 } from '@/tools/constants'

const CHASER_IDS = {
  "eb7ded21-88bd-4dd2-a5f9-a06d964ec4a7": {
    "name": "Brett Adair"
  },
  "00992981-423a-42c2-a1ea-b8e174a627ed": {
    "name": "Kyle Davies"
  },
  "0bf5bceb-e8f3-4dba-88f7-3a3532610d6a": {
    "name": "Jason Cooley"
  },
  "0c532840-cbd7-447f-b338-b1bd244c788b": {
    "name": "Tyler Kurtz"
  },
  "1f22aacc-bb43-4159-b64b-15e1fcecb0d3": {
    "name": "Jennifer McMahan"
  },
  "42addff2-f955-46a8-9243-333bc8ae6567": {
    "name": "Taylor Woodall"
  },
  "55980514-07b6-4692-81cb-da88123ccfbc": {
    "name": "Austin Detzel"
  },
  "7ea10d5a-6127-4292-beab-3cc75b44b3e3": {
    "name": "Ryan Pitt"
  },
  "9dbdb352-2cfc-49a8-bd5c-e482811c558d": {
    "name": "Sam Camp"
  },
  "a38cf438-c02a-4ba7-b33b-d275d3d73278": {
    "name": "Justin Selig"
  },
  "a9aca452-487b-4604-90d2-b22c7c58554b": {
    "name": "Eric Tole"
  },
  "d0a7de4a-f7bc-4506-80ec-ae486bb44fdb": {
    "name": "Nick Smego"
  },
  "da9c93f4-7a70-4c71-be70-f61976b28406": {
    "name": "Cody Hudson"
  },
  "e461dc0e-8dc7-4ed4-bb84-ce14aa36f562": {
    "name": "David Drummond"
  },
  "ec5c0159-4c32-40fc-bf39-ad6a0c0a393f": {
    "name": "Michael Beard"
  },
  "eed90169-afbc-4aac-bc2b-26739b1c0173": {
    "name": "Nick Busby"
  },
  "fa487540-6c87-478e-b555-adac51feda2f": {
    "name": "Michael Craddock"
  },
  "cb17a19e-41d7-47d1-bdf1-e8dee0ec6ac8": {
    "name": "Drew Richards"
  },
  "6b61a2b9-9199-42ce-a11b-029d74f508dd": {
    "name": "Aaron"
  },
  "028fd498-54bd-4b7a-b6f4-c84f9ccc9ca8": {
    "name": "Joe Pisani"
  },
  "b6b0ae73-000f-499e-9ac9-71dfe3f607f3": {
    "name": "James Hilger"
  },
  "0c94c44c-dd00-4a4f-90a1-ba5c6a95c890": {
    "name": "Nacoma Hutchinson"
  },
  "21f6a705-3d57-4c22-974b-530a169f9393": {
    "name": "Chris Jackson"
  },
};

class Hazcams {
  constructor() {
    this.sourceId = 'ww-hazcams-source'
    this.pointLayerId = 'ww-hazcams-point-layer'
    this.boundPointOnClick = null
    this.stations = {
      type: 'FeatureCollection',
      features: []
    };

    this.pointOnClick = renderToPopup((e) => {
      const features = uniqueList(e.features, feature => feature.properties.id)
      if(features.length == 0) return;

      return () => {
        const container = window.document.createElement('div');

        features.forEach((feature, idx) => {
          const popupContainer = window.document.createElement('div');

        render(h(HazcamsPopup, {
          feature,
          isLast: idx === features.length - 1,
          onClick: () => {
            MapKeeper.popups.clear()
            if (App.isNativeVersionAtleast(APP_VERSION_1_2_0) || !App.isAppleNative()) {
              this.openPlaybackModal(feature);
            } else {
              // <1.2.0 fix for hazcams modal
              window.open(`https://web2.weatherwise.app/hazcams/${feature.properties.id}`, '_blank')
            }
          }
        }), popupContainer);

          container.appendChild(popupContainer);
        });

        return container;
      }
    });

    const params = new UrlHash();
    this.stationsEndpoint = '/vendors/hazcams/stations.geojson';
    this.socketRoom = 'vendors:hazcams:stations';
    if(params.has('rw')) {
      this.stationsEndpoint = '/vendors/hazcams/stations-rw.geojson';
      this.socketRoom = 'vendors:hazcams:stations:rw';
    }
  }

  async draw() {
    try {
      const tasks = [];
      tasks.push(async () => {
        await MapKeeper.asyncLoadAndAddImage(new URL('../assets/vendors/hazcams/hazcam-point.png', import.meta.url).href, 'hazcams-dot')
      })

      tasks.push(async () => {
        await MapKeeper.asyncLoadAndAddImage(new URL('../assets/vendors/hazcams/hazcam-mobile-point.png', import.meta.url).href, 'hazcams-mobile-dot')
      });

      Object.keys(CHASER_IDS).forEach(chaserId => {
        tasks.push(async () => {
          await MapKeeper.asyncLoadAndAddImage(new URL(`../assets/vendors/hazcams/chaser-${chaserId}.png`, import.meta.url).href, `hazcams-${chaserId}`);
        });
      });

      const results = await allSettledLimit(tasks, 6);

      // Log out any errors here
      results.filter(r => r.status === 'rejected').forEach((result) => console.error(result.reason))

      this.addLayer()
      await this.addData()

      this.boundPointOnClick = this.pointOnClick.bind(this)
      MapKeeper.on('click', this.pointLayerId, this.boundPointOnClick)
    } catch(e) {
      // Cleanup any partial state if drawing fails
      this.clear()
      throw new Error(`Failed to draw hazcams: ${e.message}`)
    }
  }

  addLayer() {
    MapKeeper.addSource(this.sourceId, {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: []
      }
    });

    MapKeeper.addLayer({
      'id': this.pointLayerId,
      'type': 'symbol',
      'source': this.sourceId,
      'layout': {
        'icon-image': ['get', 'icon-image'],
        'icon-size': 0.15,
        'icon-allow-overlap': false,
        'icon-anchor': 'center',
        'icon-offset': [0, 0],
        'icon-pitch-alignment': 'map',
        'symbol-sort-key': ["get", "symbol-sort-key"]
      }
    }, 'ww-radar-towers-dot-layer');
  }

  async fetchAndSetData() {
    const stationsCopy = JSON.parse(JSON.stringify(this.stations));

    await this.fetchData();
    MapKeeper.getSource(this.sourceId).setData(this.stations);

    const params = new UrlHash();
    if(params.has('hctrackid')) {
      const hcId = params.get('hctrackid');

      const currentStation = this.stations.features.find(f => f.properties.id === hcId);

      if(currentStation === undefined) return;

      const previousStation = stationsCopy.features.find(f => f.properties.id === hcId);

      if(previousStation === undefined || (previousStation !== undefined && JSON.stringify(currentStation.geometry) !== JSON.stringify(previousStation.geometry))) {
        const location = currentStation.geometry.coordinates;

        MapKeeper.mapboxMapsFirst().easeTo({
          center: location,
          duration: 1000
        });

        setTimeout(() => {
          App.radar.turnOnClosestRadar(location);
        }, 1010);
      }
    }
  }

  async addData() {
    try {
      await this.fetchAndSetData();

      socket.roomJoin(this.socketRoom)
      socket.on(this.socketRoom, async (data) => {
        console.log('Hazcams update', this.socketRoom, data);

        try {
          await this.fetchAndSetData();
        } catch(e) {
          console.error('Failed to load hazcams data after update', e)
        }
      });
    } catch(e) {
      console.error('Failed to load hazcams data', e)
    }
  }

  async fetchData() {
    this.stations = await api.instance().get(this.stationsEndpoint)

    this.stations.features = this.stations.features.filter(station => {
      const allowed = station.properties.online && !station.properties.unlisted;

      if(station.properties.type === 'chaser') {
        if(CHASER_IDS[station.properties.id] !== undefined) return allowed;
        return false;
      }

      return allowed;
    }).map(f => {
      let iconImage = 'hazcams-dot';
      let priority = 3;

      if(f.properties.id.startsWith('hazcams-mobile-')) {
        iconImage = 'hazcams-mobile-dot';
        priority = 2;
      }
      else if(f.properties.type === 'chaser') {
        priority = 1;

        if(CHASER_IDS[f.properties.id] !== undefined) {
          f.properties.name = CHASER_IDS[f.properties.id].name;
          iconImage = `hazcams-${f.properties.id}`;
        }
        else {
          if(f.properties.sponsors && f.properties.sponsors.length > 0) {
            f.properties.name = f.properties.sponsors[0].name;
          }
          iconImage = 'hazcams-mobile-dot';
        }
      }

      // console.log(iconImage, priority, f.geometry)

      f.properties['icon-image'] = iconImage;
      f.properties['symbol-sort-key'] = priority;

      return f;
    });
  }

  openPlaybackModal(feature) {
    useModal({
      defaultModelValue: true,
      component: VideoModal,
      attrs: {
        title: `${feature.properties.name}`,
        onOpened() {
          const params = new UrlHash();
          params.set('hcid', feature.properties.id)
          params.save()
        },
        onClosed() {
          const params = new UrlHash();
          params.delete('hcid')
          params.save()
        },
      },
      slots: {
        default: useModalSlot({
          component: HazcamPlaybackModal,
          attrs: {
            feature: feature,
          }
        })
      },
    });
  }

  show() {
    for(const layerId of [this.pointLayerId]) {
      MapKeeper.setLayoutProperty(layerId, 'visibility', 'visible');
    }
  }

  hide() {
    for(const layerId of [this.pointLayerId]) {
      MapKeeper.setLayoutProperty(layerId, 'visibility', 'none');
    }
  }

  clear() {
    try {
      MapKeeper.popups.clear()

      if (this.boundPointOnClick) {
        MapKeeper.off('click', this.pointLayerId, this.boundPointOnClick)
        this.boundPointOnClick = null
      }

      if (MapKeeper.hasImage('hazcams-dot')) {
        MapKeeper.removeImage('hazcams-dot')
      }
      if (MapKeeper.hasImage('hazcams-mobile-dot')) {
        MapKeeper.removeImage('hazcams-mobile-dot')
      }
      Object.keys(CHASER_IDS).forEach(chaserId => {
        if (MapKeeper.hasImage(`hazcams-${chaserId}`)) {
          MapKeeper.removeImage(`hazcams-${chaserId}`)
        }
      });

      // Check if layers/source exist before removing
      if (MapKeeper.getLayer(this.pointLayerId)) {
        MapKeeper.removeLayer(this.pointLayerId)
      }

      if (MapKeeper.getSource(this.sourceId)) {
        MapKeeper.removeSource(this.sourceId)
      }

      socket.roomLeave(this.socketRoom)
      socket.removeAllListeners(this.socketRoom)

    } catch(e) {
      throw new Error(`Failed to clear hazcams: ${e.message}`)
    }
  }

  getStations() {
    return this.stations.features
  }
}

const hazcams = new Hazcams()

const createHazcams = {
  async install(app) {
    const init = async () => {
      console.log('Installing hazcams')

      const urlHash = new UrlHash()

      if (urlHash.has('hcid')) {
        await hazcams.fetchData()
        const feature = hazcams.getStations().find(station => station.properties.id === urlHash.get('hcid'))
        if (feature) {
          hazcams.openPlaybackModal(feature)
        }
      }

      console.log('Hazcams initialized')
    }

    // Start initialization
    try {
      await init()
    } catch(e) {
      console.error('Failed to initialize hazcams:', e)
    }
  }
}

const useHazcams = () => {

  const toggle = async () => {
    try {
      if (useHazcamsStore().mapLayerOn) {
        await hide()
      } else {
        await show()
      }
    } catch(e) {
      console.error('Failed to toggle hazcams:', e)
      throw new Error('Failed to toggle hazcam visibility')
    }
  }

  const show = async () => {
    await hazcams.draw()
    useHazcamsStore().set(true)
  }

  const hide = async () => {
    hazcams.clear()
    useHazcamsStore().set(false)
  }

  const update = async () => {
    if (! isVisible()) {
      return;
    }
    try {
      await hazcams.fetchAndSetData()
    } catch (e) {
      console.log(`Failed to update hazcams`, e)
    }
  }

  const isVisible = () => useHazcamsStore().mapLayerOn

  const showIfVisible = () => {
    if (isVisible()) {
      show()
    }
  }

  return {
    toggle,
    show,
    hide,
    isVisible,
    showIfVisible,
    hazcams,
    update
  }
}

export { useHazcams, createHazcams }
