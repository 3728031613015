import { useAppStore } from '@/stores/app'
import { storeToRefs } from 'pinia';
import { onUnmounted, watch } from 'vue';
import modals from '@/logic/Modals/Helper'

const routes = {
    'share-location': (url) => {
        if (!url.searchParams) {
            return;
        }

        const params = new URLSearchParams(url.searchParams)

        const shareWith = params.get('with');
        console.log(`share location with`, shareWith)

        switch (shareWith) {
            case 'rob':
                modals.shareLocationWith()
                break;

            default:
                break;
        }
    },
    'test': (url) => {
        console.log(`test route hit`)
    },
    'reset-password': (url) => {
        // This will open the password reset modal
        // After successful reset, user will need to log in with their new credentials
        modals.resetPassword();
    }
}

export function useDeepLink() {
    const appStore = useAppStore();
    const {deepLink} = storeToRefs(appStore)

    const stop = watch(deepLink, () => {
        console.log(`Deep link watch`, deepLink.value)

        if (! deepLink.value) {
            return;
        }

        const url = new URL(deepLink.value)
        console.log(`deep link path`, url.pathname, url.searchParams)

        for (const key in routes) {
            if (Object.prototype.hasOwnProperty.call(routes, key) && url.pathname.includes(key)) {
                const element = routes[key];
                console.log(`route match`, key, element)
                element(url)
            }
        }
    }, {immediate: true})

    onUnmounted(() => stop())
}

