export default [
  {
    "type": "Feature",
    "geometry": {
      "type": "Point",
      "coordinates": [
        -87.325439453125,
        35.25498962402344,
        303.0
      ]
    },
    "properties": {
      "id": "FWLX",
      "code": "FWLX",
      "secondary": true,
      "products": [
        "REF0",
        "VEL0",
        "CC0",
        "ZDR0"
      ],
      "elevations": [0.5]
    }
  },
  {
    "type": "Feature",
    "geometry": {
      "type": "Point",
      "coordinates": [
        -84.00341033935547,
        33.980499267578125
      ]
    },
    "properties": {
      "id": "GAWX",
      "code": "GAWX",
      "secondary": true,
      "products": [
        "REF0",
        "REF1",
        "REF2",
        "VEL0",
        "VEL1",
        "VEL2",
        "CC0",
        "CC1",
        "CC2",
        "ZDR0",
        "ZDR1",
        "ZDR2"
      ],
      "elevations": [0.0, 2.0, 4.0]
    }
  }
]