<template>
  <div class="relative inline-flex items-center justify-center w-full mb-6">
    <hr class="w-full h-px bg-gray-300 border-0">
    <span class="absolute px-3 font-medium text-gray-900 -translate-x-1/2 bg-white left-1/2 text-xs rounded-lg">Settings</span>
  </div>
  <div class="grid grid-cols-2 gap-4">
    <div v-if="stormTracksSettingSupported">
      <label class="inline-flex items-center cursor-pointer w-full">
        <ToggleSwitch class="sr-only peer" v-model.number="radarSettings.storm_tracks" :true-value="1" :false-value="0" @change="onStormTracksChange" />
        <span class="ms-3 text-sm font-bold">Storm Tracks</span>
        <a href="#" class="ml-auto" @click.prevent="showExtraHelp('STORM_TRACKS')"><Icon icon="carbon:help" class="size-5" /></a>
      </label>
    </div>
    <div v-if="stormTracksSettingSupported&&false">
      <label class="inline-flex items-center cursor-pointer w-full"><span class="text-sm font-bold">Storm Tracks</span></label>
      <select class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg inline p-1" v-model.number="radarSettings.storm_tracks" @change="onStormTracksChange">
        <option value="0">Off</option>
        <option value="1">Standard</option>
        <!-- <option value="2">Pro</option> -->
      </select>
      <a href="#" class="inline float-right" @click.prevent="showExtraHelp('STORM_TRACKS')"><Icon icon="carbon:help" class="inline size-5" /></a>
    </div>
    <div v-if="meltingLayerSettingSupported&&false">
      <label class="inline-flex items-center cursor-pointer w-full">
        <ToggleSwitch class="sr-only peer" v-model.number="radarSettings.melting_layer" :true-value="1" :false-value="0" @change="onMeltingLayerChange" />
        <span class="ms-3 text-sm font-bold">Melting Layer</span>
        <a href="#" class="ml-auto" @click.prevent="showExtraHelp('MELTING_LAYER')"><Icon icon="carbon:help" class="size-5" /></a>
      </label>
    </div>
    <div v-if="lightningSettingSupported">
      <template v-if="shouldShow120Features">
        <label class="inline-flex items-center cursor-pointer w-full">
        <span class="text-sm font-bold">Lightning <Plus class="ml-1" /></span>
        </label>
        <select class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg inline p-1" v-model.number="radarLightning" :key="lightningKey" @change="onLightningChange">
          <option value="0">Off</option>
          <option value="1">Standard</option>
          <option value="2">Plus</option>
        </select>
        <a href="#" class="inline float-right" @click.prevent="showExtraHelp('LIGHTNING')"><Icon icon="carbon:help" class="inline size-5" /></a>
      </template>
      <template v-else>
        <label class="inline-flex items-center cursor-pointer w-full">
        <ToggleSwitch class="sr-only peer" v-model.number="radarLightning" :true-value="1" :false-value="0" @change="onLightningChange" />
        <span class="ms-3 text-sm font-bold">Lightning</span>
        <a href="#" class="ml-auto" @click.prevent="showExtraHelp('LIGHTNING')"><Icon icon="carbon:help" class="inline size-5" /></a>
        </label>
      </template>
    </div>
    <div v-if="shouldShow120Features">
      <label class="inline-flex items-center cursor-pointer w-full">
        <ToggleSwitch v-model="threeDRadar" :key="threeDRadarKey" />
        <span class="ms-3 text-sm font-bold">
          3D Radar<br />
          <Plus />
        </span>
        <a href="#" class="ml-auto" @click.prevent="showExtraHelp('3D_RADAR')"><Icon icon="carbon:help" class="inline size-5" /></a>
      </label>

    </div>
    <div v-if="shouldShow120Features">
      <label class="inline-flex items-center cursor-pointer w-full"><span class="text-sm font-bold">Max Scans <Plus class="ml-1" /></span></label>
      <select class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg inline p-1" v-model.number="radarScanFrames" :key="maxScansKey">
        <option value="15">15 scans</option>
        <option value="30">30 scans</option>
        <option value="50">50 scans</option>
        <option value="75">75 scans</option>
        <option value="100">100 scans</option>
      </select>
      <a href="#" class="inline float-right" @click.prevent="showExtraHelp('MAX_SCANS')"><Icon icon="carbon:help" class="inline size-5" /></a>
    </div>
    <div>
      <label class="inline-flex items-center cursor-pointer w-full"><span class="text-sm font-bold">Reflectivity Unit</span></label>
      <select class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg inline p-1" v-model="radarSettings.units['REF']" @change="onReflectivityUnitChange">
        <option value="dbz">dBZ</option>
        <option value="dbz_rr_in_hr">dBZ + RR (in/hr)</option>
        <option value="dbz_rr_mm_hr">dBZ + RR (mm/hr)</option>
      </select>
      <a href="#" class="inline float-right" @click.prevent="showExtraHelp('UNIT_REFLECTIVITY')"><Icon icon="carbon:help" class="inline size-5" /></a>
    </div>
    <div>
      <label class="inline-flex items-center cursor-pointer w-full"><span class="text-sm font-bold">Velocity Unit</span></label>
      <select class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg inline p-1" v-model="radarSettings.units['VEL']" @change="onVelocityUnitChange">
        <option value="m/s">m/s</option>
        <option value="mph">mph</option>
        <option value="kph">kph</option>
        <option value="kt">knots</option>
        <option value="ft/s">ft/s</option>
      </select>
      <a href="#" class="inline float-right" @click.prevent="showExtraHelp('UNIT_VELOCITY')"><Icon icon="carbon:help" class="inline size-5" /></a>
    </div>
    <div>
      <label class="inline-flex items-center cursor-pointer w-full"><span class="text-sm font-bold">Tower Icon</span></label>
      <select class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg inline p-1" v-model="radarSettings.tower_icon_design" @change="onTowerIconChange">
        <option value="dot">Dot</option>
        <option value="pill">Pill with Code</option>
      </select>
      <a href="#" class="inline float-right" @click.prevent="showExtraHelp('TOWER_ICON_DESIGN')"><Icon icon="carbon:help" class="inline size-5" /></a>
    </div>
    <div class="col-span-2">
      <label class="inline-flex items-center cursor-pointer w-full">
        <span class="text-sm font-bold">Playback Speed</span>
        <a href="#" class="ml-auto" @click.prevent="showExtraHelp('PLAYBACK_SPEED')"><Icon icon="carbon:help" class="size-5" /></a>
      </label>
      <div class="space-y-1">
        <Slider
          v-model.number="radarSettings.playback_speed_ms"
          :min="75"
          :max="300"
          :step="25"
          class="my-2"
          style="direction: rtl;"
        />
        <div class="flex justify-between px-1 pointer-events-none">
          <span class="text-xs text-gray-500 dark:text-gray-400">Slow</span>
          <span class="text-xs text-gray-500 dark:text-gray-400">Fast</span>
        </div>
      </div>
    </div>
    <div class="col-span-2" v-if="shouldShow120Features && smoothingIsSupported">
      <label class="inline-flex items-center cursor-pointer w-full">
        <span class="text-sm font-bold">Smoothing <Plus class="ml-1" /></span>
        <a href="#" class="ml-auto" @click.prevent="showExtraHelp('SMOOTHING')"><Icon icon="carbon:help" class="size-5" /></a>
      </label>
      <div class="space-y-1">
        <div @click="!subscription.hasActiveSubscription() && useUpgradeNotifcation('RADAR_SMOOTHING')">
          <Slider
            v-model.number="smoothing"
            :min="0"
            :max="1"
            :step="0.1"
            :disabled="!subscription.hasActiveSubscription()"
            class="my-2"
            @update:model-value="onApplySettings"
          />
        </div>
        <div class="flex justify-between px-1 pointer-events-none">
          <span class="text-xs text-gray-500 dark:text-gray-400">None</span>
          <span class="text-xs text-gray-500 dark:text-gray-400">Full</span>
        </div>
      </div>
    </div>
    <div class="col-span-2" v-if="filteringIsSupported">
      <label class="inline-flex items-center cursor-pointer w-full">
        <span class="text-sm font-bold">Reflectivity Gate Filter: {{ gateFilter[0] }} - {{ gateFilter[1] }} {{ productGroup.unit }}</span>
        <a href="#" class="ml-auto" @click.prevent="showExtraHelp('GATE_FILTERING')"><Icon icon="carbon:help" class="size-5" /></a>
      </label>
      <div class="space-y-1">
        <div>
          <Slider
            v-model="gateFilter"
            :key="gateFilterKey"
            range
            :min="gateFilterLimit.min"
            :max="gateFilterLimit.max"
            :step="1"
            class="my-2"
            @update:model-value="onApplySettings"
          />
        </div>
        <div class="flex justify-between px-1 pointer-events-none">
          <span class="text-xs text-gray-500 dark:text-gray-400" v-text="gateFilterLimit.min.toFixed(0)"></span>
          <span class="text-xs text-gray-500 dark:text-gray-400" v-text="gateFilterLimit.max.toFixed(0)"></span>
        </div>
        <div>
          <div v-if="gateFilter[0] > gateFilterWarning.min" class="flex items-center p-4 mt-4 mb-4 text-sm text-yellow-800 border border-yellow-300 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-300 dark:border-yellow-800" role="alert">
            <svg class="shrink-0 inline w-4 h-4 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
              <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
            </svg>
            <div>
              <span class="font-bold">Warning!</span> We recommend a minimum of <span class="font-medium underline">{{ gateFilterWarning.min.toFixed(0) }} {{ productGroup.unit }}</span>.
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-span-2">
      <button @click.prevent="onColortableChangeClick($event)" type="button" class="block w-full text-white bg-blue-700 hover:bg-blue-800 font-bold rounded-lg text-sm px-2.5 py-2 pt-1.5" :disabled='isPlaybackBuffering'><Icon icon="fluent:color-20-regular" class="inline size-5 mr-2" />Customize Color Tables</button>
    </div>
  </div>
</template>

<script>
import { toRaw } from 'vue'
import { Icon } from '@iconify/vue'
import ToggleSwitch from '@/components/UI/Form/ToggleSwitch.vue';
import Slider from '@/components/UI/Form/Slider.vue';
import Plus from '@/components/Labels/Plus.vue'

import { useRadarTowersStore } from '@/stores/radar_towers'
import { useRadarSettingsStore } from '@/stores/settings/radar'

import { useSubscription, useUpgradeNotifcation } from '@/logic/Composables/Subscription'

import { APP_VERSION_1_2_0 } from '@/tools/constants'
import App from '@/logic/App'
import MapKeeper from '@/logic/MapKeeper'
import { useDebounceFn } from '@vueuse/core';

export default {
  name: 'RadarSettings',
  components: {
    Icon,
    ToggleSwitch,
    Slider,
    Plus
  },
  setup() {
    const subscription = useSubscription()

    return {
      subscription,
      App,
      useUpgradeNotifcation
    }
  },
  data() {
    return {
      gateFilterKey: 0,
      threeDRadarKey: 0,
      lightningKey: 0,
      maxScansKey: 0,
      gateFilterLimit: {
        // Min/max for REF (the only supported product)
        min: -30,
        max: 90
      },
      gateFilterWarning: {
        min: 10
      }
    }
  },
  computed: {
    radarTowersStore() {
      return useRadarTowersStore()
    },
    radarSettings() {
      return useRadarSettingsStore()
    },
    mapRadar() {
      return MapKeeper.activeMap().stores['radar'];
    },
    productGroup() {
      return this.mapRadar.activeProductGroup;
    },
    stormTracksSettingSupported() {
      return this.mapRadar.availableProducts.includes('NST');
    },
    lightningSettingSupported() {
      return App.radar.isLightningSupported(this.mapRadar.activeTower);
    },
    shouldShow120Features() {
      return App.shouldShowFeatures(APP_VERSION_1_2_0)
    },
    meltingLayerSettingSupported() {
      // TODO
      // Move this elsewhere...
      return this.mapRadar.availableProducts.some(p => ['NXM', 'NYM', 'NZM', 'N0M', 'NAM', 'N1M', 'NBM', 'N2M', 'N3M'].includes(p))
    },
    isPlaybackBuffering() {
      return this.radarTowersStore.isPlaybackBuffering;
    },
    activeProductGroup() {
      return MapKeeper.activeMap().stores['radar'].activeProductGroup;
    },
    smoothingIsSupported() {
      return this.activeProductGroup.id !== 'PRT';
    },
    filteringIsSupported() {
      return this.activeProductGroup.id === 'REF';
    },
    radarScanFrames: {
      get() {
        return this.radarSettings.max_scans
      },
      set(val) {
        if (val > 15 && !this.subscription.isAtleast(this.subscription.tiers.PLUS)) {
          useUpgradeNotifcation('RADAR_MAX_SCANS')
          this.maxScansKey++
          return;
        }

        this.radarSettings.max_scans = val
      }
    },
    radarLightning: {
      get() {
        return this.radarSettings.lightning
      },
      set(val) {
        if (val > 1 && !this.subscription.isAtleast(this.subscription.tiers.PLUS)) {
          useUpgradeNotifcation('RADAR_ENHANCED_LIGHTNING')
          this.lightningKey++
          return;
        }

        this.radarSettings.lightning = val
      }
    },

    threeDRadar: {
      get() {
        return this.radarSettings.three_d
      },
      set(val) {
        console.log(`setting 3D radar`, val)

        if (val === true && this.subscription.isAtleast(this.subscription.tiers.PLUS)) {
          this.radarSettings.three_d = true
          App.radar.redrawScanAll()
        } else if (val === true && !this.subscription.isAtleast(this.subscription.tiers.PLUS)) {
          this.radarSettings.three_d = false
          useUpgradeNotifcation('RADAR_3D')
          this.threeDRadarKey++
        } else {
          this.radarSettings.three_d = false
          App.radar.redrawScanAll()
        }
      }
    },

    smoothing: {
      get() {
        return this.radarSettings.smoothing[MapKeeper.activeMap().stores['radar'].activeProductGroup.id]
      },
      set(val) {
        if (val > 0 && this.subscription.isAtleast(this.subscription.tiers.PLUS)) {
          this.radarSettings.smoothing[MapKeeper.activeMap().stores['radar'].activeProductGroup.id] = val
        } else if (val > 0 && !this.subscription.isAtleast(this.subscription.tiers.PLUS)) {
          this.radarSettings.smoothing[MapKeeper.activeMap().stores['radar'].activeProductGroup.id] = 0
          useUpgradeNotifcation('Radar Smoothing', 'RADAR_SMOOTHING')
        } else {
          this.radarSettings.smoothing[MapKeeper.activeMap().stores['radar'].activeProductGroup.id] = val
        }
      }
    },

    // Only works with REF
    gateFilter: {
      get() {
        return this.radarSettings.gate_filter[MapKeeper.activeMap().stores['radar'].activeProductGroup.id]
      },
      set(val) {
        const productGroupId = MapKeeper.activeMap().stores['radar'].activeProductGroup.id
        let lower = parseInt(val[0])
        let higher = parseInt(val[1])

        // Ensure always have a range of 5
        const minRange = 5;

        if(higher - lower < minRange) {
          lower = higher - minRange;
          if(lower < this.gateFilterLimit.min) {
            lower = this.gateFilterLimit.min
          }
        }
        if(higher < this.gateFilterLimit.min + minRange) {
          higher = this.gateFilterLimit.min + minRange;
        }

        if (higher > lower) {
          this.radarSettings.gate_filter[productGroupId] = [lower, higher]
        } else {
          // Force re-render with current store value (last valid state)
          this.gateFilterKey++
        }
      }
    }
  },
  methods: {
    onApplySettings: useDebounceFn(() => {
      const activeProductCode = MapKeeper.activeMap().stores['radar'].activeProductCode;

      MapKeeper.forEach(m => {
        const store = m.stores['radar']

        if(store.activeProductCode !== activeProductCode) return;

        App.radar.redrawScan(m)
      })
    }, 20),

    async onStopClick() {
      await App.radar.resetAndLoadLatestRadarDataAll()
    },
    async onStormTracksChange() {
      if(this.radarTowersStore.isPlaying) {
        try {
          await this.onStopClick()
        }
        catch(e) {
          console.log('Failed to stop playback')
        }
      }

      if(this.radarSettings.storm_tracks > 0) {
        try {
          await App.radar.loadLatestStormTracks(this.mapRadar.activeTower);
        }
        catch(e) {
          console.log('Failed to load latest storm tracks')
        }
      }
      else {
        App.radar.clearStormTracks(this.mapRadar.activeTowerId);
      }
    },
    async onLightningChange() {
      if(this.radarTowersStore.isPlaying) {
        try {
          await this.onStopClick()
        }
        catch(e) {
          console.log('Failed to stop playback')
        }
      }

      if(this.radarSettings.lightning > 0) {
        try {
          await App.radar.lightning.load(this.mapRadar.activeTowerId);
        }
        catch(e) {
          console.log('Failed to load lightning data for radar')
        }
        App.radar.lightning.show()
      }
      else {
        App.radar.lightning.clear(this.mapRadar.activeTowerId);
      }
    },
    async onMeltingLayerChange() {
      if(this.radarTowersStore.isPlaying) {
        try {
          await this.onStopClick()
        }
        catch(e) {
          console.log('Failed to stop playback')
        }
      }

      if(this.radarSettings.melting_layer > 0) {
        // await App.radar.lightning.load(this.mapRadar.activeTowerId);
      }
      else {
        // App.radar.lightning.clear();
      }
    },
    showExtraHelp(key) {
      this.$parent.showExtraHelp(key);
    },
    onColortableChangeClick(e) {
      this.$parent.onColortableChangeClick(e);
    },
    onReflectivityUnitChange(e) {
      MapKeeper.forEach(m => {
        const store = m.stores['radar'];

        if(['REF'].includes(store.activeProductGroup.id)) {
          // Hack to force the radar value inspector to redraw
          // Because it listens on 'scanDatetime' for changes
          const scanDatetime = store.scanDatetime;
          store.$patch({
            scanDatetime: "0000-00-00 00:00:00"
          })
          this.$nextTick(() => {
            store.$patch({
              scanDatetime: JSON.parse(JSON.stringify(toRaw(scanDatetime)))
            })
          })
        }
      });
    },
    onVelocityUnitChange(e) {
      MapKeeper.forEach(m => {
        const store = m.stores['radar'];

        if(['VEL', 'SRV'].includes(store.activeProductGroup.id)) {
          // Hack to force the radar value inspector to redraw
          // Because it listens on 'scanDatetime' for changes
          const scanDatetime = store.scanDatetime;
          store.$patch({
            scanDatetime: "0000-00-00 00:00:00"
          })
          this.$nextTick(() => {
            store.$patch({
              scanDatetime: JSON.parse(JSON.stringify(toRaw(scanDatetime)))
            })
          })
        }
      });
    },
    onTowerIconChange(e) {
      App.radar.changeTowerIcon()
    }
  }
}
</script>

<style scoped>

</style>
