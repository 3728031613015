import MapKeeper from '@/logic/MapKeeper'

export const useMapStyleChanger = () => {
    const change = (style) => {
        const activeStyle = MapKeeper.mapboxMapsFirst().getStyle();
        const layers = activeStyle.layers;
        const sources = activeStyle.sources;

        // console.log(layers, sources)

        layers.forEach(l => {
          if(! l.id.startsWith('ww-')) {
            MapKeeper.removeLayer(l.id)
          }
        });

        for(const key in sources) {
          if(! key.startsWith('ww-')) {
            MapKeeper.removeSource(key)
          }
        }

        MapKeeper.forEach(m => {
          m.style._loadSprite(style.sprite);

          m.style.glyphManager.setURL(style.glyphs, m.style.scope);
        })

        for(const key in style.sources) {
          MapKeeper.addSource(key, style.sources[key])
        }

        // TODO
        // Look for these specific layer IDs could be improved
        // They could potentially change in the future
        // I think this is good enough for now
        const midLevellayerId = style.layers.findIndex(l => l.id === 'land-structure-polygon');

        style.layers.forEach((l, i) => {
          const insertBeforeLayerId = i < midLevellayerId
            ? 'ww-top-middle-satellite-layer'
            : 'ww-tropical-forecast-wind-radii-fill-layer';

          MapKeeper.addLayer(l, insertBeforeLayerId);
        });
    };

    return {
        change
    }
}