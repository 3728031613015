<script setup lang="ts">
import { VueFinalModal } from 'vue-final-modal'

defineProps<{
    title?: string,
    closeButtonWrapperClass?: string,
    closeIconClass?: string
}>()

const emit = defineEmits<{
    (e: 'update:modelValue', modelValue: boolean): void
}>()
</script>

<template>
    <VueFinalModal content-transition="vfm-fade" overlay-transition="vfm-fade" content-class="absolute inset-0"
        :lock-scroll="false" @update:model-value="(val) => emit('update:modelValue', val)">
        <div class="overflow-auto h-full flex justify-center pt-[calc(10%)] md:pt-[calc(5%)]" @click.self="() => emit('update:modelValue', false)">
            <div>
                <slot />
            </div>
        </div>
    </VueFinalModal>
</template>
