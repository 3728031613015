import ToDesktop from '@/logic/ToDesktop'

const LOCAL_STORAGE_URL_HASH_KEY = 'url_hash';

class UrlHash extends URLSearchParams {
  constructor(useLocalStorage = false) {
    if(useLocalStorage) {
      super(window.localStorage.getItem(LOCAL_STORAGE_URL_HASH_KEY))
    }
    else {
      super(window.location.hash.substr(1));
    }
  }

  save(force = false) {
    // For whatever reason, Mapbox uses non-url encoded characters for the map position in the url
    const hash = this.toString().replaceAll('%2F', '/')

    if(window.location.hash.substr(1) !== hash || force) {
      // Update url hash
      window.location.hash = hash;
    }

    if(ToDesktop.isToDesktop()) {
      if(window.localStorage.getItem(LOCAL_STORAGE_URL_HASH_KEY) !== hash || force) {
        // Write to local storage (used for to-desktop)
        window.localStorage.setItem(LOCAL_STORAGE_URL_HASH_KEY, hash);
      }
    }
  }

  setFromLocalStorage() {
    if(! ToDesktop.isToDesktop()) return;
    if(window.location.hash.length > 0) return;

    const urlHash = window.localStorage.getItem(LOCAL_STORAGE_URL_HASH_KEY);
    if(typeof urlHash === 'string' && urlHash.includes('map=')) {
      window.location.hash = urlHash;
    }
  }
}

export default UrlHash
