export default [
  {
    "type": "Feature",
    "geometry": {
      "type": "Point",
      "coordinates": [
        12.4493,
        55.3262,
        42
      ]
    },
    "properties": {
      "id": "DKSTE",
      "code": "DKSTE",
      "secondary": false,
      "products": [
        "REF",
        "VEL",
        "CC",
        "ZDR",
        "SW"
      ],
      "elevations": [
        0.5,
        0.7,
        2.4,
        1.5,
        1,
        4.5,
        8.5,
        10,
        13,
        15
      ]
    }
  },
  {
    "type": "Feature",
    "geometry": {
      "type": "Point",
      "coordinates": [
        8.552,
        55.1731,
        5
      ]
    },
    "properties": {
      "id": "DKROM",
      "code": "DKROM",
      "secondary": false,
      "products": [
        "REF",
        "VEL",
        "CC",
        "ZDR",
        "SW"
      ],
      "elevations": [
        0.5,
        0.6,
        2.4,
        1.5,
        1,
        4.8,
        8.4,
        10,
        13,
        15
      ]
    }
  },
  {
    "type": "Feature",
    "geometry": {
      "type": "Point",
      "coordinates": [
        10.1365,
        57.4893,
        104
      ]
    },
    "properties": {
      "id": "DKSIN",
      "code": "DKSIN",
      "secondary": false,
      "products": [
        "REF",
        "VEL",
        "CC",
        "ZDR",
        "SW"
      ],
      "elevations": [
        0.5,
        0.7,
        2.4,
        1,
        1.5,
        4.5,
        8.5,
        10,
        13,
        15
      ]
    }
  },
  {
    "type": "Feature",
    "geometry": {
      "type": "Point",
      "coordinates": [
        14.8875,
        55.1127,
        162
      ]
    },
    "properties": {
      "id": "DKBOR",
      "code": "DKBOR",
      "secondary": false,
      "products": [
        "REF",
        "VEL",
        "CC",
        "ZDR",
        "SW"
      ],
      "elevations": [
        0.5,
        0.7,
        2.4,
        1,
        1.5,
        4.8,
        8.4,
        10,
        13,
        15
      ]
    }
  },
  {
    "type": "Feature",
    "geometry": {
      "type": "Point",
      "coordinates": [
        10.585485,
        55.812009,
        24
      ]
    },
    "properties": {
      "id": "DKSAM",
      "code": "DKSAM",
      "secondary": false,
      "products": [
        "REF",
        "VEL",
        "CC",
        "ZDR",
        "SW"
      ],
      "elevations": [
        0.5,
        0.6,
        2.4,
        1.5,
        1,
        4.8,
        8.4,
        10,
        13,
        15
      ]
    }
  }
]