import { useModal, useModalSlot } from 'vue-final-modal'
import SimpleModal from '@/logic/Modals/Templates/Simple.vue'
import ExpoBridge from '@/logic/Expo/ExpoBridge'
import { hasWeatherWiseBridge } from '@/logic/Extra/helpers'

const AlertWrapper = {
  alert: (message, title) => {
    if (hasWeatherWiseBridge()) {
      return ExpoBridge.alert.alert(title, message);
    } else {
      return useModal({
        defaultModelValue: true,
        component: SimpleModal,
        attrs: {
          title: title
        },
        slots: {
            default: `<hr><p>${message}</p>`
        }
      });
    }
  }
}

export default AlertWrapper;