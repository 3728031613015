export default [
  {
    "type": "Feature",
    "geometry": {
      "type": "Point",
      "coordinates": [
        4.79061,
        52.9528,
        52
      ]
    },
    "properties": {
      "id": "NLDHL",
      "code": "NLDHL",
      "secondary": false,
      "products": [
        "REF",
        "VEL",
        "KDP",
        "CC",
        "SW"
      ],
      "elevations": [
        0.3,
        0.8,
        1.2,
        2,
        2.8,
        4.5,
        6,
        8,
        10,
        12
      ]
    }
  },
  {
    "type": "Feature",
    "geometry": {
      "type": "Point",
      "coordinates": [
        5.138,
        51.8371,
        1
      ]
    },
    "properties": {
      "id": "NLHRW",
      "code": "NLHRW",
      "secondary": false,
      "products": [
        "REF",
        "VEL",
        "KDP",
        "CC",
        "SW"
      ],
      "elevations": [
        0.3,
        0.8,
        1.2,
        2,
        2.8,
        4.5,
        6,
        8,
        10,
        12
      ]
    }
  }
]