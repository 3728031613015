import { getList, CATEGORY_STORMBASED, CATEGORY_WINTER, CATEGORY_HYDRO, CATEGORY_WIND_AIR } from '@/data/nws_warning_config.js'

export const weatherAlerts = getList();

export const alertSections = [
  {
    title: 'Warnings',
    significance: 'W',
    alerts: weatherAlerts.filter(alert => alert.significance === 'W')
  },
  {
    title: 'Watches',
    significance: 'A',
    alerts: weatherAlerts.filter(alert => alert.significance === 'A')
  },
  {
    title: 'Advisories',
    significance: 'Y',
    alerts: weatherAlerts.filter(alert => alert.significance === 'Y')
  },
  {
    title: 'Statements',
    significance: 'S',
    alerts: weatherAlerts.filter(alert => alert.significance === 'S')
  }
];

export const tabs = [
  { id: CATEGORY_STORMBASED, label: 'Storm Based' },
  { id: CATEGORY_WINTER, label: 'Winter' },
  { id: CATEGORY_HYDRO, label: 'Hydro' },
  { id: CATEGORY_WIND_AIR, label: 'Wind/Air' },
  // { id: 'marine', label: 'Marine' },
  // { id: 'fire', label: 'Fire' },
];