<template>
  <div>
    <WspButton />
    <ToaButton :storm="storm" />
    <button type="button" class="font-bold rounded-lg text-xs sm:text-sm px-2.5 py-1 text-center me-2 mt-2" :class="{
      'text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl': !forecastWindRadiiActive,
      'border border-blue-700 text-purple-600': forecastWindRadiiActive,
    }" @click.prevent="onForecastWindRadiiClick($event)"><Icon icon="oui:radius" class="inline mr-1 size-5" />Forecast Wind Radii</button>
    <button type="button" class="bg-[#F6B91A] text-[#17274F] hover:bg-[#17274F] hover:text-[#F6B91A] dark:hover:bg-[#F6B91A] dark:hover:text-[#17274F] rounded-lg text-xs px-2.5 py-0.5 relative top-[-2px] text-center me-2 mb-2 font-medium transition-all duration-200 transform active:scale-90 active:shadow-[0_0_0_2px_#17274F,0_0_0_4px_#F6B91A] dark:active:shadow-[0_0_0_2px_#F6B91A,0_0_0_4px_#17274F]" @click.prevent="onShareClick($event)"><Icon icon="ion:share-outline" class="inline mr-1 size-5" />Share</button>
  </div>
  <div class="border-b border-gray-200 hidden sm:block">
    <ul class="flex flex-wrap -mb-px text-sm font-medium text-center text-gray-500">
      <li v-for="t in tabs" class="me-2">
        <a href="#" @click.prevent="onTabClick($event, t.id)" class="inline-flex items-center justify-center p-4 border-b-2 rounded-t-lg group" :class="{
            'text-blue-600 border-blue-600 active': this.tab === t.id,
            'border-transparent hover:text-gray-600 hover:border-gray-300': this.tab !== t.id
          }" v-text="t.name"></a>
      </li>
      <li>
        <a href="#"class="inline-flex items-center justify-center p-4 rounded-t-lg group hover:text-gray-600" @click.prevent="showHelp($event)"><Icon icon="carbon:help" class="size-5 align-middle" /></a>
      </li>
    </ul>
  </div>

  <div class="sm:hidden">
    <div class="flex">
      <select class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-1" v-model="tab">
        <option v-for="t in tabs" :value="t.id" v-text="t.name"></option>
      </select>
      <a href="#"class="inline-flex items-center justify-center p-2 pl-4" @click.prevent="showHelp($event)"><Icon icon="carbon:help" class="size-5 align-middle" /></a>
    </div>
  </div>

  <p v-html="storm[this.tab].replace(/(?:\r\n|\r|\n)/g, '<br>')"></p>

  <hr class="my-4">
  <p class="text-sm text-slate-600 dark:text-slate-400">The information is provided to us in realtime from the National Weather Service (NWS), specifically from the National Hurricane Center (NHC). Last update at: {{ lastUpdatedAt }}</p>
</template>

<script>
import { Icon } from '@iconify/vue'
import { useModal, useModalSlot } from 'vue-final-modal'
import moment from 'moment'

import WspButton from './WspButton.vue'
import ToaButton from './ToaButton.vue'

import modals from '@/logic/Modals/Helper'
import SimpleModal from '@/logic/Modals/Templates/Simple.vue'
import StormProductHelpModal from '@/logic/Tropical/StormProductHelp.vue'

import { useTropicalStore } from '@/stores/tropical'

import App from '@/logic/App'

export default {
  name: 'Discussion',
  props: ['storm'],
  emits: ['close'],
  components: {
    Icon,
    WspButton,
    ToaButton
  },
  data() {
    return {
      tab: 'public_advisory',
      tabs: [{
        id: 'public_advisory',
        name: 'Public Advisory',
        help: `A Public Advisory is a key text product issued by both the National Weather Service (NWS) and the National Hurricane Center (NHC) to provide critical information to the public about ongoing or forecasted tropical cyclones, hurricanes, or other significant weather events. These advisories typically contain details about the storm's current location, movement, intensity, and any potential hazards such as heavy rainfall, strong winds, or storm surges. Additionally, the Public Advisory may outline warnings or watches in effect for specific areas, recommendations for preparedness actions, and projected paths or updates on the storm's expected evolution. Public Advisories are updated regularly as conditions change, serving as an essential tool for keeping the public informed and safe during hazardous weather events.`
      },{
        id: 'forecast_discussion',
        name: 'Forecast Discussion',
        help: `A Forecast Discussion is a detailed text product issued by both the National Weather Service (NWS) and the National Hurricane Center (NHC) that provides an in-depth analysis of the meteorological reasoning behind a forecast, particularly for significant weather events like tropical cyclones or hurricanes. This product explains the thought process of forecasters, including how current observations, computer models, and other data sources contribute to the forecast's development. It discusses uncertainties, potential changes in track or intensity, and why specific warnings or watches have been issued. The Forecast Discussion offers valuable insights for weather professionals, emergency managers, and the general public who seek to understand the technical aspects behind a weather forecast or storm update.`
      },{
        id: 'forecast_advisory',
        name: 'Forecast Advisory',
        help: `A Forecast Advisory is a text product issued by the National Hurricane Center (NHC) that provides the latest forecast information regarding a tropical cyclone's expected path, intensity, and size. This advisory typically includes the storm's current position, wind speeds, and central pressure, as well as a forecast track for the next several hours or days. The Forecast Advisory is designed to give a clear, concise update on the tropical cyclone's expected movement and impacts, offering essential guidance for decision-makers and the general public in affected areas. It also includes information about any active watches or warnings, helping communities to prepare for potential threats like high winds, storm surges, or heavy rainfall. Forecast Advisories are issued regularly as new data becomes available and conditions evolve.`
      },{
        id: 'wind_speed_probabilities',
        name: 'Wind Speed Probabilities',
        help: `Wind Speed Probabilities is a specialized text product issued by the National Hurricane Center (NHC) that provides information on the likelihood of experiencing tropical storm-force, hurricane-force, or other specific wind speeds at various locations over a given time period. This product uses a probabilistic approach to communicate the chances that sustained winds at or above certain thresholds will affect a particular area as a tropical cyclone approaches.

The Wind Speed Probabilities are based on a combination of the forecast track, intensity, and size of the storm, as well as the inherent uncertainties in those forecasts. This product helps emergency managers, decision-makers, and the public understand the potential for damaging winds at specific locations, aiding in preparedness actions and decision-making. The probabilities are typically displayed in tabular or graphical form and updated regularly to reflect changes in the storm's forecast.`
      }]
    }
  },
  methods: {
    async onForecastWindRadiiClick(e) {
      this.$emit('close');

      if(this.storm.id === this.tropicalStore.fwr) {
        App.tropical.clearFwr();
      }
      else {
        this.tropicalStore.setFwr(this.storm.id);

        await App.tropical.fetchAndRenderFwr(this.storm);
      }
    },
    onShareClick(e) {
      modals.share();
    },
    onTabClick(e, tab) {
      this.tab = tab;

      return false;
    },
    showHelp(e) {
      const t = this.tabs.find(t => t.id === this.tab)

      const modal = useModal({
        defaultModelValue: true,
        component: SimpleModal,
        attrs: {
          title: t.name
        },
        slots: {
          default: useModalSlot({
            component: StormProductHelpModal,
            attrs: {
              text: t.help,
              onClose() {
                modal.close()
              },
            }
          })
        },
      })
    }
  },
  computed: {
    tropicalStore: () => useTropicalStore(),
    lastUpdatedAt() {
      return moment.utc(this.storm.last_update_at).local().format('YYYY-MM-DD HH:mm:ss')
    },
    forecastWindRadiiActive() {
      return this.tropicalStore.fwr === this.storm.id;
    }
  }
}
</script>
