<script setup>
import { Icon } from '@iconify/vue'
import { formatLocalDate } from '@/tools/helpers'
import moment from 'moment';
import { vOnClickOutside } from '@vueuse/components'

const emit = defineEmits(['close', 'share']);
</script>

<template>
  <div class="mb-10 mx-auto hyphens-auto w-full md:max-w-[60%] relative" v-on-click-outside="[() => emit('close')]">
    <!-- Profile Header -->
         <div class="absolute top-0 right-0 z-50">
      <button @click="emit('close')" class="p-[5px]">
        <Icon icon="clarity:window-close-line" class="size-8 sm:size-9 text-white" />
      </button>
    </div>
    <div class="bg-gradient-to-tr from-red-500 to-black rounded-t-lg p-4 md:p-8 text-white relative overflow-hidden">
      <div class="absolute top-0 right-0 opacity-10">
        <Icon icon="game-icons:binoculars" class="w-48 h-48" />
      </div>
      <div class="flex items-center space-x-4">
        <div>
          <h2 class="text-3xl">
            <span class="font-bold capitalize" v-if="spotter.last">{{ spotter.last }}</span>
            <span class="font-bold capitalize" v-if="spotter.first && spotter.last">, </span>
            <span class="font-bold capitalize" v-if="spotter.first">{{ spotter.first }}</span>
          </h2>
          <p class="flex items-center mt-2">
            <Icon icon="mdi:clock-outline" class="min-w-5 min-h-5 mr-2" />
            Last update: {{ formatLocalDate(moment(spotter.report_at), 'HH:mm') }}
          </p>
        </div>
      </div>
    </div>

    <!-- Main Content -->
    <div class="bg-white dark:bg-ww-blue shadow-xl rounded-b-lg">
      <div class="p-4 md:p-8">
        <div class="grid md:grid-cols-2 gap-8">
          <!-- Location Card -->
          <div class="bg-gray-50 dark:bg-ww-blue-200 rounded-xl p-6">
            <div class="flex items-center mb-4">
              <Icon icon="mdi:map-marker-radius" class="w-6 h-6 md:w-8 md:h-8 text-red-500 mr-2" />
              <h3 class="text-lg font-semibold text-gray-800 dark:text-gray-200">Location Details</h3>
            </div>
            <div class="space-y-3 text-wrap">
              <div class="flex items-center">
                <Icon icon="mdi:latitude" class="min-w-5 min-h-5 text-gray-500 dark:text-gray-400 mr-2" />
                <span class="text-gray-600 dark:text-gray-300 whitespace-pre-wrap">{{ spotter.lat.toFixed(6) }}° N</span>
              </div>
              <div class="flex items-center">
                <Icon icon="mdi:longitude" class="min-w-5 min-h-5 text-gray-500 dark:text-gray-400 mr-2" />
                <span class="text-gray-600 dark:text-gray-300 whitespace-pre-wrap">{{ spotter.lon.toFixed(6) }}° W</span>
              </div>
              <div class="flex items-center" v-if="spotter.elev !== 0">
                <Icon icon="mdi:elevation-rise" class="min-w-5 min-h-5 text-gray-500 dark:text-gray-400 mr-2" />
                <span class="text-gray-600 dark:text-gray-300 whitespace-pre-wrap">{{ spotter.elev }}m elevation</span>
              </div>
              <div class="flex items-center" v-if="spotter.dir > 0">
                <Icon icon="mdi:compass" class="min-w-5 min-h-5 text-gray-500 dark:text-gray-400 mr-2" />
                <span class="text-gray-600 dark:text-gray-300 whitespace-pre-wrap">{{ spotter.dir }}° direction</span>
              </div>
            </div>
          </div>

          <!-- Contact Card -->
          <div class="bg-gray-50 dark:bg-ww-blue-200 rounded-xl p-6"
            v-if="spotter.email || spotter.phone || spotter.ham || spotter.freq">
            <div class="flex items-center mb-4">
              <Icon icon="mdi:contact-phone" class="w-6 h-6 md:w-8 md:h-8 text-blue-500 mr-2" />
              <h3 class="text-lg font-semibold text-gray-800 dark:text-gray-200">Contact Information</h3>
            </div>
            <div class="space-y-3">
              <a class="flex items-center group" v-if="spotter.email">
                <Icon icon="mdi:email" class="min-w-5 min-h-5 text-gray-500 dark:text-gray-400 mr-2" />
                <span class="text-gray-600 dark:text-gray-300 whitespace-pre-wrap">{{ spotter.email }}</span>
              </a>
              <a class="flex items-center group" v-if="spotter.phone">
                <Icon icon="mdi:phone" class="min-w-5 min-h-5 text-gray-500 dark:text-gray-400 mr-2" />
                <span class="text-gray-600 dark:text-gray-300 whitespace-pre-wrap">{{ spotter.phone }}</span>
              </a>
              <div v-if="spotter.ham" class="flex items-center">
                <Icon icon="mdi:radio" class="min-w-5 min-h-5 text-gray-500 dark:text-gray-400 mr-2" />
                <span class="text-gray-600 dark:text-gray-300 whitespace-pre-wrap">{{ spotter.ham }}</span>
              </div>
              <div v-if="spotter.freq" class="flex items-center">
                <Icon icon="mdi:radio-tower" class="min-w-5 min-h-5 text-gray-500 dark:text-gray-400 mr-2" />
                <span class="text-gray-600 dark:text-gray-300 whitespace-pre-wrap">{{ spotter.freq }}</span>
              </div>
            </div>
          </div>
        </div>

        <!-- Additional Info -->
        <div class="mt-8 bg-gray-50 dark:bg-ww-blue-200 rounded-xl p-6"
          v-if="spotter.note || spotter.web || spotter.twitter">
          <div class="flex items-center mb-4">
            <Icon icon="mdi:information" class="w-6 h-6 md:w-8 md:h-8 text-purple-500 mr-2" />
            <h3 class="text-lg font-semibold text-gray-800 dark:text-gray-200">Additional Information</h3>
          </div>
          <div class="space-y-3">
            <div v-if="spotter.note" class="flex items-center">
              <Icon icon="mdi:note-text" class="min-w-5 min-h-5 text-gray-500 dark:text-gray-400 mr-2" />
              <span class="text-gray-600 dark:text-gray-300 whitespace-pre-wrap">{{ spotter.note }}</span>
            </div>
            <a v-if="spotter.web" class="flex items-center group">
              <Icon icon="mdi:web" class="min-w-5 min-h-5 text-gray-500 dark:text-gray-400 mr-2" />
              <div class="text-gray-600 dark:text-gray-300 whitespace-pre-wrap">{{ spotter.web }}</div>
            </a>
            <a v-if="spotter.twitter" class="flex items-center group">
              <Icon icon="mdi:twitter" class="min-w-5 min-h-5 text-gray-500 dark:text-gray-400 mr-2" />
              <span class="text-gray-600 dark:text-gray-300 whitespace-pre-wrap">{{ spotter.twitter }}</span>
            </a>
          </div>
        </div>
      </div>

      <!-- Adjoined Footer -->
      <div class="border-t border-gray-100 dark:border-ww-blue-200">
        <div class="px-8 py-4">
          <p class="mt-2 text-sm text-gray-600 dark:text-gray-400">
            The data provided by <a href="https://www.spotternetwork.org/" class="font-medium text-blue-600 dark:text-blue-500 hover:underline" target="_blank" rel="noopener nofollow">Spotter Network</a> is intended solely for
            storm tracking and response
            efforts. Contact
            with spotters should only occur when necessary and must remain professional, respectful, and relevant to
            these activities. Respecting the privacy of individuals by refraining from sharing or misusing personal
            details is essential to maintaining trust and the integrity of this collaborative network.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PlaybackModal',
  props: ['feature'],
  computed: {
    spotter() {
        return this.feature.properties
    }
  },
  methods: {

  }
}
</script>

<style scoped>
.bg-gradient-to-r {
  position: relative;
}

.bg-gradient-to-r::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
}

.peer ~ div p {
  max-height: 3rem;
  transition: max-height 0.3s ease-in-out;
}

.peer:checked ~ div p {
  max-height: 1000px;
  transition: max-height 0.5s ease-in-out;
}
</style>
