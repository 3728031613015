<script setup lang="ts">
import { ref, onMounted, onUnmounted } from 'vue'
import { Icon } from '@iconify/vue'
import ColorPicker from './ColorPicker.vue'
import BrushSizeSlider from './BrushSizeSlider.vue'
import { useDrawingSettingsStore } from '@/stores/settings/drawing'
import { storeToRefs } from 'pinia'
import { OnClickOutside } from '@vueuse/components'

const drawingStore = useDrawingSettingsStore()
const { lineWidth } = storeToRefs(drawingStore)

interface ToolbarEmits {
  (event: 'update:color', value: Array<number>): void
  (event: 'update:brushSize', value: number): void
  (event: 'undo'): void
  (event: 'redo'): void
  (event: 'toggleEraser', value: boolean): void
  (event: 'toggleLock', value: boolean): void
  (event: 'destroy'): void
  (event: 'close'): void
}

const emit = defineEmits<ToolbarEmits>()

const brushSize = ref(lineWidth.value)
const isEraser = ref(false)
const isLocked = ref(true)
const isBrushDropdownOpen = ref(false)
const isDragging = ref(false)
const toolbarRef = ref<HTMLDivElement | null>(null)
const position = ref({ x: window.innerWidth - (window.innerWidth / 2) - 180, y: window.innerHeight - 200 })
const dragOffset = ref({ x: 0, y: 0 })
const isMobile = ref(false)
const dropdownOpenPosition = ref<string>('bottom')

const updateColor = (color: number[]) => {
  emit('update:color', color)
}

const updateBrushSize = (size: number) => {
  brushSize.value = size
  emit('update:brushSize', size)
}

const toggleEraser = () => {
  isEraser.value = !isEraser.value
  emit('toggleEraser', isEraser.value)
}

const toggleLock = () => {
  isLocked.value = !isLocked.value
  emit('toggleLock', isLocked.value)
}

const positionDropdown = () => {
  dropdownOpenPosition.value = (window.innerHeight - position.value.y) > 250 ? 'top' : 'bottom'
}

const toggleBrushDropdown = (event: MouseEvent | TouchEvent) => {
  // Prevent event from bubbling up to drag handler
  event.stopPropagation()
  positionDropdown()
  isBrushDropdownOpen.value = !isBrushDropdownOpen.value
}

const constrainPosition = (x: number, y: number) => {
  const toolbar = toolbarRef.value
  if (!toolbar) return { x, y }

  const rect = toolbar.getBoundingClientRect()
  const maxX = window.innerWidth - rect.width
  const maxY = window.innerHeight - rect.height

  return {
    x: Math.max(0, Math.min(x, maxX)),
    y: Math.max(0, Math.min(y, maxY))
  }
}

const handleDragStart = (event: MouseEvent | TouchEvent) => {
  const target = event.target as HTMLElement
  // Check if the click/touch is on any interactive elements
  if (target.closest('.tool-button, .color-picker-container, .slider-track, .brush-size-container')) {
    return
  }

  isDragging.value = true

  const clientX = 'touches' in event ? event.touches[0].clientX : event.clientX
  const clientY = 'touches' in event ? event.touches[0].clientY : event.clientY

  dragOffset.value = {
    x: clientX - position.value.x,
    y: clientY - position.value.y
  }

  event.preventDefault()
}

const handleDrag = (event: MouseEvent | TouchEvent) => {
  if (!isDragging.value) return

  const clientX = 'touches' in event ? event.touches[0].clientX : event.clientX
  const clientY = 'touches' in event ? event.touches[0].clientY : event.clientY

  const newPosition = constrainPosition(
    clientX - dragOffset.value.x,
    clientY - dragOffset.value.y
  )

  position.value = newPosition
}

const handleDragEnd = () => {
  isDragging.value = false
}

const checkMobile = () => {
  isMobile.value = window.innerWidth <= 768
}

const handleResize = () => {
  checkMobile()
  position.value = constrainPosition(position.value.x, position.value.y)
}

onMounted(() => {
  handleResize()
  window.addEventListener('resize', handleResize)
  document.addEventListener('mousemove', handleDrag)
  document.addEventListener('mouseup', handleDragEnd)
  document.addEventListener('touchmove', handleDrag, { passive: false, capture: true })
  document.addEventListener('touchend', handleDragEnd, { capture: true })
  document.addEventListener('touchcancel', handleDragEnd, { capture: true })
})

onUnmounted(() => {
  window.removeEventListener('resize', handleResize)
  document.removeEventListener('mousemove', handleDrag)
  document.removeEventListener('mouseup', handleDragEnd)
  document.removeEventListener('touchmove', handleDrag, { capture: true })
  document.removeEventListener('touchend', handleDragEnd, { capture: true })
  document.removeEventListener('touchcancel', handleDragEnd, { capture: true })
})
</script>

<template>
  <div
    ref="toolbarRef"
    class="fixed flex items-center py-1 px-2 gap-2 bg-gray-50 dark:bg-ww-blue rounded-lg shadow-sm select-none cursor-move z-[26] max-w-[calc(100vw)] touch-none"
    :class="{
      'opacity-90 shadow-md': isDragging,
      'cursor-default': isLocked
    }"
    :style="{
      left: `${position.x}px`,
      top: `${position.y}px`
    }"
    @mousedown="handleDragStart"
    @touchstart="handleDragStart"
  >
    <div class="flex items-center px-0 text-gray-400 dark:text-gray-500 cursor-move">
      <Icon icon="mdi:drag" class="w-4 h-4" />
    </div>

    <div class="flex items-center gap-1">
      <div class="flex items-center">
        <ColorPicker
          @on-open="isBrushDropdownOpen = false"
          @update:modelValue="updateColor"
        />
      </div>

      <OnClickOutside @trigger="isBrushDropdownOpen = false">
        <div class="relative brush-size-container">
          <button
            @click.prevent="toggleBrushDropdown"
            class="flex items-center gap-1 h-9 md:h-11 sm:h-10 min-w-[80px] p-2 border border-gray-200 dark:border-gray-700 rounded bg-white dark:bg-ww-blue-200 text-gray-700 dark:text-gray-300 transition-all duration-200 hover:bg-gray-100 dark:hover:bg-ww-blue-100 hover:border-gray-300 dark:hover:border-gray-600 touch-manipulation"
            :class="{
              'bg-gray-200 dark:bg-gray-600 text-gray-800 dark:text-gray-200 border-gray-400 dark:border-gray-500': isBrushDropdownOpen,
              'h-10 min-w-[70px]': isMobile
            }"
            title="Brush Size"
          >
            <Icon icon="mdi:brush" class="w-5 h-5" />
            <span class="text-sm">{{ brushSize }}</span>
            <Icon
              :icon="isBrushDropdownOpen ? 'mdi:chevron-up' : 'mdi:chevron-down'"
              class="w-4 h-4 ml-auto"
            />
          </button>

          <div v-show="isBrushDropdownOpen" class="absolute left-0 bg-white dark:bg-ww-blue rounded-lg shadow-lg p-2 w-60 z-10" :class="{ 'top-[calc(100%+0.5rem)]': dropdownOpenPosition === 'top', 'bottom-[calc(100%+0.5rem)]': dropdownOpenPosition === 'bottom' }">
            <BrushSizeSlider
                v-model="brushSize"
                @update:modelValue="updateBrushSize"
              />
          </div>
        </div>
      </OnClickOutside>
    </div>

    <div class="flex items-center">
      <button
        @click="emit('undo')"
        class="tool-button undo-button"
        title="Undo"
      >
        <Icon icon="mdi:undo" class="w-5 h-5" />
      </button>

      <button
        @click="emit('redo')"
        class="tool-button redo-button"
        title="Redo"
      >
        <Icon icon="mdi:redo" class="w-5 h-5" />
      </button>
    </div>

    <div class="flex items-center gap-2">
      <button
        @click="toggleLock"
        class="tool-button"
        :class="{ '!bg-gray-200 dark:!bg-blue-700 !text-gray-800 dark:!text-gray-200 !border-gray-400 dark:!border-gray-500': isLocked }"
        title="Lock Toolbar Position"
      >
        <Icon :icon="isLocked ? 'mdi:lock' : 'mdi:lock-open'" class="w-5 h-5" />
      </button>

      <button
        @click.prevent="emit('destroy')"
        @dblclick.prevent="emit('close')"
        @doubletap.prevent="emit('close')"
        class="tool-button !text-red-500 !hover:bg-red-50 dark:!hover:bg-red-900/20 !hover:border-red-500"
        title="Delete Drawing"
      >
        <Icon icon="mdi:delete" class="w-5 h-5" />
      </button>
      <button @click="emit('close')" class="tool-button">
        <Icon icon="mdi:close" class="w-6 h-6" />
      </button>
    </div>
  </div>
</template>

<style scoped>
/* Keep only the essential custom styles that can't be handled by Tailwind */
.tool-button {
  @apply flex items-center justify-center h-11 min-w-[44px] p-2 border border-gray-200 dark:border-gray-700 rounded bg-white dark:bg-ww-blue-200 text-gray-700 dark:text-gray-300 cursor-pointer transition-all duration-200 hover:bg-gray-100 dark:hover:bg-[#243a6d] hover:border-gray-300 dark:hover:border-gray-600 touch-manipulation;
}

.undo-button {
  @apply rounded-r-none border-r-0
}
.redo-button {
  @apply rounded-l-none
}

@media (max-width: 768px) {
  .tool-button {
    @apply h-10 min-w-[40px] p-1.5;
  }
}

@media (max-width: 380px) {
  .tool-button {
    @apply h-9 min-w-[36px] p-1;
  }
}
</style>