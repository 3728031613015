<script setup lang="ts">
import { useMapSettingsStore } from '@/stores/settings/map';
import { useMapStyle } from '@/logic/Composables/MapStyle'
import ToggleSwitch from '@/components/UI/Form/ToggleSwitch.vue';
import Plus from '@/components/Labels/Plus.vue';
import { computed, ref } from 'vue';
import { MAP_PROJECTION_MERCATOR, MAP_PROJECTION_GLOBE, APP_VERSION_1_2_0 } from '@/tools/constants'
import { useSubscription, useUpgradeNotifcation } from '@/logic/Composables/Subscription'
import App from '@/logic/App'

const mapSettingsStore = useMapSettingsStore();
const subscription = useSubscription();

const mapStyle = useMapStyle();

const useSatelliteStyle = () => {
    mapStyle.satelliteTiles()
}

const useDefaultStyle = () => {
    mapStyle.defaultTiles()
}

let projectionKey = ref(0);

const projection = computed({
    get: () => mapSettingsStore.getProjection === MAP_PROJECTION_GLOBE,
    set: (value) => {
        if (value === true && subscription.isAtleast(subscription.tiers.PLUS)) {
            mapStyle.setProjection(MAP_PROJECTION_GLOBE)
        } else if (value === true && !subscription.isAtleast(subscription.tiers.PLUS)) {
            mapStyle.setProjection(MAP_PROJECTION_MERCATOR)
            useUpgradeNotifcation('GLOBE_PROJECTION')
            projectionKey.value++ // Force UI to redraw
        } else {
            mapStyle.setProjection(MAP_PROJECTION_MERCATOR)
        }
    }
})
</script>

<template>
    <div class="mt-6 dark:text-slate-300">
        <h3 class="text-xl font-bold text-gray-800 mb-4 text-gray-800 dark:text-slate-300">Style</h3>
        <div class="flex gap-10 justify-center">
            <div>
                <button @click.prevent="useDefaultStyle">
                    <img src="@/assets/map-tiles/default-tile.png" class="w-24 md:w-32 h-auto rounded-lg" :class="{
                        'border-sky-400 border-4': mapSettingsStore.tileStyle === 'default'
                    }" />
                    <div class="text-sm">Default</div>
                </button>
            </div>
            <div>
                <button @click.prevent="useSatelliteStyle">
                    <img src="@/assets/map-tiles/satellite-tile.png" class="w-24 md:w-32 h-auto rounded-lg" :class="{
                        'border-sky-400 border-4': mapSettingsStore.tileStyle === 'satellite'
                    }" />
                    <div class="text-sm">Satellite</div>
                </button>
            </div>
        </div>
        <div v-if="App.shouldShowFeatures(APP_VERSION_1_2_0)" class="mt-5">
            <h3 class="text-xl font-bold text-gray-800 dark:text-slate-300 mb-4 inline sm:block">Globe Projection  <span class="ml-1"><Plus /></span></h3>
            <ToggleSwitch v-model="projection" class="sm:float-none float-right top-1" :key="projectionKey" />
            <div class="clearfix"></div>
        </div>
    </div>
</template>