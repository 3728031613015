export default [
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          13.8178,
          49.6583,
          916
        ]
      },
      "properties": {
        "id": "CZBRD",
        "code": "CZBRD",
        "secondary": false,
        "products": ["REF", "VEL", "CC", "ZDR", "SW"],
        "elevations": [0.1, 0.5, 0.9, 1.3, 1.7, 2.2, 3.2, 4.5, 6.3, 8.7]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          16.7885,
          49.5011,
          767
        ]
      },
      "properties": {
        "id": "CZSKA",
        "code": "CZSKA",
        "secondary": false,
        "products": ["REF", "VEL", "CC", "ZDR", "SW"],
        "elevations": [0.1, 0.5, 0.9, 1.3, 1.7, 2.2, 3.2, 4.5, 6.3, 8.7]
      }
    }/*,
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          17.574166666666667,
          49.31472222222222,
          240.0
        ]
      },
      "properties": {
        "id": "CZHOL",
        "code": "CZHOL",
        "secondary": false,
        "products": ["REF"],
        "elevations": [0.0, 1.0, 2.5, 4.0, 5.5, 7.0, 9.0, 12.0, 16.0, 25.0]
      }
    }*/
  ]