import LocationModule from './modules/LocationModule';
import DownloadModule from './modules/DownloadModule';
import AlertModule from './modules/AlertModule';
import Paywall from './modules/Paywall';
import WakeLockModule from './modules/WakeLockModule';
import Customer from './modules/CustomerModule';

const ExpoBridge = {
  location: LocationModule,
  download: DownloadModule,
  alert: AlertModule,
  paywall: Paywall,
  wakeLock: WakeLockModule,
  customer: Customer
};

export default ExpoBridge;
