import modals from '@/logic/Modals/Helper'
import { useRecordingStore } from '@/stores/recording'
import { watchUserPosition, stopWatchingPosition, locateUser, locationState } from '@/tools/locationUtils';
import { useDrawingStore } from '@/stores/drawing';
import { useAppStore } from '@/stores/app';
import { hasWeatherWiseBridge } from '@/logic/Extra/helpers'
import { watch, ref, readonly } from 'vue';
import { useThrottleFn } from '@vueuse/core';
import MapKeeper from '@/logic/MapKeeper'
import App from '@/logic/App'
import UserSocket from '@/logic/UserSocket'

export const LocationMode = {
    STATIC: 'static',
    DRIVING: 'driving'
};

const state = ref({
    locationMode: null,
})

let unwatchPosition = null;
let isAnimating = true;

let moveMapTimestamp = null;

const handleDragEnd = () => moveMapTimestamp = Date.now();

const removeMapListeners = () => {
    MapKeeper.off('dragend', handleDragEnd);
};

export function startWakeLock() {
    useAppStore().activateWakeLock()
}

export function stopWakeLock() {
    useAppStore().deactivateWakeLock()
}

export function shareMap() {
    modals.share()
}

export function screenshot() {
    App.screenshot.take()
}

export function recordGif() {
    App.gif.record()
    useRecordingStore().start('GIF')
}

export function recordVideo() {
    App.mp4.record()
    useRecordingStore().start('MP4')
}

export async function locateMe() {
    if(locationState.value.isLocating) return;
    try {
        await locateUser();
    } catch (error) {
        if (hasWeatherWiseBridge()) {
            window._alert(error.message, 'Location Error');
        }
        console.error(error);
    }
}

async function toggleLocationTracking() {
    if (locationState.value.isLocating) return;

    try {
        MapKeeper.on('dragend', handleDragEnd);
        let isFirstUpdate = true;

        // Watch for position updates and update map accordingly
        unwatchPosition = watch(() => locationState.value.userPosition, async (position) => {
            if (!position) return;

            const coords = position.coords;
            console.log('Position update:', { isFirstUpdate });

            if (isFirstUpdate) {
                isAnimating = true;
                // First position - fly to location with mode-specific zoom
                MapKeeper.flyTo({
                    center: [coords.longitude, coords.latitude],
                    zoom: state.value.locationMode === LocationMode.DRIVING ? 14 : 8,
                    essential: true
                });

                try {
                    // Only need this event on one map
                    await new Promise(resolve => MapKeeper.mapboxMapsFirst().once('moveend', resolve));
                } catch (e) {
                    console.error('Waiting for map move ending failed')
                }

                isAnimating = false;
                // Only set isFirstUpdate to false after animation completes
                isFirstUpdate = false;

                // Try to turn on closest radar
                try {
                    App.radar.turnOnClosestRadar([coords.longitude, coords.latitude]);
                } catch (e) {
                    console.warn('Failed to turn on radar:', e);
                }
                return;
            }

            if (state.value.locationMode === LocationMode.DRIVING) {
                // Throttle the map updates
                throttleCenterMap(coords);

                UserSocket.updateLocation({
                    latitude: coords.latitude,
                    longitude: coords.longitude
                })
            }
        }, { immediate: true });


        await watchUserPosition();
    } catch (error) {
        removeMapListeners();
        // This will "alert" user-friendly messages sent back from the native app
        if (hasWeatherWiseBridge()) {
            window._alert(error.message, 'Location Error');
        }
        console.error(error);
    }
}

// Create a debounced function for position updates
const throttleCenterMap = useThrottleFn((coords) => {
    const ut = Date.now();
    // Skip update if user moved the map within the last 5 seconds
    if ((moveMapTimestamp && (ut - moveMapTimestamp < 5000)) || isAnimating) return;

    const position = [coords.longitude, coords.latitude]

    MapKeeper.easeTo({
        center: position,
        duration: 1000
    });

    // Try to turn on closest radar
    try {
        App.radar.turnOnClosestRadar(position);
    } catch (e) {
        console.warn('Failed to turn on radar:', e);
    }
}, 1000); // 1s throttle

async function turnOffWatchingPosition() {
    if (unwatchPosition) {
        unwatchPosition();
        unwatchPosition = null;
    }

    removeMapListeners();
    stopWatchingPosition();
    await stopWakeLock();

    state.value.locationMode = null
}

// Wrapper functions to maintain existing API
export async function toggleWatchMyPosition() {
    if (state.value.locationMode === LocationMode.STATIC && locationState.value.isWatching) {
        turnOffWatchingPosition()
    } else {
        state.value.locationMode = LocationMode.STATIC;
        await toggleLocationTracking();
    }
}

export async function toggleDrivingMode() {
    if (state.value.locationMode === LocationMode.DRIVING && locationState.value.isWatching) {
        turnOffWatchingPosition()
    } else {
        state.value.locationMode = LocationMode.DRIVING;
        await startWakeLock();
        await toggleLocationTracking();

        if (App.isNativeApp()) {
            window._alert(`While in Storm Chaser Mode your screen will stay awake. This may cause battery drain.`, `Storm Chaser Mode On`)
        }
    }
}

export function draw() {
    if (!useDrawingStore().isAnnotating) {
        App.drawing.enable();
    }
    else {
        App.drawing.disable();
    }
}

export const menuActionState = readonly(state)
