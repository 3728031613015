import { toCanvas } from 'html-to-image';
import { useRecordingStore } from '@/stores/recording'
import { wait } from '@/tools/helpers'
import App from '@/logic/App'
import {APP_VERSION_1_2_0} from '@/tools/constants'

import MapKeeper from '@/logic/MapKeeper'

export default class Screenshot {
  constructor(map) {
    this.recordingStore = useRecordingStore()
  }

  async takeNativeScreenshot() {
    await wait(500);

    return new Promise((resolve, reject) => {
      const messageHandler = function(event) {
        try {
          const data = JSON.parse(event.data || event.nativeEvent?.data);
          // console.log('Parsed data:', data);
          if (data.type === 'ERROR') {
            window.removeEventListener('message', messageHandler);
            reject(data.message);  // Reject the promise instead of throwing
          } else if (data.type === 'SCREENSHOT_CAPTURED') {
            window.removeEventListener('message', messageHandler);
            resolve();  // Resolve the promise on success
          }
        } catch (error) {
          window.removeEventListener('message', messageHandler);
          reject(error);  // Reject the promise if JSON parsing fails
        }
      };

      window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'CAPTURE_SCREENSHOT' }));
      window.addEventListener('message', messageHandler, true);
    });
  }

  async takeBrowserScreenshot() {
    const canvases = MapKeeper.mapboxMaps().map(map => map.getCanvas())

    const canvasRects = canvases.map(canvas => {
      const rect = canvas.getBoundingClientRect();
      return {
        canvas,
        left: rect.left,
        top: rect.top,
        width: rect.width,
        height: rect.height
      };
    });

    // Compute bounding box in CSS px
    const { minLeft, minTop, maxRight, maxBottom } = canvasRects.reduce(
      (acc, { left, top, width, height }) => {
        const right = left + width;
        const bottom = top + height;
        acc.minLeft = Math.min(acc.minLeft, left);
        acc.minTop = Math.min(acc.minTop, top);
        acc.maxRight = Math.max(acc.maxRight, right);
        acc.maxBottom = Math.max(acc.maxBottom, bottom);
        return acc;
      },
      { minLeft: Infinity, minTop: Infinity, maxRight: -Infinity, maxBottom: -Infinity }
    );

    const totalWidth = maxRight - minLeft;
    const totalHeight = maxBottom - minTop;

    // Step 2: create new canvas in CSS px
    const newCanvas = document.createElement('canvas');
    newCanvas.width = totalWidth;     // in CSS px
    newCanvas.height = totalHeight;   // in CSS px
    const ctx = newCanvas.getContext('2d');

    // Step 3: draw each map, scaling from its actual pixel size to the displayed size
    for (const { canvas, left, top, width, height } of canvasRects) {
      // Source rectangle: entire actual canvas
      const sX = 0;
      const sY = 0;
      const sW = canvas.width;   // actual pixel width
      const sH = canvas.height;  // actual pixel height

      // Destination rectangle: matches bounding box in CSS px
      const dX = left - minLeft;
      const dY = top - minTop;
      const dW = width;  // bounding rect width
      const dH = height; // bounding rect height

      ctx.drawImage(canvas, sX, sY, sW, sH, dX, dY, dW, dH);
    }

    const watermark = new Image();
    watermark.src = 'ww-watermark-300.png'; // Replace with your watermark image path
    await watermark.decode(); // Ensure the image is loaded before using it

    // Add watermark to the top right corner
    const watermarkWidth = 150; // Adjust as needed
    const watermarkHeight = 75; // Adjust as needed
    const marginTop = 20;
    const marginRight = 10;
    ctx.drawImage(
      watermark,
      newCanvas.width - watermarkWidth - marginRight,
      marginTop,
      watermarkWidth,
      watermarkHeight
    );

    const overlays = document.querySelectorAll('.map-overlay');

    for (const overlay of overlays) {
      // Convert the overlay DOM element to a canvas snapshot
      // e.g. using https://github.com/niklasvh/html2canvas or your own `toCanvas`
      const overlayCanvas = await toCanvas(overlay);

      // Use its bounding rectangle to find top-left
      const rect = overlay.getBoundingClientRect();
      const sourceW = overlayCanvas.width;
      const sourceH = overlayCanvas.height;

      // The displayed size in CSS px
      const destW = rect.width;
      const destH = rect.height;

      // The offset for the new canvas coordinate space
      // (assuming you've computed minLeft, minTop for your bounding area)
      const dx = rect.left - minLeft;
      const dy = rect.top - minTop;

      // Finally, draw the entire overlay snapshot scaled to match how it looks in the browser
      ctx.drawImage(
        overlayCanvas,
        0,         // sx
        0,         // sy
        sourceW,   // sWidth
        sourceH,   // sHeight
        dx,        // dx
        dy,        // dy
        destW,     // dWidth
        destH      // dHeight
      );
    }

    this.recordingStore.stopScreenshot()

    setTimeout(() => {
      const mimeType = 'image/png';
      newCanvas.toBlob((blob) => {
        navigator._download.blob(blob, mimeType);
      }, mimeType);
    }, 1000)
  }

  async take() {
    this.recordingStore.startScreenshot()

    // The react-native-view-shot plugin doesn't capture webgl content on Android
    if (App.isAppleNative() && App.isNativeVersionAtleast(APP_VERSION_1_2_0)) {
      try {
        await this.takeNativeScreenshot()
      } catch (error) {
        console.error(error)
        window._alert(`Failed to take screenshot. Please enable screenshot permission in your settings to use this feature.`, `Screenshot Failed`)
      } finally {
        this.recordingStore.stopScreenshot()
      }

      return;
    }    

    try {
      await this.takeBrowserScreenshot()
    } catch (error) {
      console.error(error)
    } finally {
      this.recordingStore.stopScreenshot()
    }
  }
}
