<template>
  <Transition name="slide-fade">
    <div class="inspector absolute left-1/2 top-1/2" :class="{
      'radar-drawer-open': shouldApplyYOffset
    }">
      <div class="relative">
        <Icon icon="game-icons:convergence-target" class="inline size-8" />
        <div ref="glass" class="color absolute h-10 w-10" :class="{
          'visible': active,
          'invisible': !active,
        }" :style="`background-color: ${color}`"></div>
        <div v-show="active" class="value-container absolute">
          <div class="value" v-html="value_str"></div>
          <div class="beam-height">{{ beam_height_kft.toFixed(1) }} kft</div>
        </div>
        <div v-show="!active" class="no-value-container absolute">
          <div class="value">No data</div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script setup lang="ts">
import { ref, onMounted, onUnmounted, computed, watch } from 'vue'
import { distance } from '@turf/turf'
import { Icon } from '@iconify/vue'
import { beamHeight, calculateRainfallRate } from '@/logic/Radar/helpers'
import { useRadarSettingsStore } from '@/stores/settings/radar'
import { useAppStore } from '@/stores/app'

import { PRT_VALUE_RANGE, PRT_RAIN_VALUE_MAX, PRT_SNOW_VALUE_MIN, PRT_SNOW_VALUE_MAX, PRT_MIXED_VALUE_MIN, PRT_MIXED_VALUE_MAX } from '@/data/radar_products.js'

import { getHCAClassification } from '@/tools/helpers'

import MapKeeper from '@/logic/MapKeeper'

const props = defineProps<{
  mapMeta: any
}>()

const map = MapKeeper.map(props.mapMeta.id);
const mapRadarStore = map.stores['radar']
const appStore = useAppStore()
const radarSettingsStore = useRadarSettingsStore()

const active = ref(true)
const color = ref('rgba(0,0,0,0)')
const value_str = ref('')
const beam_height_kft = ref(0)
const glass = ref<HTMLElement | null>(null)

const shouldApplyYOffset = computed(() => {
  return appStore.radar_drawer_open && window.innerWidth < 640
})

const getMapPositionFromGlass = () => {
  if (!glass.value) return null

  const glassRect = glass.value.getBoundingClientRect()
  const mapElement = map.getContainer()
  const mapRect = mapElement.getBoundingClientRect()

  // Calculate the position relative to the map
  const relativeX = glassRect.left + (glassRect.width / 2) - mapRect.left
  const relativeY = glassRect.top + (glassRect.height / 2) - mapRect.top

  // Convert the pixel coordinates to map coordinates
  const point = map.unproject([relativeX, relativeY])
  return point
}

const updateInspectorValue = () => {
  if (!glass.value || mapRadarStore.scanDatetime === null) return

  const position = getMapPositionFromGlass()
  if (!position) return

  const renderer = map.components['radar-renderer'];
  const point = renderer.getValueAt(position)

  if (point === null) {
    color.value = 'rgba(0,0,0,0)'
    value_str.value = 'No data'
  } else {
    color.value = `rgba(${point.rgbaColor?.join(', ')})`

    const groupId = mapRadarStore.activeProductGroup.id;

    if(groupId === 'HCA') {
      const classification = getHCAClassification(point.value);

      value_str.value = classification;
    }
    else if(groupId === 'PRT') {
      let value = point.value;
      let prt = 'Rain';
      if(value > PRT_SNOW_VALUE_MIN && value <= PRT_SNOW_VALUE_MAX) {
        prt = 'Snow';
        value-=(PRT_VALUE_RANGE * 1);
      }
      else if(value > PRT_MIXED_VALUE_MIN && value <= PRT_MIXED_VALUE_MAX) {
        prt = 'Mixed';
        value-=(PRT_VALUE_RANGE * 2);
      }

      value_str.value = `${value.toFixed(1)} ${mapRadarStore.activeProductGroupUnit}<br>(${prt})`;
    }
    else if('REF' === groupId) {
      const unit = radarSettingsStore.units['REF'];

      switch(unit) {
      case 'dbz':
        value_str.value = `${point.value.toFixed(1)} ${mapRadarStore.activeProductGroupUnit}`;
        break;
      case 'dbz_rr_in_hr':
        {
          const rr = calculateRainfallRate(point.value) * 0.0393701
          value_str.value = `${point.value.toFixed(1)} ${mapRadarStore.activeProductGroupUnit}<br>(${rr.toFixed(1)} in/hr)`;
        }
        break;
      case 'dbz_rr_mm_hr':
        {
          const rr = calculateRainfallRate(point.value)
          value_str.value = `${point.value.toFixed(1)} ${mapRadarStore.activeProductGroupUnit}<br>(${rr.toFixed(1)} mm/hr)`;
        }
        break;
      }
    }
    else if(['VEL', 'SRV'].includes(groupId)) {
      const unit = radarSettingsStore.units['VEL'];

      switch(unit) {
      case 'm/s':
        value_str.value = `${point.value.toFixed(1)} ${mapRadarStore.activeProductGroupUnit}`;
        break;
      case 'mph':
        {
          const value = point.value * 2.23694;
          value_str.value = `${value.toFixed(1)} mph`;
        }
        break;
      case 'kph':
        {
          const value = point.value * 3.6;
          value_str.value = `${value.toFixed(1)} kph`;
        }
        break;
      case 'kt':
        {
          const value = point.value * 1.94384;
          value_str.value = `${value.toFixed(1)} kt`;
        }
        break;
      case 'ft/s':
        {
          const value = point.value * 3.28084;
          value_str.value = `${value.toFixed(1)} ft/s`;
        }
        break;
      }
    }
    else {
      value_str.value = `${point.value.toFixed(1)} ${mapRadarStore.activeProductGroupUnit}`;
    }
  }

  const dist = distance(position.toArray(), renderer.getData().location, { units: "meters" })

  // TODO
  // Fix this, as this assumes the radar max range is 400km
  active.value = dist < 400 * 1000;

  const altitude = mapRadarStore.activeTower.geometry.coordinates[2] ?? 0;

  // TODO
  // Bug here, fix this...
  // We need to use the real elevation angle (0.5 is typical but not always)
  const bh = altitude + beamHeight(dist, 0.5)

  // Convert meters to kilo-feet
  beam_height_kft.value = bh * 0.00328083989;
}

// Watch for changes that should trigger an update
watch(() => mapRadarStore.scanDatetime, (newValue) => {
  if (newValue !== null) {
    updateInspectorValue()
  }
})

watch([
  () => appStore.radar_drawer_open,
  () => appStore.colortable_bar_open
], () => {
  setTimeout(updateInspectorValue, 310)
})

onMounted(() => {
  MapKeeper.mapboxMapsFirst().on('move', updateInspectorValue)
  MapKeeper.mapboxMapsFirst().on('zoom', updateInspectorValue)
  updateInspectorValue()
})

onUnmounted(() => {
  MapKeeper.mapboxMapsFirst().off('move', updateInspectorValue)
  MapKeeper.mapboxMapsFirst().off('zoom', updateInspectorValue)
})
</script>

<style scoped>
.inspector {
  width: 30px;
  height: 30px;
  margin-left: -15px;
  margin-top: -15px;
  pointer-events: none;
}

.inspector.radar-drawer-open {
  transition: margin-top 300ms;
  /* Substract half the height of the radar drawer */
  margin-top: -215px;
}

.inspector .color {
  top: 7px;
  left: 7px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid #2C3D50;
}

.inspector .value-container {
  width: 80px;
  margin-left: -25px;
  margin-top: 4px;
  background-color: rgba(255,255,255,0.8);
  border-radius: 20px;
  border: 1px solid #2C3D50;
  text-align: center;
  font-size: 11px;
  font-weight: bold;
}

.inspector .value-container .value {
  font-weight: bold;
}

.inspector .value-container .beam-height {
  font-weight: bold;
}

.inspector .no-value-container {
  width: 60px;
  margin-left: -15px;
  margin-top: 4px;
  background-color: rgba(255,255,255,0.8);
  border-radius: 20px;
  border: 1px solid #2C3D50;
  text-align: center;
  font-size: 11px;
  font-weight: bold;
}
.inspector .no-value-container .value {
  font-weight: bold;
}
</style>
