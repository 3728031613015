export default [
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -22.6353,
          64.0257,
          34
        ]
      },
      "properties": {
        "id": "ISKEF",
        "code": "ISKEF",
        "secondary": false,
        "products": [
          "REF"
        ],
        "elevations": [0.3, 0.5, 0.8, 1.3, 1.8, 2.5, 3.5, 4.8, 7.0, 9.0]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -15.0382,
          65.0279,
          693
        ]
      },
      "properties": {
        "id": "ISEGS",
        "code": "ISEGS",
        "secondary": false,
        "products": [
          "REF"
        ],
        "elevations": [0.3, 0.5, 0.8, 1.3, 1.8, 2.5, 3.5, 4.8, 7.0, 9.0]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -17.965098,
          63.774975,
          -20.6
        ]
      },
      "properties": {
        "id": "ISX1",
        "code": "ISX1",
        "secondary": false,
        "products": [
          "REF"
        ],
        "elevations": [0.5, 0.8, 1.4, 2.3, 3.9, 6.5, 10.8, 18.0, 30.0]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -22.396235,
          63.967778,
          -4.57
        ]
      },
      "properties": {
        "id": "ISX2",
        "code": "ISX2",
        "secondary": false,
        "products": [
          "REF"
        ],
        "elevations": [0.5, 0.8, 1.4, 2.3, 3.9, 6.5, 10.8, 18.0, 30.0]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -20.268,
          66.0557,
          156
        ]
      },
      "properties": {
        "id": "ISSKA",
        "code": "ISSKA",
        "secondary": false,
        "products": [
          "VEL"
        ],
        "elevations": [0.3, 0.5, 0.8, 1.3, 1.8, 2.5, 3.5, 4.8, 7.0, 9.0]
      }
    },
    {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -14.0618,
          65.2658,
          1083
        ]
      },
      "properties": {
        "id": "ISBJO",
        "code": "ISBJO",
        "secondary": false,
        "products": [
          "REF"
        ],
        "elevations": [0.3, 0.5, 0.8, 1.3, 1.8, 2.5, 3.5, 4.8, 7.0, 9.0]
      }
    }
  ]