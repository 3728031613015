<!-- Button.vue -->
<template>
  <!-- When asChild is false, render a <button> element -->
  <button
    v-if="!asChild"
    v-bind="otherAttrs"
    :class="computedClass"
  >
    <slot />
  </button>
  <!-- When asChild is true, render the cloned child -->
  <template v-else>
    <component :is="clonedChild" />
  </template>
</template>

<script>
import { defineComponent, cloneVNode } from 'vue'

function cn(...inputs) {
  return inputs.filter(Boolean).join(" ")
}

// Base classes for the button
const baseClasses =
  "inline-flex items-center justify-center gap-2 whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colours focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 [&_svg]:pointer-events-none [&_svg]:size-4 [&_svg]:shrink-0"

// Mapping for variant styles
const variantClasses = {
  default: "bg-primary text-primary-foreground hover:bg-primary/90",
  destructive: "bg-destructive text-destructive-foreground hover:bg-destructive/90",
  outline: "border border-input bg-background hover:bg-accent hover:text-accent-foreground",
  secondary: "bg-secondary text-secondary-foreground hover:bg-secondary/80",
  ghost: "hover:bg-accent hover:text-accent-foreground",
  link: "text-primary underline-offset-4 hover:underline",
}

// Mapping for size styles
const sizeClasses = {
  default: "h-10 px-4 py-2",
  sm: "h-9 rounded-md px-3",
  lg: "h-11 rounded-md px-8",
  icon: "h-10 w-10",
}

export default defineComponent({
  name: "Button",
  inheritAttrs: false,
  props: {
    asChild: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: "default",
    },
    size: {
      type: String,
      default: "default",
    },
    // Allow extra classes to be passed as a prop.
    // Note: Vue automatically merges the `class` attribute but we expose it explicitly here.
    class: {
      type: [String, Array, Object],
      default: "",
    },
  },
  computed: {
    // Merge the base, variant, and size classes with any extra classes.
    computedClass() {
      const variantClass = variantClasses[this.variant] || variantClasses.default
      const sizeClass = sizeClasses[this.size] || sizeClasses.default
      // Merge any classes provided via the class prop or via $attrs
      const extraClass = this.class || this.$attrs.class
      return cn(baseClasses, variantClass, sizeClass, extraClass)
    },
    // Remove the "class" attribute from $attrs to avoid duplication.
    otherAttrs() {
      const { class: _ignored, ...rest } = this.$attrs
      return rest
    },
    // When asChild is true, clone the single child VNode and merge our classes and attributes.
    clonedChild() {
      if (!this.asChild) return null
      const children = this.$slots.default ? this.$slots.default() : []
      if (!children || children.length !== 1) {
        console.warn('[Button]: When using asChild, please provide exactly one child.')
        return null
      }
      return cloneVNode(children[0], {
        class: cn(this.computedClass, children[0].props?.class),
        ...this.otherAttrs,
      })
    },
  },
})
</script>