import { productGroups, PRT_RAIN_VALUE_MIN, PRT_RAIN_VALUE_MAX, PRT_SNOW_VALUE_MIN, PRT_SNOW_VALUE_MAX, PRT_MIXED_VALUE_MIN, PRT_MIXED_VALUE_MAX } from '@/data/radar_products.js'
import { parsePal } from '@/tools/pal.js'

class RadarColortable {
    lookup = {};

    constructor() {
        productGroups.forEach(g => {
            if(typeof g.id !== 'string') return;

            this.lookup[g.id] = {};
        })
    }

    parse(groupId, tableId, text, step = null) {
        const stepMap = {
            'REF': 1,
            'PRT': 0.1,
            'VEL': 1,
            'ZDR': 0.1,
            'CC': 0.01,
            'SW': 0.1,
            'KDP': 0.1,
            'HCA': 1,
            'SRV': 1,
            'DTA': 1,
            'OHA': 0.1,
            'EET': 0.1,
            'VIL': 0.1
        };

        if(step === null) {
            step = stepMap[groupId];

            if(step === undefined) throw new Error(`Unable to locate colortable step for: ${groupId}`);
        }

        this.lookup[groupId][tableId] = parsePal(text, step);
    }

    get(groupId, tableId) {
        if(this.lookup[groupId] === undefined) return null;
        if(this.lookup[groupId][tableId] === undefined) return null;

        return this.lookup[groupId][tableId];
    }

    getAsCssGradient(groupId, tableId) {
        const table = this.get(groupId, tableId);

        if(table === null) return "background: #000000";

        const len = table.colors.length || 0;
        const colorsAsRgb = table.colors.map((c, i) => {
            return `rgb(${c[0]},${c[1]},${c[2]}) ${(i/len)*100}%`
        });
        return `background: linear-gradient(90deg, ${colorsAsRgb.join(', ')});`;
    }
}

const colortable = new RadarColortable();

export default colortable;

const ReflectivityPal = `
Product: BR
Units: dBZ
Step: 5
Scale: 1
Offset: 0

Color: -12 53 8 72
Color: -10 59 33 64
Color: -5 64 63 66
Color: 0 118 117 118
Color: 5 188 187 187
Color: 10 63 94 161
Color: 15 92 171 216
Color: 20 35 187 83
Color: 25 2 154 11
Color: 30 0 115 5
Color: 35 0 77 0
Color: 40 255 214 0
Color: 45 255 173 0
Color: 50 255 23 0
Color: 55 213 18 3 168 15 0
Color: 60 96 8 0 51 4 4
Color: 65 255 191 253
Color: 70 200 117 199 148 56 149
Color: 75 255 252 255
Color: 80 255 252 255
`;

colortable.parse('REF', 'DEFAULT', ReflectivityPal);

const ReflectivityPal2 = `
Product: BR
Units: DBZ
Step: 5

Color4: -30 0 0 0 255
Color4: 0 255 255 255 233
Color4: 10 0 36 255 97
Color4: 34.9999 0 255 255 255
Color4: 35 255 237 0 255
Color4: 35.001 255 230 0 255 255 108 0 255
Color4 49.9999 255 108 0 255
Color4: 50 255 0 0 255
Color4: 64.9999 119 0 0 210
Color: 65 255 255 255
Color4: 74.999 209 7 236 255
Color4: 75 5 211 214 255
Color4: 92 1 56 56 255
`;

colortable.parse('REF', 'OTHER1', ReflectivityPal2);

const ReflectivityPal3 = `
Product: BR
Units: dBZ
Step: 5
Offset: 0
Scale: 1

Color: 0 244 241 254
Color: 10 100 96 133
Color: 15 110 105 182 123 124 241
Color: 20 108 113 253
Color: 25 16 25 255
Color: 30 7 108 7
Color: 35 25 176 94
Color: 40 0 211 0
Color: 45 241 219 27
Color: 50 255 165 5
Color: 55 254 88 64
Color: 60 162 30 16
Color: 65 113 24 81
Color: 70 138 30 170
Color: 75 243 33 234
Color: 80 251 96 255
Color: 85 225 224 222
Color: 90 71 49 83
`;

colortable.parse('REF', 'OTHER2', ReflectivityPal3);

const ReflectivityRW1 = `
Product: BR
Units: dBZ
Step: 5
Offset: 0
Scale: 1

Color: 0 244 241 254
Color: 10 100 96 133
Color: 15 110 105 182 123 124 241
Color: 20 108 113 253
Color: 25 16 25 255
Color: 30 7 108 7
Color: 35 25 176 94
Color: 40 0 211 0
Color: 45 241 219 27
Color: 50 255 165 5
Color: 55 254 88 64
Color: 60 162 30 16
Color: 65 113 24 81
Color: 70 138 30 170
Color: 75 243 33 234
Color: 80 251 96 255
Color: 85 225 224 222
Color: 90 71 49 83
`;

colortable.parse('REF', 'RW1', ReflectivityRW1);

const PrecipType1 = `
Product: PM
Units: dBZ

# Rain
Color: -12 53 8 72 RAIN
Color: -10 59 33 64 RAIN
Color: -5 64 63 66 RAIN
Color: 0 118 117 118 RAIN
Color: 5 188 187 187 RAIN
Color: 10 63 94 161 RAIN
Color: 15 92 171 216 RAIN
Color: 20 35 187 83 RAIN
Color: 25 2 154 11 RAIN
Color: 30 0 115 5 RAIN
Color: 35 0 77 0 RAIN
Color: 40 255 214 0 RAIN
Color: 45 255 173 0 RAIN
Color: 50 255 23 0 RAIN
Color: 55 213 18 3 168 15 0 RAIN
Color: 60 96 8 0 51 4 4 RAIN
Color: 65 255 191 253 RAIN
Color: 70 200 117 199 148 56 149 RAIN
Color: 75 255 252 255 RAIN
Color: 80 255 252 255 RAIN

# Snow
color: -30 0 0 0 SNOW
color: -10.0 36 115 132 SNOW
color: 0.0 2 117 255 SNOW
color: 50.0 0 0 50 SNOW
color: 70.0 0 0 0 SNOW
color: 80 0 0 0 SNOW

# Mixed
Color: -30 0 0 0 MIX
Color: -10 121 93 89 MIX
Color: 0.0 224 80 68 MIX
Color: 50 34 17 20 MIX
Color: 70 0 0 0 MIX
Color: 80 0 0 0 MIX
`;

colortable.parse('PRT', 'DEFAULT', PrecipType1);

const PrecipType2 = `
Product: PM
Units: dBZ

Color4: 0.0 4 4 6 33 96 97 120 124 RAIN
Color4: 10.0 99 100 130 128 139 140 172 200 RAIN
Color4: 14.0 100 120 160 180 52 106 145 160 RAIN
Color4: 15.0 0 85 128 140 24 183 255 250 RAIN
Color: 22.0 22 188 243 RAIN
Color: 25.0 0 255 120 RAIN
Color: 32.0 0 35 0 255 243 46 RAIN
Color: 40.0 255 246 51 253 168 53 RAIN
Color: 44.0 253 160 53 205 25 8 RAIN
Color: 52.0 96 5 0 53 1 49 RAIN
Color: 56.0 67 1 60 204 0 166 RAIN
Color: 64.0 24 19 43 5 204 209 RAIN
Color: 74.5 255 255 255 13 13 13 RAIN
Color: 90.0 0 0 0 RAIN

Color4: -30.0 65 12 39 75 MIX
Color4: 0.0 121 52 87 110 MIX
Color4: 10.0 176 91 136 145 MIX
Color4: 20.0 232 131 184 240 MIX
Color: 30.0 195 106 152 MIX
Color: 40.0 158 80 121 MIX
Color: 50.0 121 55 89 MIX
Color: 60.0 84 29 58 MIX
Color: 90.0 47 4 26 MIX

Color4: -30.0 0 51 51 75 SNOW
Color4: 0.0 82 119 119 110 SNOW
Color4: 10.0 163 187 187 145 SNOW
Color4: 20.0 245 245 245 180 SNOW
Color: 30.0 197 213 218 SNOW
Color: 40.0 149 171 182 SNOW
Color: 50.0 101 128 145 SNOW
Color: 60.0 53 86 109 SNOW
Color: 90.0 5 44 72 SNOW
`;

colortable.parse('PRT', 'OTHER1', PrecipType2);

const PrecipType3 = `
Product: PM
Units: dBZ

Color: 5   37 225 125 RAIN
Color: 14    1  96   5 RAIN
Color: 35  255 255  33 255 128 0 RAIN
Color: 45  255   0   0 205   0 0 RAIN
Color: 55  180   0 180 RAIN
Color: 60  253 117 243 RAIN
Color: 65   29  29  29 RAIN
Color: 80  255 255 255 RAIN

Color4: -10.0 69 8 39 75 MIX
Color4: 0.0 124 54 90 115 MIX
Color4: 5.0 180 101 142 145 MIX
Color4: 10.0 235 147 193 180 MIX
Color: 20.0 199 121 163 MIX
Color: 25.0 164 96 132 MIX
Color: 30.0 128 70 102 MIX
Color: 45.0 93 45 71 MIX
Color: 70.0 57 19 41 MIX

Color4: -10.0 0 32 32 75 SNOW
Color4: 0.0 0 56 64 127 SNOW
Color4: 10.0 0 79 95 180 SNOW
Color: 20.0 0 128 255 SNOW
Color: 25.0 0 85 255 SNOW
Color: 30.0 0 0 255 SNOW
Color: 45.0 0 0 175 SNOW
Color: 70.0 0 0 50 SNOW
`;

colortable.parse('PRT', 'OTHER2', PrecipType3);

const PrecipTypeRW1 = `
Product: PM
Units: dBZ

Color: 5   37 225 125 RAIN
Color: 14    1  96   5 RAIN
Color: 35  255 255  33 255 128 0 RAIN
Color: 45  255   0   0 205   0 0 RAIN
Color: 55  180   0 180 RAIN
Color: 60  253 117 243 RAIN
Color: 65   29  29  29 RAIN
Color: 80  255 255 255 RAIN

Color4: -10.0 69 8 39 75 MIX
Color4: 0.0 124 54 90 115 MIX
Color4: 5.0 180 101 142 145 MIX
Color4: 10.0 235 147 193 180 MIX
Color: 20.0 199 121 163 MIX
Color: 25.0 164 96 132 MIX
Color: 30.0 128 70 102 MIX
Color: 45.0 93 45 71 MIX
Color: 70.0 57 19 41 MIX

Color4: -10.0 0 32 32 75 SNOW
Color4: 0.0 0 56 64 127 SNOW
Color4: 10.0 0 79 95 180 SNOW
Color: 20.0 0 128 255 SNOW
Color: 25.0 0 85 255 SNOW
Color: 30.0 0 0 255 SNOW
Color: 45.0 0 0 175 SNOW
Color: 70.0 0 0 50 SNOW
`;

colortable.parse('PRT', 'RW1', PrecipTypeRW1);

const VelocityPal = `
Product: BV
Units: KTS
Scale: 1.942

Color: -120 252 0 131
Color: -100 110 3 152
Color: -90  24 39 164
Color: -80 30 111 187
Color: -70 47 222 225
Color: -50 181 237 240
Color: -40 3 234 3
Color: -10 78 121 77
Color: 0 137 117 123
Color: 10 109 0 1
Color: 40 249 51 77
Color: 55 253 160 202
Color: 60 253 228 159
Color: 80 254 142 79
Color: 120 110 14 8
`;

colortable.parse('VEL', 'DEFAULT', VelocityPal);

const VelocityPal2 = `
Units: KTS
Step: 10
Product: BV
Scale: 1.942

Color: 100 172 169 171
Color: 70 15 0 7
Color: 50 246 0 13
Color: 40 193 3 253 154 0 156
Color: 30 252 151 252
Color: 27 151 82 30
Color: 20 200 119 25
Color: 10 249 239 96
Color: 5 148 113 125
Color: 0 120 122 120
Color: -5 136 146 129
Color: -10 127 185 113
Color: -30 3 65 5
Color: -40 3 133 136
Color: -60 32 9 228 1 245 253
Color: -63 0 3 46
Color: -90 48 49 90
Color: -100 68 68 100
`;

colortable.parse('VEL', 'OTHER1', VelocityPal2);

const VelocityPal3 = `
Units: KTS
Step: 10
Product: BV
Scale: 1.942

Color: 80 0 0 0
Color: 60 69 21 33
Color: 40 124 48 87
Color: 30 164 75 95
Color: 10 188 117 116
Color: 5 235 215 204

Color: 0 245 242 237

Color: -5 183 202 177
Color: -10 82 147 127
Color: -30 40 77 95
Color: -40 24 34 64
Color: -60 29 15 51
Color: -80 0 0 0
`;

colortable.parse('VEL', 'OTHER2', VelocityPal3);

const VelocityRW1 = `
Units: KTS
Step: 10
Product: BV
Scale: 1.942

Color: 80 0 0 0
Color: 60 69 21 33
Color: 40 124 48 87
Color: 30 164 75 95
Color: 10 188 117 116
Color: 5 235 215 204

Color: 0 245 242 237

Color: -5 183 202 177
Color: -10 82 147 127
Color: -30 40 77 95
Color: -40 24 34 64
Color: -60 29 15 51
Color: -80 0 0 0
`;

colortable.parse('VEL', 'RW1', VelocityRW1);

const DiffReflectivityPal = `
Product: BV

Color: -3.0 75 0 130      # Indigo (Hex: #4B0082) (Strong negative ZDR)
Color: -2.0 128 0 128     # Purple (Hex: #800080)
Color: -1.0 139 0 139     # Dark Magenta (Hex: #8B008B)
Color: 0.0 255 255 255    # White (Hex: #FFFFFF) (Neutral ZDR, near zero)
Color: 0.5 173 216 230    # Light Blue (Hex: #ADD8E6)
Color: 1.0 135 206 250    # Sky Blue (Hex: #87CEFA)
Color: 1.5 0 191 255      # Deep Sky Blue (Hex: #00BFFF)
Color: 2.0 0 128 0        # Green (Hex: #008000)
Color: 3.0 50 205 50      # Lime Green (Hex: #32CD32)
Color: 4.0 255 255 0      # Yellow (Hex: #FFFF00)
Color: 5.0 255 165 0      # Orange (Hex: #FFA500)
Color: 6.0 255 69 0       # Orange Red (Hex: #FF4500)
Color: 7.0 255 0 0        # Red (Hex: #FF0000) (Strong positive ZDR)
`;

colortable.parse('ZDR', 'DEFAULT', DiffReflectivityPal);

const CorrelationCoefficientPal = `
Product: CC

Color: 0.2 0 0 0
Color: 0.4 148 148 148
Color: 0.6 117 147 255
Color: 0.2 0 69 189
Color: 0.8 173 244 255
Color: 1 194 0 71
Color: 1.2 255 235 242
`;

colortable.parse('CC', 'DEFAULT', CorrelationCoefficientPal);

const CorrelationCoefficientPal2 = `
Product: CC
Units: %
Scale: 100
Step: 4

Color: 104 255 172 255
Color: 101.5 253 162 254
Color: 101 154 19 140
Color: 98 93 0 29
Color: 96 230 2 0
Color: 92 254 202 0
Color: 88 116 242 28
Color: 84 95 229 125
Color: 80 123 132 249
Color: 76 38 39 254
Color: 20 22 0 44
`;

colortable.parse('CC', 'OTHER1', CorrelationCoefficientPal2);

const CorrelationCoefficientPal3 = `
Product:  CC
Units:    %
Scale:    100
Step:     5

SolidColor4: 105    250 250 250 50
SolidColor: 104     240 200 240
Color: 103 77 0 77
Color: 95 128 0 0
Color: 85 204 153 0
Color: 75 0 153 0
Color: 65 51 102 204
Color: 55 0 0 102
Color: 45 41 61 61
Color: 35 38 38 38
Color: 25 0 0 0
Color: 15 153 153 153
Color: 0 255 255 255
`;

colortable.parse('CC', 'OTHER2', CorrelationCoefficientPal3);

const CorrelationCoefficientRW1 = `
Product:  CC
Units:    %
Scale:    100
Step:     5

SolidColor4: 105    250 250 250 50
SolidColor: 104     240 200 240
Color: 103 77 0 77
Color: 95 128 0 0
Color: 85 204 153 0
Color: 75 0 153 0
Color: 65 51 102 204
Color: 55 0 0 102
Color: 45 41 61 61
Color: 35 38 38 38
Color: 25 0 0 0
Color: 15 153 153 153
Color: 0 255 255 255
`;

colortable.parse('CC', 'RW1', CorrelationCoefficientRW1);

const SpectrumWidthPal = `
Product: SW
Units: KTS
Step: 2
Scale: 1.9426

Color: 0 5 5 6
Color: 2 42 42 49
Color: 4 78 77 91
Color: 6 115 114 133
Color: 8 152 148 173
Color: 10 200 109 105
Color: 12 246 75 42
Color: 14 248 116 52
Color: 16 250 161 63
Color: 18 252 203 73
Color: 20 255 244 83
Color: 22 255 248 111
Color: 24 255 250 140
Color: 26 255 251 170
Color: 28 255 252 199
Color: 30 255 254 226
Color: 32 246 246 246
`;

colortable.parse('SW', 'DEFAULT', SpectrumWidthPal);

const SpectrumWidthPal2 = `
Product: SW
Units:   KTS
Step:    2
Scale:   1.9426

color: 0 20 5 72
color: 2 50 20 140
color: 4 124 38 190
color: 7 218 55 120
color: 15 251 126 33
color: 18 255 255 0
color: 22 153 255 51
color: 30 0 153 230
color: 35 0 17 26
color: 40 255 255 255
`;

colortable.parse('SW', 'OTHER1', SpectrumWidthPal2);

const SpectrumWidthPal3 = `
Product: SW
Units: KTS
Step: 1
Scale: 1.9426

Color: 32 0 0 0
Color: 18 79 0 81
Color: 5 154 177 216
Color: 4 249 250 252

Color: 0 24 24 24
`;

colortable.parse('SW', 'OTHER2', SpectrumWidthPal3);

const SpectrumWidthRW1 = `
Product: SW
Units: KTS
Step: 1
Scale: 1.9426

Color: 32 0 0 0
Color: 18 79 0 81
Color: 5 154 177 216
Color: 4 249 250 252

Color: 0 24 24 24
`;

colortable.parse('SW', 'RW1', SpectrumWidthRW1);

const SpecificDifferentialPhasePal = `
Product: BV

Color: -2.0 75 0 130       # Indigo (Hex: #4B0082) (Strong negative KDP, rare or noise)
Color: -1.0 139 0 139      # Dark Magenta (Hex: #8B008B)
Color: -0.5 128 0 128      # Purple (Hex: #800080)
Color: 0.0 255 255 255     # White (Hex: #FFFFFF) (Neutral, no specific differential phase)
Color: 0.5 173 216 230     # Light Blue (Hex: #ADD8E6)
Color: 1.0 0 191 255       # Deep Sky Blue (Hex: #00BFFF)
Color: 2.0 0 128 0         # Green (Hex: #008000)
Color: 3.0 50 205 50       # Lime Green (Hex: #32CD32)
Color: 4.0 255 255 0       # Yellow (Hex: #FFFF00)
Color: 5.0 255 165 0       # Orange (Hex: #FFA500)
Color: 6.0 255 69 0        # Orange Red (Hex: #FF4500)
Color: 7.0 255 0 0         # Red (Hex: #FF0000) (High positive KDP, intense rainfall)
`;

colortable.parse('KDP', 'DEFAULT', SpecificDifferentialPhasePal);

const HydrometeorClassificationPal = `
Product: BV

solidcolor: 10 156 156 156
solidcolor: 20 118 118 118
solidcolor: 30 243 179 178
solidcolor: 40 117 250 243
solidcolor: 50 63 141 247
solidcolor: 60 115 247 154
solidcolor: 70 84 184 54
solidcolor: 80 208 207 112
solidcolor: 90 199 135 134
solidcolor: 100 234 51 36
solidcolor: 110 147 37 30
solidcolor: 120 255 254 84
solidcolor: 130 0 0 0
`;

colortable.parse('HCA', 'DEFAULT', HydrometeorClassificationPal);

const StormRelativeVelocityPal = `
Product: BV

Color: -50.0 0 100 0 # Dark Green (Hex: #006400)
Color: -40.0 0 128 0 # Green (Hex: #008000)
Color: -30.0 0 192 0 # Lighter Green (Hex: #00C000)
Color: -20.0 128 224 128 # Light Green (Hex: #80E080)
Color: -10.0 192 255 192 # Very Light Green (Hex: #C0FFC0)
Color: 0.0 255 255 255 # White (Hex: #FFFFFF)
Color: 10.0 255 224 224 # Very Light Pink (Hex: #FFE0E0)
Color: 20.0 255 192 192 # Light Pink (Hex: #FFC0C0)
Color: 30.0 255 128 128 # Light Red (Hex: #FF8080)
Color: 40.0 255 0 0 # Red (Hex: #FF0000)
Color: 50.0 139 0 0 # Dark Red (Hex: #8B0000)
`;

colortable.parse('SRV', 'DEFAULT', StormRelativeVelocityPal);

const StormTotalPrecipAccumPal = `
Product: BV

Color: 0 0 0 0
Color: 0.1 100 100 100
Color: 0.25 60 60 60
Color: 0.5 0 200 255
Color: 0.75 0 150 255
Color: 1 0 100 255
Color: 1.5 0 0 255
Color: 2 0 255 0
Color: 2.5 0 200 0
Color: 3 0 150 0
Color: 4 255 255 0
Color: 5 255 200 0
Color: 6 255 150 0
Color: 8 255 100 0
Color: 10 255 0 0
Color: 12 200 0 0
Color: 15 150 0 0
Color: 20 100 0 0
`;

colortable.parse('DTA', 'DEFAULT', StormTotalPrecipAccumPal);

const OneHourPrecipAccumPal = `
Product: BV

Color: 0.0 255 255 255    # White (0 inches, no precipitation)
Color: 0.1 173 216 230    # Light Blue (Hex: #ADD8E6) (0.1 inches)
Color: 0.2 135 206 250    # Sky Blue (Hex: #87CEFA) (0.2 inches)
Color: 0.3 0 191 255      # Deep Sky Blue (Hex: #00BFFF) (0.3 inches)
Color: 0.5 0 128 0        # Green (Hex: #008000) (0.5 inches)
Color: 0.75 50 205 50     # Lime Green (Hex: #32CD32) (0.75 inches)
Color: 1.0 255 255 0      # Yellow (Hex: #FFFF00) (1.0 inch)
Color: 1.5 255 165 0      # Orange (Hex: #FFA500) (1.5 inches)
Color: 2.0 255 69 0       # Orange Red (Hex: #FF4500) (2.0 inches)
Color: 2.5 255 0 0        # Red (Hex: #FF0000) (2.5 inches)
Color: 3.0 139 0 0        # Dark Red (Hex: #8B0000) (3.0 inches)
Color: 4.0 128 0 128      # Purple (Hex: #800080) (4.0 inches)
Color: 5.0 75 0 130       # Indigo (Hex: #4B0082) (5.0 inches and above)
`;

colortable.parse('OHA', 'DEFAULT', OneHourPrecipAccumPal);

const EchoTopsPal = `
Product: BV

Color: 0.0 255 255 255    # White (0 kft, no significant echo)
Color: 5.0 173 216 230    # Light Blue (Hex: #ADD8E6) (5 kft)
Color: 10.0 135 206 250   # Sky Blue (Hex: #87CEFA) (10 kft)
Color: 15.0 0 191 255     # Deep Sky Blue (Hex: #00BFFF) (15 kft)
Color: 20.0 0 128 0       # Green (Hex: #008000) (20 kft)
Color: 25.0 50 205 50     # Lime Green (Hex: #32CD32) (25 kft)
Color: 30.0 255 255 0     # Yellow (Hex: #FFFF00) (30 kft)
Color: 35.0 255 165 0     # Orange (Hex: #FFA500) (35 kft)
Color: 40.0 255 69 0      # Orange Red (Hex: #FF4500) (40 kft)
Color: 45.0 255 0 0       # Red (Hex: #FF0000) (45 kft)
Color: 50.0 139 0 139     # Dark Magenta (Hex: #8B008B) (50 kft)
Color: 55.0 128 0 128     # Purple (Hex: #800080) (55 kft)
Color: 60.0 75 0 130      # Indigo (Hex: #4B0082) (60 kft)
Color: 65.0 148 0 211     # Dark Violet (Hex: #9400D3) (65 kft)
`

colortable.parse('EET', 'DEFAULT', EchoTopsPal);

const VILPal = `
Product: BV

Color: 0.0 255 255 255    # White (0 kg/m², no significant VIL)
Color: 5.0 173 216 230    # Light Blue (Hex: #ADD8E6) (5 kg/m²)
Color: 10.0 135 206 250   # Sky Blue (Hex: #87CEFA) (10 kg/m²)
Color: 15.0 0 191 255     # Deep Sky Blue (Hex: #00BFFF) (15 kg/m²)
Color: 20.0 0 128 0       # Green (Hex: #008000) (20 kg/m²)
Color: 25.0 50 205 50     # Lime Green (Hex: #32CD32) (25 kg/m²)
Color: 30.0 255 255 0     # Yellow (Hex: #FFFF00) (30 kg/m²)
Color: 35.0 255 165 0     # Orange (Hex: #FFA500) (35 kg/m²)
Color: 40.0 255 69 0      # Orange Red (Hex: #FF4500) (40 kg/m²)
Color: 45.0 255 0 0       # Red (Hex: #FF0000) (45 kg/m²)
Color: 50.0 139 0 0       # Dark Red (Hex: #8B0000) (50 kg/m²)
Color: 55.0 128 0 128     # Purple (Hex: #800080) (55 kg/m²)
Color: 60.0 75 0 130      # Indigo (Hex: #4B0082) (60 kg/m²)
Color: 65.0 148 0 211     # Dark Violet (Hex: #9400D3) (65 kg/m²)
`

colortable.parse('VIL', 'DEFAULT', VILPal);

