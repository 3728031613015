<script setup>
import { ref, onMounted } from 'vue';

// Import custom UI components (adjust the paths/extensions as needed)
import Card from 'primevue/card';
import Button from '@/components/ShareLocationView/Button.vue';
import Error from '@/components/UI/Messages/Error.vue'
import Secondary from '@/components/UI/Messages/Secondary.vue'
import Inplace from 'primevue/inplace';

import { APPLE_APP_STORE_URL, GOOGLE_PLAY_STORE_URL } from '@/tools/constants'

// Define store links
const STORE_LINKS = {
    ios: APPLE_APP_STORE_URL,
    android: GOOGLE_PLAY_STORE_URL
};

const platform = ref(null);
const error = ref(null);
const downloaded = ref(false)

onMounted(() => {
    // Detect the user's platform based on the user agent
    const userAgent = navigator.userAgent.toLowerCase();
    if (/iphone|ipad|ipod/.test(userAgent)) {
        platform.value = 'ios';
    } else if (/android/.test(userAgent)) {
        platform.value = 'android';
    }
});

const hasDownloaded = () => downloaded.value = true;

const handleDownload = () => {
    if (!platform.value) {
        error.value =
            'Unable to detect your device\'s platform. Please choose from the App Store or Play Store manually.';
        return;
    }
    hasDownloaded()
    window.location.href = STORE_LINKS[platform.value];
};

const handleOpenApp = () => {
    try {
        window.location.href = 'weatherwise://d/share-location?with=rob';
    } catch (e) {
        alert(e)
    }
};
</script>

<template>
    <div class="min-h-screen bg-gradient-to-b from-[#17274F] via-[#1a2f5e] to-[#17274F] flex flex-col relative overflow-hidden">
        <!-- Animated Background Elements -->
        <div class="absolute inset-0 overflow-hidden pointer-events-none">
            <div class="absolute top-0 left-0 w-96 h-96 bg-[#F6B91A] rounded-full filter blur-[128px] opacity-20 animate-pulse"></div>
            <div class="absolute bottom-0 right-0 w-96 h-96 bg-[#FF5722] rounded-full filter blur-[128px] opacity-10 animate-pulse delay-1000"></div>
        </div>

        <!-- Header -->
        <header class="fixed top-0 left-0 right-0 bg-[#17274F]/80 backdrop-blur-sm py-4 px-6 flex items-center justify-between z-50 border-b border-white/10">
            <div class="flex text-center w-full justify-center items-center">
                <img src="/src/assets/weatherwise-logo.svg" class="h-3/4 max-w-[27px] object-contain" alt="WeatherWise Logo">
                <div class="font-[Outfit] text-lg flex items-center ml-3">
                    <span class="text-gray-100 font-bold drop-shadow-sm transition-colors duration-200">Weather</span>
                    <span class="text-[#F6B91A] font-bold drop-shadow-sm group-hover:text-[#F6B91A] transition-colors duration-200">Wise</span>
                </div>
            </div>
        </header>

        <!-- Main Content -->
        <main class="flex-1 pt-20 pb-16">
            <div class="max-w-4xl mx-auto px-4">
                <!-- Media Personality Section -->
                <div class="bg-gradient-to-r from-[#17274F]/80 to-[#17274F]/60 backdrop-blur rounded-lg p-8 mb-8 text-white border border-white/10 shadow-xl transform hover:scale-[1.02] transition-transform">
                    <div class="flex flex-col md:flex-row items-center gap-8">
                        <div class="w-40 h-40 rounded-full bg-gradient-to-br from-[#F6B91A] to-[#FF5722] p-1">
                            <div class="w-full h-full rounded-full bg-[#17274F] flex items-center justify-center relative overflow-hidden">
                                <!-- <span class="text-5xl font-bold text-[#F6B91A]">RW</span> -->
                                <!-- <img src="/test/robwest_avatar.webp" /> -->
                            </div>
                        </div>
                        <div class="flex-1 text-center md:text-left">
                            <div class="flex items-center gap-2 justify-center md:justify-start">
                                <h2 class="text-3xl font-bold mb-2 bg-gradient-to-r from-[#F6B91A] to-[#FF5722] text-transparent bg-clip-text">
                                    Join Rob West, Live!
                                </h2>
                            </div>
                            <p class="text-xl text-white/90 mb-4">
                                Share your location with Rob, live now on his stream.
                            </p>
                            <p class="text-white/80">
                                Join us on WeatherWise to share your location and access Rob Mode to match Rob's map and colors!
                            </p>
                        </div>
                    </div>
                </div>

                <!-- Main Card -->
                <Card class="md:p-8 shadow-2xl bg-white/95 backdrop-blur border-t-4 border-t-[#F6B91A]">
                    <template #content v-if="downloaded">
                            <div>
                                <div>
                                    <Button @click="handleOpenApp" class="w-full bg-gradient-to-r from-[#FF5722] to-[#ff7b22] hover:opacity-90 text-white h-16 text-xl font-bold shadow-lg transform hover:scale-[1.02] transition-all">
                                        Share Location with Rob
                                    </Button>
                                </div>
                                <div>
                                    <Inplace class="mt-3">
                                        <template #display>Click here if you are having trouble</template>

                                        <template #content>
                                            <div class="text-center">
                                                Ensure you have downloaded the WeatherWise app
                                            </div>
                                            <div class="flex mt-6 justify-center space-x-10">
                                                <a :href="STORE_LINKS.ios" @click="hasDownloaded" target="_blank">
                                                    <img src="@/assets/mobile/app-store-badge.svg" alt="App Store" class="w-[128px] sm:w-[168px] h-auto object-contain mx-auto flex justify-center">
                                                </a>
                                                <a :href="STORE_LINKS.android" @click="hasDownloaded" target="_blank">
                                                    <img src="@/assets/mobile/google-play-badge.svg" alt="Google Play" class="w-[138px] sm:w-[178px] h-auto object-contain mx-auto flex justify-center">
                                                </a>
                                            </div>
                                        </template>
                                </Inplace>
                            </div>
                        </div>
                    </template>

                    <template #content v-else>
                        <h1 class="text-4xl font-bold text-[#17274F] mb-8">
                            You need the WeatherWise app to share your location, here's how to get it:
                        </h1>

                        <p class="text-[#231F20] text-lg mb-8" v-if="false">
                            Don't miss out on interacting with Rob's live stream. Follow these simple steps to join the excitement!
                        </p>

                        <!-- Steps Section -->
                        <div class="space-y-8 mb-10">
                            <div class="flex items-start gap-6 transform hover:translate-x-2 transition-transform">
                                <div class="w-12 h-12 rounded-full bg-gradient-to-br from-[#F6B91A] to-[#FF5722] flex items-center justify-center text-white font-bold shrink-0">
                                    1
                                </div>
                                <div class="flex-1">
                                    <h3 class="font-bold text-xl text-[#17274F] mb-2">Download from the App Store</h3>
                                    <p class="text-[#231F20]/80 text-lg">
                                        WeatherWise is available in the iOS and Google Play Stores - click the button below to download the app now.
                                    </p>
                                </div>
                            </div>

                            <div class="flex items-start gap-6 transform hover:translate-x-2 transition-transform">
                                <div class="w-12 h-12 rounded-full bg-gradient-to-br from-[#F6B91A] to-[#FF5722] flex items-center justify-center text-white font-bold shrink-0">
                                    2
                                </div>
                                <div class="flex-1">
                                    <h3 class="font-bold text-xl text-[#17274F] mb-2">Return to This Page</h3>
                                    <p class="text-[#231F20]/80 text-lg">
                                        After you have installed the app, come back here or rescan the QR code.
                                    </p>
                                </div>
                            </div>

                            <div class="flex items-start gap-6 transform hover:translate-x-2 transition-transform">
                                <div class="w-12 h-12 rounded-full bg-gradient-to-br from-[#F6B91A] to-[#FF5722] flex items-center justify-center text-white font-bold shrink-0">
                                    3
                                </div>
                                <div class="flex-1">
                                    <h3 class="font-bold text-xl text-[#17274F] mb-2">Share your Location</h3>
                                    <p class="text-[#231F20]/80 text-lg">
                                        Connect directly to Rob's live stream and share your approximate location
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div v-if="error" class="mb-6">
                            <Error class="py-5 px-3 text-center" v-text="error" />
                            <div class="flex mt-6 justify-center space-x-10">
                                <a :href="STORE_LINKS.ios" @click="hasDownloaded" target="_blank">
                                    <img src="@/assets/mobile/app-store-badge.svg" alt="App Store" class="w-[128px] sm:w-[168px] h-auto object-contain mx-auto flex justify-center">
                                </a>
                                <a :href="STORE_LINKS.android" @click="hasDownloaded" target="_blank">
                                    <img src="@/assets/mobile/google-play-badge.svg" alt="Google Play" class="w-[138px] sm:w-[178px] h-auto object-contain mx-auto flex justify-center">
                                </a>
                            </div>
                        </div>

                        <!-- Action Section -->
                        <div class="space-y-4">
                            <Button @click="handleDownload" class="w-full bg-gradient-to-r from-[#FF5722] to-[#ff7b22] hover:opacity-90 text-white h-16 text-xl font-bold shadow-lg transform hover:scale-[1.02] transition-all">
                                                        Download WeatherWise Now
                                                    </Button>
                            <p class="text-base text-center text-[#231F20]/70">
                                After downloading, return to this page or rescan the QR code to join the live event
                            </p>
                        </div>

                        <!-- QR Code Section -->
                        <Secondary class="mt-8">
                            Sharing your location requires access to Rob Mode, which requires a WeatherWise Plus subscription. Your precise location isn't shared with Rob, just an approximate location.
                        </Secondary>
                    </template>
                </Card>
            </div>
        </main>

        <!-- Footer -->
        <!-- <footer class="fixed bottom-0 left-0 right-0 bg-[#17274F]/80 backdrop-blur-sm py-4 border-t border-white/10">
        <div class="container mx-auto px-4 text-center text-sm text-white/80">
            Experience extreme weather events like never before with WeatherWise
        </div>
    </footer> -->
    </div>
</template>

<style>
html.has-scroll,
html.has-scroll body,
html.has-scroll #app {
    overflow: auto;
}
</style>