<script setup>
import { Icon } from '@iconify/vue'
import { useTimeAgo } from '@vueuse/core'
import { centroid } from '@turf/turf'

import { degreesToCardinal, tropicalStormWindSpeedToCategory, tropicalStormOceanToName, isDark } from '@/tools/helpers'

const adjustColorForDarkMode = (color) => {
  // Convert hex to RGB
  const hex = color.replace('#', '')
  const r = parseInt(hex.substr(0, 2), 16)
  const g = parseInt(hex.substr(2, 2), 16)
  const b = parseInt(hex.substr(4, 2), 16)

  // Darken the color by reducing RGB values
  const darkenFactor = 0.6 // Adjust this value to make it darker or lighter
  const newR = Math.floor(r * darkenFactor)
  const newG = Math.floor(g * darkenFactor)
  const newB = Math.floor(b * darkenFactor)

  // Convert back to hex
  return `#${newR.toString(16).padStart(2, '0')}${newG.toString(16).padStart(2, '0')}${newB.toString(16).padStart(2, '0')}`
}
</script>
<template>
  <Transition name="slide-fade">
    <div v-if="active"
      class="absolute top-4 bottom-4 right-4 w-11/12 sm:w-80 max-w-md bg-white dark:bg-ww-blue rounded-2xl shadow-2xl z-50 overflow-hidden flex flex-col mb-10 sm:mb-0">
      <div class="p-4 border-b border-gray-200 dark:border-gray-700">
        <div class="flex justify-between items-center">
          <h2 class="text-xl font-semibold text-[#17274F] dark:text-white">Current Alerts<span class="ml-2 bg-[#17274F] text-white dark:bg-ww-blue-100 font-bold px-2.5 py-0.5 rounded-full" v-text="warningsCount"></span></h2>
          <button @click="onCloseClick" class="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300">
            <Icon icon="mdi:close" class="size-6" />
          </button>
        </div>
        <div v-if="1" class="flex flex-wrap gap-2 text-sm mt-4">
          <button v-for="filter in filters" :key="filter" @click="currentFilter = filter"
            class="px-3 py-1 rounded-full font-semibold transition-colors"
            :class="currentFilter === filter ? 'bg-[#F6B91A] text-[#17274F] dark:bg-[#F6B91A] dark:text-[#17274F]' : 'bg-[#17274F] text-[#F6B91A] dark:bg-ww-blue-100 dark:hover:bg-ww-blue-200 dark:text-[#F6B91A]'">
            {{ filter }}
          </button>
        </div>
        <div v-if="false" class="mt-3">
          <select class="bg-gray-50 border border-gray-300 text-gray-900 text-sm block w-full rounded-lg p-1" v-model="warningsSettingStore.sort_by">
            <option value="ISSUANCE-A-Z">Issuance (Oldest First)</option>
            <option value="ISSUANCE-Z-A">Issuance (Newest First)</option>
            <!-- <option>Start (Oldest First)</option>
            <option>Start (Soonest First)</option>
            <option>Expiration (Oldest First)</option>
            <option>Expiration (Soonest First)</option>
            <option>Watch (Most)</option>
            <option>Watch (Least)</option>
            <option>Population (Most)</option>
            <option>Population (Least)</option> -->
          </select>
        </div>
      </div>
      <div class="p-2 flex-grow overflow-y-auto" ref="scrollable-list">
        <!-- Warnings -->
        <div v-if="showWarnings">
          <div class="group mb-4">
            <h3 class="text-lg font-semibold text-[#17274F] dark:text-white mb-2 flex">
              Warnings<span class="ml-2 text-sm mt-2 relative top-[-5px] bg-[#17274F] text-white dark:bg-ww-blue-100 font-bold px-2.5 py-0.5 rounded-full" v-text="warningsStore.filter('W').length"></span>
              <span class="ml-auto">
                <a href="#" class="inline-block mr-3" @click.prevent="openSettings()">
                  <Icon icon="material-symbols:settings-outline" class="w-6 h-6" />
                </a>
                <a href="#" class="inline-block" @click.prevent="toggleWarnings()">
                  <Icon :icon="!warningsSettingStore.visible ? 'mdi:eye-off-outline' : 'mdi:eye-outline'" class="w-6 h-6" />
                </a>
              </span>
            </h3>
            <div v-for="product in codes.warnings" :key="product">
              <div v-if="warningsStore.filter('W', product).length" class="mb-3">
                <div class="flex items-center mb-2">
                  <Icon :icon="codeToConfig(product, 'W').icon" class="w-8 h-8 mr-2"
                    :style="{ color: warningsSettingStore.config['USA'][`${product}.W`].color }" />
                  <span class="font-semibold text-[#17274F] dark:text-white">{{ codeToConfig(product, 'W').name.replace(' Warning', '') }}<span class="ml-2 text-xs mt-2 relative top-[-1px] bg-[#17274F] text-white dark:bg-ww-blue-100 font-bold px-2.5 py-0.5 rounded-full" v-text="warningsStore.filter('W', product).length"></span></span>
                  <a href="#" class="ml-auto" @click.prevent="showWarningHelp(`${product}.W`)"><Icon icon="carbon:help" class="w-6 h-6 dark:text-white" /></a>
                </div>
                <div v-for="(feature, index) in warningsStore.filter('W', product)" :key="feature.properties.id"
                  :class="{'border-2 border-red-500': feature.properties.emergency}"
                  class="bg-red-100 dark:bg-red-900/50 p-3 rounded-lg mb-2 cursor-pointer" @click="goToWarning(feature)">
                  <div class="flex items-center mb-2">
                    <Icon v-if="feature.properties.emergency" icon="mdi:alert" class="text-red-500 mr-2 size-10" />
                    <div class="font-bold text-red-700 dark:text-red-300 feature-title">{{ featureTitle(feature) }}</div>
                  </div>

                  <AlertTags :feature="feature" />

                  <div class="text-sm text-red-900 dark:text-red-200 mt-1" :title="feature.properties.expires_at">Expires {{ useTimeAgo(feature.properties.expires_at) }}</div>
                </div>
              </div>
            </div>
          </div>
          <hr class="border-t border-gray-200 mb-4" />
        </div>


        <div v-if="false">
          <!-- Warnings Example -->
          <div class="group mb-4">
            <h3 class="text-lg font-semibold text-[#17274F] dark:text-white mb-2 flex">
              Warnings (1)
              <a href="#" class="ml-auto">
                <Icon icon="mdi:eye-outline" class="w-6 h-6" />
              </a>
            </h3>
            <div class="mb-3">
              <div class="flex items-center mb-2">
                <Icon icon="mdi:weather-tornado" class="w-8 h-8 mr-2" style="color: #FF0000" />
                <span class="font-semibold text-[#17274F] dark:text-white">Tornado (1)</span>
                <a href="#" class="ml-auto"><Icon icon="carbon:help" class="w-6 h-6 dark:text-white" /></a>
              </div>
              <div class="bg-red-100 dark:bg-red-900/50 p-3 rounded-lg mb-2 cursor-pointer">
                <div class="flex items-center mb-2">
                  <Icon icon="mdi:alert" class="text-red-500 mr-2 size-6" />
                  <div class="font-bold text-red-700 dark:text-red-300">Tornado Warning</div>
                </div>
                <div class="text-sm text-red-900 dark:text-red-200 mt-1">Expires in 30 minutes</div>
              </div>
            </div>
          </div>
        </div>

        <!-- Watches -->
        <div v-if="showWatches">
          <div class="group mb-4">
            <h3 class="text-lg font-semibold text-[#17274F] dark:text-white mb-2">Watches<span class="ml-2 text-sm mt-2 relative top-[-2px] bg-[#17274F] text-white dark:bg-ww-blue-100 font-bold px-2.5 py-0.5 rounded-full" v-text="warningsStore.filter('A').length"></span></h3>
            <div v-for="product in codes.watches" :key="product">
              <div v-if="warningsStore.filter('A', product).length" class="mb-3">
                <div class="flex items-center mb-2">
                  <Icon :icon="codeToConfig(product, 'A').icon" class="w-8 h-8 mr-2"
                    :style="{ color: warningsSettingStore.config['USA'][`${product}.A`].color }" />
                  <span class="font-semibold text-[#17274F] dark:text-white">{{ codeToConfig(product, 'A').name.replace(' Watch', '') }}<span class="ml-2 text-xs mt-2 relative top-[-1px] bg-[#17274F] text-white dark:bg-ww-blue-100 font-bold px-2.5 py-0.5 rounded-full" v-text="warningsStore.filter('A', product).length"></span></span>
                  <a href="#" class="ml-auto" @click.prevent="showWarningHelp(`${product}.A`)"><Icon icon="carbon:help" class="w-6 h-6 dark:text-white" /></a>
                </div>
                <div v-for="feature in warningsStore.filter('A', product)" :key="feature.properties.id"
                  :class="{'border-2 border-red-500': feature.properties.emergency}"
                  class="bg-orange-100 dark:bg-orange-900/30 p-3 rounded-lg mb-2 cursor-pointer" @click="goToWarning(feature)">
                  <div class="flex items-center mb-2">
                    <Icon v-if="feature.properties.emergency" icon="mdi:alert" class="text-orange-500 mr-2 size-10" />
                    <div class="font-bold text-orange-700 dark:text-orange-300 feature-title">{{ featureTitle(feature) }}</div>
                  </div>

                  <AlertTags :feature="feature" />

                  <div class="text-sm text-red-900 dark:text-red-200 mt-1" :title="feature.properties.expires_at">Expires
                    {{ useTimeAgo(feature.properties.expires_at) }}</div>
                </div>
              </div>
            </div>
          </div>
          <hr class="border-t border-gray-200 mb-4" />
        </div>

        <div v-if="false">
          <!-- Watches Example -->
          <div class="group mb-4">
            <h3 class="text-lg font-semibold text-[#17274F] dark:text-white mb-2">Watches (1)</h3>
            <div class="mb-3">
              <div class="flex items-center mb-2">
                <Icon icon="mdi:weather-lightning" class="w-8 h-8 mr-2" style="color: #F97316" />
                <span class="font-semibold text-[#17274F] dark:text-white">Severe Thunderstorm (1)</span>
                <a href="#" class="ml-auto"><Icon icon="carbon:help" class="w-6 h-6 dark:text-white" /></a>
              </div>
              <div class="bg-orange-100 dark:bg-orange-900/30 p-3 rounded-lg mb-2 cursor-pointer">
                <div class="font-bold text-orange-700 dark:text-orange-300">Severe Thunderstorm Watch</div>
                <div class="text-sm text-red-900 dark:text-red-200 mt-1">Expires in 6 hours</div>
              </div>
            </div>
          </div>
        </div>

        <!-- Watches -->
        <div v-if="showAdvisories">
          <div class="group mb-4">
            <h3 class="text-lg font-semibold text-[#17274F] dark:text-white mb-2">Advisories<span class="ml-2 text-sm mt-2 relative top-[-2px] bg-[#17274F] text-white dark:bg-ww-blue-100 font-bold px-2.5 py-0.5 rounded-full" v-text="warningsStore.filter('Y').length"></span></h3>
            <div v-for="product in codes.advisories" :key="product">
              <div v-if="warningsStore.filter('Y', product).length" class="mb-3">
                <div class="flex items-center mb-2">
                  <Icon :icon="codeToConfig(product, 'Y').icon" class="w-8 h-8 mr-2"
                    :style="{ color: warningsSettingStore.config['USA'][`${product}.Y`].color }" />
                  <span class="font-semibold text-[#17274F] dark:text-white">{{ codeToConfig(product, 'Y').name.replace(' Advisory', '') }}<span class="ml-2 text-xs mt-2 relative top-[-1px] bg-[#17274F] text-white dark:bg-ww-blue-100 font-bold px-2.5 py-0.5 rounded-full" v-text="warningsStore.filter('Y', product).length"></span></span>
                  <a href="#" class="ml-auto" @click.prevent="showWarningHelp(`${product}.A`)"><Icon icon="carbon:help" class="w-6 h-6 dark:text-white" /></a>
                </div>
                <div v-for="feature in warningsStore.filter('Y', product)" :key="feature.properties.id"
                  :class="{'border-2 border-red-500': feature.properties.emergency}"
                  class="bg-orange-100 dark:bg-orange-900/30 p-3 rounded-lg mb-2 cursor-pointer" @click="goToWarning(feature)">
                  <div class="flex items-center mb-2">
                    <Icon v-if="feature.properties.emergency" icon="mdi:alert" class="text-orange-500 mr-2 size-10" />
                    <div class="font-bold text-orange-700 dark:text-orange-300 feature-title">{{ featureTitle(feature) }}</div>
                  </div>

                  <AlertTags :feature="feature" />

                  <div class="text-sm text-red-900 dark:text-red-200 mt-1" :title="feature.properties.expires_at">Expires
                    {{ useTimeAgo(feature.properties.expires_at) }}</div>
                </div>
              </div>
            </div>
          </div>
          <hr class="border-t border-gray-200 mb-4" />
        </div>

        <div v-if="false">
          <!-- Watches Example -->
          <div class="group mb-4">
            <h3 class="text-lg font-semibold text-[#17274F] dark:text-white mb-2">Watches (1)</h3>
            <div class="mb-3">
              <div class="flex items-center mb-2">
                <Icon icon="mdi:weather-lightning" class="w-8 h-8 mr-2" style="color: #F97316" />
                <span class="font-semibold text-[#17274F] dark:text-white">Severe Thunderstorm (1)</span>
                <a href="#" class="ml-auto"><Icon icon="carbon:help" class="w-6 h-6 dark:text-white" /></a>
              </div>
              <div class="bg-orange-100 dark:bg-orange-900/30 p-3 rounded-lg mb-2 cursor-pointer">
                <div class="font-bold text-orange-700 dark:text-orange-300">Severe Thunderstorm Watch</div>
                <div class="text-sm text-red-900 dark:text-red-200 mt-1">Expires in 6 hours</div>
              </div>
            </div>
          </div>
        </div>

        <!-- Statements -->
        <div v-if="showStatements">
          <div class="group mb-4">
            <h3 class="text-lg font-semibold text-[#17274F] dark:text-white mb-2">Statements<span class="ml-2 text-sm mt-2 relative top-[-2px] bg-[#17274F] text-white dark:bg-ww-blue-100 font-bold px-2.5 py-0.5 rounded-full" v-text="warningsStore.filter('S').length"></span></h3>
            <div v-for="product in codes.statements" :key="product">
              <div v-if="warningsStore.filter('S', product).length" class="mb-3">
                <div class="flex items-center mb-2">
                  <Icon :icon="codeToConfig(product, 'S').icon" class="w-8 h-8 mr-2"
                    :style="{ color: warningsSettingStore.config['USA'][`${product}.S`].color }" />
                  <span class="font-semibold text-[#17274F] dark:text-white">{{ codeToConfig(product, 'S').name.replace(' Statement', '') }}<span class="ml-2 text-xs mt-2 relative top-[-1px] bg-[#17274F] text-white dark:bg-ww-blue-100 font-bold px-2.5 py-0.5 rounded-full" v-text="warningsStore.filter('S', product).length"></span></span>
                  <a href="#" class="ml-auto" @click.prevent="showWarningHelp(`${product}.S`)"><Icon icon="carbon:help" class="w-6 h-6 dark:text-white" /></a>
                </div>
                <div v-for="feature in warningsStore.filter('S', product)" :key="feature.properties.id"
                  class="bg-blue-50 dark:bg-blue-900/30 p-3 rounded-lg mb-2 cursor-pointer" @click="goToWarning(feature)">
                  <div class="flex items-center mb-2">
                    <Icon v-if="feature.properties.emergency" icon="mdi:alert" class="text-orange-500 mr-2 size-10" />
                    <div class="font-bold text-[#17274F] dark:text-white feature-title">{{ featureTitle(feature) }}</div>
                  </div>

                  <AlertTags :feature="feature" />

                  <div class="text-sm text-gray-600 dark:text-gray-300 mt-1" :title="feature.properties.expires_at">Expires
                    {{ useTimeAgo(feature.properties.expires_at) }}</div>
                </div>
              </div>
            </div>
          </div>
          <hr class="border-t border-gray-200 mb-4" />
        </div>

        <div v-if="false">
          <!-- Statements Example -->
          <div class="group mb-4">
            <h3 class="text-lg font-semibold text-[#17274F] dark:text-white mb-2">Statements (1)</h3>
            <div class="mb-3">
              <div class="flex items-center mb-2">
                <Icon icon="mdi:weather-cloudy-alert" class="w-8 h-8 mr-2" style="color: #3B82F6" />
                <span class="font-semibold text-[#17274F] dark:text-white">Special Weather (1)</span>
                <a href="#" class="ml-auto"><Icon icon="carbon:help" class="w-6 h-6 dark:text-white" /></a>
              </div>
              <div class="bg-blue-50 dark:bg-blue-900/30 p-3 rounded-lg mb-2 cursor-pointer">
                <div class="font-semibold text-[#17274F] dark:text-white">Special Weather Statement</div>
                <div class="text-sm text-gray-600 dark:text-gray-300 mt-1">Expires in 2 hours</div>
              </div>
            </div>
          </div>
        </div>

        <!-- Mesoscale Discussions -->
        <div v-if="showDiscussions">
          <div class="group mb-4">
            <h3 class="text-lg font-semibold text-[#17274F] dark:text-white mb-2 flex">
              Mesoscale Discussions<span class="ml-2 text-sm mt-2 relative top-[-5px] bg-[#17274F] text-white dark:bg-ww-blue-100 font-bold px-2.5 py-0.5 rounded-full" v-text="mesoscaleDiscussionsStore.geojson.features.length"></span>
              <a href="#" @click.prevent="toggleMesoscaleDiscussions()" class="ml-auto">
                <Icon :icon="!mesoscaleDiscussionsSettingsStore.visible ? 'mdi:eye-off-outline' : 'mdi:eye-outline'" class="w-6 h-6" />
              </a>
            </h3>
            <div v-for="product in codes.mesoscale_discussons" :key="product">
              <div v-if="mesoscaleDiscussionsStore.filter(product).length" class="mb-3">
                <div class="flex items-center mb-2">
                  <Icon :icon="codeToMDConfig(product).icon" class="w-8 h-8 mr-2"
                    :style="{ color: codeToMDConfig(product).color }" />
                  <span class="font-semibold text-[#17274F] dark:text-white">{{ codeToMDConfig(product).name.replace('Mesoscale ', '').replace(' Discussion', '') }}<span class="ml-2 text-xs mt-2 relative top-[-1px] bg-[#17274F] text-white dark:bg-ww-blue-100 font-bold px-2.5 py-0.5 rounded-full" v-text="mesoscaleDiscussionsStore.filter(product).length"></span></span>
                    <a href="#" class="ml-auto" @click.prevent="showMDHelp(product)"><Icon icon="carbon:help" class="w-6 h-6 dark:text-white" /></a>
                </div>
                <div v-for="feature in mesoscaleDiscussionsStore.filter(product)" :key="feature.properties.id"
                  class="bg-green-50 dark:bg-green-900/30 p-3 rounded-lg mb-2 cursor-pointer" @click="goToMesoscaleDiscussion(feature)">
                  <div class="font-semibold text-[#17274F] dark:text-white"><strong>#{{ feature.properties.number }}</strong> affects:
                    <strong>{{ feature.properties.tags.AREAS_AFFECTED.join(', ') }}</strong></div>
                  <div class="text-sm text-gray-600 dark:text-gray-300">Concerning:
                    <strong>{{ feature.properties.tags.CONCERNING.join(', ') }}</strong></div>
                  <div v-if="feature.properties.tags.WATCH_PROBABILITY" class="text-sm text-gray-600 dark:text-gray-300">Probability of Watch:
                    <strong>{{ feature.properties.tags.WATCH_PROBABILITY }}%</strong></div>
                  <div class="text-sm text-gray-600 dark:text-gray-300 mt-1" :title="feature.properties.expires_at">Expires
                    {{ useTimeAgo(feature.properties.expires_at) }}</div>
                </div>
              </div>
            </div>
          </div>
          <hr class="border-t border-gray-200 mb-4" />
        </div>

        <div v-if="false">
        <!-- Mesoscale Discussions Example -->
        <div class="group mb-4">
          <h3 class="text-lg font-semibold text-[#17274F] dark:text-white mb-2 flex">
            Mesoscale Discussions (1)
            <a href="#" class="ml-auto">
              <Icon icon="mdi:eye-outline" class="w-6 h-6" />
            </a>
          </h3>
          <div class="mb-3">
            <div class="flex items-center mb-2">
              <Icon icon="mdi:weather-cloudy-alert" class="w-8 h-8 mr-2" style="color: #22C55E" />
              <span class="font-semibold text-[#17274F] dark:text-white">Severe Weather (1)</span>
              <a href="#" class="ml-auto"><Icon icon="carbon:help" class="w-6 h-6 dark:text-white" /></a>
            </div>
            <div class="bg-green-50 dark:bg-green-900/30 p-3 rounded-lg mb-2 cursor-pointer">
              <div class="font-semibold text-[#17274F] dark:text-white"><strong>#1234</strong> affects: <strong>Central Texas</strong></div>
              <div class="text-sm text-gray-600 dark:text-gray-300">Concerning: <strong>Severe Thunderstorms</strong></div>
              <div class="text-sm text-gray-600 dark:text-gray-300">Probability of Watch: <strong>80%</strong></div>
              <div class="text-sm text-gray-600 dark:text-gray-300 mt-1">Expires in 1 hour</div>
            </div>
            </div>
          </div>
        </div>

        <!-- Tropical -->
        <div v-if="showTropical">
          <div class="group mb-4">
            <h3 class="text-lg font-semibold text-[#0F224E] dark:text-white mb-2 flex">
              Tropical<span class="ml-2 text-sm mt-2 relative top-[-5px] bg-[#17274F] text-white dark:bg-ww-blue-100 font-bold px-2.5 py-0.5 rounded-full" v-text="tropicalStore.storms.length + tropicalStore.disturbances.length"></span>
              <a href="#" @click.prevent="toggleTropicalStorms()" class="ml-auto">
                <Icon :icon="!tropicalSettingsStore.visible ? 'mdi:eye-off-outline' : 'mdi:eye-outline'" class="w-6 h-6" />
              </a>
            </h3>
            <div v-if="tropicalStore.storms.length > 0">
              <div class="mb-3">
                <div class="flex items-center mb-2">
                  <Icon icon="twemoji:cyclone" class="w-8 h-8 mr-2 text-[#0F224E]" />
                  <span class="font-semibold text-[#17274F] dark:text-white">Storms<span class="ml-2 text-xs mt-2 relative top-[-1px] bg-[#17274F] text-white dark:bg-ww-blue-100 font-bold px-2.5 py-0.5 rounded-full" v-text="tropicalStore.storms.length"></span></span>
                    <a href="#" class="ml-auto" @click.prevent="showTropicalHelp('storms')"><Icon icon="carbon:help" class="w-6 h-6 dark:text-white" /></a>
                </div>
                <div v-for="storm in tropicalStore.storms" :key="storm.id"
                  class="bg-blue-50 dark:bg-blue-900/30 p-3 rounded-lg mb-2 cursor-pointer" @click="goToTropicalStorm(storm.id)">
                  <div class="font-semibold text-[#17274F] dark:text-white"><strong>{{ storm.name }}</strong></div>
                  <div v-if="storm.classification === 'HU' && tropicalStormWindSpeedToCategory(storm.wind_speed_kt) > 0" class="text-sm text-gray-600 dark:text-gray-300">Category: <strong>{{ tropicalStormWindSpeedToCategory(storm.wind_speed_kt) }}</strong></div>
                  <div v-if="storm.wind_speed_mph" class="text-sm text-gray-600 dark:text-gray-300">Wind speed: <strong>{{ storm.wind_speed_mph.toFixed(0) }}mph</strong></div>
                  <div v-if="storm.pressure" class="text-sm text-gray-600 dark:text-gray-300">Pressure: <strong>{{ storm.pressure }}mb</strong></div>
                  <div v-if="storm.movement_direction && storm.movement_speed" class="text-sm text-gray-600 dark:text-gray-300">Movement: <strong>{{ degreesToCardinal(storm.movement_direction) }}</strong> at <strong>{{ storm.movement_speed.toFixed(0) }}mph</strong></div>
                  <div v-if="storm.ocean" class="text-sm text-gray-600 dark:text-gray-300">Ocean: <strong>{{ tropicalStormOceanToName(storm.ocean) }}</strong></div>
                  <div class="text-sm text-gray-600 dark:text-gray-300 mt-1" :title="storm.last_update_at">Last update: {{ useTimeAgo(storm.last_update_at) }}</div>
                </div>
              </div>
            </div>

            <div v-if="tropicalStore.disturbances.length > 0">
              <div class="mb-3">
                <div class="flex items-center mb-2">
                  <Icon icon="carbon:outlook-severe" class="w-8 h-8 mr-2 text-[#0F224E] dark:text-white" />
                  <span class="font-semibold text-[#17274F] dark:text-white">Disturbances<span class="ml-2 text-xs mt-2 relative top-[-1px] bg-[#17274F] text-white dark:bg-ww-blue-100 font-bold px-2.5 py-0.5 rounded-full" v-text="tropicalStore.disturbances.length"></span></span>
                    <a href="#" class="ml-auto" @click.prevent="showTropicalHelp('disturbances')"><Icon icon="carbon:help" class="w-6 h-6 dark:text-white" /></a>
                </div>
                <div v-for="disturbance in tropicalStore.disturbances"
                     class="p-3 rounded-lg mb-2 cursor-pointer"
                     :style="`background-color: ${isDark() ? adjustColorForDarkMode(disturbance.properties.fill) : disturbance.properties.fill};`"
                     @click="goToTropicalDisturbance(disturbance)">
                  <div class="text-sm text-gray-600 dark:text-white">{{ disturbance.properties.discussion.substr(0, 70) }}...</div>
                  <div class="text-sm text-gray-600 dark:text-white">Day 2: <strong>{{ disturbance.properties.day_2_percentage }} ({{ disturbance.properties.day_2_category }})</strong></div>
                  <div class="text-sm text-gray-600 dark:text-white">Day 7: <strong>{{ disturbance.properties.day_7_percentage }} ({{ disturbance.properties.day_7_category }})</strong></div>
                  <div v-if="disturbance.properties.ocean" class="text-sm text-gray-600 dark:text-white">Ocean: <strong>{{ tropicalStormOceanToName(disturbance.properties.ocean) }}</strong></div>
                  <div class="text-sm text-gray-600 dark:text-white mt-1" :title="disturbance.last_update_at">Last update: {{ useTimeAgo(disturbance.properties.last_update_at) }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Tropical Example -->
        <div v-if="false">
          <div class="group mb-4">
          <h3 class="text-lg font-semibold text-[#0F224E] dark:text-white mb-2 flex">
            Tropical (2)
            <a href="#" class="ml-auto">
              <Icon icon="mdi:eye-outline" class="w-6 h-6" />
            </a>
          </h3>
          <!-- Storm -->
          <div class="mb-3">
            <div class="flex items-center mb-2">
              <Icon icon="twemoji:cyclone" class="w-8 h-8 mr-2 text-[#0F224E]" />
              <span class="font-semibold text-[#17274F] dark:text-white">Storms (1)</span>
              <a href="#" class="ml-auto"><Icon icon="carbon:help" class="w-6 h-6 dark:text-white" /></a>
            </div>
            <div class="bg-blue-50 dark:bg-blue-900/30 p-3 rounded-lg mb-2 cursor-pointer">
              <div class="font-semibold text-[#17274F] dark:text-white"><strong>Hurricane Ian</strong></div>
              <div class="text-sm text-gray-600 dark:text-gray-300">Category: <strong>4</strong></div>
              <div class="text-sm text-gray-600 dark:text-gray-300">Wind speed: <strong>130mph</strong></div>
              <div class="text-sm text-gray-600 dark:text-gray-300">Pressure: <strong>947mb</strong></div>
              <div class="text-sm text-gray-600 dark:text-gray-300">Movement: <strong>NW</strong> at <strong>10mph</strong></div>
              <div class="text-sm text-gray-600 dark:text-gray-300">Ocean: <strong>Atlantic</strong></div>
              <div class="text-sm text-gray-600 dark:text-gray-300 mt-1">Last update: 30 minutes ago</div>
            </div>
          </div>
          <!-- Disturbance -->
          <div class="mb-3">
            <div class="flex items-center mb-2">
              <Icon icon="carbon:outlook-severe" class="w-8 h-8 mr-2 text-[#0F224E] dark:text-white" />
              <span class="font-semibold text-[#17274F] dark:text-white">Disturbances (1)</span>
              <a href="#" class="ml-auto"><Icon icon="carbon:help" class="w-6 h-6 dark:text-white" /></a>
            </div>
            <div class="p-3 rounded-lg mb-2 cursor-pointer" style="background-color: rgba(255, 235, 205, 0.5)">
              <div class="text-sm text-gray-600 dark:text-white">Tropical wave moving westward across the central Atlantic...</div>
              <div class="text-sm text-gray-600 dark:text-white">Day 2: <strong>20% (Low)</strong></div>
              <div class="text-sm text-gray-600 dark:text-white">Day 7: <strong>40% (Medium)</strong></div>
              <div class="text-sm text-gray-600 dark:text-white">Ocean: <strong>Atlantic</strong></div>
              <div class="text-sm text-gray-600 dark:text-white mt-1">Last update: 2 hours ago</div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Transition>


  <button class="absolute top-4 z-[20] right-3 w-12 h-12 rounded-full transition-all duration-300 group primary-button" @click="onOpenClick($event)" v-show="! active">
    <Icon icon="mingcute:alert-line" class="inline size-8 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 transition-colors duration-300
                                          text-[#17274F] group-hover:text-[#F6B91A]" />
    <SidebarIconWarningCount />
  </button>


</template>

<script>
import { useAppStore } from '@/stores/app'
import { useWarningsStore } from '@/stores/warnings'
import { useWarningsSettingsStore } from '@/stores/settings/warnings'
import { useMesoscaleDiscussionsStore } from '@/stores/mesoscale_discussions'
import { useMesoscaleDiscussionsSettingsStore } from '@/stores/settings/mesoscale_discussions'
import { useTropicalStore } from '@/stores/tropical'
import { useTropicalSettingsStore } from '@/stores/settings/tropical'

import { MODE_REALTIME } from '@/tools/constants'

import { warningConfig, codesForSignificance } from '@/data/nws_warning_config.js'
import nwsMDConfig from '@/data/nws_mesoscale_discussion_config.js'

import AlertTags from '@/components/Alert/AlertTags.vue'
import SidebarIconWarningCount from '@/components/Sidebar/SidebarIconWarningCount.vue'

import App from '@/logic/App'
import MapKeeper from '@/logic/MapKeeper'

export default {
  name: 'Sidebar',
  data() {
    return {
      codes: {
        // These are ordered according to their importance
        warnings: codesForSignificance('W'),
        watches: codesForSignificance('A'),
        advisories: codesForSignificance('Y'),
        statements: codesForSignificance('S'),
        mesoscale_discussons: ['MCD', 'MPD']
      },
      filters: ['All', 'Warnings', 'Watches', 'Advisories', 'Statements', 'Discussions', 'Tropical'],
      currentFilter: 'All',
    }
  },
  components: {
    AlertTags,
    SidebarIconWarningCount
  },
  computed: {
    appStore: () => useAppStore(),
    warningsStore: () => useWarningsStore(),
    warningsSettingStore: () => useWarningsSettingsStore(),
    mesoscaleDiscussionsStore: () => useMesoscaleDiscussionsStore(),
    mesoscaleDiscussionsSettingsStore: () => useMesoscaleDiscussionsSettingsStore(),
    tropicalStore: () => useTropicalStore(),
    tropicalSettingsStore: () => useTropicalSettingsStore(),
    warningsCount() {
      return this.warningsStore.features.length + this.mesoscaleDiscussionsStore.geojson.features.length + this.tropicalStore.storms.length + this.tropicalStore.disturbances.length
    },
    showWarnings() {
      return this.currentFilter === 'All' || this.currentFilter === 'Warnings';
    },
    showWatches() {
      return this.currentFilter === 'All' || this.currentFilter === 'Watches';
    },
    showAdvisories() {
      return this.currentFilter === 'All' || this.currentFilter === 'Advisories';
    },
    showStatements() {
      return this.currentFilter === 'All' || this.currentFilter === 'Statements';
    },
    showDiscussions() {
      return this.currentFilter === 'All' || this.currentFilter === 'Discussions';
    },
    showTropical() {
      return this.currentFilter === 'All' || this.currentFilter === 'Tropical';
    },
    active() {
      return this.appStore.warnings_sidebar_open;
    }
  },
  watch: {
    'appStore.warnings_sidebar_open': {
      handler(newValue) {
        if(newValue) {
          this.$nextTick(() => {
            this.$refs['scrollable-list'].scrollTop = this.appStore.warnings_sidebar_scroll_top;
          });
        }
        else {
          this.appStore.warnings_sidebar_scroll_top = this.$refs['scrollable-list'].scrollTop;
        }
      }
    }
  },
  methods: {
    openSettings() {
      App.warnings.openSettings();
    },
    toggleWarnings() {
      this.warningsSettingStore.toggleVisible();
    },
    toggleMesoscaleDiscussions() {
      this.mesoscaleDiscussionsSettingsStore.toggleVisible();
    },
    toggleTropicalStorms() {
      this.tropicalSettingsStore.toggleVisible();
    },
    setFilter(filter) {
      this.currentFilter = filter;
    },
    onOpenClick(e) {
      this.appStore.toggleWarningsSidebar(true);
    },
    onCloseClick(e) {
      this.appStore.toggleWarningsSidebar(false);
    },
    featureTitle(feature) {
      return feature.properties.tags.HAZARD || feature.properties.tags.WHAT || feature.properties.tags.HEADING || this.codeToConfig(feature.properties.product, feature.properties.significance).name;
    },
    goToWarning(feature) {
      if(feature.geometry === null) return;

      this.appStore.toggleWarningsSidebar(false);

      App.warnings.fitBounds(feature);
      setTimeout(() => {
        App.warnings.flashFeature(feature);
      }, 400);

      if(this.appStore.mode === 'RADAR' && App.warnings.getMode() === MODE_REALTIME) {
        const center = centroid(feature.geometry);

        // For some strange reason, a delay is required otherwise the map does not move properly
        // When people select a warning quickly
        setTimeout(() => {
          App.radar.turnOnClosestRadar(center)
        }, 100)
      }
    },
    showWarningHelp(type) {
      if(warningConfig[type] === undefined) {
        return alert('Unable to locate help information');
      }

      App.warnings.openWarningHelpModal(type);
    },
    goToMesoscaleDiscussion(feature) {
      this.active = false

      this.appStore.toggleWarningsSidebar(false);

      App.mesoscaleDiscussions.fitBounds(feature);
      setTimeout(() => {
        App.mesoscaleDiscussions.flashFeature(feature);
      }, 400);

      if(this.appStore.mode === 'RADAR' && App.mesoscaleDiscussions.getMode() === MODE_REALTIME) {
        const center = centroid(feature.geometry);

        // For some strange reason, a delay is required otherwise the map does not move properly
        // When people select a warning quickly
        setTimeout(() => {
          App.radar.turnOnClosestRadar(center)
        }, 100)
      }
    },
    goToTropicalStorm(stormId) {
      this.active = false

      const storm = App.tropical.getStorm(stormId)

      App.tropical.fitBoundsForForecast(storm);
    },
    goToTropicalDisturbance(feature) {
      this.active = false

      App.tropical.fitBoundsForDisturbance(feature);
    },
    showMDHelp(code) {
      if(nwsMDConfig[code] === undefined) {
        return alert('Unable to locate help information');
      }

      App.mesoscaleDiscussions.openMDHelpModal(code);
    },
    showTropicalHelp(code) {
      App.tropical.openTropicalHelpModal(code);
    },
    codeToConfig(product, significance) {
      if(warningConfig[`${product}.${significance}`] === undefined) {
        console.error(`NWS config missing for: ${product}.${significance}`);
      }

      return warningConfig[`${product}.${significance}`]
    },
    codeToMDConfig(code) {
      return nwsMDConfig[code]
    }
  }
}
</script>

<style scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from {
  transform: translateX(20px);
  opacity: 0;
}

.slide-fade-leave-to {
  display: none;
}

.open-sidebar {
  position: fixed;
  right: 0px;
  top: 10px;
  z-index: 4;
}

#sidebar {
  position: fixed;
  top: 10px;
  right: 10px;
  height: calc(100% - 40px);
  width: 350px;
  color: white;
  background: rgba(255, 255, 255, 0.28);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(12.4px);
  -webkit-backdrop-filter: blur(12.4px);
  overflow: auto;
  z-index: 4;
  user-select: none;

  .close-sidebar {
    position: absolute;
    right: 6px;
    top: 6px;
  }
}

.group {
  padding: 10px;
}

.product-group {
  margin-bottom: 10px;
}

.title {
  font-weight: 900;
  margin-bottom: 6px;
}

.product-title {
  font-weight: 700;
  font-size: 18px;
}

.feature-title {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;


  @supports (-webkit-line-clamp: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.alert {
  margin-bottom: 5px;

  .heading {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .expires {
    font-size: 12px;
  }
}

.divider {
  width: 80%;
  margin: 10px 10% 10px 10%;
}

.primary-button {
    background-color: #F6B91A;
    color: #17274F;
    transform-origin: center;
    transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.primary-button:active {
    background-color: #17274F;
    color: #F6B91A;
    box-shadow: 0 0 0 2px #17274F, 0 0 0 4px #F6B91A;
    transform: scale(0.9);
}

@media (hover: hover) and (pointer: fine) {
    .primary-button:hover {
        background-color: #17274F;
        color: #F6B91A;
    }
}
</style>

