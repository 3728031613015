<script setup lang="ts">
import { ref, onMounted, onUnmounted } from 'vue'
import { Icon } from '@iconify/vue'
import { vOnClickOutside } from '@vueuse/components'
import { useDrawingSettingsStore } from '@/stores/settings/drawing'
import { storeToRefs } from 'pinia'

const drawingStore = useDrawingSettingsStore()

const { lineColor } = storeToRefs(drawingStore)

interface ColorPickerEmits {
  (event: 'update:modelValue', value: Array<number>): void
  (event: 'onOpen'): void
}

const emit = defineEmits<ColorPickerEmits>()


// Add this utility function for RGB to HSB conversion
const rgbToHsb = (r: number, g: number, b: number) => {
  r /= 255
  g /= 255
  b /= 255

  const max = Math.max(r, g, b)
  const min = Math.min(r, g, b)
  const delta = max - min

  let h = 0
  let s = max === 0 ? 0 : delta / max
  let v = max

  if (delta !== 0) {
    switch (max) {
      case r: h = ((g - b) / delta) % 6; break
      case g: h = (b - r) / delta + 2; break
      case b: h = (r - g) / delta + 4; break
    }
    h *= 60
    if (h < 0) h += 360
  }

  return {
    h: Math.round(h),
    s: Math.round(s * 100),
    b: Math.round(v * 100)
  }
}

const isOpen = ref(false)
const rgba = ref({
  r: lineColor.value[0],
  g: lineColor.value[1],
  b: lineColor.value[2],
  a: lineColor.value[3]
})

const initialHsb = rgbToHsb(
  rgba.value.r,
  rgba.value.g,
  rgba.value.b
)

const hue = ref(initialHsb.h)
const saturation = ref(initialHsb.s)
const brightness = ref(initialHsb.b)
const isDraggingControl = ref(false)
const dropdownOpenPosition = ref<string>('bottom')
const colorPickerRef = ref<HTMLDivElement | null>(null)


const positionDropdown = () => {
  const position = colorPickerRef.value?.getBoundingClientRect()
  if (!position) return
  dropdownOpenPosition.value = position.y > 250 ? 'bottom' : 'top'
}

// Add this utility function before the component logic
const getEventCoordinates = (event: MouseEvent | TouchEvent) => {
  if ('touches' in event) {
    return {
      clientX: event.touches[0].clientX,
      clientY: event.touches[0].clientY
    }
  }
  return {
    clientX: (event as MouseEvent).clientX,
    clientY: (event as MouseEvent).clientY
  }
}

// Convert internal RGBA to CSS rgba string for display
const rgbaToCss = (rgba: { r: number, g: number, b: number, a: number }) => {
  return `rgba(${rgba.r}, ${rgba.g}, ${rgba.b}, ${rgba.a})`
}

// HSB to RGB conversion
const hsbToRgb = (h: number, s: number, b: number) => {
  h = h / 360
  s = s / 100
  b = b / 100

  let r, g, bl
  if (s === 0) {
    r = g = bl = b
  } else {
    const i = Math.floor(h * 6)
    const f = h * 6 - i
    const p = b * (1 - s)
    const q = b * (1 - f * s)
    const t = b * (1 - (1 - f) * s)

    switch (i % 6) {
      case 0: r = b; g = t; bl = p; break
      case 1: r = q; g = b; bl = p; break
      case 2: r = p; g = b; bl = t; break
      case 3: r = p; g = q; bl = b; break
      case 4: r = t; g = p; bl = b; break
      case 5: r = b; g = p; bl = q; break
      default: r = 0; g = 0; bl = 0
    }
  }

  return {
    r: Math.round(r * 255),
    g: Math.round(g * 255),
    b: Math.round(bl * 255)
  }
}

const updateFromHsb = () => {
  const rgb = hsbToRgb(hue.value, saturation.value, brightness.value)
  rgba.value = { ...rgb, a: rgba.value.a }

  // Emit the RGBA values in the original format
  emit('update:modelValue', [
    rgba.value.r,
    rgba.value.g,
    rgba.value.b,
    rgba.value.a
  ])
}

const handleHueChange = (event: MouseEvent | TouchEvent) => {
  event.preventDefault()
  event.stopPropagation()
  isDraggingControl.value = true
  const container = event.currentTarget as HTMLElement
  const rect = container.getBoundingClientRect()

  const updateHue = (e: MouseEvent | TouchEvent) => {
    const coords = getEventCoordinates(e)
    const x = Math.max(0, Math.min(rect.width, coords.clientX - rect.left))
    hue.value = Math.round((x / rect.width) * 360)
    updateFromHsb()
  }

  updateHue(event) // Handle initial touch/click

  const handleMove = (e: MouseEvent | TouchEvent) => {
    e.preventDefault()
    e.stopPropagation()
    updateHue(e)
  }

  const handleEnd = () => {
    isDraggingControl.value = false
    window.removeEventListener('mousemove', handleMove)
    window.removeEventListener('mouseup', handleEnd)
    window.removeEventListener('touchmove', handleMove)
    window.removeEventListener('touchend', handleEnd)
  }

  // Use window instead of document for better touch handling
  window.addEventListener('mousemove', handleMove)
  window.addEventListener('mouseup', handleEnd)
  window.addEventListener('touchmove', handleMove, { passive: false })
  window.addEventListener('touchend', handleEnd)
}

const handleSaturationBrightnessChange = (event: MouseEvent | TouchEvent) => {
  event.preventDefault()
  event.stopPropagation()
  isDraggingControl.value = true
  const container = event.currentTarget as HTMLElement
  const rect = container.getBoundingClientRect()

  const updateValues = (e: MouseEvent | TouchEvent) => {
    const coords = getEventCoordinates(e)
    const x = Math.max(0, Math.min(1, (coords.clientX - rect.left) / rect.width))
    const y = Math.max(0, Math.min(1, (coords.clientY - rect.top) / rect.height))

    saturation.value = Math.round(x * 100)
    brightness.value = Math.round((1 - y) * 100)
    updateFromHsb()
  }

  updateValues(event)

  const handleMove = (e: MouseEvent | TouchEvent) => {
    e.preventDefault()
    updateValues(e)
  }

  const handleEnd = () => {
    document.removeEventListener('mousemove', handleMove)
    document.removeEventListener('mouseup', handleEnd)
    document.removeEventListener('touchmove', handleMove)
    document.removeEventListener('touchend', handleEnd)
  }

  document.addEventListener('mousemove', handleMove)
  document.addEventListener('mouseup', handleEnd)
  document.addEventListener('touchmove', handleMove, { passive: false })
  document.addEventListener('touchend', handleEnd)
}

const toggleDropdown = (event: MouseEvent | TouchEvent) => {
  event.stopPropagation()
  positionDropdown()
  isOpen.value = !isOpen.value

  if (isOpen.value === true) {
    emit('onOpen')
  }
}

// Close dropdown when clicking outside
const handleClickOutside = (event: MouseEvent) => {
  if (isDraggingControl.value) return
  const target = event.target as HTMLElement
  if (!target.closest('.color-picker-container')) {
    isOpen.value = false
  }
}

onMounted(() => {
  document.addEventListener('click', handleClickOutside)
})

onUnmounted(() => {
  document.removeEventListener('click', handleClickOutside)
})
</script>

<template>
  <div ref="colorPickerRef" class="relative" @mousedown.stop v-on-click-outside="[() => isOpen = false, {ignore: []}]">
    <button
      @click="toggleDropdown"
      @touchstart.prevent="toggleDropdown"
      class="w-9 h-9 sm:h-10 sm:w-10 md:h-11 md:w-11 rounded flex items-center justify-center cursor-pointer p-0 border border-gray-200 dark:border-gray-700"
      :style="{
        backgroundColor: rgbaToCss(rgba)
      }"
      title="Color Picker"
    >
      <Icon icon="mdi:eyedropper" class="eyedropper-icon" />
    </button>

    <div
      v-show="isOpen"
      :class="`absolute ${dropdownOpenPosition}-[calc(100%+0.5rem)] left-0 bg-white dark:bg-ww-blue rounded-lg shadow-lg dark:shadow-gray-900/50 p-4 w-60 z-10`"
    >
      <div
        class="saturation-brightness-area"
        @mousedown="handleSaturationBrightnessChange"
        @touchstart="handleSaturationBrightnessChange"
        :style="{
          backgroundColor: `hsl(${hue}, 100%, 50%)`
        }"
      >
        <div class="white-gradient"></div>
        <div class="black-gradient"></div>
        <div
          class="picker-cursor"
          :style="{
            left: `${saturation}%`,
            top: `${100 - brightness}%`,
            backgroundColor: rgbaToCss(rgba)
          }"
        ></div>
      </div>

      <div
        class="hue-slider"
        @mousedown.prevent="handleHueChange"
        @touchstart.prevent="handleHueChange"
        @click.stop
      >
        <div
          class="hue-cursor"
          :style="{ left: `${(hue / 360) * 100}%` }"
        ></div>
      </div>
    </div>
  </div>
</template>

<style scoped>
/* Update styles with dark mode support */
.eyedropper-icon {
  @apply w-5 h-5 text-white opacity-0 transition-opacity duration-200;
  filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.3));
}

button:hover .eyedropper-icon {
  @apply opacity-100;
}

.saturation-brightness-area {
  @apply relative w-full h-[150px] rounded cursor-crosshair mb-4;
}

.white-gradient {
  @apply absolute inset-0 rounded;
  background: linear-gradient(to right, #fff, rgba(255,255,255,0));
}

.black-gradient {
  @apply absolute inset-0 rounded;
  background: linear-gradient(to bottom, rgba(0,0,0,0), #000);
}

.picker-cursor {
  @apply absolute w-5 h-5 border-2 border-white dark:border-gray-200 rounded-full -translate-x-1/2 -translate-y-1/2;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
}

.hue-slider {
  @apply relative w-full h-5 rounded-[6px] cursor-pointer touch-none;
  background: linear-gradient(to right,
    #ff0000 0%,
    #ffff00 17%,
    #00ff00 33%,
    #00ffff 50%,
    #0000ff 67%,
    #ff00ff 83%,
    #ff0000 100%
  );
}

.hue-cursor {
  @apply absolute w-5 h-5 bg-white dark:bg-gray-200 rounded-full -translate-x-1/2;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
}
</style>