import { useSubscriptionStore } from '@/stores/subscription'
import { useSubscription } from '@/logic/Composables/Subscription'
import MapKeeper from '@/logic/MapKeeper'

export class BaseRevenueCat {
    constructor() {
        this.subscriptionStore = useSubscriptionStore()
        this.subscription = useSubscription()
    }

    /**
     * Install RevenueCat into the Vue app
     * @param {import('vue').App} app - The Vue app instance
     */
    async install(app) {
        throw new Error('install() must be implemented by platform-specific class')
    }

    /**
     * Log in a user to RevenueCat
     * @param {string} userId - The user's unique identifier
     */
    async logIn(userId) {
        throw new Error('logIn() must be implemented by platform-specific class')
    }

    /**
     * Log out the current user from RevenueCat
     */
    async logOut() {
        throw new Error('logOut() must be implemented by platform-specific class')
    }

    /**
     * Handle customer info updates
     * @param {import('./types').RevenueCatCustomerInfo} customerInfo - The updated customer info
     */
    handleCustomerInfoUpdate(customerInfo) {
        this.subscriptionStore.setRevenueCatCustomerInfo(customerInfo)
        
        MapKeeper.onLoadFirstMap(map => {
            if (this.subscription.hasActiveSubscription() === true) {
                this.subscription.enablePlusFeatures()
            } else {
                this.subscription.disablePlusFeatures()
            }
        })
    }

    /**
     * Reset the subscription state
     */
    resetSubscriptionState() {
        this.subscriptionStore.$reset()
        this.subscription.disablePlusFeatures()
    }
} 