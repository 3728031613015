import { Purchases } from '@revenuecat/purchases-js'
import { BaseRevenueCat } from '../BaseRevenueCat'
import { useUserAccount } from '@/logic/Composables/UserAccount'
import MapKeeper from '@/logic/MapKeeper'

export class WebRevenueCat extends BaseRevenueCat {
    constructor() {
        super()
        this.purchases = null
    }

    async install(app) {
        if (useUserAccount().isAuthenticated()) {
            useUserAccount().setupUser()
        }
        else {
            MapKeeper.onLoadFirstMap(map => {
                this.subscription.disablePlusFeatures()
            })
        }
    }

    async logIn(userId) {
        if (this.purchases) {
            console.warn('RevenueCat already configured')
            return
        }

        this.purchases = Purchases.configure(import.meta.env.VITE_REVENUECAT_API_KEY, userId)
        const customerInfo = await this.purchases.getCustomerInfo()
        this.handleCustomerInfoUpdate(customerInfo)
    }

    async logOut() {
        if (this.purchases) {
            Purchases.getSharedInstance().close()
        }
        this.resetSubscriptionState()
        this.purchases = null
    }
}