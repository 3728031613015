import { defineStore } from 'pinia'

import { productGroups } from '@/data/radar_products.js'

export const useRadarSettingsStore = defineStore('settings/radar', {
  state: () => ({
    storm_tracks: 1,
    lightning: 1,
    melting_layer: 0,
    max_scans: 15,
    playback_speed_ms: 150,
    three_d: false,
    tower_icon_design: 'dot',
    smoothing: (() => {
      const table = {}
      productGroups.forEach(g => {
        if(typeof g.id !== 'string') return;

        table[g.id] = 0;
      })
      return table;
    })(),
    gate_filter: (() => {
      const table = {
        'REF': [-30, 90]
      };
      return table;
    })(),
    colortable: (() => {
      const table = {}
      productGroups.forEach(g => {
        if(typeof g.id !== 'string') return;

        table[g.id] = "DEFAULT";
      })
      return table;
    })(),
    colortable_pal_upload: (() => {
      const table = {}
      productGroups.forEach(g => {
        if(typeof g.id !== 'string') return;

        table[g.id] = [];
      })
      return table;
    })(),
    units: (() => {
      const table = {
        'REF': 'dbz',
        'VEL': "m/s"
      };
      return table;
    })()
  }),

  actions: {
    resetMaxScans() {
      this.max_scans = 15
    },

    resetLightning() {
      if(this.lightning > 1) {
        this.lightning = 1
      }
    },

    reset3D() {
      this.three_d = false
    }
  },

  persist: true
})
