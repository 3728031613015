import { ref } from 'vue'
import AppServer from '@/logic/AppServer'
import { useAccountStore } from '@/stores/user/account'
import { useRevenueCat } from '@/logic/RevenueCat'
import { useSubscriptionStore } from '@/stores/subscription'
import { APP_VERSION_1_3_0 } from '@/tools/constants'
import App from '@/logic/App'
import ToDesktop from '@/logic/ToDesktop'
import UserSocket from '@/logic/UserSocket'

export function isSupported() {
  return App.shouldShowFeatures(APP_VERSION_1_3_0);
}

export function useUserAccount() {
  const loading = ref(false)
  const errors = ref({})
  const success = ref(false)

  const accountStore = useAccountStore()
  const subscriptionStore = useSubscriptionStore()
  const revenueCat = useRevenueCat()

  // Helper function to handle API requests with consistent error handling and loading states
  const handleApiRequest = async (requestFn, options = {}) => {
    const { onSuccess, resetState = true } = options

    if (resetState) {
      loading.value = true
      errors.value = {}
      success.value = false
    }

    try {
      const { data, error } = await requestFn()

      if (error) {
        console.log('API request error:', error)
        errors.value = error.errors || { message: error.message || 'An unexpected error occurred' }
        return false
      }

      if (onSuccess) {
        await onSuccess(data)
      }

      success.value = true
      return true
    } catch (error) {
      console.error('handleApiRequest unexpected error:', error)
      errors.value = { message: 'An unexpected error occurred' }
      return false
    } finally {
      loading.value = false
    }
  }

  const isAuthenticated = () => {
    return accountStore.isAuthenticated
  }

  const register = async (formData) => {
    return handleApiRequest(
      () => AppServer.register({
        name: formData.name,
        email: formData.email,
        password: formData.password,
        password_confirmation: formData.password_confirmation,
        captcha_token: formData.captcha_token
      }),
      {
        onSuccess: async (data) => {
          accountStore.token = data.token
          await setupUser()
        }
      }
    )
  }

  const login = async (formData) => {
    return handleApiRequest(
      () => AppServer.login({
        email: formData.email,
        password: formData.password
      }),
      {
        onSuccess: async (data) => {
          accountStore.token = data.token
          await setupUser()
        }
      }
    )
  }

  const logout = async () => {
    const success = handleApiRequest(
      () => AppServer.logout(accountStore.token),
      {
        onSuccess: async () => {
          try {
            await revenueCat.logOut()
          } catch (error) {
            console.error('logout', error)
          }

          UserSocket.disconnect()
        }
      }
    )

    accountStore.$reset()

    if (ToDesktop.isToDesktop()) {
      window.location.reload()
    }

    return success
  }

  const setupUser = async () => {
    return handleApiRequest(
      () => AppServer.getUser(accountStore.token),
      {
        onSuccess: async (data) => {
          accountStore.attributes = {
            id: data.id,
            name: data.name,
            email: data.email
          }
          await revenueCat.logIn(data.revenuecat_id)
        }
      }
    )
  }

  const forgotPassword = async (email) => {
    return handleApiRequest(
      () => AppServer.forgotPassword({ email }),
      {
        onSuccess: () => {
          // No additional action needed on success, API will handle sending the email
        }
      }
    )
  }

  const resetPassword = async (data) => {
    return handleApiRequest(
      () => AppServer.resetPassword({
        email: data.email,
        password: data.password,
        password_confirmation: data.password_confirmation,
        token: data.token
      }),
      {
        onSuccess: (responseData) => {
          // Password reset successful, but user needs to log in manually
          // The API returns a success message rather than logging the user in directly
          return responseData
        }
      }
    )
  }

  return {
    loading,
    errors,
    success,
    register,
    login,
    logout,
    setupUser,
    forgotPassword,
    resetPassword,
    isAuthenticated
  }
}