<script setup>
import modals from '../Modals/Helper'
</script>

<template>
  <div class="aspect-video">
    <iframe
      :src="`https://hazcams.com/embed/station/${feature.properties.id}?linkout=false&hd=true`"
      class="w-full h-full"
      webkit-playsinline
      playsinline
      frameborder="0"
      allowfullscreen
      loading="lazy"
      referrerpolicy="no-referrer"
      sandbox="allow-scripts allow-same-origin allow-popups"
    />
  </div>

  <p class="text-xs sm:text-sm text-slate-600 dark:text-slate-400 px-2 pb-1 pt-1">Access to this live video stream is kindly provided by <a href="https://hazcams.com/" target="_blank" class="font-medium text-blue-600 dark:text-blue-500 hover:underline">Hazcams</a>. All content within the stream is owned and controlled by Hazcams. The stream may contain sponsored third party content not affiliated with WeatherWise.</p>
</template>

<script>
export default {
  name: 'PlaybackModal',
  props: ['feature'],
  methods: {

  }
}
</script>
