import { toRaw } from 'vue'

import { useRobWestStore } from '@/stores/settings/vendors/robwest'
import { useRadarSettingsStore } from '@/stores/settings/radar'
import { useWarningsSettingsStore } from '@/stores/settings/warnings'

import { useMapStyleChanger } from '@/logic/Composables/MapStyleChanger'

import MapStyleWestMode from '@/data/Mapbox/Styles/westmode.json'

import modals from '@/logic/Modals/Helper'

import App from '@/logic/App'
import MapKeeper from '@/logic/MapKeeper'

const useWestMode = () => {
  const robWestStore = useRobWestStore();
  const radarSettingsStore = useRadarSettingsStore();
  const warningsSettingsStore = useWarningsSettingsStore();

  const mapStyleChanger = useMapStyleChanger();

  const supportedColortables = ['REF', 'VEL', 'CC', 'SW', 'PRT'];

  const redrawRadar = () => {
    MapKeeper.forEach(m => {
      const store = m.stores['radar'];
      const renderer = m.components['radar-renderer'];

      App.radar.drawScan(m, renderer.getData(), store.activeTowerId, store.activeProductCode);
    });
  }

  const hide = () => {
    // Change map style
    mapStyleChanger.change(MapKeeper.defaultMapStyle());

    // Change radar colortables
    for(const key of supportedColortables) {
      radarSettingsStore.colortable[key] = JSON.parse(JSON.stringify(toRaw(robWestStore.radar.colortable_backup[key])))
    }

    // Redraw radar if needed
    redrawRadar();

    // Change warnings
    warningsSettingsStore.config['USA'] = JSON.parse(JSON.stringify(toRaw(robWestStore.warnings.config_backup['USA'])));
  };

  const show = () => {
    // Change map style
    mapStyleChanger.change(MapStyleWestMode);

    // Change radar colortables
    robWestStore.radar.colortable_backup = JSON.parse(JSON.stringify(toRaw(radarSettingsStore.colortable)));

    for(const key of supportedColortables) {
      radarSettingsStore.colortable[key] = 'RW1';
    }

    // Redraw radar if needed
    redrawRadar();

    // Change warnings
    robWestStore.warnings.config_backup['USA'] = JSON.parse(JSON.stringify(toRaw(warningsSettingsStore.config['USA'])));

    // TODO
    // Just for demo
    for(const key in warningsSettingsStore.config['USA']) {
      warningsSettingsStore.config['USA'][key].color = '#'+(Math.random() * 0xFFFFFF << 0).toString(16).padStart(6, '0');
    }
  };

  const toggle = async () => {

    try {
      if (robWestStore.mapLayerOn) {
        hide()
      } else {
        show()
      }
    } catch(e) {
      console.error('Failed to toggle West Mode:', e)
      throw new Error('Failed to toggle West mode visibility')
    }
  }

  const isVisible = () => robWestStore.isWestModeOn

  const showSettings = () => modals.westMode()

  return {
    show,
    hide,
    isVisible,
    showSettings
  }
}

export { useWestMode }
