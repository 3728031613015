<script setup>
import { ref } from 'vue';

import { APP_VERSION_1_2_0 } from '@/tools/constants'

import App from '@/logic/App'
import DotNavigation from './DotNavigation.vue'

const emit = defineEmits(['update:step', 'upgrade'])

const handleUpgrade = () => {
  emit('upgrade');
};
</script>

<template>
  <div class="step">
    <div class="flex-1 text-center">
      <div class="mb-6">
        <img src="../../assets/onboarding/premium-plus.png" class="object-contain w-full h-auto rounded-lg" alt="SPC Outlooks">
      </div>
      <div class="flex items-center gap-2 justify-center">
        <h2 class="text-2xl font-bold mb-2 bg-gradient-to-r from-[#F6B91A] to-[#FF5722] text-transparent bg-clip-text">
          Upgrade Your Experience
        </h2>
      </div>
      <p class="text-xl mt-2 mb-6 text-gray-700 font-medium dark:text-white/90">
        Unlock premium features with <strong>WeatherWise</strong><span class="ml-1 bg-gradient-to-r from-[#F6B91A] to-[#FF5722] rounded px-1 font-bold text-white border border-[#FF5722]">Plus</span>
      </p>

      <!-- Upgrade Button -->
      <button
        v-if="App.shouldShowFeatures(APP_VERSION_1_2_0)"
        @click="handleUpgrade"
        class="px-8 py-3 rounded-full bg-gradient-to-r from-[#F6B91A] to-[#FF5722] text-white font-bold text-lg hover:shadow-lg transition-all duration-300 hover:scale-105"
      >
        Upgrade Now
      </button>

      <!-- Features List -->
      <div class="mt-4">
        <h3 class="text-xl font-bold mb-4 text-[#FF5722] dark:text-[#F6B91A]">Other Premium Benefits:</h3>
        <ul class="space-y-3">
          <li class="flex items-center gap-3">
            <div class="w-6 h-6 rounded-full bg-gradient-to-r from-[#F6B91A] to-[#FF5722] flex items-center justify-center">
              <svg class="w-4 h-4 text-white" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
              </svg>
            </div>
            <span class="text-gray-700 font-semibold dark:text-white/90">Globe Projection: 3D Map</span>
          </li>
          <li class="flex items-center gap-3">
            <div class="w-6 h-6 rounded-full bg-gradient-to-r from-[#F6B91A] to-[#FF5722] flex items-center justify-center">
              <svg class="w-4 h-4 text-white" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
              </svg>
            </div>
            <span class="text-gray-700 font-semibold dark:text-white/90">Split View Radar Visuals</span>
          </li>
          <li class="flex items-center gap-3">
            <div class="w-6 h-6 rounded-full bg-gradient-to-r from-[#F6B91A] to-[#FF5722] flex items-center justify-center">
              <svg class="w-4 h-4 text-white" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
              </svg>
            </div>
            <span class="text-gray-700 font-semibold dark:text-white/90">Radar Smoothing</span>
          </li>
          <li class="flex items-center gap-3">
            <div class="w-6 h-6 rounded-full bg-gradient-to-r from-[#F6B91A] to-[#FF5722] flex items-center justify-center">
              <svg class="w-4 h-4 text-white" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
              </svg>
            </div>
            <span class="text-gray-700 font-semibold dark:text-white/90">Enhanced Lightning Data</span>
          </li>
        </ul>
      </div>
    </div>
    <DotNavigation :current-step="5" @update:step="$emit('update:step', $event)" />
  </div>
</template>

<style scoped>
.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
}

.illustration {
  width: 240px;
  height: 240px;
  margin-bottom: 2rem;
}

h1 {
  font-size: 1.875rem;
  font-weight: 700;
}

.video-container {
  position: relative;
  width: 100%;
  aspect-ratio: 16/9;
  background-color: black;
}

.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.5rem;
}
</style>