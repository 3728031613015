import { defineStore } from 'pinia';

interface MapLayersSettings {
  isWestModeOn: boolean;
}

const defaults: MapLayersSettings = {
  isWestModeOn: false,
  radar: {
    colortable_backup: null
  },
  warnings: {
    config_backup: {
      'USA': null
    }
  }
};

export const useRobWestStore = defineStore('settings/vendors/robwest', {
  state: (): MapLayersSettings => ({
    ...JSON.parse(JSON.stringify(defaults))
  }),

  actions: {
    toggle() {
      this.isWestModeOn = !this.isWestModeOn;
    },

    set(value: boolean) {
      this.isWestModeOn = value;
    },

    reset() {
      this.$state = JSON.parse(JSON.stringify(defaults));
    }
  },

  persist: true
});
