import mapboxgl from 'mapbox-gl'

class MapboxPopups {
  constructor(mapKeeper) {
    this.mapKeeper = mapKeeper;

    this.popups = []
  }

  create(options) {
    return new mapboxgl.Popup(options);
  }

  render(p) {
    this.mapKeeper.mapboxMaps().forEach(m => {
      console.log(m)

      p.addTo(m)
    });

    this.push(p);
  }

  push(p) {
    if(this.popups.find(po => po === p) !== undefined) return;

    this.popups.push(p)
  }

  clear() {
    this.popups.forEach(p => p.remove());
    this.popups = [];
  }
}

export default MapboxPopups;
