// overrideAPIs.js
import GeolocationWrapper from '../logic/Wrappers/GeolocationWrapper';
import WakeLockWrapper from '../logic/Wrappers/WakeLockWrapper';
import DownloadWrapper from '../logic/Wrappers/DownloadWrapper.js';
import AlertWrapper from '../logic/Wrappers/AlertWrapper.js';

export function overrideAPIs() {
  if (typeof navigator !== 'undefined') {
    if (!navigator._geolocation) {
      navigator._geolocation = GeolocationWrapper;
    }

    if (!navigator._download) {
      navigator._download = DownloadWrapper;
    }

    if (!navigator._wakeLock) {
      navigator._wakeLock = WakeLockWrapper;
    }
  }

  if (typeof window !== 'undefined') {
    if (!window._alert) {
      window._alert = AlertWrapper.alert;
    }
  }
}
