import { point, bearing, destination } from '@turf/turf'

import { stopPropagation } from '@/tools/mapbox-map'

import MapKeeper from '@/logic/MapKeeper'

export default class StormTracks {
  constructor(radar) {
    this.radar = radar;

    this.pointsSourceId = 'ww-radar-storm-tracks-points-source'
    this.pointsLayerId = 'ww-radar-storm-tracks-points-layer'

    this.forecastSourceId = 'ww-radar-storm-tracks-forecast-source'
    this.forecastLayerId = 'ww-radar-storm-tracks-forecast-layer'

    this.layers = [
      this.pointsLayerId,
      this.forecastLayerId
    ];

    // Add source/layer for points
    MapKeeper.addSource(this.pointsSourceId, {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: []
      }
    })

    MapKeeper.addLayer({
      id: this.pointsLayerId,
      type: 'circle',
      source: this.pointsSourceId,
      paint: {
        'circle-radius': 3,
        'circle-stroke-width': 1,
        'circle-color': '#FFFFFF',
        'circle-stroke-color': '#000000',
        'circle-pitch-alignment': 'map'
      },
      minzoom: 6
    })

    // const pointOnClick = stopPropagation((e) => {
    //   if(e.features.length == 0) return;
    //   console.log(e.features)
    // });
    // MapKeeper.on('click', this.pointsLayerId, pointOnClick)

    // Add source/layer for forecast
    MapKeeper.addSource(this.forecastSourceId, {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: []
      }
    })

    MapKeeper.addLayer(
      {
        id: this.forecastLayerId,
        type: 'line',
        source: this.forecastSourceId,
        layout: {
          'line-join': 'round',
          'line-cap': 'round'
        },
        paint: {
          'line-color': '#FFFFFF',
          'line-width': 2
        },
        minzoom: 6
      },
      this.pointsLayerId
    )
  }

  draw(towerId, tracks) {
    // console.log(tracks)

    this.drawPoints(towerId, tracks)

    this.drawForecast(towerId, tracks)
  }

  drawPoints(towerId, tracks) {
    const features = tracks.data.map((t) => {
      return {
        type: 'Feature',
        geometry: { type: 'Point', coordinates: t.current },
        properties: { id: `${towerId}-${t.id}` }
      }
    })

    const collection = {
      type: 'FeatureCollection',
      features: features
    }

    MapKeeper.getSource(this.pointsSourceId).setData(collection)
  }

  drawForecast(towerId, tracks) {
    let features = tracks.data.map((t) => {
      const track = JSON.parse(JSON.stringify(t));
      const coordinates = track.forecast
      coordinates.unshift(track.current)

      return {
        type: 'Feature',
        geometry: { type: 'LineString', coordinates: coordinates },
        properties: { id: `${towerId}-${track.id}` }
      }
    })

    // console.log(tracks)

    tracks.data.forEach((t) => {
      t.forecast.forEach((f, i) => {
        // if (i == 0) return
        const p = point(f)
        const length = i + 1;

        const prevPoint = (i == 0) ? t.current : t.forecast[i - 1];

        const b = bearing(prevPoint, f)
        const leftBearing = (b - 90 + 360) % 360
        const rightBearing = (b + 90) % 360

        const left = destination(p, length, leftBearing, { units: 'miles' })
        const right = destination(p, length, rightBearing, { units: 'miles' })

        const coordinates = [left.geometry.coordinates, right.geometry.coordinates]

        features.push({
          type: 'Feature',
          geometry: { type: 'LineString', coordinates: coordinates },
          properties: { id: `${towerId}-${t.id}-${i}` }
        })
      })
    })

    const collection = {
      type: 'FeatureCollection',
      features: features
    }

    MapKeeper.getSource(this.forecastSourceId).setData(collection)
  }

  clear(towerId) {
    MapKeeper.getSource(this.pointsSourceId).setData({
      type: 'FeatureCollection',
      features: []
    })
    MapKeeper.getSource(this.forecastSourceId).setData({
      type: 'FeatureCollection',
      features: []
    })
  }

  show() {
    for(const layerId of this.layers) {
      MapKeeper.setLayoutProperty(layerId, 'visibility', 'visible');
    }
  }

  hide() {
    for(const layerId of this.layers) {
      MapKeeper.setLayoutProperty(layerId, 'visibility', 'none');
    }
  }
}
