<template>
  <div class="text-gray-800 dark:text-gray-200">
    <div>
      <iframe style="width: 100%; aspect-ratio: 1.77" src="https://www.youtube.com/embed/eOsBIKfINRk?si=oY0-siz6yG51BkyT" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
    </div>

    <p class="mt-5">A collaboration among three Georgia institutions of higher education operate a new weather radar system that will enhance student learning, provide new opportunities for research, and help improve severe weather coverage in north Georgia.  Installed recently at Georgia Gwinnett College (GGC), an X-band weather radar purchased two years ago by the Georgia Institute of Technology and the University of Georgia (UGA) is now providing data for a section of north Georgia where information on severe storms such as tornados can be limited by terrain.  The radar will also be used for research into weather and severe storms and by students at the three institutions for learning about everything from physics and engineering to weather, rainfall, and the effects of changing climate on the migration patterns of birds and insects. The instrument will be one of just a handful of weather radars operated by universities in the United States.</p>

    <p class="mt-5">More details can be found here: <a href="https://gtri.gatech.edu/newsroom/weather-radar-supports-research-and-education-helps-fill-coverage-gaps" class="font-medium text-blue-600 dark:text-blue-400 hover:underline" target="_blank">https://gtri.gatech.edu/newsroom/weather-radar-supports-research-and-education-helps-fill-coverage-gaps</a></p>
  </div>
</template>