const Customer = {
    getInfo() {
        return new Promise((resolve, reject) => {
            const messageHandler = (event) => {
                try {
                    const data = JSON.parse(event.data || event.nativeEvent?.data)
                    if (data.type === 'CUSTOMER_INFO') {
                        resolve(data.customerInfo)
                        window.removeEventListener('message', messageHandler, true)
                    }
                } catch(error)  {
                    window.removeEventListener('message', messageHandler, true)
                    reject(error)
                }
            }

            window.addEventListener('message', messageHandler, true)

            window.ReactNativeWebView.postMessage(JSON.stringify({
                type: 'GET_CUSTOMER_INFO'
            }))
        })
    },

    setCustomerUUID(uuid) {
        return new Promise((resolve, reject) => {
            const messageHandler = (event) => {
                try {
                    const data = JSON.parse(event.data || event.nativeEvent?.data)
                    if (data.type === 'SET_CUSTOMER_UUID_RESPONSE') {
                        resolve(data.success)
                        window.removeEventListener('message', messageHandler, true)
                    }
                } catch(error) {
                    window.removeEventListener('message', messageHandler, true)
                    reject(error)
                }
            }

            window.addEventListener('message', messageHandler, true)

            window.ReactNativeWebView.postMessage(JSON.stringify({
                type: 'SET_CUSTOMER_UUID',
                uuid
            }))
        })
    },

    clearCustomerUUID() {
        return new Promise((resolve, reject) => {
            const messageHandler = (event) => {
                try {
                    const data = JSON.parse(event.data || event.nativeEvent?.data)
                    if (data.type === 'CLEAR_CUSTOMER_UUID_RESPONSE') {
                        resolve(data.success)
                        window.removeEventListener('message', messageHandler, true)
                    }
                } catch(error) {
                    window.removeEventListener('message', messageHandler, true)
                    reject(error)
                }
            }

            window.addEventListener('message', messageHandler, true)

            window.ReactNativeWebView.postMessage(JSON.stringify({
                type: 'CLEAR_CUSTOMER_UUID'
            }))
        })
    }
}

export default Customer