import MapKeeper from '@/logic/MapKeeper'
import { MAP_PROJECTION_MERCATOR, MAP_PROJECTION_GLOBE } from '@/tools/constants'
import { useMapSettingsStore } from '@/stores/settings/map';

export const useMapStyle = () => {
    const mapSettingsStore = useMapSettingsStore()

    // This is an exception where we avoid using the ww- prefix
    // Because this forms part of the underlying map style
    // As opposed to an additional weatherwise layer
    const satelliteTileSourceId = 'satellite-tiles'
    const satelliteTileLayerId = 'satellite-tiles'

    const hiddenSatelliteLayers = [
        'building',
        'building-outline',
    ];

    const insertBeforeLayerIdx = (style) => {
        return style.layers.findIndex(l => l.id === 'water') + 1
    };

    const sourceId = () => {
        return satelliteTileSourceId;
    }

    const defaultTiles = () => {
        mapSettingsStore.setDefaultMapStyle()
        if(MapKeeper.getLayer(satelliteTileLayerId) === undefined) return;

        MapKeeper.removeLayer(satelliteTileLayerId);
        MapKeeper.removeSource(satelliteTileLayerId);

        hiddenSatelliteLayers.forEach(layerId => MapKeeper.setLayoutProperty(layerId, 'visibility', 'visible'));
    }

    const satelliteTilesSource = () => {
        return { "type": "raster", "url": "mapbox://mapbox.satellite", "tileSize": 256 };
    }

    const satelliteTilesLayer = () => {
        return {
            id: satelliteTileLayerId,
            source: satelliteTileSourceId,
            type: "raster"
        };
    };

    const satelliteTiles = () => {
        mapSettingsStore.setSatelliteMapStyle()
        MapKeeper.addSource(satelliteTileSourceId, satelliteTilesSource());

        const style = MapKeeper.mapboxMapsFirst().getStyle();

        const layer = style.layers[insertBeforeLayerIdx(style)];

        MapKeeper.addLayer(satelliteTilesLayer(), layer.id);

        hiddenSatelliteLayers.forEach(layerId => MapKeeper.setLayoutProperty(layerId, 'visibility', 'none'));
    }

    const setProjection = (projection) => {
        const allowedProjections = [
            MAP_PROJECTION_GLOBE,
            MAP_PROJECTION_MERCATOR
        ];

        if (! allowedProjections.includes(projection)) {
            return;
        }

        mapSettingsStore.projection = projection

        if(MapKeeper.maps()?.length === 1) {
            MapKeeper.setProjection(projection)
        }
    }

    return {
        defaultTiles,
        sourceId,
        insertBeforeLayerIdx,
        satelliteTilesSource,
        satelliteTilesLayer,
        setProjection,
        satelliteTiles
    }
}